<template>
  <modal
    name="add-person"
    :scrollable="false"
    width="60%"
    height="75%"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2 data-cy="add-person-modal-title">Add {{ title }}</h2>
      </div>
      <v-layout class="modal-body" wrap>
        <v-flex xs12>
          <p v-if="clientType">
            This will add a {{ clientLabel.label }} to this inspection. They
            will receive a confirmation of the date/time of the inspection, as
            well as automated reminders/follow-ups according to your automation
            settings.
          </p>
          <p v-else>
            This will add a {{ title.toLowerCase() }} to this inspection. They
            will receive a confirmation of the date/time of the inspection, as
            well as automated reminders/follow-ups according to your automation
            settings.
          </p>
        </v-flex>

        <v-flex xs12 class="searches-container">
          <client-search
            v-if="clientType"
            initial-mode="form"
            @change="setPerson"
          />
          <agent-search
            v-else
            initial-mode="search"
            :capture-address="captureAgentTypeAddress"
            @change="setPerson"
          />
        </v-flex>

        <v-flex xs12 class="center button-row">
          <div class="btn unbutton btn-xlarge" @click="close">Cancel</div>
          <div class="btn btn-xlarge" @click="save">
            <div v-if="!saving" class="fa-regular fa-check-square" />
            <div v-else class="fa-solid fa-spinner fa-spin" />
            Add {{ title }}
          </div>
        </v-flex>
      </v-layout>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import axios from '../../../utils/AxiosService'
import AgentSearch from '../AgentSearch.vue'
import ClientSearch from '../ClientSearch.vue'
import { captureException } from '@/utils'

export default {
  components: {
    AgentSearch,
    ClientSearch,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['inspectionId', 'personType'],
  data() {
    return {
      person: { attributes: {} },
      saving: false,
    }
  },
  computed: {
    ...mapState('reports', ['company']),
    ...mapGetters('reports', ['clientLabel', 'personTitles']),
    captureAgentTypeAddress() {
      if (this.personType.includes('buying')) {
        return this.company.attributes.settings.capture_buyer_agent_address
      } else {
        return this.company.attributes.settings.capture_selling_agent_address
      }
    },
    clientType() {
      return this.personType.includes('buyer')
    },
    title() {
      return this.personTitles[this.personType]
    },
  },
  mounted() {
    this.$modal.show('add-person')
  },
  methods: {
    close() {
      this.$modal.hide('add-person')
      this.$emit('closed')
    },
    personParams() {
      this.person.attributes.inspection_id = this.inspectionId
      return this.person
    },
    save() {
      if (!this.saving) {
        this.saving = true
        const saveFunc = this.clientType ? this.saveClient : this.saveAgent
        let saveError
        saveFunc()
          .then((response) => {
            this.$store.dispatch('notifier/show', {
              type: 'Saved',
              msg: `${this.title} Added!`,
            })
            this.$emit('personAdded', response.data.data)
          })
          .catch((error) => {
            captureException(error)
            saveError = error
            this.$store.dispatch('notifier/show', {
              type: 'Error',
              msg: `There was an error adding your ${this.title.toLowerCase()}.`,
            })
          })
          .then((response) => {
            this.saving = false
            if (!saveError) this.close()
          })
      }
    },
    saveAgent() {
      return axios.post(`/api/v1/agents`, this.personParams())
    },
    saveClient() {
      return axios.post(`/api/v1/buyers`, this.personParams())
    },
    setPerson(person) {
      this.person = person
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  height: 100%;
}
.modal-body {
  height: inherit;
  flex-direction: column;
  flex-wrap: nowrap;

  > .flex {
    flex: 0;
  }
}

.searches-container {
  margin: 2em 0;
}

.button-row {
  margin-top: auto;
  margin-bottom: 1.5em;
}
</style>
