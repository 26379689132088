<template>
  <span v-if="valid">
    <span>{{ inclusive }}</span>
    <span v-if="!subfield">{{ field }}</span>
    <span v-if="subfield">{{ subfield }}</span>
    <span>{{ operator }}</span>
    <span>{{ value }}</span>
  </span>
</template>

<script>
import Inflect from 'i'
import formatCustomField from '../../utils/formatCustomField'
const inflect = Inflect()

export default {
  props: {
    dateFormat: {
      type: String,
      default: null,
    },
    template: {
      type: Object,
      default: () => {},
    },
    condition: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    customField() {
      const { subfield } = this.condition

      if (!subfield) {
        return {}
      }

      if (!this.template.choices) {
        return {}
      }

      return this.template.choices.find(
        (choice) => choice.id === parseInt(subfield)
      )
    },
    customFieldValue() {
      const { value } = this.condition
      const formatted = formatCustomField({
        field: this.customField,
        options: { dateFormat: this.dateFormat },
        value: value,
      })

      return `"${formatted}"`
    },
    field() {
      return `${inflect.humanize(this.template.label).toLowerCase()}`
    },
    inclusive() {
      return this.condition.inclusive ? 'having' : 'not having'
    },
    isCustomField() {
      return this.condition.field === 'custom_field'
    },
    operator() {
      const { operator } = this.condition

      // don't display equal_to to keep things a little simpler
      if (operator === 'equal_to') {
        return ''
      }

      if (!operator) {
        return ''
      }

      return inflect.humanize(operator).toLowerCase()
    },
    subfield() {
      const { subfield } = this.condition

      if (!subfield || (subfield && subfield.length === 0)) {
        return null
      }

      if (this.isCustomField) {
        return `${this.customField.name}`
      }

      return inflect.humanize(subfield).toLowerCase()
    },
    value() {
      const { value } = this.condition

      if (!this.template) {
        return `"${value}"`
      }

      if (this.isCustomField) {
        return this.customFieldValue
      }

      if (value !== true) {
        // Non-boolean condition
        const conditionChoice = this.template.choices.find(
          (x) => x[1] === value
        )
        return conditionChoice ? `"${conditionChoice[0]}"` : `"${value}"`
      } else {
        return ''
      }
    },
    valid() {
      const { field, value } = this.condition
      const valueIsPresent =
        value !== undefined && value !== null && value !== ''

      if (valueIsPresent && field) {
        return true
      }

      return false
    },
  },
}
</script>

<style></style>
