<template>
  <v-layout wrap class="stats">
    <v-flex xs12 class="bubble-row">
      <v-layout justify-center wrap>
        <v-flex v-if="displayQualityScore" xs6 sm4 md3>
          <div class="number quality">
            {{ Math.round(report.attributes.quality_score) }}
          </div>
          <div class="hidden-md-and-up label-row">Quality Score</div>
        </v-flex>
        <v-flex v-if="displayCount" xs6 sm4 md3>
          <div class="number count" :class="{ big: stats.total_items > 99 }">
            <span>{{ stats.total_items }}</span>
          </div>
          <div class="hidden-md-and-up label-row">Items Inspected</div>
        </v-flex>
        <v-flex v-if="displayLow" xs6 sm4 md3>
          <div class="number low" :class="{ big: stats.category_low > 99 }">
            <span>{{ stats.category_low }}</span>
          </div>
          <div class="hidden-md-and-up label-row">
            {{ report.attributes.settings.category_low }}
          </div>
        </v-flex>
        <v-flex v-if="displayMedium" xs6 sm4 md3>
          <div class="number med" :class="{ big: stats.category_med > 99 }">
            <span>{{ stats.category_med }}</span>
          </div>
          <div class="hidden-md-and-up label-row">
            {{ report.attributes.settings.category_med }}
          </div>
        </v-flex>
        <v-flex v-if="displayHigh" xs6 sm4 md3>
          <div class="number high" :class="{ big: stats.category_high > 99 }">
            <span>{{ stats.category_high }}</span>
          </div>
          <div class="hidden-md-and-up label-row">
            {{ report.attributes.settings.category_high }}
          </div>
        </v-flex>

        <v-flex v-if="displayReInspectionStats" xs6 sm4 md3>
          <div class="number count" :class="{ big: stats.category_good > 99 }">
            <span>{{ stats.category_good }}</span>
          </div>
          <div class="hidden-md-and-up label-row">
            {{
              report.attributes.settings.reinspect_category_good ||
              report.attributes.settings.category_good
            }}
          </div>
        </v-flex>
        <v-flex v-if="displayReInspectionStats" xs6 sm4 md3>
          <div class="number med" :class="{ big: stats.category_partial > 99 }">
            <span>{{ stats.category_partial }}</span>
          </div>
          <div class="hidden-md-and-up label-row">
            {{
              report.attributes.settings.reinspect_category_partial ||
              report.attributes.settings.category_partial
            }}
          </div>
        </v-flex>
        <v-flex v-if="displayReInspectionStats" xs6 sm4 md3>
          <div class="number high" :class="{ big: stats.category_bad > 99 }">
            <span>{{ stats.category_bad }}</span>
          </div>
          <div class="hidden-md-and-up label-row">
            {{
              report.attributes.settings.reinspect_category_bad ||
              report.attributes.settings.category_bad
            }}
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 class="label-row">
      <v-layout justify-center wrap>
        <v-flex v-if="displayQualityScore" hidden-sm-and-down md3
          >Quality Score</v-flex
        >
        <v-flex v-if="displayCount" hidden-sm-and-down md3
          >Items Inspected</v-flex
        >
        <v-flex v-if="displayLow" hidden-sm-and-down md3>{{
          report.attributes.settings.category_low
        }}</v-flex>
        <v-flex v-if="displayMedium" hidden-sm-and-down md3>{{
          report.attributes.settings.category_med
        }}</v-flex>
        <v-flex v-if="displayHigh" hidden-sm-and-down md3>{{
          report.attributes.settings.category_high
        }}</v-flex>
        <v-flex v-if="displayReInspectionStats" hidden-sm-and-down md3>{{
          report.attributes.settings.reinspect_category_good ||
          report.attributes.settings.category_good
        }}</v-flex>
        <v-flex v-if="displayReInspectionStats" hidden-sm-and-down md3>{{
          report.attributes.settings.reinspect_category_partial ||
          report.attributes.settings.category_partial
        }}</v-flex>
        <v-flex v-if="displayReInspectionStats" hidden-sm-and-down md3>{{
          report.attributes.settings.reinspect_category_bad ||
          report.attributes.settings.category_bad
        }}</v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('reports', ['company', 'report', 'reinspection']),
    displayCount() {
      return (
        this.report.attributes.settings.display_items_inspected_count &&
        !this.report.attributes.settings.report_view_id
      )
    },
    displayLow() {
      return !this.reinspection && this.report.categories === 3 // && count > 0?
    },
    displayMedium() {
      return !this.reinspection && [1, 2, 3].includes(this.report.categories) // && count > 0?
    },
    displayHigh() {
      return !this.reinspection && [2, 3].includes(this.report.categories) // && count > 0?
    },
    displayReInspectionStats() {
      return this.reinspection
    },
    displayQualityScore() {
      return (
        this.company.attributes.settings.enable_quality_score &&
        !this.reinspection &&
        this.report.attributes.quality_score
      )
    },
    stats() {
      return this.report.attributes.stats
    },
  },
}
</script>

<style lang="scss" scoped>
.stats {
  margin: 2em 0;
}

.bubble-row {
  text-align: center;
}

.number {
  color: #fff;
  font-size: 4.5em;
  font-weight: bold;
  height: 1.75em;
  width: 1.75em;
  line-height: 1.75em;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #000;
  display: inline-block;

  &.quality {
    background-color: #5c9ccf;
  }
  &.count {
    background-color: #65c635;
  }
  &.low {
    background-color: #45769c;
  }
  &.med {
    background-color: #f9890e;
  }
  &.high {
    background-color: #d53636;
  }

  &.big {
    span {
      font-size: 0.75em;
      vertical-align: top;
    }
  }
}

.label-row {
  text-align: center;
  text-transform: capitalize;
  margin: 1em 0;
  color: #555;
}
</style>
