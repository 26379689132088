<template>
  <div class="get-quotes-modal__body--thanks">
    <img
      src="@/assets/finish_get_quotes.png"
      class="get-quotes-modal__body__half get-quotes-modal__body__half--image"
    />
    <div
      class="get-quotes-modal__body__half get-quotes-modal__body__half--content"
    >
      <h1 class="get-quotes-modal__body__title">
        Congrats!
        <br />
        All the details have been sent.
      </h1>
      <div class="get-quotes-modal__body__subtitle">
        Now just sit back and wait for them to contact you.
      </div>
      <div
        class="spectora-ds__button spectora-ds__button--default"
        @click="handleClose"
      >
        Close
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleClose() {
      this.$emit('close')
    },
  },
}
</script>
