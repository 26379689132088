<template>
  <div
    :id="`obs-${observation.attributes.id}`"
    class="defect-card"
    :class="{ expanded: expanded, 'with-photos': hasPhotos }"
    :data-id="observation.attributes.id"
  >
    <v-layout wrap :class="{ expanded: expanded }">
      <v-flex
        xs12
        class="defect-header"
        :class="{
          defect: isDefect,
          'category-low': category === -1,
          'category-med': category === 0 || category === 3,
          'category-high': category === 1 || category === 4,
          'category-good': category === 2,
          'category-non-low': category !== -1,
          'category-non-high': category !== 1,
        }"
      >
        <v-tooltip top content-class="info-tooltip" class="defect-label">
          <div slot="activator">{{ label }} - {{ item.attributes.name }}</div>
          {{ item.attributes.name }}
        </v-tooltip>
        <v-tooltip v-if="isDefect" top content-class="info-tooltip">
          <div slot="activator" class="icon fa" :class="iconClass" />
          {{ iconLabel }}
        </v-tooltip>
      </v-flex>

      <v-flex xs12 class="body">
        <v-layout class="body-layout">
          <card-photos
            v-if="hasPhotos"
            :id="observation.attributes.id"
            :photos="observation.attributes.observation_photos"
          />

          <div
            class="body-inner"
            :class="{ 'extra-padding-bottom': displayEstimate && expanded }"
          >
            <v-flex xs12 class="name">
              {{ observation.attributes.name }}
            </v-flex>
            <v-flex xs12 class="subtitle small">{{
              observation.attributes.location
            }}</v-flex>
            <v-flex v-if="displayValue" xs12 class="value">
              {{ observation.attributes.value }}
              <span v-if="observation.attributes.value2">
                - {{ observation.attributes.value2 }}</span
              >
              <span v-if="observation.attributes.unit_type">
                {{ observation.attributes.unit_type }}</span
              >
            </v-flex>
            <v-flex v-if="canShow" xs12 class="text-container">
              <!-- eslint-disable vue/no-v-html -->
              <div
                ref="textBody"
                class="text show-bullets"
                :class="{ shorten: additionalData }"
                v-html="observation.attributes.text"
              />
              <!-- eslint-enable -->
            </v-flex>

            <div
              v-if="canEdit"
              class="quick-edit btn"
              :data-cy="`edit-defect-card-${index}`"
              @click="setAsEditObservation"
            >
              <div class="fa-solid fa-pencil" />
              Edit
            </div>
          </div>
        </v-layout>

        <div class="bottom-content">
          <v-flex v-if="hasMoreContent" x12>
            <button
              v-if="!expanded"
              class="btn btn-primary btn-blank btn-flat mb-2"
              @click="expand"
            >
              See More
            </button>
            <button
              v-if="expanded"
              class="btn btn-primary btn-blank btn-flat mb-2"
              @click="expand"
            >
              See Less
            </button>
          </v-flex>

          <v-flex
            v-if="
              isDefect &&
              recommendation &&
              report.attributes.settings.display_recommendations
            "
            xs12
            class="rec-container"
            @click="handleRecommendationClick(recommendation)"
          >
            <v-tooltip top content-class="info-tooltip">
              <div slot="activator" class="recommendation">
                <span class="rec-name">{{
                  recommendation.attributes.name
                }}</span>
              </div>
              {{ recommendationText(recommendation) }}
            </v-tooltip>
          </v-flex>
        </div>

        <v-tooltip
          v-if="isDefect && observation.attributes.display_estimate"
          top
          content-class="info-tooltip"
          class="estimate"
        >
          <div slot="activator" class="estimate-inner">
            {{
              estimateRange
                ? `${currencySym}${observation.attributes.estimate_min} - ${currencySym}${observation.attributes.estimate_max}`
                : currencySym + observation.attributes.estimate_min
            }}
          </div>
          Estimate provided by your inspector for informational purposes only.
        </v-tooltip>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CardPhotos from './CardPhotos.vue'

export default {
  components: {
    CardPhotos,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'observation', 'index'],
  data() {
    return {
      expanded: false,
      hasMoreContent: false,
    }
  },
  computed: {
    ...mapState('reports', [
      'accessTokenValid',
      'company',
      'loggedIn',
      'recommendations',
      'report',
      'userProfile',
    ]),
    displayEstimate() {
      return this.isDefect && this.observation.attributes.display_estimate
    },
    additionalData() {
      return (
        this.displayValue ||
        (this.observation.attributes.location || '').length > 0
      )
    },
    canEdit() {
      return (
        this.loggedIn &&
        !this.viewingRestricted &&
        ((['inspector', 'manager'].includes(this.userProfile.type) &&
          this.userProfile.attributes.company_id ===
            this.company.attributes.id) ||
          this.userProfile.type === 'admin')
      )
    },
    canShow() {
      return !this.viewingRestricted || this.accessTokenValid
    },
    category() {
      return this.observation.attributes.category
    },
    currencySym() {
      return this.company.attributes.currency_sym
    },
    displayValue() {
      return (
        this.observation.attributes.value &&
        this.observation.attributes.value !== '' &&
        !['boolean', 'signature'].includes(
          this.observation.attributes.answer_type
        )
      )
    },
    estimateRange() {
      return (
        this.observation.attributes.estimate_min !==
        this.observation.attributes.estimate_max
      )
    },
    hasPhotos() {
      return (
        this.observation.attributes.observation_photos &&
        this.observation.attributes.observation_photos.length > 0
      )
    },
    iconClass() {
      if (this.category === 1) {
        return 'fa-solid fa-warning'
      } else if (this.report.attributes.settings.categories === 3) {
        if (this.category === -1) {
          return 'fa-solid fa-wrench'
        } else if (this.category === 0) {
          return 'fa-solid fa-minus-circle'
        }
      }

      if (this.category === 2) {
        return 'fa-regular fa-thumbs-up'
      } else if (this.category === 3) {
        return 'fa-regular fa-hand'
      } else if (this.category === 4) {
        return 'fa-regular fa-thumbs-down'
      }

      return ''
    },
    iconLabel() {
      if (this.category === 1) {
        return this.report.attributes.settings.category_high
      } else if (this.report.attributes.settings.categories === 3) {
        if (this.category === -1) {
          return this.report.attributes.settings.category_low
        } else if (this.category === 0) {
          return this.report.attributes.settings.category_med
        }
      }

      if (this.category === 2) {
        return (
          this.report.attributes.settings.reinspect_category_good ||
          this.report.attributes.settings.category_good
        )
      } else if (this.category === 3) {
        return (
          this.report.attributes.settings.reinspect_category_partial ||
          this.report.attributes.settings.category_partial
        )
      } else if (this.category === 4) {
        return (
          this.report.attributes.settings.reinspect_category_bad ||
          this.report.attributes.settings.category_bad
        )
      }

      return ''
    },
    isDefect() {
      return this.observation.attributes.comment_type === 'defect'
    },
    item() {
      return this.observation.attributes.report_item
    },
    recommendation() {
      return this.recommendations.find((r) => {
        return r.attributes.id === this.observation.attributes.recommendation_id
      })
    },
    viewingRestricted() {
      return this.report.attributes.viewing_restricted === true
    },
  },
  mounted() {
    this.setHasMoreContent()
  },
  methods: {
    expand() {
      this.expanded = !this.expanded
    },
    handleRecommendationClick(recommendation) {
      document
        .getElementById(`contractor-${recommendation.id}-recommendations`)
        .scrollIntoView({ behavior: 'smooth' })
    },
    setAsEditObservation() {
      this.$store.commit('reports/setValue', {
        key: 'editObservation',
        value: this.observation,
      })
    },
    recommendationText(recommendation) {
      if (recommendation.attributes.name === 'DIY') {
        return 'Contact a handyman or DIY project'
      } else {
        const text = recommendation.attributes.text
        if (text) return text
        return `Contact a qualified ${recommendation.attributes.name}`
      }
    },
    setHasMoreContent() {
      const element = this.$refs.textBody
      this.hasMoreContent = element.offsetHeight < element.scrollHeight
    },
  },
}
</script>

<style lang="scss" scoped>
#report .body:hover .quick-edit {
  display: inline-block;
}

.defect-card {
  transition: all 0.3s linear;
  height: 30em;
  min-height: 30em;
  overflow: hidden;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  position: relative;

  &.expanded {
    height: auto;
  }
  &.with-photos {
    .body-inner {
      position: relative;
      max-height: 16.25em;
    }
    .text {
      max-height: 7em;
      min-height: 7em;
    }
  }

  > .layout {
    height: 100%;
    flex-direction: column;
    &.expanded {
      .body-inner {
        max-height: none;
      }
      .text-container:after {
        content: none;
      }
      .text {
        max-height: none;
      }
    }
  }

  .bottom-content {
    background-color: white;
    position: absolute;
    padding-top: 0.25rem;
    bottom: 0.75rem;
    left: 1rem;
    width: 100%;
    z-index: 2;
  }
}

.defect-header {
  height: 2.75em;
  max-height: 2.75em;
  min-height: 2.75em;
  display: flex;
  padding: 0 1em;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  color: #fff;

  &.category-low {
    background-color: #45769c;
  }
  &.category-med {
    background-color: #f9890e;
  }
  &.category-high {
    background-color: #d53636;
  }
  &.category-good {
    background-color: #65c635;
  }
}

.defect-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow-x: hidden;
}

.body {
  flex: 1;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.body-layout {
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;

  .extra-padding-bottom {
    padding-bottom: 3.6em;
  }

  > .flex {
    flex-basis: 0;
  }
}

.body-inner {
  padding: 1em;
  padding-bottom: 6em;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 27.25em;

  > .flex {
    flex-basis: 0;
  }
}

.subtitle {
  color: #777;
  text-transform: uppercase;
}

.name {
  color: #555;
  font-size: 1.25em;
  text-transform: uppercase;
}

.value {
  font-style: italic;
}

.text-container {
  flex: 1;
  position: relative;
  &:after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.text {
  color: #555;
  min-height: 19em;
  max-height: 19em;
  overflow: hidden;

  &.shorten {
    min-height: 16em;
    max-height: 16em;
  }
}

.rec-container {
  margin-top: auto;
}

.recommendation {
  display: inline-block;
  padding: 0.25em 0.75em;
  border-radius: 2em;
  color: rgba(0, 0, 0, 0.6);
  background-color: #e4e4e4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .rec-icon {
    height: 1.5em;
    width: 1.5em;
    display: inline-block;
    margin-right: 0.5em;
    vertical-align: middle;
  }

  .rec-name {
    font-weight: bold;
    vertical-align: middle;
  }
}

.estimate {
  position: absolute;
  color: #fff;
  background-color: #65c635;
  transform: rotate(-45deg);
  width: 11em;
  height: 6.5em;
  line-height: 1em;
  font-size: 0.9em;
  bottom: -1.75em;
  right: -4em;
  font-size: 0.9em;
  text-align: center;
  padding-top: 0.5em;
  z-index: 3;
}

@media (max-width: 960px) {
  .defect-card {
    .body-inner {
      max-height: 26em;
    }
    &.with-photos .body-inner {
      max-height: 15em;
    }
  }

  .defect-header {
    min-height: 4em;
    max-height: 4em;
  }
}
</style>
