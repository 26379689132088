export default (resource, emptyText = '') => {
  const { address, city, state, zip, country } = resource

  const addressArr = []
  if (address) addressArr.push(address)
  if (city) addressArr.push(city)
  if (state) addressArr.push(state)
  if (zip) addressArr.push(zip)
  if (country) addressArr.push(country)

  return addressArr.length > 0 ? addressArr.join(', ') : emptyText
}
