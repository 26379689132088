import * as actions from './actions'
import * as mutations from './mutations'

export default {
  namespaced: true,
  state: {
    addAgreementModal: false,
    addOns: [],
    agreements: [],
    assignedInspectors: [],
    company: {
      id: undefined,
      attributes: {
        settings: {},
      },
    },
    customFields: [],
    editFeesModal: false,
    inspection: {},
    inspectionCharges: [],
    inspectionPayments: [],
    inspectors: [],
    propertyDetailsModal: false,
    services: [],
    reload: {},
    rescheduleModal: false,
    shareDetailsModal: false,
    templates: [],
    userProfile: {
      id: undefined,
      attributes: {},
    },
  },
  actions,
  mutations,
}
