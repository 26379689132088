<template>
  <div class="recommended-contractors-recommendations-item">
    <div class="recommended-contractors-recommendations-item-info__left">
      <img
        v-if="contractor.logo_url"
        :src="contractor.logo_thumb_url"
        class="recommended-contractors-recommendations-item__logo"
      />
      <div
        v-else
        class="recommended-contractors-recommendations-item__missing-logo"
      >
        <i
          class="fa fa-building"
          style="font-size: 2.5em; color: #666"
          aria-hidden="true"
        />
      </div>
      <div class="recommended-contractors-recommendations-item-info">
        <div class="recommended-contractors-recommendations-item-info__name">
          {{ formatContractorName(contractor) }}
        </div>
        <div class="recommended-contractors-recommendations-item-info__types">
          {{ displayableContractorTypes(contractor.contractor_types) }}
        </div>
        <div class="recommended-contractors-recommendations-item-info__address">
          {{ formatAddress(contractor) }}
        </div>
        <div
          v-if="contractor.phone"
          class="recommended-contractors-recommendations-item-info__phone"
        >
          <a :href="`tel:${formattedPhone}`">
            <i class="fa fa-phone" />
            {{ formattedPhone }}
          </a>
        </div>
      </div>
      <div
        v-if="contractor.description"
        class="recommended-contractors-recommendations-item-details"
        @click="handleLearnMoreOpen"
      >
        Learn More
      </div>
    </div>
  </div>
</template>

<script>
import formatAddress from '@/utils/formatAddress'
import formatContractorName from '@/utils/formatContractorName'
import formatPhone from '@/utils/formatPhone'

export default {
  props: {
    contractor: {
      type: Object,
      default: () => ({}),
    },
    recommendationTypes: {
      type: Map,
      default: () => [],
    },
  },
  computed: {
    formattedPhone() {
      return formatPhone({
        value: this.contractor.phone,
        locale: this.contractor.country,
      })
    },
  },
  methods: {
    formatAddress,
    formatPhone,
    formatContractorName,
    displayableContractorTypes(types) {
      const names = types
        .map((type) => {
          const availableType = this.recommendationTypes.get(type)
          if (availableType) {
            return availableType.attributes.name
          }
        })
        .filter((name) => name !== undefined && name !== null)
      return names.join(', ')
    },
    handleLearnMoreOpen() {
      this.$emit('learnMoreClicked', this.contractor)
    },
  },
}
</script>

<style lang="scss">
.recommended-contractors {
  &-recommendations {
    &-item {
      display: flex;
      align-items: center;
      padding: 1.5em;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      &__missing-logo,
      &__logo {
        width: 56px;

        @media (max-width: 600px) {
          align-self: self-start;
        }
      }

      &__missing-logo {
        text-align: center;
      }

      &-info {
        margin-left: 1em;
        flex: 1;
        width: 100%;

        &__left {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 2rem;
        }

        &__name {
          font-size: 17px;
          color: #1f2937;
          font-weight: 700;
          margin-bottom: 4px;
        }

        &__types {
          color: #4b5563;
          font-weight: 500;
          font-size: 14px;
        }

        &__address {
          color: #4b5563;
          font-size: 14px;
          font-weight: 500;
        }
      }

      &-details {
        font-family: 'Inter', 'Open Sans', sans-serif;
        margin-left: auto;
        font-size: 14px;
        font-weight: 600;
        color: #3074aa;
        cursor: pointer;

        @media (min-width: 960px) {
          text-align: center;
        }
      }
    }
  }
}
</style>
