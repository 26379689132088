<template>
  <transition name="fade">
    <div
      z-index="201"
      opacity="0.64"
      :value="shouldShow"
      color="rgb(240, 240, 240)"
    >
      <div class="overlay-wrapper">
        <img
          src="@/assets/spectora_logo.png"
          class="spectora-logo"
          alt="Shows the Spectora logo"
        />
        <h1 class="overlay-title">Downloading your report 🌎</h1>
        <p class="overlay-subtitle">
          This process is automatic. We are optimizing your data for a better
          experience ({{ loadingProgress }}%).
        </p>
        <v-progress-circular
          :value="loadingProgress"
          color="primary"
          pr
          size="75"
        >
        </v-progress-circular>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    shouldShow: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loadingProgress: 0,
    timeout: null,
  }),
  mounted() {
    this.progressLoop()
  },
  destroyed() {
    clearTimeout(this.timeout)
  },
  methods: {
    progressLoop() {
      this.timeout = setTimeout(() => {
        this.loadingProgress = this.loadingProgress + 1
        if (this.loadingProgress < 100) {
          this.progressLoop()
        }
      }, 50)
    },
  },
}
</script>

<style scoped>
.spectora-logo {
  width: 64px;
  margin: 32px;
}
.overlay-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  min-height: 70vh;
}
.overlay-title {
  text-align: center;
  color: #233c4f;
  font-size: 1.25em;
}
.overlay-subtitle {
  color: rgb(35, 60, 79);
  font-size: 0.9em;
  margin-bottom: 16px;
  font-weight: 300;
}
</style>
