<template>
  <v-layout justify-center wrap class="rib">
    <v-flex xs12 md10>
      <v-layout wrap class="rib-form">
        <v-flex xs12 md6 vpx-2>
          <v-text-field v-model="rib.name" label="Report Name" />

          <p>
            Select the defects you'll be reinspecting in your reinspection
            report. When you're finished selecting your defects click
            <template v-if="hasInspection">
              'COPY REPORT TO INSPECTION' and then you'll be redirected back to
              your inspection details. It may take a minute or two for your
              reinspection report to appear.
            </template>
            <template v-else>
              'SAVE REPORT FOR INSPECTION' and then you'll be redirected to the
              'New Inspection Form' to schedule your reinspection.
            </template>
          </p>
        </v-flex>
        <v-flex xs6 md2 vpx-2> </v-flex>
        <v-flex xs12 md4 vpx-2 class="actions-column">
          <div class="number">{{ selectedObservations.length }}</div>
          <div class="light number-caption">Defects Selected</div>

          <div class="button-row">
            <div class="btn" @click="save">
              <div v-if="!saving" class="fa-regular fa-square-square" />
              <div v-else class="fa-solid fa-spinner fa-spin" />
              <template v-if="hasInspection">
                Copy Report To Inspection
              </template>
              <template v-else> Save Report for Inspection </template>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
    <div v-if="!viewingRestricted" class="close light" @click="close">
      <div class="fa-solid fa-close" />
    </div>
    <v-layout justify-center class="options-container">
      <v-flex xs12 md10 class="options-row">
        <div class="options">
          <div
            class="option"
            :class="{ active: activeFilter === 'all' }"
            @click="setActiveFilter('all')"
          >
            <div class="fa-regular fa-file-lines" />
            <span>All</span>
          </div>
          <div
            class="option"
            :class="{ active: activeFilter === 'rh' }"
            @click="setActiveFilter('rh')"
          >
            <div class="fa-solid fa-circle-minus" />
            <span
              >Recommendations &
              {{ report.attributes.settings.category_high }}</span
            >
          </div>
          <div
            class="option"
            :class="{ active: activeFilter === 'hazards' }"
            @click="setActiveFilter('hazards')"
          >
            <div class="fa-solid fa-warning" />
            <span>{{ report.attributes.settings.category_high }}</span>
          </div>
        </div>
        <div class="options">
          <v-tooltip bottom content-class="info-tooltip">
            <div slot="activator" class="option" @click="selectVisible">
              <div class="fa-solid fa-check" />
              <span>Select Visible Defects</span>
            </div>
            Select all visible defects for your reinspection. This will also
            un-select the defects not currently displayed if there are any.
          </v-tooltip>
          <a
            href="https://youtu.be/Ao1Hfjv-b3w"
            target="_blank"
            class="option option-green"
          >
            <div class="fa-solid fa-circle-question" />
            <span>Help</span>
          </a>
        </div>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../utils/AxiosService'
import config from '@/utils/config'
import { captureException } from '@/utils'

const RAILS_DOMAIN = config('RAILS_DOMAIN')

export default {
  data() {
    return {
      reportView: null,
      rib: {
        name: '',
        text: '',
      },
      saving: false,
    }
  },
  computed: {
    ...mapState('reports', [
      'activeFilter',
      'company',
      'inspection',
      'observations',
      'report',
      'reportViewObservations',
    ]),
    ...mapState('reports', {
      existingRRB: 'reportView',
    }),
    displayHazards() {
      return this.activeFilter === 'hazards'
    },
    hasInspection() {
      return !!this.$route.query.inspection_id
    },
    sampleReport() {
      return this.$route.name === 'sample-report'
    },
    selectedObservations() {
      return this.reportViewObservations.filter((o) => {
        return o.selected
      })
    },
    sortItems() {
      return [
        { text: 'Original Report Order', value: '' },
        { text: 'Severity Category', value: 'category' },
        { text: 'Recommendation', value: 'recommendation' },
      ]
    },
    summaryCategories() {
      const cats = []
      if (this.report.attributes.settings.category_high_in_summary) cats.push(1)
      if (this.report.attributes.settings.category_low_in_summary) cats.push(-1)
      if (this.report.attributes.settings.category_med_in_summary) cats.push(0)

      return cats
    },
    viewableObservations() {
      return this.observations.filter((o) => {
        return (
          o.attributes.comment_type === 'defect' &&
          (this.activeFilter === 'all' ||
            (this.displayHazards && o.attributes.category === 1) ||
            (this.activeFilter === 'summary' &&
              this.summaryCategories.includes(o.attributes.category)))
        )
      })
    },
    viewingRestricted() {
      return this.report.attributes.viewing_restricted
    },
  },
  mounted() {
    this.rib.name = [this.report.attributes.name, 'Reinspection'].join(' ')
    if (this.existingRRB) this.fillData()
  },
  methods: {
    close() {
      this.$store.commit('reports/setValue', {
        key: 'reportViewObservations',
        value: [],
      })
      this.$store.commit('reports/setValue', {
        key: 'activeFilter',
        value: 'full',
      })
      this.$store.commit('reports/setValue', { key: 'rib', value: false })
    },
    fillData() {
      const selectedObs = this.existingRRB.attributes.whitelist.map((o) => {
        o.selected = true
        return o
      })

      this.$store.commit('reports/setValue', {
        key: 'reportViewObservations',
        value: selectedObs,
      })
    },
    save() {
      if (this.saving) return
      this.saving = true

      const params = {
        name: this.rib.name,
        sort_by: this.rib.sortBy,
        header_text: this.rib.text,
        report_id: this.report.id,
        type: 'ReinspectionView',
      }

      if (this.hasInspection)
        params.inspection_id = this.$route.query.inspection_id

      params.whitelist = this.selectedObservations.map((o) => {
        const obs = {
          observation_id: o.observation_id,
          number: o.number,
        }

        return obs
      })

      axios
        .post(`/api/v1/public/reports/${this.report.id}/report_views`, {
          attributes: params,
        })
        .then((response) => {
          this.reportView = response.data.data
          this.$store.dispatch('notifier/show', {
            type: 'Saved',
            msg: 'Saved! Redirecting you to your Inspection!',
          })
          if (this.hasInspection) {
            window.location = `https://${RAILS_DOMAIN}/inspections/${this.$route.query.inspection_id}/edit`
          } else {
            window.location = `https://${RAILS_DOMAIN}/v/inspections/new?source_inspection=${this.inspection.attributes.slug}&reinspection_view=${this.reportView.id}`
          }
        })
        .catch((error) => {
          captureException(error)
          this.saving = false
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: 'There was an error starting your reinspection.',
          })
        })
    },
    selectVisible() {
      const selectedObs = this.viewableObservations.map((o) => {
        return {
          observation_id: o.id,
          number: o.number,
          selected: true,
        }
      })

      this.$store.commit('reports/setValue', {
        key: 'reportViewObservations',
        value: selectedObs,
      })
    },
    setActiveFilter(filter) {
      this.$store.commit('reports/setValue', {
        key: 'activeFilter',
        value: filter,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.rib {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 3px 7px #555;
}

.close {
  position: absolute;
  top: 1em;
  right: 2em;
  cursor: pointer;

  .fa {
    &-solid,
    &-regular {
      font-size: 1.5em;
    }
  }
}

.rib-form {
  padding: 1em 0;
}

.actions-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.options-container {
  background-color: #3b464f;
}

#report .options .option.option-green {
  background-color: #65c635;
}

.number {
  width: 100%;
  color: #5c9ccf;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  align-self: flex-end;
}

.number-caption {
  align-self: flex-start;
  text-align: center;
  width: 100%;
}

.button-row .btn {
  margin: 0;
}

@media (max-width: 960px) {
  .rib-form {
    padding-top: 4em;
  }

  .close.fa {
    &-solid,
    &-regular {
      font-size: 2em;
    }
  }
}
</style>
