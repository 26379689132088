<template>
  <modal
    id="post-publish-prompt"
    name="post-publish-prompt"
    :scrollable="false"
    width="60%"
    height="auto"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>Report Published!</h2>
      </div>
      <div class="modal-body">
        <v-layout wrap>
          <v-flex xs12>
            <p>
              The report has been sent and the finalized PDF is being built. You
              can either wait to see it in a couple minutes or return to your
              dashboard.
            </p>
          </v-flex>
          <v-flex xs12>
            <p>Another inspection complete - nice job!</p>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 class="center">
            <div
              class="btn unbutton btn-xlarge"
              data-cy="close-report-published"
              @click="close"
            >
              close
            </div>
            <a :href="railsDashboardUrl" class="save-btn btn btn-large">
              Go To Dashboard
            </a>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </modal>
</template>

<script>
import config from '@/utils/config'

const RAILS_DOMAIN = config('RAILS_DOMAIN')

export default {
  computed: {
    railsDashboardUrl() {
      return `https://${RAILS_DOMAIN}/`
    },
  },
  beforeDestroy() {
    this.close()
  },
  mounted() {
    this.$modal.show('post-publish-prompt')
  },
  methods: {
    close() {
      this.$modal.hide('post-publish-prompt')
      this.$emit('close')
    },
  },
}
</script>
