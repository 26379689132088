<template>
  <div ref="slider" />
</template>

<script>
import noUiSlider from 'nouislider'
import wNumb from 'wnumb'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['range', 'step', 'value'],
  data() {
    return {
      slider: undefined,
    }
  },
  mounted() {
    this.initSlider()
  },
  methods: {
    initSlider() {
      const vm = this
      const slider = this.$refs.slider
      noUiSlider.create(slider, {
        start: this.value,
        connect: true,
        step: this.step,
        format: wNumb({
          decimals: 0,
          encoder: function (a) {
            return Math.round(a * 100) / 100
          },
          thousand: ',',
        }),
        range: this.range,
      })
      slider.noUiSlider.on('update', function (values, handle, unenc) {
        vm.$emit(
          'input',
          values.map((v) => Number.parseInt(v.replace(',', '')))
        )
      })
      this.slider = slider
    },
  },
}
</script>
