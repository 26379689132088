import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'

export default {
  namespaced: true,
  state: {
    accessTokenValid: false,
    activeSectionId: null,
    activeFilter: 'full',
    company: {
      id: undefined,
      attributes: {
        settings: {},
      },
    },
    companyId: undefined,
    defectCounts: null,
    editLocation: false,
    editObservation: null,
    inspection: {
      id: undefined,
      attributes: {},
    },
    items: [],
    isAdmin: false,
    isFirestoreAuthenticated: false,
    loggedIn: false,
    observations: [],
    defects: [],
    defectsBySection: {},
    originalReport: null,
    recommendations: [],
    overlayPhoto: null,
    overlayPhotos: [],
    showPanoButton: false,
    currentPanoPhoto: null,
    recommendedContractors: [],
    reportContractors: [],
    report: {
      id: undefined,
      attributes: {},
    },
    reportRails: {
      id: undefined,
      attributes: {},
    },
    reportAssignments: [],
    reportView: null,
    reportViewObservations: [],
    reportViews: [],
    rib: false,
    rrb: false,
    sampleReport: null,
    sections: [],
    userProfile: {
      id: undefined,
      attributes: {},
    },
    viewer: null,
    userEmail: undefined,
    userId: undefined,
    reinspection: false,
    report_view_id: undefined,
  },
  actions,
  getters,
  mutations,
}
