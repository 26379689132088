<template>
  <i v-if="icon" :class="iconClass" />
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClass() {
      return {
        'icon color-overlay section-icon': true,
        [this.icon]: true,
        active: this.active,
      }
    },
  },
}
</script>

<style>
.section-icon {
  opacity: 0.3;
  vertical-align: middle;
  display: inline-block;
  height: 16px;
  width: 16px;
  &.active {
    opacity: 1;
  }
}
</style>
