const notifySaved = (options = {}) => {
  const { msg, store } = options
  store.dispatch('notifier/show', { type: 'Saved', msg }, { root: true })
}

const notifySuccess = function (options = {}) {
  const { msg, store } = options
  store.dispatch('notifier/show', { type: 'Success', msg }, { root: true })
}

const notifyError = function (options = {}) {
  const { msg, store } = options
  store.dispatch('notifier/show', { type: 'Error', msg }, { root: true })
}

const VueToaster = {
  install(Vue, opts = {}) {
    Vue.prototype.$toaster = {
      notifySaved: (options = {}) => {
        options.store = opts.store
        notifySaved(options)
      },
      notifySuccess: (options = {}) => {
        options.store = opts.store
        notifySuccess(options)
      },
      notifyError: (options = {}) => {
        options.store = opts.store
        notifyError(options)
      },
    }
  },
}

export default VueToaster
