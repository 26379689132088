<template>
  <div :data-id="'auto-track-' + (track && track.id)" class="track-wrapper">
    <div class="track">
      <div v-if="trackMode" class="drag-handle track-handle mt-2">
        <div class="handle"></div>
      </div>
      <div
        :class="{
          'track-line row mt-2 ml-0 pr-1 mb-0': true,
          'track-mode': trackMode,
          failed: failed,
          fulfilled: fulfilled,
          locked: locked,
        }"
      >
        <template v-if="track !== undefined">
          <div class="col s2 m1 l1 small pa-1">
            <template v-if="trackMode">
              <template v-if="invalidSmsTemplate">
                <v-tooltip top>
                  <div slot="activator" close-delay="2000">
                    <div
                      :class="{
                        'mt-1': true,
                        'green-text': active,
                        'red-text': !active,
                      }"
                    >
                      <v-switch
                        v-model="active"
                        hide-messages
                        hide-details
                        :disabled="invalidSmsTemplate"
                        class="ma-0"
                      />
                      <span class="hint by-switch text-center">
                        {{ activeText }}
                      </span>
                    </div>
                  </div>
                  To re-enable your custom text messages, go to <br />
                  Settings > Business Tools > Text /SMS <br />
                  to purchase a custom phone number.
                </v-tooltip>
              </template>
              <template v-else>
                <div
                  :class="{
                    'mt-1': true,
                    'green-text': active,
                    'red-text': !active,
                  }"
                >
                  <v-switch
                    v-model="active"
                    hide-messages
                    hide-details
                    :disabled="invalidSmsTemplate"
                    class="ma-0"
                  />
                  <span class="hint by-switch text-center">
                    {{ activeText }}
                  </span>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="col sw12">
                <div
                  v-if="!isSubRun || (isSubRun && !fulfilled)"
                  :class="{ 'mt-1': true, 'green-text': !locked }"
                >
                  <v-switch
                    v-model="notlocked"
                    hide-messages
                    hide-details
                    class="ma-0 inline-block"
                    @click.prevent.stop="
                      switchLock = !locked
                      showLockDialog = true
                    "
                  />
                  <span class="hint by-switch">
                    {{ lockedText }}
                  </span>
                </div>
              </div>
              <template v-if="!isSubRun">
                <div
                  v-if="fulfilledRunCount > 0"
                  class="col s12 mt-1 fg-success"
                >
                  <div><div class="fa-regular fa-paper-plane" /></div>
                  <div class="ml-2">
                    {{ fulfilledRunCount }}
                    {{ fulfilledRunCount === 1 ? 'Trigger' : 'Triggers' }}
                  </div>
                </div>
                <div
                  v-if="subruns.length > 0 || hasDetails"
                  class="col s12 mt-1 light pointer"
                  @click="displayTriggers = !displayTriggers"
                >
                  <em
                    class="fa-solid fa-chevron-right"
                    :class="{ rotate90: displayTriggers }"
                  />
                  &nbsp;&nbsp;View Triggers
                </div>
              </template>
            </template>
          </div>
          <div class="col s7 m4 l2 small pa-1" @click="editStep(3)">
            <div class="track-part action fill-height">
              <span :class="`pr-2 fa ${actionIcon}`" :tile="actionText" />
              <span v-if="track.attributes.name">
                {{ track.attributes.name }}
              </span>
              <span v-else>
                {{ capitalize(actionText) }}
                <span class="hidden-sm-and-up">
                  {{ offsetText.toLowerCase() }} {{ eventText }}
                </span>
              </span>
            </div>
          </div>
          <div
            class="col m2 l2 hidden-xs-only params small px-0 pb-0 pt-1"
            @click="editStep(4)"
          >
            <div
              v-for="param in selectedActionParamsWithValuesMain"
              :key="param.name"
              class="track-part param mb-1 center"
              :title="stringify(getParamValue(param.name))"
            >
              {{ getParamValue(param.name) | truncate(100) }}
            </div>
            <div
              v-if="
                !track.attributes.params ||
                Object.keys(track.attributes.params).length === 0
              "
              class="fill-height w-100 pb-1"
            >
              <div class="track-part param fill-height fill-width"></div>
            </div>
          </div>
          <div class="col m2 l2 hidden-xs-only small pa-1" @click="editStep(1)">
            <div class="track-part event fill-height">
              {{ offsetText }}
              {{ eventText }}
            </div>
          </div>
          <div
            class="col m2 l2 hidden-xs-only small conditions px-0 pb-0 pt-1"
            @click="editStep(2)"
          >
            <div
              v-for="(condition, index) in track.attributes.conditions"
              :key="condition.uuid"
            >
              <div
                class="track-part condition"
                style="display: block; width: 100%; margin-bottom: 2px"
                :style="
                  track.attributes.conditions.length > 1 &&
                  track.attributes.conditions.length === index + 1
                    ? 'margin-bottom: 4px;'
                    : ''
                "
              >
                <track-condition-text
                  :condition="condition"
                  :date-format="dateFormat"
                  :template="conditionDef(condition)"
                />
              </div>
              <div
                v-if="
                  track.attributes.conditions.length > 1 &&
                  track.attributes.conditions.length !== index + 1
                "
                class="conditions-operator"
              >
                <template
                  v-if="
                    (
                      track.attributes.conditions_operator || ''
                    ).toLowerCase() !== 'or'
                  "
                  >AND</template
                >
                <template
                  v-if="
                    (
                      track.attributes.conditions_operator || ''
                    ).toLowerCase() === 'or'
                  "
                  >OR</template
                >
              </div>
            </div>
            <div
              v-if="
                !track.attributes.conditions ||
                track.attributes.conditions.length === 0
              "
              class="mb-1 track-part condition"
            >
              on all inspections
            </div>
          </div>
          <div
            class="col l2 hidden-md-and-down params small pl-1 pr-0 pb-0 pt-1"
            @click="editStep(4)"
          >
            <div
              v-for="param in selectedActionParamsWithValuesSecondary"
              :key="param.name"
              class="track-part param mb-1"
              :title="stringify(getParamValue(param.name))"
            >
              <strong> {{ param.name }}: </strong>
              &nbsp;
              {{ getParamValue(param.name) | truncate(40) }}
            </div>
            <div
              v-if="
                !track.attributes.params ||
                Object.keys(track.attributes.params).length === 0
              "
              class="fill-height w-100 pb-1"
            >
              <div class="track-part param fill-height fill-width"></div>
            </div>
          </div>
          <div class="col s2 m1 l1 small pa-1 track-actions">
            <template v-if="!isSubRun">
              <v-flex xs12>
                <v-tooltip v-if="$route.query.debug" top>
                  <v-btn
                    slot="activator"
                    :href="
                      '/admin/admin_tools/' +
                      track.attributes.id +
                      '/action_debug'
                    "
                    target="_BLANK"
                    flat
                    outline
                    small
                    color="primary"
                  >
                    <div class="fa-solid fa-bug" />
                    &nbsp; Debug
                  </v-btn>

                  Shows a lot more information for debugging
                </v-tooltip>
              </v-flex>
              <v-flex xs12>
                <v-tooltip v-if="!embedded && !trackMode && notlocked" top>
                  <v-btn
                    slot="activator"
                    flat
                    outline
                    small
                    color="primary"
                    @click="showSendDialog = true"
                  >
                    <div class="fa-regular fa-paper-plane" />
                    &nbsp; Send
                  </v-btn>

                  Trigger this action now if conditions are met.
                </v-tooltip>
              </v-flex>
              <template v-if="trackMode">
                <v-tooltip top>
                  <div slot="activator" @click="duplicate">
                    <i class="fa-solid fa-copy" />
                  </div>
                  Create a copy of this Action.
                </v-tooltip>
                <v-tooltip v-if="!embedded" top>
                  <div slot="activator" class="px-2" @click="deleting = true">
                    <i class="fa-solid fa-trash" />
                  </div>
                  Delete this Action.
                </v-tooltip>
              </template>
            </template>
          </div>
        </template>
        <template v-else>
          <i class="fa-solid fa-bars" />
          <v-chip label color="primary" text-color="white" />
        </template>
      </div>
    </div>
    <div v-if="displayTriggers" class="details">
      <triggers :run="track" :subruns="subruns" />
    </div>
    <confirmation-dialog
      v-if="deleting"
      header-text="Delete Action"
      query-text="Really delete this?"
      @cancel="deleting = false"
      @confirm="destroy"
    />
    <confirmation-dialog
      v-if="refreshing"
      header-text="Refresh Action"
      :query-text="`Refreshing this will overwrite any changes you have made locally. This may also delete this record if the ${autotrackName} it is based on has been removed, deactivated, or if this inspection is no longer applicable.`"
      confirm-text="Refresh"
      @confirm="refresh"
      @cancel="refreshing = false"
    />
    <confirmation-dialog
      v-if="showLockDialog && localLocked === true"
      header-text="Disable Action"
      query-text="Disabling this action will prevent it from triggering."
      confirm-text="Disable"
      @cancel="
        localLocked = undefined
        showLockDialog = false
      "
      @confirm="
        showLockDialog = false
        toggleLocked()
      "
    />
    <confirmation-dialog
      v-if="showLockDialog && localLocked === false"
      header-text="Enable Action"
      query-text="Enabling this action will allow it to be triggered, even if it has triggered before. Inspection Actions typically are disabled after they have completed."
      confirm-text="Enable"
      @cancel="
        localLocked = undefined
        showLockDialog = false
      "
      @confirm="
        showLockDialog = false
        toggleLocked()
      "
    />
    <confirmation-dialog
      v-if="showSendDialog && !trackMode"
      width="50%"
      header-text="Send Now"
      confirm-text="Send"
      confirm-color="primary"
      @cancel="showSendDialog = false"
      @confirm="
        showSendDialog = false
        sendNow()
      "
    >
      <v-layout slot="confirmationBody" wrap class="send-confirmation">
        <v-flex xs12 px-2>
          <p>
            This Action will be sent with the following information.
            <br />
            If all conditions for this Action aren't met it will be prevented
            from being sent.
          </p>
        </v-flex>
        <template v-if="actionText !== 'call webhook'">
          <v-flex
            xs12
            class="confirmation-param px-2 mb-2"
            :class="{ md6: actionText === 'send email' }"
          >
            <div class="param-inner">
              <div class="left-border">
                <div class="small light">To:</div>
                {{ (getParamValue('to') || []).join(', ') }}
              </div>
            </div>
          </v-flex>
          <template v-if="actionText === 'send email'">
            <v-flex xs12 md6 class="confirmation-param px-2 mb-2">
              <div class="param-inner">
                <div class="left-border">
                  <div class="small light">From:</div>
                  {{ getParamValue('from') }}
                </div>
              </div>
            </v-flex>
            <v-flex xs12 md6 class="confirmation-param px-2 mb-2">
              <div class="param-inner">
                <div class="left-border">
                  <div class="small light">CC:</div>
                  {{
                    (getParamValue('cc') || '').length > 0
                      ? getParamValue('cc').join(', ')
                      : 'No CC'
                  }}
                </div>
              </div>
            </v-flex>
            <v-flex xs12 md6 class="confirmation-param px-2 mb-2">
              <div class="param-inner">
                <div class="left-border">
                  <div class="small light">BCC:</div>
                  {{
                    (getParamValue('bcc') || '').length > 0
                      ? getParamValue('bcc').join(', ')
                      : 'No BCC'
                  }}
                </div>
              </div>
            </v-flex>
            <v-flex xs12 class="confirmation-param px-2 mb-2">
              <div class="param-inner">
                <div class="left-border">
                  <div class="small light">Subject:</div>
                  {{
                    (getParamValue('subject') || '').length > 0
                      ? getParamValue('subject')
                      : 'No Subject'
                  }}
                </div>
              </div>
            </v-flex>
          </template>
          <v-flex xs12 class="confirmation-param px-2 mb-2">
            <div class="param-inner">
              <div class="left-border">
                <div class="small light">Body:</div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="getParamValue('body')" />
              </div>
            </div>
          </v-flex>
        </template>
        <template v-else>
          <v-flex xs12 class="confirmation-param px-2 mb-2">
            <div class="param-inner">
              <div class="left-border">
                <div class="small light">Url:</div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="getParamValue('url')" />
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="confirmation-param px-2 mb-2">
            <div class="param-inner">
              <div class="left-border">
                <div class="small light">Payload:</div>
                {{ (getParamValue('payload') || []).join(', ') }}
              </div>
            </div>
          </v-flex>
        </template>
      </v-layout>
    </confirmation-dialog>
  </div>
</template>

<script>
import Inflect from 'i'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ConfirmationDialog from './ConfirmationDialog.vue'
import dig from '../../utils/Dig'
import Triggers from './Triggers.vue'
import TrackConditionText from './TrackConditionText.vue'

const inflect = Inflect()

const stringify = (value) => {
  if (Array.isArray(value)) {
    value = `[${value.join(', ')}]`
  }
  return value.replace(/(<([^>]+)>)/gi, '')
}
const mainParams = ['to', 'url']
const secParams = {
  send_email: ['subject'],
  send_text: ['body'],
  call_webhook: ['payload'],
}

export default {
  name: 'AutoTrack',
  components: {
    ConfirmationDialog,
    Triggers,
    TrackConditionText,
  },
  filters: {
    truncate(value, limit) {
      value = stringify(value)
      if (value && value.length > limit) {
        value = value.substring(0, limit - 3) + '...'
      }
      return value
    },
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['autotrackName', 'embedded', 'companyId', 'track', 'trackGroupId'],
  data() {
    return {
      deleting: false,
      displayTriggers: false,
      localActive: undefined,
      localLocked: undefined,
      openStep: 1,
      showLockDialog: false,
      showSendDialog: false,
      refreshing: false,
    }
  },
  computed: {
    ...mapGetters('autotracksManager', ['dateFormat']),
    ...mapState('autotracksManager', [
      'actions',
      'conditions',
      'events',
      'trackMode',
      'tracks',
      'company',
      'smsTemplates',
    ]),
    action() {
      return dig(this, 'track.attributes.auto_action', '')
    },
    actionIcon() {
      switch (this.action) {
        case 'send_email':
          return 'fa-regular fa-envelope'
        case 'send_text':
          return 'fa-regular fa-comment'
        case 'call_webhook':
          return 'fa-solid fa-terminal'
      }
      return ''
    },
    actionText() {
      return inflect.humanize(this.action).toLowerCase()
    },
    active: {
      get() {
        if (this.localActive) {
          return this.localActive
        }
        return this.track.attributes.active
      },
      set(newValue) {
        this.localActive = newValue
        const newTrack = JSON.parse(JSON.stringify(this.track))
        newTrack.attributes.active = newValue
        if (this.embedded) {
          this.track.attributes.active = newValue // eslint-disable-line vue/no-mutating-props
          this.$emit('updatedRecord', newTrack)
        } else {
          this.updateRecord(newTrack)
        }
      },
    },
    activeText() {
      return this.active ? 'Enabled' : 'Disabled'
    },
    body() {
      return dig(this, 'track.attributes.params.body', '')
    },
    canCustomizeSms() {
      return this.company.attributes.can_customize_sms
    },
    details() {
      if (
        this.track &&
        this.track.attributes &&
        this.track.attributes.details
      ) {
        return this.track.attributes.details
      }
      return []
    },
    event() {
      const eventName = this.track.attributes.auto_event
      if (eventName === undefined || eventName === '') {
        return {}
      }
      return this.events.find((x) => x.name === eventName)
    },
    eventText() {
      return this.event
        ? this.event.label.toLowerCase()
        : this.track.attributes.auto_event.replace('_', ' ')
    },
    failed() {
      if (this.track && this.track.attributes) {
        return this.track.attributes.is_failed
      }
      return false
    },
    fulfilled() {
      if (this.track && this.track.attributes) {
        return this.track.attributes.is_fulfilled
      }
      return false
    },
    fulfilledRunCount() {
      return (
        (this.fulfilled ? 1 : 0) +
        this.subruns.filter((t) => {
          return t.attributes.is_fulfilled
        }).length
      )
    },
    hasDetails() {
      return this.details.length > 0 || this.hasSubruns
    },
    hasSubruns() {
      return this.subruns.length > 0
    },
    invalidSmsTemplate() {
      const isDefaultTemplate = (body) => {
        return (
          this.smsTemplates.filter((template) => {
            return template.body === body
          }).length > 0
        )
      }

      return (
        this.action === 'send_text' &&
        !this.canCustomizeSms &&
        !isDefaultTemplate(this.body)
      )
    },
    isSubRun() {
      return !!this.track.attributes.parent_run_id
    },
    locked() {
      if (this.track && this.track.attributes) {
        return this.track.attributes.is_locked
      }
      return false
    },
    lockedText() {
      return this.locked ? 'Disabled' : 'Enabled'
    },
    notlocked() {
      return !this.locked
    },
    offsetText() {
      const offset = this.track.attributes.offset
      const offsetMultiplier = this.track.attributes.offset_multiplier || 3600
      if (offset === undefined) {
        return ''
      }
      if (offset === 0) {
        return 'At time of'
      }
      let humanWord = 'hours'

      switch (offsetMultiplier) {
        case 24 * 3600 * 30:
          humanWord = 'months'
          break
        case 24 * 3600 * 7:
          humanWord = 'weeks'
          break
        case 24 * 3600:
          humanWord = 'days'
          break
        case 60:
          humanWord = 'minutes'
          break
      }
      let humanOffset = offset / offsetMultiplier
      let directionWord = 'after'
      if (humanOffset === 1 || humanOffset === -1) {
        humanWord = inflect.singularize(humanWord)
      }
      if (offset < 0) {
        directionWord = 'before'
        humanOffset *= -1
      }
      return [humanOffset, humanWord, directionWord].join(' ')
    },
    selectedActionParams() {
      const action = this.actions.find(
        (a) => a.name === this.track.attributes.auto_action
      )
      if (action === undefined) {
        return []
      }
      return action.params
    },
    selectedActionParamsWithValuesMain() {
      return this.selectedActionParams.filter((param) =>
        this.filterParams(param, 'main')
      )
    },
    selectedActionParamsWithValuesSecondary() {
      return this.selectedActionParams.filter((param) =>
        this.filterParams(param, 'secondary')
      )
    },
    siblingTracks() {
      if (!this.trackGroupId) return
      return this.tracks
        .filter((t) => t.attributes.auto_track_group_id === this.trackGroupId)
        .sort((a, b) => {
          const ap = a.attributes.priority || 0
          const bp = b.attributes.priority || 0
          return ap - bp
        })
    },
    switchLock: {
      get() {
        if (this.localLocked !== undefined) {
          return !this.localLocked
        }
        return !this.locked
      },
      set(newValue) {
        this.localLocked = newValue
      },
    },
    subruns() {
      if (this.trackMode || !this.track.id) {
        return []
      }
      return this.tracks.filter(
        (x) => x.attributes.parent_run_id === this.track.attributes.id
      )
    },
  },
  methods: {
    ...mapActions('autotracksManager', [
      'deleteRecord',
      'refreshRun',
      'updateRecord',
    ]),
    ...mapMutations('autotracksManager', ['setEditTarget', 'setWizardStep']),
    capitalize(s) {
      if (!s) {
        return s
      }
      const head = s.slice(0, 1)
      const tail = s.slice(1)
      return head.toUpperCase() + tail
    },
    conditionDef(condition) {
      return this.conditions.find((x) => x.name === condition.field) || {}
    },
    destroy() {
      const vm = this
      this.deleteRecord(this.track).then(function () {
        vm.deleting = false
      })
    },
    duplicate() {
      const dup = JSON.parse(JSON.stringify(this.track))
      delete dup.id
      delete dup.attributes.id
      dup.duplicate = true

      const priorities = this.siblingTracks
        .map((t) => {
          return t.attributes.priority || 0
        })
        .sort((a, b) => {
          return b - a
        })
      const lastPriority = priorities.length > 0 ? priorities[0] : -1
      dup.attributes.priority = lastPriority + 1

      this.setWizardStep(4)
      this.setEditTarget(dup)
    },
    editStep(step) {
      if (!this.trackMode) step = 4
      this.setWizardStep(step)
      this.setEditTarget(this.track)
    },
    filterParams(param, mode = null) {
      if (mode === 'main') {
        if (!mainParams.includes(param.name)) {
          return false
        }
      }
      if (mode === 'secondary') {
        if (!dig(secParams, this.action, []).includes(param.name)) {
          return false
        }
      }
      const value = this.getParamValue(param.name)
      if (value === undefined) {
        return false
      }
      if (value === null) {
        return false
      }
      if (value.length === 0) {
        return false
      }
      return true
    },
    getParamValue(key) {
      return dig(this.track || {}, `attributes.params.${key}`, undefined)
    },
    refresh() {
      this.refreshing = false
      this.refreshRun(this.track)
    },
    sendNow() {
      const sendTrack = JSON.parse(JSON.stringify(this.track))
      const unwantedParams = [
        'event_trigger_summary',
        'last_event',
        'runtime_date_formatted',
        'runtime_time_formatted',
        'auto_track_group_id',
      ]

      unwantedParams.forEach((k) => {
        delete sendTrack.attributes[k]
      })

      sendTrack.attributes.runtime = new Date()
      sendTrack.attributes.is_locked = false

      if (sendTrack.attributes.occurs_once || this.event.occurs_once) {
        sendTrack.attributes.is_failed = false
        sendTrack.attributes.is_fulfilled = false
      }

      this.updateRecord(sendTrack).then(() => {
        this.localLocked = undefined
        this.$store.dispatch(
          'notifier/show',
          {
            type: 'Saved',
            msg: 'This Action is scheduled to be sent! It may take a few minutes for it to show up.',
          },
          { root: true }
        )
      })
    },
    stringify,
    toggleLocked() {
      const vm = this
      const newTrack = JSON.parse(JSON.stringify(this.track))
      newTrack.attributes = { is_locked: !newTrack.attributes.is_locked }
      this.updateRecord(newTrack).then(() => {
        vm.localLocked = undefined
      })
    },
  },
}
</script>

<style scoped lang="scss">
.conditions-operator {
  text-align: center;
  font-size: 0.7em;
  color: #666;
  margin-bottom: 2px;
}

.details {
  margin: 0;
  padding: 0.5em 0;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-top-width: 0;
  color: black;
}

.track {
  display: flex;
  flex-direction: row;

  .drag-handle {
    display: flex;
    align-items: stretch;
    width: 14px;
    border: 1px solid #ccc;
    border-right: 0;
    background-color: #f8f8f8;
    flex-direction: row;
    .handle {
      display: flex;
      align-items: stretch;
      margin: 4px;
      width: 10px;
      border-left: 1px solid #888;
      border-right: 1px solid #888;
    }
  }

  .left-buttons {
    display: flex;
    align-items: flex-start;
    width: 56px;
    border: 1px solid #ccc;
    border-right: 0;
    flex-direction: column;
    padding-left: 12px;
    > div {
      cursor: pointer;
      margin-bottom: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 24px;
      i {
        line-height: 24px;
        margin-left: 10px;
        width: 14px;
      }
    }
    .hint {
      display: none;
    }
    &.show-help {
      width: 112px;
      .hint {
        display: inline-block;
        padding-left: 8px;
        &.by-switch {
          padding-left: 0;
        }
      }
    }
    .help-button {
      margin-top: auto;
    }
  }

  .track-line {
    text-align: left;
    margin: 0;
    padding-left: 0;
    padding-right: 10px;
    background-color: white;
    border: 1px solid #ccc;
    color: black;
    display: flex;
    align-items: stretch;
    flex-grow: 2;
    flex-direction: row;
    &.locked {
      background-color: #ddd;
    }
    &.failed {
      background-color: #fdd;
    }
    &.fulfilled {
      background-color: #dfd;
    }
    .col {
      display: flex;
      // align-items: center;
      justify-content: center;
      &.conditions,
      &.params {
        display: flex;
        flex-direction: column;
        .track-part {
          display: flex;
          &.center {
            align-items: center;
            justify-content: center;
          }
          &:last-child {
            flex-grow: 1;
            margin-bottom: 0;
          }
        }
      }
      &:first-child {
        flex-wrap: wrap;
        justify-content: flex-start;
        .col {
          justify-content: flex-start;
        }
      }
    }

    &.track-mode .col:first-child {
      justify-content: center;
    }
    .track-part {
      width: 100%;
      text-align: center;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #bbb;
      padding: 10px 5px;
      background-color: #f8f8f8;
      cursor: pointer;
      overflow: hidden;
      &.fill-height {
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
      &.event {
        border-left: 4px solid #5c9ccf;
      }
      &.condition {
        border-left: 4px solid #45769c;
      }
      &.action {
        border-left: 4px solid #f57c00;
      }
      &.param {
        border-left: 4px solid #388e3c;
      }
    }
  }

  .track-actions .fa {
    &-solid,
    &-regular {
      cursor: pointer;
    }
  }

  .w-100 {
    width: 100%;
  }
}

.send-confirmation {
  .param-inner {
    background-color: #f8f8f8;
    border-radius: 3px;
    border: 1px solid #bbb;
  }

  .left-border {
    height: 100%;
    border-left: 4px solid #388e3c;
    padding: 8px;
  }
}
</style>
