export default (contractor) => {
  const {
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
  } = contractor

  if (firstName) {
    if (companyName) return `${firstName} ${lastName} (${companyName})`
    return `${firstName} ${lastName}`
  }

  return `${companyName}`
}
