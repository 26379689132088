<template>
  <modal
    id="leave-review"
    name="leave-review"
    :scrollable="false"
    width="100%"
    height="100%"
    :click-to-close="false"
    @closed="close"
  >
    <v-layout wrap justify-center align-center class="modal-content">
      <v-flex xs11 sm8 md8 lg5 class="review">
        <v-layout wrap>
          <v-flex xs12 class="review-header">
            <div class="image-container">
              <img
                v-for="(img, idx) in images"
                :key="idx"
                class="circle"
                :src="img"
                :class="{ active: idx === activeImage }"
              />
            </div>
          </v-flex>

          <v-flex xs12 justify-center class="review-body">
            <v-layout wrap justify-center>
              <v-flex xs12> Let us know how {{ reviewedName }} did </v-flex>
              <v-flex xs10 md6 class="star-row">
                <div
                  :class="{
                    'fa-solid fa-star': rating >= 1,
                    'fa-regular fa-star': rating < 1,
                  }"
                  @click="rating = 1"
                />
                <div
                  :class="{
                    'fa-solid fa-star': rating >= 2,
                    'fa-regular fa-star': rating < 2,
                  }"
                  @click="rating = 2"
                />
                <div
                  :class="{
                    'fa-solid fa-star': rating >= 3,
                    'fa-regular fa-star': rating < 3,
                  }"
                  @click="rating = 3"
                />
                <div
                  :class="{
                    'fa-solid fa-star': rating >= 4,
                    'fa-regular fa-star': rating < 4,
                  }"
                  @click="rating = 4"
                />
                <div
                  :class="{
                    'fa-solid fa-star': rating >= 5,
                    'fa-regular fa-star': rating < 5,
                  }"
                  @click="rating = 5"
                />
              </v-flex>

              <v-flex v-if="!rating" xs12 center class="section">
                <span class="close" @click="close">Ask me next time</span>
              </v-flex>

              <transition name="appear-up">
                <v-flex v-if="rating" xs10>
                  <div class="copy">
                    Thanks for the feedback!
                    <br />
                    Can you tell us more?
                  </div>
                  <v-textarea
                    v-model="description"
                    solo
                    autofocus
                    placeholder="If you don't want to leave any additional feedback click 'Leave Review'"
                  />
                  <div class="button-row">
                    <div class="btn btn-large info submit" @click="save">
                      <span v-if="saving" class="saving" />
                      Leave Review
                    </div>
                    <div class="as">
                      <span>as</span>
                      <v-select
                        v-model="reviewerId"
                        attach
                        class="reviewer-select"
                        :items="reviewers"
                        item-value="attributes.id"
                        item-text="attributes.name"
                        placeholder="let us know who you are"
                      />
                    </div>
                  </div>
                </v-flex>
              </transition>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../../utils/AxiosService'
import { captureException } from '@/utils'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['reviewer'],
  data() {
    return {
      activeImage: 0,
      description: null,
      rating: null,
      reviewerId: null,
      saving: false,
    }
  },
  computed: {
    ...mapState('reports', ['company', 'inspection', 'report']),
    images() {
      const list = this.inspection.inspectors.map((i) => i.attributes.image)
      list.push(this.company.attributes.thumb_url)

      return list
    },
    reviewers() {
      const reviewers = []
      if (this.inspection.attributes.buyer)
        reviewers.push(this.inspection.attributes.buyer.data)
      if (this.inspection.attributes.buyer_2)
        reviewers.push(this.inspection.attributes.buyer_2.data)
      if (this.inspection.attributes.buying_agent)
        reviewers.push(this.inspection.attributes.buying_agent.data)
      return reviewers
    },
    reviewedName() {
      if (this.inspection.inspectors.length > 1) {
        return this.company.attributes.name
      } else {
        return this.inspection.inspectors[0].attributes.first_name
      }
    },
  },
  beforeDestroy() {
    this.close()
  },
  mounted() {
    this.reviewerId = this.reviewer.attributes.id
    if (this.inspection.inspectors.length > 1) this.pollImages()
    this.$modal.show('leave-review')
  },
  methods: {
    close() {
      this.$modal.hide('leave-review')
      this.$emit('close')
    },
    pollImages() {
      setInterval(() => {
        const nextImg = this.activeImage + 1
        if (this.images[nextImg]) {
          this.activeImage += 1
        } else {
          this.activeImage = 0
        }
      }, 10000)
    },
    save() {
      if (this.saving) return
      this.saving = true

      const reviewerType = this.reviewers.find(
        (i) => i.attributes.id === this.reviewerId
      ).type

      const params = {
        description: this.description,
        rating: this.rating,
        reviewed_from: 'Report Popup',
        inspection_id: this.inspection.id,
        company_id: this.inspection.attributes.company_id,
        reviewer_id: this.reviewerId,
        reviewer_type: reviewerType === 'agent' ? 'Agent' : 'Buyer',
      }

      axios
        .post(
          `/api/v1/public/inspections/${this.inspection.attributes.slug}/inspection_reviews`,
          { attributes: params }
        )
        .then(() => {
          this.saving = false
          this.$store.dispatch('notifier/show', {
            type: 'Saved',
            msg: 'Thanks for your feedback!!',
          })
          this.close()
        })
        .catch((error) => {
          captureException(error)
          this.saving = false
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: 'There was an error leaving your review!',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  font-size: 1.5em;
  height: 100%;
}

.review {
  text-align: center;
  background-color: #fafafa;
  border-radius: 1em;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.review-header {
  height: 3.5em;
}

.review-header .image-container {
  font-size: 2em;
  position: relative;
  bottom: 2em;
  height: 4em;
  width: 4em;
  margin: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-header .circle {
  position: absolute;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s linear;
  &.active {
    opacity: 1;
  }
}

.reviewer-select {
  display: inline-block;
  min-width: 20em;
  margin-left: 1em;
}

.reviewer-selection {
  padding: 0.5em 0;
  display: flex;
  align-items: center;
}

.reviewer-selection img {
  height: 4em;
  width: 4em;
  border-radius: 50%;
}

.reviewer-selected {
  padding-bottom: 0;
}
.reviewer-selected img {
  font-size: 0.75em;
}

.reviewer-info {
  padding-left: 1em;
}

.reviewer-info .label {
  font-size: 0.75em;
  text-transform: capitalize;
  color: #888;
}

.star-row {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  margin: 1em auto;
  background-color: #fff;
  box-shadow: 0 0 3px 2px #ccc;
}

.star-row .fa {
  &-solid,
  &-regular {
    cursor: pointer;
  }
}

.star-row .fa-regular .fa-star {
  transition: all 0.3s linear;
  color: #ddd;

  &:hover {
    color: gold;
    opacity: 0.75;
  }
}
.star-row .fa-solid .fa-star {
  color: gold;
}

.close {
  font-size: 0.75em;
  cursor: pointer;
  font-style: italic;
  color: #5c9ccf;
}

.copy {
  margin-bottom: 1em;
}

.save-btn .fa {
  vertical-align: middle;
}

.appear-up-leave-active,
.appear-up-enter-active {
  transition: 0.3s;
}

.appear-up-enter {
  opacity: 0;
  transform: translate(0, 100%);
}

.appear-up-enter-to {
  opacity: 1;
  transform: translate(0);
}

.appear-up-leave-to {
  opacity: 0;
}
</style>

<style>
#leave-review .v--modal-box.v--modal {
  background: transparent;
}
#leave-review .v-list a.v-list__tile.v-list__tile--link {
  height: auto;
  min-height: 5em;
}
#leave-review .v-input__append-inner {
  margin-top: auto;
}
</style>
