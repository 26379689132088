<template>
  <div>
    <div class="">
      <div class="row">
        <div class="col s12">
          <label class="v-label small">{{ label }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col m9 s12">
          <v-select
            v-model="localValue"
            :items="smsTemplates"
            item-text="name"
            item-value="body"
            label="Select a Message template"
            :error-messages="errorMessages"
            @input="$emit('input', localValue)"
          />
          <v-textarea id="editor-textarea" disabled :value="localValue" />
        </div>
        <div class="col m3 s12">
          Want to customize your text messages?
          <a href="/settings#text-settings" target="_blank">Click here</a>
          to get a custom phone number that will give you complete control over
          the content of your text messages.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'value', 'errorMessages'],
  data() {
    return {
      localValue: this.value,
    }
  },
  computed: {
    ...mapState('autotracksManager', ['company', 'smsTemplates']),
  },
}
</script>
