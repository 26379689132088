<template>
  <v-app
    id="report"
    :class="{ 'sample-report': isSampleReport, rrb: customView }"
  >
    <transition name="slide-down" appear>
      <v-container v-if="!viewable" fluid>
        <downloading-content-overlay
          :loading-progress="50"
          :should-show="!viewable"
        />
      </v-container>
    </transition>
    <div v-if="showPanoButton" class="pano-button" @click="openPanoView">
      <div class="fa-solid fa-cube icon" />
      <span>Open in 360 viewer</span>
    </div>
    <transition name="fade-in">
      <div v-if="viewable && report.id">
        <v-container>
          <v-layout v-if="!loadingPdf" wrap justify-center>
            <v-flex
              v-if="!customView"
              xs8
              md4
              lg2-5
              class="sidebar-container"
              :class="{
                display: displaySidebar,
                remove: displaySidebar === false,
              }"
            >
              <sidebar-menu
                v-if="viewable"
                :firestore="true"
                @change="displaySidebar = false"
                @publish="setReportPublish"
                @submit="submitReport = true"
              />
            </v-flex>
            <v-flex
              id="scrollable"
              xs12
              class="main-container"
              :class="{ 'md8 lg9-5': !customView, md12: customView }"
              @click.capture="displaySidebar = false"
            >
              <v-layout wrap justify-center class="main">
                <transition name="slide-down">
                  <v-flex v-if="rrb" xs12>
                    <r-r-b />
                  </v-flex>
                  <v-flex v-else-if="rib" xs12>
                    <r-i-b />
                  </v-flex>
                </transition>
                <template v-if="!customView">
                  <v-flex id="header" xs12>
                    <report-header
                      v-if="viewable"
                      :rails-report="railsReport"
                      @adding-agent="setupAgentModal"
                      @show-sample-report="newSampleReport = true"
                      @copy-text="copyText = true"
                    />
                    <div v-else class="report-header-placeholder">&nbsp;</div>
                  </v-flex>
                  <v-flex
                    v-if="viewable"
                    id="options-row"
                    xs12
                    class="options-row"
                    :class="{ scroll: scrollHeader }"
                  >
                    <div class="options sidebar-option">
                      <div class="option" @click="displaySidebar = true">
                        <div class="fa-solid fa-bars" />
                      </div>
                    </div>
                    <div class="options">
                      <div
                        class="option"
                        :class="{ active: displayAll }"
                        data-cy="full-report-option-button"
                        @click="setActiveFilter('full')"
                      >
                        <div class="fa-regular fa-file-text mr-1" />
                        <span>Full Report</span>
                      </div>
                      <template v-if="viewable">
                        <template v-if="reinspection">
                          <div
                            class="option"
                            :class="{ active: activeFilter === 'resolved' }"
                            @click="setActiveFilter('resolved')"
                          >
                            <div class="fa-regular fa-thumbs-up" />
                            <span>{{
                              report.attributes.settings.reinspect_category_good
                            }}</span>
                          </div>
                          <div
                            v-if="
                              report.attributes.settings
                                .reinspect_categories === 3
                            "
                            class="option"
                            :class="{
                              active: activeFilter === 'unsatisfactory',
                            }"
                            @click="setActiveFilter('unsatisfactory')"
                          >
                            <div class="fa-regular fa-hand" />
                            <span>{{
                              report.attributes.settings
                                .reinspect_category_partial
                            }}</span>
                          </div>
                          <div
                            v-if="
                              report.attributes.settings
                                .reinspect_categories !== 1
                            "
                            class="option"
                            :class="{ active: activeFilter === 'notResolved' }"
                            @click="setActiveFilter('notResolved')"
                          >
                            <div class="fa-regular fa-thumbs-down" />
                            <span>{{
                              report.attributes.settings.reinspect_category_bad
                            }}</span>
                          </div>
                        </template>
                        <template v-else>
                          <div
                            v-if="summaryCategories.length > 0"
                            class="option"
                            :class="{ active: activeFilter === 'summary' }"
                            data-cy="summary-option-button"
                            @click="setActiveFilter('summary')"
                          >
                            <div class="fa-solid fa-list-ul" />
                            <span>Summary</span>
                          </div>
                          <div
                            v-if="report.attributes.settings.categories !== 1"
                            class="option"
                            :class="{ active: activeFilter === 'hazards' }"
                            data-cy="hazards-option-button"
                            @click="setActiveFilter('hazards')"
                          >
                            <div class="fa-solid fa-warning" />
                            <span>{{
                              report.attributes.settings.category_high
                            }}</span>
                          </div>
                        </template>
                      </template>
                    </div>

                    <div class="options">
                      <template
                        v-if="!isViewingRestricted && !ignoreClientComplete"
                      >
                        <div
                          v-if="webShareApiSupported"
                          class="option"
                          @click="share"
                        >
                          <div class="fa-solid fa-share-alt" />
                          <span>Share</span>
                        </div>
                        <a
                          v-else
                          class="option"
                          :href="emailShareLink"
                          target="_blank"
                        >
                          <div class="fa-solid fa-envelope" />
                          <span>Share</span>
                        </a>
                      </template>

                      <v-menu
                        v-if="!pdfsBeingRefreshed"
                        v-model="showPdfOptions"
                        absolute
                        offset-y
                        min-width="10em"
                        data-cy="pdf-button"
                      >
                        <div slot="activator" class="pdf-btn option">
                          <i class="fa-regular fa-file-pdf" />
                          PDF
                          <i class="fa-solid fa-caret-down" />
                        </div>

                        <v-list>
                          <v-list-tile
                            v-if="!reinspection"
                            :href="summaryUrl"
                            target="_blank"
                            class="report-option"
                            data-cy="summary-button"
                          >
                            <v-list-tile-title>
                              <div class="icon-container">
                                <div class="fa-solid fa-list" />
                              </div>
                              Summary
                            </v-list-tile-title>
                          </v-list-tile>
                          <v-list-tile
                            :href="pdfUrl"
                            target="_blank"
                            class="report-option"
                            data-cy="full-report-button"
                          >
                            <v-list-tile-title>
                              <div class="icon-container">
                                <div class="fa-regular fa-file-pdf" />
                              </div>
                              Full Report
                            </v-list-tile-title>
                          </v-list-tile>
                          <v-list-tile
                            v-if="canRefresh"
                            class="report-option"
                            data-cy="refresh-pdf-button"
                            @click="refreshPdfs"
                          >
                            <v-list-tile-title>
                              <div class="icon-container">
                                <div class="fa-solid fa-arrows-rotate" />
                              </div>
                              Refresh PDFs
                            </v-list-tile-title>
                          </v-list-tile>
                        </v-list>
                      </v-menu>
                      <v-tooltip v-else left content-class="info-tooltip">
                        <div slot="activator" class="pdf-btn refreshing">
                          <div class="fa-solid fa-arrows-rotate rotating" />
                          <span>PDF</span>
                        </div>
                        Your PDFs are currently being updated with the latest
                        information. They should be ready within a minute or
                        two.
                      </v-tooltip>
                    </div>
                  </v-flex>
                </template>

                <!-- TODO messaging when report loads an is not viewable by current user -->
                <div v-if="loaded && !viewable"></div>
                <v-flex
                  v-if="viewable"
                  id="report-body"
                  class="report-body"
                  :class="{ xs11: !customView, xs10: customView }"
                  data-cy="report_body"
                >
                  <div v-if="activeFilter === 'summary'" class="summary-text">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="report.attributes.settings.summary_text" />
                  </div>

                  <stats v-if="displaySummary" id="summary" />

                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    v-if="displayReportText"
                    class="report-text"
                    v-html="renderFullReportText"
                  />
                  <!-- eslint-enable -->

                  <template v-for="numberedSection in numberedSections">
                    <div
                      v-if="
                        displayAll ||
                        defectCounts[activeFilter][numberedSection.section.id]
                          .length > 0
                      "
                      :id="`section-${numberedSection.section.id}`"
                      :key="numberedSection.number"
                      style="clear: both"
                    >
                      <div class="section-header">
                        <span class="section-name">
                          <span
                            :class="numberedSection.section.attributes.icon"
                          />
                          {{ numberedSection.number }} -
                          {{ numberedSection.section.attributes.name }}
                        </span>

                        <template
                          v-if="
                            canEdit && numberedSection.observations.length > 0
                          "
                        >
                          <a
                            class="btn btn-blank btn-flat btn-small"
                            :href="
                              editorUrl(
                                numberedSection.section.id,
                                firstItemId(numberedSection.section.id)
                              )
                            "
                          >
                            <span class="fa-solid fa-pencil" />
                            Edit Section
                          </a>
                        </template>
                      </div>

                      <v-flex
                        v-if="displayAll && !customView && !reinspection"
                        xs12
                      >
                        <section-tabs
                          ref="sectionTabs"
                          :section-number="numberedSection.number"
                          :section="numberedSection.section"
                          :observations="numberedSection.observations"
                        />
                        <div
                          v-if="
                            report.attributes.settings.use_ratings &&
                            report.attributes.settings.display_report_grids &&
                            !numberedSection.section.attributes.is_overview
                          "
                          class="small ratings-legend"
                        >
                          <span
                            v-for="(abbr, i) in report.attributes
                              .ratings_abbreviations"
                            :key="i"
                          >
                            {{ abbr }} =
                            {{ report.attributes.ratings_names[i] }}
                          </span>
                        </div>
                      </v-flex>

                      <v-layout wrap class="observations">
                        <template v-if="numberedSection.observationsLoaded">
                          <template
                            v-for="(obs, index) in defectObservations(
                              numberedSection
                            )"
                          >
                            <v-flex
                              v-if="
                                shouldShowDefect(obs.attributes.category) &&
                                (displayAll ||
                                  displayable(obs.attributes.category))
                              "
                              :key="`${obs.attributes.item_id}-${obs.id}`"
                              xs12
                              sm6
                              md6
                              lg4
                              class="observation-container"
                            >
                              <defect-options
                                v-if="customView"
                                :observation-id="obs.id"
                                :label="obs.number"
                              />
                              <defect-card
                                :data-cy="`cy-defect-card-${index}`"
                                :index="index"
                                :label="
                                  reinspection
                                    ? obsLabel(obs.attributes.source_id)
                                    : obs.number
                                "
                                :observation="obs"
                              />
                            </v-flex>
                          </template>
                        </template>
                        <v-flex v-else layout wrap class="loading-wrapper">
                          <div class="swoosh" />
                          <v-flex
                            xs12
                            sm6
                            md6
                            lg4
                            class="fake observation-container"
                          >
                            <div class="defect-card">
                              <div class="defect-header">
                                {{ numberedSection.number }}.1.1 Foundation,
                                Basement & Crawlspaces
                              </div>
                              <div class="defect-body">
                                <div class="name">Heaving/Settling</div>
                                <div class="text">
                                  The floor slab shows movement/settling due to
                                  soil movement. This can compromise the
                                  structural integrity of the home. Recommend a
                                  qualified structural engineer evaluate and
                                  advise on how to remedy.
                                </div>
                              </div>
                            </div>
                          </v-flex>
                          <v-flex
                            hidden-xs-only
                            sm6
                            md6
                            lg4
                            class="fake observation-container"
                          >
                            <div class="defect-card">
                              <div class="defect-header">
                                {{ numberedSection.number }}.1.2 Floor Structure
                              </div>
                              <div class="defect-body">
                                <div class="name">
                                  Evidence of Structural Damage
                                </div>
                                <div class="text">
                                  Structural damage was observed in the
                                  underlying floor structure. Recommend a
                                  structural engineer evaluate.
                                </div>
                              </div>
                            </div>
                          </v-flex>
                          <v-flex
                            hidden-md-and-down
                            lg4
                            class="fake observation-container"
                          >
                            <div class="defect-card">
                              <div class="defect-header">
                                {{ numberedSection.number }}.1.3 Roof Structure
                                & Attic
                              </div>
                              <div class="defect-body">
                                <div class="name">Safety Hazard</div>
                                <div class="text">
                                  This area was unsafe to inspect. If you are
                                  interested in having this area inspected,
                                  please contact me about a follow-up
                                  inspection.
                                </div>
                              </div>
                            </div>
                          </v-flex>
                        </v-flex>
                      </v-layout>
                    </div>
                  </template>
                  <recommended-contractors-section
                    v-if="loaded && canRecommendContractors"
                    :numbered-sections="numberedSectionsWithObservations"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout v-else justify-center class="pdf-background">
            <div class="loading">
              <div>
                <div class="fa-solid fa-arrows-rotate rotating" />
                Loading your report
              </div>
            </div>

            <v-flex xs11 sm8 md6 class="pdf-inner">
              <div class="fake-pdf">
                <div class="section">
                  <div class="section-header">Risus viverra adipiscing</div>
                  <div class="section-body">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit.
                    Tristique et egestas quis ipsum suspendisse ultrices gravida
                    dictum. Vitae aliquet nec ullamcorper sit amet risus nullam
                    eget. Cras tincidunt lobortis feugiat vivamus at. Duis
                    ultricies lacus sed turpis tincidunt id aliquet. Tortor
                    vitae purus faucibus ornare suspendisse sed nisi lacus sed.
                    Sagittis aliquam malesuada bibendum arcu vitae elementum
                    curabitur vitae nunc. Morbi non arcu risus quis varius quam
                    quisque id.
                  </div>
                </div>
                <div class="section">
                  <div class="section-header">Lorem ipsum dolor sit amet</div>
                  <div class="section-body">
                    <div class="row">
                      <strong>1.</strong> sed do eiusmod tempor incididunt ut
                      labore
                    </div>
                    <div class="row">
                      <strong>2.</strong> Lorem ipsum dolor sit amet consectetur
                      adipiscing elit
                    </div>
                    <div class="row">
                      <strong>3.</strong> Tristique et egestas quis ipsum
                      suspendisse ultrices gravida dictum.
                    </div>
                  </div>
                </div>
                <div class="section">
                  <div class="section-header">Nec feugiat nisl pretium</div>
                  <div class="section-body">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Risus viverra adipiscing at in tellus integer
                      feugiat. Nec feugiat nisl pretium fusce id velit. Lorem
                      ipsum dolor sit amet consectetur adipiscing elit.
                    </p>
                    <p>
                      Risus viverra adipiscing at in tellus integer feugiat. Nec
                      feugiat nisl pretium fusce id velit. Lorem ipsum dolor sit
                      amet consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
                <div class="footer-row">
                  <div>
                    <strong>Revision 1.2.3</strong>
                  </div>
                  <div>Page 1 of 1</div>
                </div>
              </div>
            </v-flex>
          </v-layout>
          <toast />
          <transition name="fade-in">
            <overlay-photo v-if="hasOverlayPhoto" />
          </transition>
          <transition name="fade-in">
            <overlay-photos v-if="hasOverlayPhotos" />
          </transition>
          <template v-if="!isSampleReport">
            <quick-edit-observation
              v-if="editObservation"
              @change="updateEditedObservation"
              @close="unsetEdit('Observation')"
            />
            <location-builder
              v-if="editLocation"
              v-model="editObservation.attributes.location"
              :options1="locationOptions1"
              :options2="locationOptions2"
              :options3="locationOptions3"
              @close="unsetEdit('Location')"
            />
            <leave-review
              v-if="review"
              :reviewer="reviewer"
              @close="review = false"
            />
            <post-publish-prompt
              v-if="postPublishPrompt"
              @close="postPublishPrompt = false"
            />
            <publish-report
              v-if="publishReport === 'automations'"
              @close="publishReport = false"
              @change="updatePublished"
            />
            <publish-report-actions
              v-if="publishReport === 'actions'"
              @close="publishReport = false"
              @change="updatePublished"
            />
            <publish-isn
              v-if="publishReport === 'isn' && isIsn"
              :report-id="report.id"
              @close="publishReport = false"
              @change="updatePublished"
            />
            <submit-report
              v-if="submitReport"
              @close="submitReport = false"
              @change="updateSubmission"
            />
          </template>
        </v-container>
      </div>
    </transition>
    <add-person
      v-if="displayAddPersonModal"
      :inspection-id="inspection.id"
      person-type="buying_agent"
      @closed="addingAgent = false"
      @personAdded="addAgent"
    />
    <copy-observation-text v-if="copyText" @close="copyText = false" />
    <new-sample-report
      v-if="newSampleReport"
      @close="newSampleReport = false"
    />
  </v-app>
</template>

<script>
/* eslint-disable no-console */
import { omit } from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'

import DefectCard from './DefectCard.vue'
import DefectOptions from './DefectOptions.vue'
import DownloadingContentOverlay from './DownloadingContentOverlay.vue'
import LocationBuilder from '../template_editor/modal/LocationBuilder.vue'
import QuickEditObservation from './modals/QuickEditObservation.vue'
import ReportHeader from './ReportHeader.vue'
import RecommendedContractorsSection from '../recommended_contractors/RecommendedContractorsSection.vue'
import RIB from './RIB.vue'
import RRB from './RRB.vue'
import SectionTabs from './SectionTabs.vue'
import SidebarMenu from './SidebarMenu.vue'
import Stats from './Stats.vue'
import Toast from '../notifier/Toast.vue'

import OverlayPhoto from './OverlayPhoto.vue'
import OverlayPhotos from './OverlayPhotos.vue'

import CopyObservationText from './modals/CopyObservationText.vue'
import LeaveReview from './modals/LeaveReview.vue'
import NewSampleReport from './modals/NewSampleReport.vue'
import PostPublishPrompt from './modals/PostPublishPrompt.vue'
import PublishIsn from './modals/PublishIsn.vue'
import PublishReport from './modals/PublishReport.vue'
import PublishReportActions from './modals/PublishReportActions.vue'
import SubmitReport from './modals/SubmitReport.vue'
import AddPerson from '../inspection/modal/AddPerson.vue'
import {
  errorReporterService,
  productAnalyticsService,
  sessionDebuggerService,
} from '@/services'

import axios from '@/utils/AxiosService'
import config from '@/utils/config'
import dig from '@/utils/Dig.js'
import capitalize from '@/utils/capitalize'
import { captureException } from '@/utils'
import replaceReportData from '@/utils/replaceReportData'
import EventBus from '@/services/EventBus'

const PDF_DOMAIN = config('PDF_DOMAIN')
const FIRESTORE_DOMAIN = config('FIRESTORE_DOMAIN')
const FIRESTORE_OPTIMIZED_DOMAIN = config('FIRESTORE_OPTIMIZED_DOMAIN')
const VIEWER_DOMAIN = config('VIEWER_DOMAIN')
const RAILS_DOMAIN = config('RAILS_DOMAIN')

export default {
  components: {
    AddPerson,
    CopyObservationText,
    DefectCard,
    DefectOptions,
    DownloadingContentOverlay,
    LeaveReview,
    LocationBuilder,
    NewSampleReport,
    QuickEditObservation,
    RIB,
    RRB,
    ReportHeader,
    RecommendedContractorsSection,
    OverlayPhoto,
    OverlayPhotos,
    SidebarMenu,
    SectionTabs,
    Stats,
    Toast,
    PostPublishPrompt,
    PublishIsn,
    PublishReport,
    PublishReportActions,
    SubmitReport,
  },
  data() {
    return {
      addingAgent: false,
      copyText: false,
      displaySidebar: false,
      loaded: false,
      loadingPdf: false,
      newSampleReport: false,
      numberedSections: [],
      payloadAvailable: false,
      poller: null,
      postPublishPrompt: false,
      publishReport: false,
      railsDown: false,
      railsReport: undefined,
      review: false,
      reviewer: null,
      scrollHeader: false,
      showPdfOptions: false,
      submitReport: false,
    }
  },
  computed: {
    ...mapState('reports', [
      'activeFilter',
      'activeSection',
      'company',
      'defectCounts',
      'editLocation',
      'editObservation',
      'inspection',
      'items',
      'loggedIn',
      'observations',
      'overlayPhoto',
      'overlayPhotos',
      'currentPanoPhoto',
      'showPanoButton',
      'sampleReport',
      'sections',
      'report',
      'reportRails',
      'reportContractors',
      'reportView',
      'recommendedContractors',
      'rib',
      'rrb',
      'userProfile',
      'reinspection',
      'report_view_id',
      'viewer',
    ]),
    accessToken() {
      // eslint-disable-next-line camelcase
      return this.$route.query.report_access_token
    },
    renderFullReportText() {
      if (!this.isSampleReport) {
        return this.report.attributes.settings.full_report_text
      }
      if (!this.sampleReport) {
        return this.report.attributes.settings.full_report_text
      }

      return replaceReportData(
        this.report.attributes.settings.full_report_text,
        {
          report_id: this.report.attributes.settings.id,
          sample_url: this.sampleReport.attributes.sample_url,
        }
      )
    },
    canEdit() {
      return (
        this.loggedIn &&
        !this.isSampleReport &&
        !this.customView &&
        ((['inspector', 'manager'].includes(this.userProfile.type) &&
          this.userProfile.attributes.company_id ===
            this.company.attributes.id) ||
          this.userProfile.type === 'admin')
      )
    },
    canRefresh() {
      return this.loggedIn && !this.isSampleReport
    },
    canRecommendContractors() {
      return (
        this.reportContractors.length > 0 &&
        this.company.attributes.settings.enable_recommended_contractors &&
        this.report.settings.display_recommendations &&
        !this.viewingRestricted &&
        !this.reportRails.attributes.not_show_recommended_contractors_on_reports
      )
    },
    categoryKeys() {
      return {
        '-1': 'category_low',
        0: 'category_med',
        1: 'category_high',
        2: 'category_good',
        3: 'category_partial',
        4: 'category_bad',
      }
    },
    customView() {
      return this.rrb || this.rib
    },
    displayAddPersonModal() {
      return this.displayBuyingAgent && !this.hasBuyingAgent && this.addingAgent
    },
    displayAll() {
      return this.activeFilter === 'full'
    },
    displayBuyingAgent() {
      return dig(this.company, 'attributes.settings.agent_display_buying')
    },
    displayHazards() {
      return this.activeFilter === 'hazards'
    },
    displayReportText() {
      return (
        (this.report.attributes.settings.full_report_text || '').length > 0 &&
        this.displayAll &&
        !this.customView
      )
    },
    displayRH() {
      return this.activeFilter === 'rh'
    },
    displaySummary() {
      return (
        this.report.attributes.settings.display_summary &&
        this.displayAll &&
        !this.customView
      )
    },
    hasBuyingAgent() {
      return (
        this.sampleReportId ||
        dig(this.inspection, 'attributes.buying_agent_id')
      )
    },
    emailShareLink() {
      const subject = 'View my inspection report!'
      const body = `View my recent inspection report from ${this.company.attributes.name}: ${this.sharableUrl}`

      return `mailto:?subject=${subject}&body=${body}`
    },
    hasOverlayPhoto() {
      return this.overlayPhoto
    },
    hasOverlayPhotos() {
      return this.overlayPhotos.length > 0
    },
    idToken() {
      return this.$route.query.id_token
    },
    isAdmin() {
      return this.userProfile.type === 'admin'
    },
    isViewingRestricted() {
      return this.report.attributes.viewing_restricted
    },
    ignoreClientComplete() {
      const { ignoreClientComplete } = this.$route.query
      return ignoreClientComplete === 'true'
    },
    isCompanyStaff() {
      return (
        this.inspection.attributes.company_id &&
        this.inspection.attributes.company_id ===
          this.userProfile.attributes.company_id
      )
    },
    isIsn() {
      return this.inspection.attributes.isn_id
    },
    isPublished() {
      return this.railsReport && this.railsReport.attributes.published
    },
    isSampleReport() {
      return (
        this.$route.name === 'sample-report' ||
        (this.$route.query.sample_id || '').length > 0
      )
    },
    locationOptions1() {
      return this.company.attributes.settings.location_options_1.split(';')
    },
    locationOptions2() {
      return this.company.attributes.settings.location_options_2.split(';')
    },
    locationOptions3() {
      return this.company.attributes.settings.location_options_3.split(';')
    },
    numberedSectionsWithObservations() {
      return this.numberedSections.filter((s) => {
        return s.observations.length > 0
      })
    },
    pdfReport() {
      return this.report.attributes.pdf_only
    },
    pdfsBeingRefreshed() {
      if (this.reinspection) {
        return !!this.report.attributes.pdf_started_at && this.poller
      } else {
        return (
          (!!this.report.attributes.pdf_started_at ||
            !!this.report.attributes.summary_pdf_started_at) &&
          this.poller
        )
      }
    },
    pdfGeneratorUrl() {
      return `https://${PDF_DOMAIN}`
    },
    pdfBaseUrl() {
      if (this.sampleReport) {
        return `${this.pdfGeneratorUrl}/sample-${this.sampleId}`
      }
      return `${this.pdfGeneratorUrl}/${this.reportId}`
    },
    pdfUrl() {
      if (this.canEdit) {
        return `${this.pdfBaseUrl}?ignoreClientComplete=true`
      }

      if (this.accessToken) {
        return `${this.pdfBaseUrl}?report_access_token=${this.accessToken}`
      }

      return this.pdfBaseUrl
    },
    reportId() {
      return this.$route.params.id || this.sampleReportId
    },
    reportTitle() {
      if (!this.loaded) {
        return ''
      } else if (this.sampleReport) {
        return `1234 Main Street | ${this.company.attributes.slug || ''}`
      } else {
        return `${
          this.inspection.attributes.property_address || 'Spectora Report'
        } | ${this.company.attributes.slug || ''}`
      }
    },
    reviews() {
      return this.inspection.inspection_reviews || []
    },
    reviewModal() {
      return (
        this.company.attributes.settings.enable_inspection_reviews &&
        this.company.attributes.settings.inspection_reviews_report_popup
      )
    },
    sampleId() {
      return this.$route.query.sample_id
    },
    sampleReportId() {
      return dig(this.sampleReport || {}, 'attributes.report_id')
    },
    sharableUrl() {
      return `https://${VIEWER_DOMAIN}${this.$route.path}`
    },
    summaryCategories() {
      const cats = []
      if (
        this.report.attributes.settings.category_high_in_summary &&
        this.report.attributes.settings.categories > 1
      )
        cats.push(1)
      if (this.report.attributes.settings.category_low_in_summary) cats.push(-1)
      if (this.report.attributes.settings.category_med_in_summary) cats.push(0)

      return cats
    },
    summaryUrl() {
      if (this.canEdit) {
        return `${this.pdfBaseUrl}?summary=true&ignoreClientComplete=true`
      }

      if (this.accessToken) {
        return `${this.pdfBaseUrl}?summary=true&report_access_token=${this.accessToken}`
      }
      return `${this.pdfBaseUrl}?summary=true`
    },
    viewable() {
      return (
        this.loaded &&
        (this.railsDown ||
          this.isPublished ||
          this.isAdmin ||
          this.isCompanyStaff ||
          this.ignoreClientComplete)
      )
    },
    viewingRestricted() {
      return this.report.attributes.viewing_restricted
    },
    webShareApiSupported() {
      return navigator && navigator.share
    },
  },
  metaInfo() {
    return {
      title: this.reportTitle,
    }
  },
  watch: {
    report: {
      handler: function (newV, oldV) {
        if (!!newV.attributes.pdf_started_at && !this.poller) {
          this.poller = setInterval(this.checkPdfStatus, 7500)
        } else if (!newV.attributes.pdf_started_at && this.poller) {
          clearInterval(this.poller)
          this.poller = null
          if (this.pdfReport) this.preparePdfReport()
        }
      },
      deep: true,
    },
    loaded: async function (newV, oldV) {
      // Scroll to hash once report is loaded
      if (newV) {
        this.scrollToRequestedId()
      }
    },
  },
  beforeCreate() {
    document.getElementsByTagName('html')[0].classList.add('hide-overflow')
  },
  async mounted() {
    await this.fetchData()
    this.bootLogging()
    this.checkSummaryParam()
  },
  methods: {
    ...mapActions('inspection', ['updateInspection']),
    ...mapActions('reports', [
      'fetchAccount',
      'fetchCompany',
      'fetchInspection',
      'fetchRecommendedContractors',
      'fetchReportRails',
      'fetchReportFirestore',
      'fetchReportForSample',
      'fetchReportView',
      'fetchSampleReport',
      'fetchToken',
      'fetchViewer',
      'setDefectCounts',
      'checkForReportUpdates',
      'updateReportPayload',
      'setReinspection',
      'setReportViewID',
      'validateAccessToken',
    ]),
    ...mapMutations('inspection', {
      inspectionModuleSetCompany: 'setCompany',
      inspectionModuleSetInspection: 'setInspection',
    }),
    ...mapMutations('reports', ['setInspection']),
    shouldShowDefect(category) {
      // Temporary hack for this issue: https://spectora.slack.com/archives/C0E34AVLG/p1662595264616049
      if (this.report.id === '71c88f32-6e0f-43d0-aa04-db67f3f00a9d') {
        return category === 0
      }
      return true
    },
    scrollToRequestedId() {
      setTimeout(() => {
        const infoTabSuffix = '-information-tab'
        let requestedId = this.$route.hash.slice(1)
        if (requestedId && requestedId.endsWith(infoTabSuffix)) {
          requestedId = requestedId.slice(0, -infoTabSuffix.length)
          // Remove 'section-' prefix if present
          const sectionId = requestedId.slice(8)
          this.openInformationTab(sectionId)
        }
        const el = document.getElementById(requestedId)
        if (el) {
          el.scrollIntoView()
        } else {
          this.scrollToRequestedId()
        }
      }, 1000)
    },
    openInformationTab(sectionId) {
      this.$refs.sectionTabs.map((sectionTab) => {
        if (sectionTab.section.id === sectionId) {
          sectionTab.setActiveTab(1)
        }
      })
    },
    openPanoView() {
      EventBus.$emit('closeGallery')

      this.$store.commit('reports/setValue', {
        key: 'overlayPhoto',
        value: this.currentPanoPhoto,
      })
    },
    addAgent(agent) {
      const params = { id: this.inspection.id, buying_agent_id: agent.id }
      this.updateInspection({ attributes: params }).then(() => {
        const inspection = JSON.parse(JSON.stringify(this.inspection))
        inspection.attributes.buying_agent_id = agent.attributes.id
        inspection.attributes.buying_agent = { data: agent }
        this.setInspection(inspection)
      })
    },
    addItemNumbers(items) {
      let counter = 0
      items.map((item) => {
        if (!item.attributes.is_overview) {
          item.number = counter + 1
          counter += 1
        }
      })
      return items
    },
    bootLogging() {
      if (this.userProfile && this.userProfile.attributes) {
        errorReporterService.identify({
          id: this.userProfile.id,
          email: this.userProfile.email,
        })
        productAnalyticsService.identify({
          id: this.userProfile.id,
        })
        sessionDebuggerService.identify({
          id: this.userProfile.id,
          email: this.userProfile.email,
        })
      }
    },
    checkSummaryParam() {
      try {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const summary = urlParams.get('summary')

        if (summary === 'true') {
          this.setActiveFilter('summary')
        }
      } catch (e) {
        console.log('Error on checkSummaryParam: ', e)
      }
    },
    buildNumberedSections() {
      this.numberedSections = this.sections.map((section, i) => {
        const items = this.items.filter(
          (x) => x.attributes.report_section_id === section.attributes.id
        )
        this.addItemNumbers(items)
        const observations = this.observations.filter(
          (x) => x.attributes.report_section_id === section.attributes.id
        )
        observations.map((observation) => {
          observation.attributes.report_item = items.find(
            (item) => item.id === observation.attributes.report_item_id
          )
        })
        return {
          number: i + 1,
          section,
          items,
          observations,
          observationsLoaded: true,
        }
      })
    },
    checkPdfStatus() {
      this.$store.dispatch('reports/fetchPdfStatus')
    },
    async checkForUpdates() {
      if (this.hasCheckedForUpdates) return
      this.hasCheckedForUpdates = true

      // Assume updates by default
      let hasUpdate = true
      try {
        hasUpdate = await this.checkForReportUpdates(this.reportId)
      } catch (e) {
        // Fail silently if this somehow breaks
        captureException(e)
      }

      console.log('hasUpdate: ', hasUpdate)
      return hasUpdate
    },
    defectObservations(numberedSection) {
      let dOs = []
      numberedSection.items.forEach((i) => {
        dOs = dOs.concat(
          numberedSection.observations
            .filter(
              (o) =>
                o.attributes.report_item_id === i.id &&
                o.attributes.comment_type === 'defect' &&
                !o.attributes.report_item.attributes.is_overview
            )
            .map((o, idx) => {
              o.number = `${numberedSection.number}.${
                o.attributes.report_item.number
              }.${idx + 1}`
              return o
            })
        )
      })

      if (this.activeFilter === 'summary') {
        dOs = dOs.sort((a, b) => {
          return b.attributes.category - a.attributes.category
        })
      }

      return dOs
    },
    displayable(category) {
      if (this.displayHazards) {
        return category === 1
      } else if (this.displayRH) {
        return [0, 1].includes(category)
      } else if (this.activeFilter === 'summary') {
        return this.summaryCategories.includes(category)
      } else if (this.reinspection) {
        if (this.activeFilter === 'resolved' && category !== 2) return false
        if (this.activeFilter === 'unsatisfactory' && category !== 3)
          return false
        if (this.activeFilter === 'notResolved' && category !== 4) return false
        return true
      } else {
        return true
      }
    },
    editorUrl(sectionId, itemId) {
      if (this.company.attributes.optimized_web_editor) {
        return `https://${FIRESTORE_OPTIMIZED_DOMAIN}/#/${this.reportId}/s/${sectionId}/i/${itemId}`
      }
      return `https://${FIRESTORE_DOMAIN}/#/${this.reportId}/s/${sectionId}/i/${itemId}`
    },
    async fetchAdditional() {
      await this.fetchAccount()
      await this.fetchPublishData()

      if (this.$route.query.report_view) {
        this.fetchReportView(this.$route.query.report_view)
      } else if (this.reinspection && !this.reportView) {
        this.fetchReportView(this.report_view_id)
      }
    },
    async fetchAuthentication() {
      try {
        await this.fetchToken()
      } catch (error) {
        if (error.request && error.request.status === 0) {
          this.railsDown = true
        }
        console.log('Rails authentication failure', error)
      }
    },
    async fetchData() {
      await this.fetchAuthentication()

      // TODO: return it to normal after finding the root cause of this issue:
      // https://spectora.slack.com/archives/C0E34AVLG/p1653573401619769
      // const hasUpdates = await this.checkForUpdates()
      // if (hasUpdates) { }

      await this.updateReportPayload({ reportId: this.reportId, clean: true })

      if (this.isSampleReport) {
        await this.fetchSampleReport(this.sampleId)
        await this.fetchReportForSample(this.sampleId)
      } else {
        await this.fetchReportFirestore(this.reportId)
      }

      this.buildNumberedSections()
      this.setDefectCounts()
      this.handleRRB()

      await this.fetchAdditional()
      await this.fetchInspection()
      await this.$store.dispatch('reports/fetchRecommendations')
      await this.fetchRecommendedContractors(this.report.id)

      try {
        await this.fetchCompany(this.inspection.attributes.company_id)
      } catch (error) {
        // Do nothing. Error expected
      }

      if (this.report.viewing_restricted && !this.$route.query.reinspection) {
        if (!this.accessToken) {
          return this.redirectToRequestAccessPage()
        }

        try {
          await this.validateAccessToken(this.accessToken)
        } catch (error) {
          this.redirectToRequestAccessPage()
        }
      }

      // console.log('admin: ', this.isAdmin)
      // console.log('ignoreClientComplete: ', this.ignoreClientComplete)
      // console.log('')
      // console.log('client complete: ', this.report.inspection_client_complete)
      // console.log('report published: ', this.report.published)
      // console.log('rails published: ', this.railsReport.attributes.published)
      // console.log('')
      // console.log('')

      if (this.isAdmin || this.ignoreClientComplete) {
        // 1. User is admin. Let it see the report
        console.log('Case 1')
        return this.handleAvailableReport()
      }

      if (this.loggedIn) {
        if (
          this.userProfile.attributes.company_id !==
          this.inspection.attributes.company_id
        ) {
          console.log('Case 2')
          // 2. User is from a different company that owns the report. Go to the feedback page
          return this.navigateToErrorPage({ name: 'report-feedback' })
        }
        console.log('Case 3')
        // 3. User is from the same company that owns the report. Let it see the report
        return this.handleAvailableReport()
      }

      if (this.report.inspection_client_complete) {
        if (this.report.published || this.railsReport.attributes.published) {
          console.log('Case 4')
          // 4. User is not loggedId, the report is completed from the client side, and it's published. Let it see the report
          return this.handleAvailableReport()
        }
        // 5. User is not loggedId, the report is completed from the client side, and it's not published. Go to the not published page
        console.log('Case 5')
        return this.navigateToErrorPage({ name: 'report-not-published' })
      }

      // 6. User is not loggedId and the report is not completed. Go to the feedback page
      console.log('Case 6')
      return this.navigateToErrorPage({ name: 'report-feedback' })
    },
    firstItemId(sectionId) {
      return (
        this.items.find((i) => {
          return sectionId === i.attributes.report_section_id
        }) || {}
      ).id
    },
    handleRRB() {
      if (this.$route.query.start_rrb) {
        this.$store.commit('reports/setValue', { key: 'rrb', value: true })
      }
      if (this.$route.query.report_view) {
        if (this.$route.query.reinspection) {
          this.$store.commit('reports/setValue', { key: 'rib', value: true })
        } else {
          this.$store.commit('reports/setValue', { key: 'rrb', value: true })
        }
        this.$store.commit('reports/setValue', {
          key: 'activeFilter',
          value: 'all',
        })
      } else {
        if (this.$route.query.reinspection) {
          this.$store.commit('reports/setValue', { key: 'rib', value: true })
          this.$store.commit('reports/setValue', {
            key: 'activeFilter',
            value: 'all',
          })
        }
      }
    },
    handleScroll(e) {
      if (this.customView) return

      const scroller = document.getElementById('scrollable')
      const position = scroller.scrollTop + 50
      const newActive = this.numberedSections.find((s, i) => {
        if (this.numberedSections[i + 1]) {
          const el1 = document.getElementById(`section-${s.section.id}`)
          const el2 = document.getElementById(
            `section-${this.numberedSections[i + 1].section.id}`
          )

          if (el1 && el2) {
            return position > el1.offsetTop && position < el2.offsetTop
          } else {
            return null
          }
        } else {
          return null
        }
      })

      const newActiveId = newActive ? newActive.section.id : 'summary'
      if (this.activeSection !== newActiveId)
        this.$store.commit('reports/setValue', {
          key: 'activeSectionId',
          value: newActiveId,
        })
    },
    incrementReportCategory(oldCategory, newCategory) {
      const stats = JSON.parse(JSON.stringify(this.report.attributes.stats))
      stats[this.categoryKeys[oldCategory]] -= 1
      if (newCategory !== undefined && newCategory !== null)
        stats[this.categoryKeys[newCategory]] += 1
      const report = omit(this.report, ['sections'])
      report.attributes.stats = stats
      this.$store.commit('reports/setValue', { key: 'report', value: report })
    },
    // Log report views and trigger view-based actions
    async fetchPublishData() {
      let railsReport

      let checkReinspection = false
      let reportViewId = this.report.attributes.settings.report_view_id

      const reportIsReinspection =
        !!this.report.attributes.settings.report_view_id

      try {
        railsReport = await this.fetchReportRails({
          reportId: this.reportId,
          idToken: this.idToken,
          readOnly: true,
        })

        if (railsReport) {
          checkReinspection =
            !!railsReport.attributes.settings.report_view_id ||
            !!reportIsReinspection

          reportViewId = railsReport.attributes.settings.report_view_id

          this.setReinspection(checkReinspection)
          this.setReportViewID(reportViewId)
        }
      } catch (error) {
        if (error.request && error.request.status === 0) {
          this.railsDown = true
        }
        console.log('Logging report view error', error)
      } finally {
        if (!railsReport) {
          this.setReinspection(reportIsReinspection)
          this.setReportViewID(reportViewId)
        }
      }

      // console.log('checkReinspection: ', checkReinspection)
      // console.log('reportViewId: ', reportViewId)
      // console.log('railsReport: ', railsReport)
      // console.log('report: ', this.report)

      this.railsReport = railsReport
    },
    obsLabel(observationId) {
      if (!this.reportView) return ''
      const entry = this.reportView.attributes.whitelist.find(
        (w) => observationId === w.observation_id
      )
      return entry ? entry.number : ''
    },
    optionsScroller(e) {
      const options = document.getElementById('options-row')
      const scroller = document.getElementById('scrollable')
      if (options) {
        if (options.offsetTop <= scroller.scrollTop) {
          if (!options.classList.value.includes('scroll')) {
            this.scrollHeader = true
          } else {
            const header = document.getElementById('header')
            if (scroller.scrollTop <= header.offsetHeight)
              this.scrollHeader = false
          }
        } else {
          this.scrollHeader = false
        }
      }
    },
    preparePdfReport() {
      this.loadingPdf = true
      if (!this.report.attributes.pdf_started_at) {
        const ctx = this
        setTimeout(() => {
          window.location.href = ctx.report.attributes.pdf_url
        }, 1000)
      }
    },
    refreshPdfs() {
      this.$store.dispatch('reports/updatePdfs')
    },
    scheduleReview() {
      if (
        !this.loggedIn &&
        this.$route.query.id_token &&
        this.inspection.attributes.published_at
      ) {
        if (!this.reviewModal) return
        axios
          .get(
            `/api/v1/public/inspections/${this.inspection.attributes.slug}/reviewers/${this.$route.query.id_token}`
          )
          .then((response) => {
            this.reviewer = response.data.data
            const reviewerType =
              this.reviewer.type === 'agent' ? 'Agent' : 'Buyer'
            if (
              !this.reviews.find(
                (r) =>
                  r.attributes.reviewer_id === this.reviewer.attributes.id &&
                  r.attributes.reviewer_type === reviewerType
              )
            ) {
              setTimeout(() => {
                this.review = true
              }, 5000)
            }
          })
      }
    },
    setReportPublish(type) {
      this.publishReport = type
    },
    share() {
      if (!this.webShareApiSupported) return

      navigator.share({
        title: 'View my inspection report!',
        text: `View my recent inspection report from ${this.company.attributes.name}`,
        url: this.sharableUrl,
      })
    },
    unsetEdit(type) {
      this.$store.commit('reports/setValue', {
        key: `edit${type}`,
        value: null,
      })
    },
    updateEditedObservation(data) {
      const updatables = [
        'name',
        'text',
        'flagged',
        'location',
        'category',
        'recommendation_id',
        'display_estimate',
        'estimate_min',
        'estimate_max',
        'value',
        'value2',
        'unit_type_choices',
        'unit_type',
        'choices',
      ]
      const item = this.items.find((i) => {
        return i.id === data.attributes.report_item_id
      })
      this.numberedSections.forEach((s) => {
        if (s.section.id === item.attributes.report_section_id) {
          s.observations.forEach((o) => {
            if (o.id === data.id) {
              const existingCategory = o.attributes.category
              const newCategory = data.attributes.category
              updatables.forEach((att) => {
                o.attributes[att] = data.attributes[att]
              })

              if (existingCategory !== newCategory)
                this.incrementReportCategory(existingCategory, newCategory)
            }
          })

          if (!data.attributes.value && !data.attributes.value2) {
            s.observations = s.observations.filter((o) => o.id !== data.id)
            this.incrementReportCategory(data.attributes.category, null)
            this.$store.dispatch('reports/removeObservation', data.id)
          }
        }
      })
    },
    updatePublished(atts) {
      this.report.attributes.published = true
      this.postPublishPrompt = true
    },
    updateSubmission(submissionDate) {
      this.report.attributes.submitted_on_formatted = submissionDate
    },
    setActiveFilter(filter) {
      this.$store.commit('reports/setValue', {
        key: 'activeFilter',
        value: filter,
      })
    },
    setupAgentModal() {
      this.inspectionModuleSetCompany(this.company)
      this.inspectionModuleSetInspection(this.inspection)
      this.addingAgent = true
    },
    setupScroller() {
      const scrollable = document.getElementById('scrollable')

      if (scrollable) {
        scrollable.addEventListener('scroll', this.handleScroll)
        scrollable.addEventListener('scroll', this.optionsScroller)
      }
    },
    navigateToErrorPage({ name = 'report-feedback' }) {
      this.$router.push({
        name: name,
        params: { id: this.inspection.attributes.slug },
      })
    },
    handleAvailableReport() {
      this.scheduleReview()
      this.loaded = true
      this.setupScroller()

      this.fetchReportRails({
        reportId: this.reportId,
        idToken: this.idToken,
      })

      if (!this.userProfile.id) {
        this.fetchViewer(this.idToken).then((resp) => {
          try {
            const viewer = resp.data.data

            if (viewer) {
              productAnalyticsService.identify({
                id: viewer.id,
                properties: {
                  email: viewer.attributes.email,
                  profileType: capitalize(viewer.type),
                },
              })
            }
          } catch (err) {}
        })
      }
    },
    redirectToRequestAccessPage() {
      window.location = `https://${RAILS_DOMAIN}/report_request/${this.reportId}`
    },
  },
}
</script>

<style lang="scss" scoped>
#report .container {
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.sidebar-container {
  padding: 1em 0;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  overflow-y: auto;
}

.main-container {
  height: 100vh;
  max-height: 100vh;
  overflow-x: scroll;
}

.main {
  justify-content: center;
}

.report-header-placeholder {
  height: 30em;
  background-color: #3b464f;
}

.pdf-btn {
  color: #dfe6f6;
  height: 3em;
  line-height: 2em;
  padding: 0.5em 1.5em;
  font-size: 0.75em;
  background-color: #45769c;
  cursor: pointer;

  &.refreshing {
    color: #888;
    background-color: #dfdfdf;
  }
}

.report-body {
  transition: all 0.5s linear;
  background-color: inherit;
}

.report-text,
.summary-text {
  margin-top: 2em;
}

.section-header {
  font-size: 2em;
  font-weight: 300;
  margin: 1em 0 0.5em 0;
  justify-content: space-between;
  display: flex;

  .section-icon {
    vertical-align: middle;
    display: inline-block;
    height: 1em;
    width: 1em;
    margin-right: 0.25em;
    opacity: 0.3;
  }

  a {
    align-self: center;
  }
}

.ratings-legend {
  text-align: right;

  span {
    margin-left: 1em;
  }
}

.observations {
  .observation-container {
    padding: 0.5em 0;
    position: relative;
    min-height: 30em;

    &:nth-child(3n) {
      padding-left: 1em;
      padding-right: 0;
    }

    &:nth-child(3n + 1) {
      padding-right: 1em;
    }

    &:nth-child(3n - 1) {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}

.loading-wrapper {
  position: relative;
  overflow: hidden;

  .swoosh {
    position: absolute;
    height: 40em;
    content: ' ';
    width: 2em;
    transform: rotate(15deg);
    background-color: #e8e8e8;
    box-shadow: 1px 1px 2em 2em #e8e8e8;
    animation: left-to-right 1.5s ease-out infinite;
  }
}

.observation-container.fake {
  opacity: 0.3;

  .defect-card {
    height: 30em;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    filter: blur(2px);

    .defect-header {
      height: 2.75em;
      display: flex;
      padding: 0 1em;
      align-items: center;
      background-color: #888;
      color: #fff;
    }

    .defect-body {
      height: 27.25em;
      padding: 1em;
      background-color: #fff;

      .name {
        color: #555;
        font-size: 1.25em;
      }

      .text {
        color: #555;
      }
    }
  }
}

.pdf-background {
  height: 100vh;
  position: relative;
  background-color: rgba(0, 0, 0, 0.75);

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 2em;
    color: rgba(0, 0, 0, 0.75);

    .fa {
      &-solid,
      &-regular {
        margin-right: 0.5em;
      }
    }
  }

  .pdf-inner {
    background-color: #fafafa;
    box-shadow: inset 0px 0px 20px 0px #a8a8a8;

    .fake-pdf {
      padding: 1em 2em;
      opacity: 0.5;
      filter: blur(4px);

      .section-header {
        border: 1px solid #000;
        padding: 0.5em;
      }

      .row {
        margin-left: 2em;
      }

      .footer-row {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@keyframes left-to-right {
  0% {
    left: -10%;
  }
  100% {
    left: 110%;
  }
}

@keyframes slide-in {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

@keyframes slide-out {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

.slide-down-leave-active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  transition: 0.5s;
}

.slide-down-enter-active {
  transition: 0.5s;
}

.slide-down-enter {
  opacity: 0;
  transform: translate(0, -100%);
}

.slide-down-enter-to,
.slide-down-leave {
  opacity: 1;
  transform: translate(0);
}

.slide-down-leave-to {
  opacity: 0;
  transform: translate(0, -100%);
}

.fade-in-leave-active,
.fade-in-enter-active {
  transition: 0.25s;
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-to,
.fade-in-leave {
  opacity: 1;
}

.fade-in-leave-to {
  opacity: 0;
}

@media (min-width: 1264px) {
  .sidebar-container {
    &.flex.lg2-5 {
      flex-basis: 21%;
      flex-grow: 0;
      max-width: 21%;
    }
  }

  .main-container {
    &.flex.lg9-5 {
      flex-basis: 79%;
      flex-grow: 0;
      max-width: 79%;
    }
  }
}

@media (max-width: 1264px) {
  .observations .observation-container:nth-child(2n) {
    padding-left: 0.5em;
    padding-right: 0;
  }
  .observations .observation-container:nth-child(2n - 1) {
    padding-right: 0.5em;
    padding-left: 0;
  }
}

@media (max-width: 960px) {
  .section-header {
    flex-wrap: wrap;

    .section-name {
      width: 100%;
      margin-bottom: 0.5em;
      border-bottom: 2px solid #e8e8e8;
    }

    a {
      margin-left: auto;
    }
  }

  .sidebar-container {
    position: absolute;
    top: 0;
    z-index: 1;
    display: none;

    &.display {
      display: block;
      animation: slide-in 0.5s ease-out forwards;
    }

    &.remove {
      display: block;
      animation: slide-out 0.5s ease-out forwards;
    }
  }

  .observation-container.fake {
    .defect-card {
      .defect-header {
        min-height: 4em;
        max-height: 4em;
      }

      .defect-body {
        height: 26em;
      }
    }
  }
}

@media (max-width: 600px) {
  .observations .observation-container:nth-child(n) {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

<style lang="scss">
#report {
  .report-text {
    ul {
      margin-bottom: 1em;
    }

    li {
      list-style: disc inside;
    }
  }

  .pano-button {
    z-index: 1099;
    position: absolute;
    top: 54px;
    right: 16px;
    background: green;
    padding: 12px 16px;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    color: white;

    .icon {
      padding-right: 8px;
    }
  }

  .defect-card,
  .tab-observation {
    .quick-edit {
      display: none;
      position: absolute;
      margin: 0;
      top: 1em;
      right: 1em;
      background-color: #fff;
      color: #888;
      border: 1px solid #888;
      box-shadow: none;
      transition: all 0.1s linear;
      font-size: 0.75em;
      padding: 0.5em 1em;
      line-height: 1.75em;
      align-self: flex-start;

      .fa {
        &-solid,
        &-regular {
          color: inherit;
        }
      }

      &:hover {
        border-color: #5c9ccf;
        color: #5c9ccf;
      }
    }
  }

  .report-option {
    color: #45769c;

    .v-list__tile__title {
      height: auto;

      a {
        color: inherit;
      }

      .icon-container {
        display: inline-block;
        margin-right: 0.25em;
      }
    }
  }

  .options-row {
    background-color: #3b464f;
    height: 3.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2em;

    .sidebar-option {
      display: none;
    }

    &.scroll {
      position: fixed;
      top: 0;
      right: 0;
      width: 79%;
      z-index: 1;
    }
  }

  .options {
    display: flex;

    .option {
      height: 3em;
      line-height: 2em;
      cursor: pointer;
      color: #fff;
      background-color: #45769c;
      color: #dfe6f6;
      padding: 0.5em 1em;
      font-size: 0.75em;
      display: inline-block;
      transition: background 0.1s linear;
      border-right: 1px solid #3b464f;

      &.active {
        color: #fff;
        background-color: #5c9ccf;
      }

      &:hover {
        background-color: #5c9ccf;
      }

      &:first-child {
        border-radius: 2px 0 0 2px;
      }

      &:last-child {
        border-radius: 0 2px 0 2px;
        border-right: none;
      }
    }
  }

  .info-tooltip {
    color: #fff;
    background-color: #65c635;
    font-size: 1em;
    max-width: 20em;
    text-align: center;
  }

  &.sample-report {
    .defect-card,
    .tab-observation {
      .quick-edit {
        visibility: hidden;
      }
    }
  }

  .observations {
    .observation-container {
      &:nth-child(3n) {
        .defect-options .select {
          left: -0.25em;
        }
      }

      &:nth-child(3n + 1) {
        .defect-options .select {
          left: -1.25em;
        }
      }

      &:nth-child(3n - 1) {
        .defect-options .select {
          left: -0.75em;
        }
      }
    }
  }
}

.firestore-not-saved {
  background-color: #5c9ccf;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  position: relative;
  top: 1rem;
}

@media (max-width: 1264px) {
  #report {
    .options-row.scroll {
      width: 66.66%;
    }

    .observations .observation-container:nth-child(2n) {
      .defect-options .select {
        left: -0.75em;
      }
    }

    .observations .observation-container:nth-child(2n - 1) {
      .defect-options .select {
        left: -1.25em;
      }
    }
  }
}

@media (max-height: 768px) and (min-width: 1024px) {
  #report {
    .sidebar-container {
      &.flex.lg2-5 {
        flex-basis: 22.5%;
        flex-grow: 0;
        max-width: 22.5%;
      }

      .sidebar-top {
        padding-left: 1em;
        padding-right: 1em;
      }
    }

    .main-container {
      &.flex.lg9-5 {
        flex-basis: 77.5%;
        flex-grow: 0;
        max-width: 77.5%;
      }
    }

    .options-row.scroll {
      width: 77.5%;
    }
  }
}

@media (max-width: 960px) {
  #report {
    .v--modal-box.v--modal {
      width: 95% !important;
      left: 2.5% !important;
    }

    .options-row.scroll {
      width: 100%;
    }

    .options {
      font-size: 16px;
    }

    .options .option {
      width: 4em;
      text-align: center;
    }

    .options-row .sidebar-option {
      display: inline-block;
    }

    .pdf-btn span,
    .option span {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  #report .observations .observation-container:nth-child(n) {
    .defect-options .select {
      left: -1em;
    }
  }
}
</style>
