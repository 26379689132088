<template>
  <div class="get-quotes-modal__body--contact">
    <h1 class="get-quotes-modal__body__title">Confirm your contact info</h1>
    <div class="get-quotes-modal__body__subtitle">
      How do you want contractors to connect with you?
    </div>
    <form
      id="get-quote-form"
      name="get-quote-form"
      class="get-quotes-modal__body__form"
      @submit.prevent="handleSubmit"
    >
      <div class="get-quotes-modal__body__form__group">
        <div class="get-quotes-modal__body__form__field">
          <label
            for="first_name"
            class="get-quotes-modal__body__form__field__label"
          >
            First Name*
          </label>
          <input
            id="first_name"
            :value="form.first_name"
            name="first_name"
            type="text"
            class="browser-default get-quotes-modal__body__form__field__input"
            placeholder="First name"
            required
            @change="handleChange"
          />
        </div>
        <div class="get-quotes-modal__body__form__field">
          <label
            for="first_name"
            class="get-quotes-modal__body__form__field__label"
          >
            Email Address*
          </label>
          <input
            id="email"
            :value="form.email"
            name="email"
            type="email"
            class="browser-default get-quotes-modal__body__form__field__input"
            placeholder="example@email.com"
            required
            @change="handleChange"
          />
        </div>
      </div>
      <div class="get-quotes-modal__body__form__group">
        <div class="get-quotes-modal__body__form__field">
          <label
            for="last_name"
            class="get-quotes-modal__body__form__field__label"
            @change="handleChange"
          >
            Last Name
          </label>
          <input
            id="last_name"
            :value="form.last_name"
            name="last_name"
            type="text"
            class="browser-default get-quotes-modal__body__form__field__input"
            placeholder="Last Name"
            @change="handleChange"
          />
        </div>
        <div class="get-quotes-modal__body__form__field">
          <label for="phone" class="get-quotes-modal__body__form__field__label">
            Phone Number
          </label>
          <input
            id="phone"
            :value="form.phone"
            :required="
              form.contact_method === 'phone' ||
              form.contact_method === 'text/sms'
            "
            name="phone"
            type="text"
            class="browser-default get-quotes-modal__body__form__field__input"
            placeholder="###-###-####"
            @change="handleChange"
          />
        </div>
      </div>
      <div class="get-quotes-modal__body__form__group">
        <div class="get-quotes-modal__body__form__field">
          <label
            for="contact_method"
            class="get-quotes-modal__body__form__field__label"
            @change="handleChange"
          >
            Preferred contact method*
          </label>
          <select
            id="contact_method"
            class="
              browser-default
              get-quotes-modal__body__form__field__input
              get-quotes-modal__body__form__field__input--select
            "
            name="contact_method"
            required
            @change="handleChange"
          >
            <option
              v-for="contactMethod in contactMethods"
              :id="`${contactMethod.value}--option`"
              :key="contactMethod.value"
              :value="contactMethod.value"
            >
              {{ contactMethod.label }}
            </option>
          </select>
          <i class="fa fa-chevron-down" />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      contactMethods: [
        { value: '', label: 'Select one' },
        { value: 'text/sms', label: 'Text/SMS' },
        { value: 'email', label: 'Email' },
        { value: 'phone', label: 'Phone call' },
      ],
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('changeForm', e.target.name, e.target.value)
    },
    handleSubmit(e) {
      this.$emit('submit')
    },
  },
}
</script>
