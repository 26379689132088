<template>
  <div class="tagger">
    <div class="tagger-container">
      <v-text-field
        v-model="dummyVal"
        class="static-field"
        label="Tags"
        :placeholder="placeholder"
        :class="{ focus: tbdFocus }"
      />
      <div class="cover-row">
        <div class="tags">
          <div v-for="(tag, i) in tags" :key="i" class="chip">
            {{ tag }}
            <em class="fa-solid fa-close" @click="removeTag(i)" />
          </div>
          <v-text-field
            ref="traveller"
            v-model="tbdTag"
            class="travel-field"
            :class="{ 'full-width': tags.length === 0 }"
            @keyup="placeOrDebounceSearch"
            @focus="tbdFocus = true"
            @blur="tbdFocus = false"
          />
        </div>
        <div v-if="showSearchResults" class="search-results">
          <div
            v-for="(result, idx) in searchResults"
            :key="idx"
            class="result"
            @click="selectResult(result)"
          >
            <div class="chip">
              {{ result.attributes.name }}
            </div>
          </div>
          <div
            v-if="!noResults"
            class="result text-result light"
            @click="selectTbd"
          >
            <div>
              <span>Not here? Enter a comma or click here to add</span>
              <div class="chip">{{ cleanTbd }}</div>
              <span>as a Tag.</span>
            </div>
          </div>
          <div v-else class="result text-result light" @click="selectTbd">
            <div>
              <span>No results found. Enter a comma or click here to add</span>
              <div class="chip">{{ cleanTbd }}</div>
              <span>as a Tag.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import axios from '../../utils/AxiosService'
import dig from '../../utils/Dig'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['companyId', 'tagType', 'value'],
  data() {
    return {
      dummyVal: null,
      searchResults: [],
      tags: [],
      tbdFocus: false,
      tbdTag: '',
    }
  },
  computed: {
    backspaceKeyCode() {
      return 8
    },
    cleanTbd() {
      return (this.tbdTag || '').trim().replace(/,/g, '')
    },
    commaKeyCode() {
      return 188
    },
    placeholder() {
      return this.tbdTag.length > 0 || this.tags.length > 0
        ? null
        : 'Enter comma separated Tags'
    },
    searchParams() {
      const params = {
        company_id: this.companyId,
        q: this.cleanTbd,
      }

      return Object.keys(params)
        .map((k) => {
          return [k, params[k]].join('=')
        })
        .join('&')
    },
    searchUrl() {
      return `/api/v1/tags?${this.searchParams}`
    },
    showSearchResults() {
      return this.searchResults.length > 0 || this.noResults
    },
  },
  watch: {
    tags(newV, oldV) {
      if (newV.length > 0 || this.tbdTag.length > 0) this.dummyVal = '&nbsp;'
      if (newV.length === 0 && this.tbdTag.length === 0) this.dummyVal = null
      this.$emit('input', newV.join(','))
    },
    tbdTag(newV, oldV) {
      if (newV.length > 0 || this.tags.length > 0) this.dummyVal = '&nbsp;'
      if (newV.length === 0 && this.tags.length === 0) this.dummyVal = null
    },
  },
  created() {
    const vm = this
    this.debounceSearch = debounce(() => {
      vm.search()
    }, 150)
  },
  mounted() {
    this.parseValue()
  },
  methods: {
    focusTraveller() {
      this.$refs.traveller.focus()
    },
    handleDeletion() {
      if (this.cleanTbd.length === 0) {
        const tagValue = this.tags.splice(this.tags.length - 1, 1)
        this.tbdTag = tagValue
      }
    },
    parseValue() {
      if (this.value) this.tags = this.value.split(',')
    },
    placeOrDebounceSearch(e) {
      if (e.keyCode === this.commaKeyCode) {
        if (this.cleanTbd.length > 0) {
          this.selectTbd()
        } else {
          this.removeExtraComma()
        }
      } else if (e.keyCode === this.backspaceKeyCode) {
        this.handleDeletion()
        this.resetSearch()
      } else {
        this.debounceSearch()
      }
    },
    removeTag(idx) {
      this.tags.splice(idx, 1)
      this.resetSearch()
      this.focusTraveller()
    },
    removeExtraComma() {
      this.tbdTag = this.tbdTag.replace(/,/g, '')
    },
    resetSearch() {
      this.searchResults = []
      this.noResults = false
    },
    search() {
      if ((this.cleanTbd || '').length > 1) {
        axios.get(this.searchUrl).then((response) => {
          if (dig(response, 'data.data', []).length > 0) {
            this.noResults = false
            this.searchResults = []
            response.data.data.forEach((t, i) => {
              if (i < 5) this.searchResults.push(t)
            })
          } else {
            this.noResults = true
            this.searchResults = []
          }
        })
      }
    },
    selectResult(result) {
      if (!this.tags.find((t) => t === result.attributes.name)) {
        this.tags.push(result.attributes.name)
      }
      this.tbdTag = ''
      this.resetSearch()
      this.focusTraveller()
    },
    selectTbd() {
      const fakeResult = { attributes: { name: this.cleanTbd } }
      this.selectResult(fakeResult)
    },
  },
}
</script>

<style scoped>
.tagger-container {
  position: relative;
}

.cover-row {
  position: absolute;
  top: 0;
  width: 100%;
}

.tags {
  padding-top: 1em;
}

.tags .chip {
  float: left;
}

.tags .chip,
.result .chip {
  position: relative;
  color: #fff;
  background-color: rgb(92, 156, 207);
  min-width: 5em;
  text-align: center;
  height: 25px;
  line-height: 1.7;
  padding-right: 2.5em;
}

.chip .fa-close {
  position: absolute;
  right: 1em;
  top: 0.45em;
  cursor: pointer;
}

.result .chip {
  padding-right: 12px;
}

.search-results {
  z-index: 1;
  position: absolute;
  width: 100%;
  margin-top: 2em;
  background-color: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.search-results .result {
  padding: 0.5em;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background 0.1s linear;
}

.search-results .result:hover {
  background-color: #f9f9f9;
}

.result.text-result {
  display: flex;
  align-items: center;
}

.text-result .chip {
  margin: 0 0.5em;
  max-width: 10em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 0.6em;
}

.travel-field {
  margin: 0;
  margin-top: -3px;
  height: auto;
  float: left;
}

.travel-field.full-width {
  width: 100%;
  margin-top: -1em;
}
</style>

<style>
.v-text-field__slot input {
  text-indent: 1px;
}
.tagger .static-field .v-text-field__slot input {
  color: transparent;
}
.tagger .static-field.focus input {
  border-bottom: 1px solid #45769c;
  box-shadow: 0 1px 0 0 #45769c;
}
.tagger .travel-field:not(.full-width) {
  max-width: 20%;
  padding-top: 0;
  margin-top: -4px;
}
</style>
