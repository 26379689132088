const serializedV10SampleReportAdapter = (report) => {
  const deserializedReport = Object.assign({}, report.data.attributes)

  // Serialized objects
  deserializedReport.company = { ...deserializedReport.company.table }
  deserializedReport.inspection = { ...deserializedReport.inspection.table }
  deserializedReport.settings = { ...deserializedReport.settings.table }
  deserializedReport.stats = { ...deserializedReport.stats.table }

  // Serialized arrays
  deserializedReport.inspectors = deserializedReport.inspectors.map(
    (inspector) => inspector.table
  )
  deserializedReport.sections = deserializedReport.sections.data.map(
    (section) => ({
      ...section.attributes,
      items: section.attributes.items.data.map((item) => ({
        ...item.attributes,
        observations: item.attributes.observations.data.map((observation) => ({
          ...observation.attributes,
          photos: observation.attributes.photos.map((photo) => photo.table),
        })),
      })),
    })
  )

  return deserializedReport
}

export default serializedV10SampleReportAdapter
