import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'materialize-css/dist/css/materialize.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/css/plugins/video.min.css'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/css/plugins/code_view.min.css'
import '@/assets/common.scss'

import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/link.min'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/code_view.min.js'

import lightGallery from 'lightgallery'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from 'lightgallery/plugins/video'
import lgThumbnail from 'lightgallery/plugins/thumbnail'

import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-video.css'
import 'lightgallery/css/lg-thumbnail.css'

import Vue from 'vue'

import './filters/commonFilters.js'
import './plugins/commonPlugins.js'

import config from './utils/config'

import App from './App.vue'
import Report from './components/reports/Report.vue'
import ReportFeedback from './components/reports/ReportFeedback.vue'
import store from './store/modules/reports/index.js'

import Vuetify from 'vuetify'
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal'
import VueFroala from 'vue-froala-wysiwyg'
import VueRouter from 'vue-router'
import VuePannellum from 'vue-pannellum'
import VueToaster from './plugins/toasterPlugin.js'

import sessionDebuggerService from '@spectora/frontend.services.session-debugger-service'
import vueErrorService from '@spectora/frontend.services.vue-error-service'
import productAnalyticsService from '@spectora/frontend.services.product-analytics-service'

import { version } from '../package.json'

Vue.use(Vuetify, {
  theme: {
    primary: '#5c9ccf',
    secondary: '#45769c',
    error: '#d53636',
    info: '#65c635',
    success: '#65c635',
    warning: '#d53636',
  },
})
Vue.use(VueMeta)
Vue.use(VModal)
Vue.use(VueFroala)
Vue.use(VueRouter)
Vue.use(VueToaster, { store })
Vue.component('VPannellum', VuePannellum)

const routes = [
  { name: 'report', path: '/v/reports/:id', component: Report },
  {
    name: 'report-feedback',
    path: '/v/reports/:id/how-to-access-your-report',
    component: ReportFeedback,
  },
  {
    name: 'report-not-published',
    path: '/v/reports/:id/not-published',
    component: ReportFeedback,
  },
  {
    name: 'sample-report',
    path: '/home-inspectors/:companySlug/sample_report',
    component: Report,
  },
]

const router = new VueRouter({ mode: 'history', routes: routes })

const PRODUCT_ANALYTICS_APP_ID = config('PRODUCT_ANALYTICS_APP_ID')
const SENTRY_DSN = config('SENTRY_DSN')
const SESSION_DEBUGGER_TOKEN = config('SESSION_DEBUGGER_TOKEN')

window.lightGallery = lightGallery
window.lgZoom = lgZoom
window.lgVideo = lgVideo
window.lgThumbnail = lgThumbnail

// Enable LogRocket
if (SESSION_DEBUGGER_TOKEN) {
  sessionDebuggerService.init({ token: config('SESSION_DEBUGGER_TOKEN') })
}

// Enable product analytics tool - heap?
if (PRODUCT_ANALYTICS_APP_ID) {
  productAnalyticsService.init({ appId: PRODUCT_ANALYTICS_APP_ID })
}

// Enable Vue Sentry
if (SENTRY_DSN) {
  vueErrorService.init({
    Vue,
    dsn: SENTRY_DSN,
    env: config('ENV'),
    router: router,
    release: `${import.meta.env.PACKAGE_NAME}-${
      import.meta.env.PACKAGE_VERSION
    }`,
    sampleRate: config('SENTRY_TRACES_SAMPLE_RATE') || 0.0,
    tracingOrigins: [
      'localhost',
      'reports-localhost.spectora.com',
      'reports-development.spectora.com',
      'reports-staging.spectora.com',
      'reports.spectora.com',
      /^\//,
    ],
    version: import.meta.env.PACKAGE_VERSION,
  })
}

// eslint-disable-next-line no-console
console.log(`web-viewer version ${version}`)

document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('report'))

  // eslint-disable-next-line no-unused-vars
  const app = new Vue({
    el,
    store,
    router,
    render: (h) => h(App),
  })
})
