import { isEmpty, pick, pickBy } from 'lodash'
import { v4 as uuid } from 'uuid'
import { serverTimestamp } from '@/db'

const pickDefined = (obs) => pickBy(obs, (v) => v !== undefined)

const buildChanges = ({ record, values }) => {
  const changes = {}

  for (const [key, value] of Object.entries(values)) {
    // Discard values that haven't changed.
    if (record[key] !== value) {
      changes[key] = value
    }
  }

  return changes
}

const buildChangeset = ({ record, values, meta }) => {
  const changes = buildChanges({ record, values })

  if (isEmpty(changes)) {
    return
  }

  const data = {
    id: uuid(),
    original_id: record.id,
    timestamp: Date.now(),
    origin: 'viewer',
    received: serverTimestamp(),
    ...changes,
    ...meta,
  }

  return Object.assign(
    data,
    pick(record, ['inspection_id', 'report_id', 'company_id'])
  )
}

const createChangeset = ({ collection, meta, record, values }) => {
  // Abort if empty record or if collection unresolved
  if (![collection, record, record.id].every((x) => x)) {
    return
  }

  const changeset = buildChangeset({ meta, record, values })

  if (isEmpty(changeset)) {
    return
  }

  // eslint-disable-next-line no-console
  console.log('[updating]', changeset, changeset.received)

  // Create a new change for this record
  return collection.doc(changeset.id).set(pickDefined(changeset))
}

export default createChangeset
