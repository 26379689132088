<template>
  <div class="row">
    <div class="col s12">
      <!-- eslint-disable vue/no-mutating-props -->
      <v-select
        v-model="condition.subfield"
        :items="template && template.choices"
        hide-details
        placeholder="Select a field..."
        @input="handleSubfieldChange"
      />
    </div>
    <div class="col s12">
      <v-select
        v-model="condition.operator"
        :items="operatorOptions"
        hide-details
        placeholder="Select an operator..."
        @input="handleChange"
      />
    </div>
    <div class="col s12">
      <v-select
        v-if="isFoundationSubfield"
        v-model="condition.value"
        :items="foundationOptions"
        hide-details
        @input="handleChange"
      />
      <v-text-field
        v-if="!isFoundationSubfield"
        v-model="condition.value"
        type="number"
        placeholder="Add a numeric value..."
        hide-details
        @input="handleChange"
      />
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    condition: {
      type: Object,
      default: () => {},
    },
    template: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isFoundationSubfield() {
      return this.condition.subfield === 'foundation'
    },
    foundationOptions() {
      return [
        { text: 'Basement', value: 'basement' },
        { text: 'Crawlspace', value: 'crawlspace' },
        { text: 'Slab', value: 'slab' },
      ]
    },
    operatorOptions() {
      const { subfield } = this.condition

      if (
        subfield === 'lot_size' ||
        subfield === 'square_feet' ||
        subfield === 'year_built'
      ) {
        return [
          {
            text: 'Greater than or equal to',
            value: 'greater_than_or_equal_to',
          },
          { text: 'Less than', value: 'less_than' },
        ]
      }

      if (subfield === 'foundation') {
        return [
          { text: 'Equal to', value: 'equal_to' },
          { text: 'Not equal to', value: 'not_equal_to' },
        ]
      }

      return []
    },
  },
  methods: {
    handleSubfieldChange() {
      this.condition.operator = null // eslint-disable-line vue/no-mutating-props
      this.condition.value = null // eslint-disable-line vue/no-mutating-props
      this.$emit('change', { condition: this.condition })
    },
    handleChange() {
      this.$emit('change', { condition: this.condition })
    },
  },
}
</script>

<style></style>
