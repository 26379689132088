<template>
  <v-flex
    xs12
    class="tab-observation"
    :class="{ 'xs12 sm12 md12 wide': wide, 'xs12 sm6 md6 lg4': !wide }"
  >
    <v-layout wrap>
      <v-flex v-if="!isOverview" xs12 class="name">
        <strong>
          {{ item.attributes.name }}:
          <span class="fg-medium-gray">{{ observation.attributes.name }}</span>
        </strong>
      </v-flex>
      <v-flex v-else xs12 class="name">
        <strong>{{ observation.attributes.name }}</strong>
      </v-flex>
      <v-flex xs12 class="subtitle small">{{
        observation.attributes.location
      }}</v-flex>
      <v-flex v-if="displayValue" xs12 class="value">
        {{ observation.attributes.value }}
        <span v-if="observation.attributes.value2">
          - {{ observation.attributes.value2 }}</span
        >
        <span v-if="observation.attributes.unit_type">
          {{ observation.attributes.unit_type }}</span
        >
      </v-flex>
      <!-- eslint-disable vue/no-v-html -->
      <v-flex xs12>
        <div
          class="text show-bullets"
          :class="{ small: wide }"
          v-html="observation.attributes.text"
        />
        <!-- eslint-enable -->
      </v-flex>
      <v-flex v-if="hasPhotos" xs12>
        <v-layout :id="galleryId" wrap class="photo-layout">
          <template
            v-for="(photo, index) in observation.attributes.observation_photos"
          >
            <v-flex
              :key="photo.id"
              class="tab-photo"
              :class="{ xs12: !wide, 'xs12 md3': wide }"
            >
              <photo
                :photo="photo"
                :photo-index="index"
                @openGallery="openGallery"
              />
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
    </v-layout>

    <div v-if="canEdit" class="quick-edit btn" @click="setAsEditObservation">
      <div class="fa-solid fa-pencil" />
      Edit
    </div>
  </v-flex>
</template>

<script>
import { mapState } from 'vuex'
import Photo from './Photo.vue'
import { buildGalleryItem } from '@/utils'
import EventBus from '@/services/EventBus'
import config from '@/utils/config'

const LIGHT_GALLERY_KEY = config('LIGHT_GALLERY_KEY')

export default {
  components: {
    Photo,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['observation'],
  computed: {
    ...mapState('reports', ['company', 'loggedIn', 'userProfile']),
    galleryId() {
      return `observation_photos-${this.observation.attributes.id}`
    },
    canEdit() {
      return (
        this.loggedIn &&
        ((['inspector', 'manager'].includes(this.userProfile.type) &&
          this.userProfile.attributes.company_id ===
            this.company.attributes.id) ||
          this.userProfile.type === 'admin')
      )
    },
    displayValue() {
      return (
        this.observation.attributes.value &&
        this.observation.attributes.value !== '' &&
        !['boolean', 'signature'].includes(
          this.observation.attributes.answer_type
        )
      )
    },
    hasEmbed() {
      return (
        this.observation.attributes.text &&
        this.observation.attributes.text.includes('iframe')
      )
    },
    hasPhotos() {
      return this.observation.attributes.observation_photos.length > 0
    },
    isOverview() {
      return this.observation.attributes.report_item.attributes.is_overview
    },
    item() {
      return this.observation.attributes.report_item
    },
    wide() {
      return (
        (this.observation.attributes.text &&
          this.observation.attributes.text.replace(/(<([^>]+)>)/gi, '').length >
            75) ||
        this.observation.attributes.observation_photos.length > 1 ||
        this.hasEmbed
      )
    },
  },
  mounted() {
    EventBus.$on('closeGallery', this.closeGallery)

    this.$galleryId = document.getElementById(this.galleryId)
    if (!this.$galleryId) return

    this.$galleryId.addEventListener('lgAfterClose', this.hidePanoButton)
    this.$galleryId.addEventListener('lgAfterSlide', (event) => {
      const { index } = event.detail
      const record = this.observation.attributes.observation_photos[index]

      if (record.attributes.is_pano) {
        this.$store.commit('reports/setValue', {
          key: 'currentPanoPhoto',
          value: record,
        })
        this.$store.commit('reports/setValue', {
          key: 'showPanoButton',
          value: true,
        })
        return
      }

      this.$store.commit('reports/setValue', {
        key: 'currentPanoPhoto',
        value: null,
      })
      this.$store.commit('reports/setValue', {
        key: 'showPanoButton',
        value: false,
      })
    })

    this.$gallery = window.lightGallery(this.$galleryId, {
      dynamic: true,
      zoom: true,
      licenseKey: LIGHT_GALLERY_KEY,
      numberOfSlideItemsInDom: 3,
      plugins: [window.lgZoom, window.lgVideo, window.lgThumbnail],
      dynamicEl: this.buildDynamicGallery(),
    })
  },
  methods: {
    closeGallery() {
      if (!this.$gallery) return
      this.$gallery.closeGallery(true)
    },
    hidePanoButton() {
      this.$store.commit('reports/setValue', {
        key: 'showPanoButton',
        value: false,
      })
    },
    setAsEditObservation() {
      this.$store.commit('reports/setValue', {
        key: 'editObservation',
        value: this.observation,
      })
    },
    buildDynamicGallery() {
      return this.observation.attributes.observation_photos.map((record) => {
        return buildGalleryItem(record)
      })
    },
    openGallery({ index, photo }) {
      if (!this.$gallery) return

      if (!photo) {
        return this.$gallery.openGallery(index || 0)
      }

      if (photo.attributes.is_pano) {
        return this.$store.commit('reports/setValue', {
          key: 'overlayPhoto',
          value: photo,
        })
      }

      return this.$gallery.openGallery(index || 0)
    },
  },
}
</script>

<style lang="scss" scoped>
#report .tab-observation:hover .quick-edit {
  display: inline-block;
}

.tab-observation {
  position: relative;
  margin: 1em 0;
  overflow: hidden;
}

.subtitle {
  color: #777;
}

.value,
.text {
  padding-left: 1em;
}

.photo-layout {
  margin-left: -0.5em;

  .tab-photo {
    padding: 0.5em;
  }
}
</style>

<style lang="scss">
.tab-observation {
  .text {
    ul {
      padding-left: 0.5em;
      margin: 1em 0;
    }
    p {
      margin: 0.5em 0;
    }
  }

  .tab-photo video {
    max-height: none;
  }
}
</style>
