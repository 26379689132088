// Avoid using this for new code. Tracking vuex changes is a PitA with this function
export const setValue = (state, { key, value }) => {
  state[key] = value
}

export const setCompanyId = (state, value) => {
  state.companyId = value
}

export const setInspection = (state, value) => {
  state.inspection = value
}

export const setIsAdmin = (state, value) => {
  state.isAdmin = value
}

export const setIsFirestoreAuthenticated = (state, value) => {
  state.isFirestoreAuthenticated = value
}

export const setUserEmail = (state, value) => {
  state.userEmail = value
}

export const setUserId = (state, value) => {
  state.userId = value
}

export const setAccessTokenValid = (state, value) => {
  state.accessTokenValid = value
}
