<template>
  <v-layout justify-center wrap class="rrb">
    <v-flex xs12 md10>
      <v-layout wrap class="rrb-form">
        <v-flex xs6 md4 vpx-2>
          <v-text-field v-model="rrb.name" label="Document Name" />
          <v-select
            v-model="rrb.sortBy"
            label="Sort Document By"
            :items="sortItems"
          />
        </v-flex>
        <v-flex xs6 md4 vpx-2>
          <froala
            v-model="rrb.text"
            label="Header Text"
            tag="textarea"
            :config="froalaConfig"
          />
        </v-flex>
        <v-flex xs12 md4 vpx-2 class="actions-column">
          <div class="light">Total Credit Requested</div>
          <div class="credit">
            {{ company.attributes.currency_sym }}{{ creditRequested }}
          </div>
          <div class="button-row">
            <div class="btn btn-flat btn-blank" @click="preview">preview</div>
            <div class="btn" @click="save">
              <div v-if="!saving" class="fa-regular fa-square-check" />
              <div v-else class="fa-solid fa-spinner fa-spin" />
              create
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
    <div class="close light" @click="close">
      <div class="fa-solid fa-close" />
    </div>
    <v-layout justify-center class="options-container">
      <v-flex xs12 md10 class="options-row">
        <div class="options">
          <div
            class="option"
            :class="{ active: activeFilter === 'all' }"
            @click="setActiveFilter('all')"
          >
            <div class="fa-regular fa-file-lines" />
            <span>All</span>
          </div>
          <div
            class="option"
            :class="{ active: activeFilter === 'rh' }"
            @click="setActiveFilter('rh')"
          >
            <div class="fa-solid fa-circle-minus" />
            <span
              >Recommendations &
              {{ report.attributes.settings.category_high }}</span
            >
          </div>
          <div
            class="option"
            :class="{ active: activeFilter === 'hazards' }"
            @click="setActiveFilter('hazards')"
          >
            <div class="fa-solid fa-warning" />
            <span>{{ report.attributes.settings.category_high }}</span>
          </div>
        </div>
        <div class="options">
          <div class="option" @click="selectVisible">
            <div class="fa-solid fa-check" />
            Select Visible Defects
          </div>
          <a
            href="https://youtu.be/Ao1Hfjv-b3w"
            target="_blank"
            class="option option-green"
          >
            <div class="fa-solid fa-circle-question" />
            <span>Help</span>
          </a>
        </div>
      </v-flex>
    </v-layout>

    <share-r-r-b
      v-if="reportView"
      :report-view="reportView"
      @closed="reportView = null"
    />
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import { froalaOptionsBare as froalaConfig } from '@/utils/froala'
import railsService from '@/utils/AxiosService'
import ShareRRB from './modals/ShareRRB.vue'
import config from '@/utils/config'
import { captureException } from '@/utils'

const RAILS_DOMAIN = config('RAILS_DOMAIN')

export default {
  components: {
    ShareRRB,
  },
  data() {
    return {
      froalaConfig: Object.assign(froalaConfig, {
        height: 150,
        placeholderText:
          'Text you want to display at the top of the repair request.',
      }),
      previewing: false,
      reportView: null,
      rrb: {
        name: 'Repair Request List',
        sortBy: '',
        text: '',
      },
      saving: false,
    }
  },
  computed: {
    ...mapState('reports', [
      'activeFilter',
      'company',
      'observations',
      'report',
      'reportViewObservations',
    ]),
    ...mapState('reports', {
      existingRRB: 'reportView',
    }),
    creditRequested() {
      const num = this.reportViewObservations.reduce((summ, o) => {
        if (o.selected && o.displayCredit) summ += Number(o.credit || '0')
        return summ
      }, 0)

      return Number(num).toFixed(2)
    },
    displayHazards() {
      return this.activeFilter === 'hazards'
    },
    sampleReport() {
      return this.$route.name === 'sample-report'
    },
    selectedObservations() {
      return this.reportViewObservations.filter((o) => {
        return o.selected
      })
    },
    sortItems() {
      return [
        { text: 'Original Report Order', value: '' },
        { text: 'Severity Category', value: 'category' },
        { text: 'Recommendation', value: 'recommendation' },
        { text: 'Dollar Amount (high to low)', value: 'amount_high_to_low' },
        { text: 'Dollar Amount (low to high)', value: 'amount_low_to_high' },
      ]
    },
    summaryCategories() {
      const cats = []
      if (this.report.attributes.settings.category_high_in_summary) cats.push(1)
      if (this.report.attributes.settings.category_low_in_summary) cats.push(-1)
      if (this.report.attributes.settings.category_med_in_summary) cats.push(0)

      return cats
    },
    viewableObservations() {
      return this.observations.filter((o) => {
        return (
          o.attributes.comment_type === 'defect' &&
          (this.activeFilter === 'all' ||
            (this.displayHazards && o.attributes.category === 1) ||
            (this.activeFilter === 'summary' &&
              this.summaryCategories.includes(o.attributes.category)))
        )
      })
    },
  },
  watch: {
    existingRRB() {
      this.fillData()
    },
  },
  mounted() {
    if (this.existingRRB) this.fillData()
  },
  methods: {
    close() {
      this.$store.commit('reports/setValue', {
        key: 'activeFilter',
        value: 'full',
      })
      this.$store.commit('reports/setValue', { key: 'rrb', value: false })
    },
    createRRB(params) {
      railsService
        .post(`/api/v1/public/reports/${this.report.id}/report_views`, {
          attributes: params,
        })
        .then((response) => {
          this.saving = false
          const reportView = response.data.data
          if (reportView.attributes.preview) {
            this.previewing = false
            window.open(
              `https://${RAILS_DOMAIN}/report_views/${reportView.id}`,
              '_blank'
            )
          } else {
            this.reportView = reportView
            this.$store.dispatch('notifier/show', {
              type: 'Saved',
              msg: 'Repair Request Created!',
            })
          }
        })
        .catch((error) => {
          captureException(error)
          if (params.preview) {
            this.previewing = false
            this.$store.dispatch('notifier/show', {
              type: 'Error',
              msg: 'There was an error previewing your Repair Request.',
            })
          } else {
            this.saving = false
            this.$store.dispatch('notifier/show', {
              type: 'Error',
              msg: 'There was an error creating your Repair Request.',
            })
          }
        })
    },
    fillData() {
      const existing = {
        name: this.existingRRB.attributes.name,
        sortBy: this.existingRRB.attributes.sort_by,
        text: this.existingRRB.attributes.header_text,
      }

      this.rrb = existing

      const selectedObs = this.existingRRB.attributes.whitelist.map((o) => {
        o.selected = true
        o.displayCredit = (o.credit || '').length > 0
        o.displayText = (o.request_text || '').length > 0
        return o
      })

      this.$store.commit('reports/setValue', {
        key: 'reportViewObservations',
        value: selectedObs,
      })
    },
    preview() {
      if (this.previewing) return
      this.previewing = true
      const params = this.rrbParams(true)
      this.createRRB(params)
    },
    rrbParams(preview = false) {
      const params = {
        name: this.rrb.name,
        sort_by: this.rrb.sortBy,
        header_text: this.rrb.text,
        report_id: this.report.id,
        type: 'RepairRequestView',
        preview: preview,
        sample: this.sampleReport,
      }

      params.whitelist = this.selectedObservations.map((o) => {
        const obs = {
          observation_id: o.observation_id,
          number: o.number,
          request_text: o.request_text,
        }

        if (o.displayCredit) obs.credit = o.credit
        if (o.displayText) obs.request_text = o.request_text

        return obs
      })
      return params
    },
    save() {
      if (this.saving) return
      this.saving = true

      const params = this.rrbParams()
      this.createRRB(params)
    },
    selectVisible() {
      const selectedObs = this.viewableObservations.map((o) => {
        return {
          observation_id: o.id,
          number: o.number,
          selected: true,
        }
      })

      this.$store.commit('reports/setValue', {
        key: 'reportViewObservations',
        value: selectedObs,
      })
    },
    setActiveFilter(filter) {
      this.$store.commit('reports/setValue', {
        key: 'activeFilter',
        value: filter,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.rrb {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 3px 7px #555;
}

.close {
  position: absolute;
  top: 1em;
  right: 2em;
  cursor: pointer;

  .fa {
    &-solid,
    &-regular {
      font-size: 1.5em;
    }
  }
}

.rrb-form {
  padding: 1em 0;
}

.actions-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.credit {
  width: 100%;
  color: #65c635;
  text-align: center;
  font-size: 1.5em;
}

.options-container {
  background-color: #3b464f;
}

#report .options .option.option-green {
  background-color: #65c635;
}

@media (max-width: 960px) {
  .rrb-form {
    padding-top: 4em;
  }
  .close .fa {
    &-solid,
    &-regular {
      font-size: 2em;
    }
  }
}
</style>

<style>
.rrb textarea {
  padding: 0.25em;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.rrb .v-textarea .v-input__slot:before,
.rrb .v-textarea .v-input__slot:after {
  content: none;
}

.rrb .fr-element.fr-view {
  padding: 0 0.5em;
}
</style>
