<template>
  <span>
    {{ recommendedContractorsTitle }}
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RecommendedContractorsTitle',

  props: {
    lowerCase: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('reports', ['company']),

    recommendedContractorsTitle() {
      const title =
        this.company?.attributes?.settings?.recommended_contractor_title ||
        'Recommended Contractors'

      return this.lowerCase ? title.toLowerCase() : title
    },
  },
}
</script>
