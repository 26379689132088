/** MAPPED FROM:  https://docs.google.com/spreadsheets/d/11pIaS5IzL3EXg-4CQeuwGPRUf0WBH5Me_uOkpD6bFB0/edit#gid=0 */
/**
 *
 *  Internal -> Label -> Angi Id
 * -------------------------------
    carpenter -> Carpenter -> ID/EPT: 39615885
    chimney -> Chimney Contractor -> ID/EPT: 39615669
    concrete -> Concrete Contractor -> ID/EPT: 39615666
    countertop -> Countertop Contractor -> ID/EPT: 39615680
    clean -> Cleaning Service -> ID/EPT: 39615665
    deck -> Deck Contractor -> ID/EPT: 39615667
    door -> Door Repair and Installation Contractor -> ID/EPT: 39615683
    driveway -> Driveway Contractor -> ID/EPT: 39615666
    drywall -> Drywall Contractor -> ID/EPT: 39615668
    electrician -> Electrician -> ID/EPT: 39615883
    fireplace -> Fireplace Contractor -> ID/EPT: 39615669
    garage -> Garage Door Contractor -> ID/EPT: 39615671
    appliance -> >Appliance Repair -> ID/EPT: 39615663
    cabinet -> Cabinet Contractor -> ID/EPT: 39615678
    gc -> General Contractor -> ID/EPT: 39615670
    handyman -> Handyman -> ID/EPT: 39615664
    handyman-diy -> Handyman/DIY -> ID/EPT: 39615664
    hvac -> HVAC Professional -> ID/EPT: 41751867
    insulation -> Insulation Contractor -> ID/EPT: 39615682
    landscape -> Landscaper / Gardener -> ID/EPT: 39615677
    lawn -> Lawncare Professional -> ID/EPT: 39615677
    mold -> Mold Inspector -> ID/EPT: 39615684
    pest -> Pest Control Pro -> ID/EPT: 39615881
    plumber -> Plumber -> ID/EPT: 41751870
    roof -> Roofing Professional -> ID/EPT: 39615681
    siding -> Siding Contractor -> ID/EPT: 41751865
    trees -> Tree Service -> ID/EPT: 39615672
    window -> Window Repair and Installation Contractor -> ID/EPT: 39615884
    structural -> Structural Engineer -> 41751851
    fire-supression -> Fire Supression Contractor -> 41751855
    foundation -> Foundation Contractor -> 41751856
    well -> Well Service Contractor -> 41751859
    locksmith -> Professional Locksmith -> 41751853
    tile -> Tile Contractor -> 41751861
    fence -> Fence Contractor -> 41751866
    grading -> Grading Contractor -> 41751852
    Sheet Metal -> Sheet Metal Contractor -> 41751860
    gutter -> Gutter Contractor -> 41751868
    home-energy -> Home Energy Contractor -> 41751862
    builder -> Builder -> 41751854
    solar -> Solar Panel Contractor -> 41751858
    pool -> Swimming Pool / Spa Contractor -> 41751863
    carpetcleaner -> Carpet Cleaner -> 41751850
    heating/cooling -> Heating and Cooling Contractor -> 39615882
    radon -> Radon Mitigation Specialist -> 41751857
    paint -> Painter -> 39615676
    mason -> Masonry, Concrete, Brick &amp; Stone -> ID/EPT: 39615673
    masonry-contractor -> Masonry Contractor -> 41751869
    Masonry-restoration -> Masonry Restoration Contractor -> 41751869
    environmental -> Environmental Contractor -> 39615684
    waterproof -> Waterproofing Contractor -> 41751856
    flooring -> Flooring Contractor -> 39615664
    sweep -> Chimney Sweep -> 39615669
    engineer -> Professional Engineer -> 41751851
    septic -> Septic System Contractor -> 41751870
    mold-remediation -> Mold Remediation Contractor -> 39615684


    monitor -> Monitor -> NONE
    hoa -> Homeowners Association -> NONE
    utility -> Utility Company -> NONE
    pro -> Qualified Professional (Other) -> NONE
    diy -> DIY -> NONE
    seller -> Inquire With Seller -> NONE


 *
 */

const categoriesMap = {
  carpenter: {
    id: '39615885',
    eptId: '39615885',
  },
  electrician: {
    id: '39615883',
    eptId: '39615883',
  },
  hvac: {
    id: '41751867',
    eptId: '41751867',
  },
  pest: {
    id: '39615881',
    eptId: '39615881',
  },
  window: {
    id: '39615884',
    eptId: '39615884',
  },
  chimney: {
    id: '39615669',
    eptId: '39615669',
  },
  fireplace: {
    id: '39615669',
    eptId: '39615669',
  },
  concrete: {
    id: '39615666',
    eptId: '39615666',
  },
  countertop: {
    id: '39615680',
    eptId: '39615680',
  },
  clean: {
    id: '39615665',
    eptId: '39615665',
  },
  deck: {
    id: '39615667',
    eptId: '39615667',
  },
  door: {
    id: '39615683',
    eptId: '39615683',
  },
  driveway: {
    id: '39615666',
    eptId: '39615666',
  },
  drywall: {
    id: '39615668',
    eptId: '39615668',
  },
  garage: {
    id: '39615671',
    eptId: '39615671',
  },
  appliance: {
    id: '39615663',
    eptId: '39615663',
  },
  cabinet: {
    id: '39615678',
    eptId: '39615678',
  },
  gc: {
    id: '39615670',
    eptId: '39615670',
  },
  handyman: {
    id: '39615664',
    eptId: '39615664',
  },
  'handyman-diy': {
    id: '39615664',
    eptId: '39615664',
  },
  insulation: {
    id: '39615682',
    eptId: '39615682',
  },
  landscape: {
    id: '39615677',
    eptId: '39615677',
  },
  lawn: {
    id: '39615677',
    eptId: '39615677',
  },
  mason: {
    id: '39615673',
    eptId: '39615673',
  },
  mold: {
    id: '39615684',
    eptId: '39615684',
  },
  plumber: {
    id: '41751870',
    eptId: '41751870',
  },
  roof: {
    id: '39615681',
    eptId: '39615681',
  },
  siding: {
    id: '41751865',
    eptId: '41751865',
  },
  trees: {
    id: '39615672',
    eptId: '39615672',
  },
  structural: {
    id: '41751851',
    eptId: '41751851',
  },
  'masonry-contractor': {
    id: '41751869',
    eptId: '41751869',
  },
  'Masonry-restoration': {
    id: '41751869',
    eptId: '41751869',
  },
  'fire-suppression': {
    id: '41751855',
    eptId: '41751855',
  },
  foundation: {
    id: '41751856',
    eptId: '41751856',
  },
  well: {
    id: '41751859',
    eptId: '41751859',
  },
  locksmith: {
    id: '41751853',
    eptId: '41751853',
  },
  tile: {
    id: '41751861',
    eptId: '41751861',
  },
  fence: {
    id: '41751866',
    eptId: '41751866',
  },
  grading: {
    id: '41751852',
    eptId: '41751852',
  },
  'Sheet Metal': {
    id: '41751860',
    eptId: '41751860',
  },
  gutter: {
    id: '41751868',
    eptId: '41751868',
  },
  'home-energy': {
    id: '41751862',
    eptId: '41751862',
  },
  builder: {
    id: '41751854',
    eptId: '41751854',
  },
  solar: {
    id: '41751858',
    eptId: '41751858',
  },
  pool: {
    id: '41751863',
    eptId: '41751863',
  },
  carpetcleaner: {
    id: '41751850',
    eptId: '41751850',
  },
  'heating/cooling': {
    id: '39615882',
    eptId: '39615882',
  },
  radon: {
    id: '41751857',
    eptId: '41751857',
  },
  paint: {
    id: '39615676',
    eptId: '39615676',
  },
  environmental: {
    id: '39615684',
    eptId: '39615684',
  },
  waterproof: {
    id: '41751856',
    eptId: '41751856',
  },
  flooring: {
    id: '39615664',
    eptId: '39615664',
  },
  sweep: {
    id: '39615669',
    eptId: '39615669',
  },
  engineer: {
    id: '41751851',
    eptId: '41751851',
  },
  septic: {
    id: '41751870',
    eptId: '41751870',
  },
  'mold-remediation': {
    id: '39615684',
    eptId: '39615684',
  },
}

export default ({ recommendationId, inspectorId }) => {
  const ANGI_BASE_URL = 'https://match.angi.com'

  const angiEquivalent = categoriesMap[recommendationId?.trim()]
  const params = new URLSearchParams()
  inspectorId && params.append('inspector_id', inspectorId)

  if (!angiEquivalent) {
    const url = new URL(ANGI_BASE_URL)
    url.search = params.toString()

    return url.href
  }

  const url = new URL(`${ANGI_BASE_URL}/dmtm/${angiEquivalent.id}`)
  params.append('entry_point_id', angiEquivalent.eptId)
  params.append('m', 'spectora_pa')

  url.search = params.toString()

  return url.href
}
