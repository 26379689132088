// taken from https://medium.com/@wikycheng/handle-duplicate-ajax-request-and-abort-the-pending-previous-http-call-when-route-changing-using-3706d769f9e6
import axios from 'axios'

import config from '@/utils/config.js'

const RAILS_DOMAIN = config('RAILS_DOMAIN')

const service = axios.create({
  baseURL: `https://${RAILS_DOMAIN}/`, // API url
  withCredentials: true,
  timeout: 30 * 1000, // API request timeout set to 30s
})

// abort duplicate request
const pending = {}
const CancelToken = axios.CancelToken
const removePending = (config, f) => {
  if (config) {
    // make sure the url is same for both request and response
    const url = config.url.replace(config.baseURL, '/')
    // stringify whole RESTful request with URL params
    const flagUrl = `${url}&${config.method}&${JSON.stringify(config.params)}`
    if (flagUrl in pending) {
      if (f) {
        f() // abort the request
      } else {
        delete pending[flagUrl]
      }
    } else {
      if (f) {
        pending[flagUrl] = f // store the cancel function
      }
    }
  }
}

// axios interceptors
service.interceptors.request.use(
  (config) => {
    // you can apply cancel token to all or specific requests
    // e.g. except config.method == 'options'
    config.cancelToken = new CancelToken((c) => {
      if (!config.dupe) {
        removePending(config, c)
      }
    })
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    removePending(response.config)
    return response
  },
  (error) => {
    removePending(error.config)

    if (!axios.isCancel(error)) {
      return Promise.reject(error)
    } else {
      // return empty object for aborted request
      return Promise.resolve({})
    }
  }
)

service.objectToQuery = (obj) => {
  return Object.keys(obj)
    .reduce((a, k) => {
      a.push(k + '=' + encodeURIComponent(obj[k]))
      return a
    }, [])
    .join('&')
}

export default service
