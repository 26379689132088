<template>
  <modal
    name="location-builder-dialog"
    height="auto"
    :scrollable="true"
    width="60%"
    @closed="onClose"
  >
    <div class="modal-content location-builder">
      <h4>Location</h4>
      <div class="location-options row">
        <div class="col s12 m4">
          <div
            v-for="location1 in options1"
            :key="location1"
            class="btn btn-blank btn-small btn-flat btn-add-location"
            @click="appendLocation(location1)"
          >
            <div class="fa-solid fa-plus" />
            {{ location1 }}
          </div>
        </div>
        <div class="col s12 m4">
          <div
            v-for="location2 in options2"
            :key="location2"
            class="btn btn-blank btn-small btn-flat btn-add-location"
            @click="appendLocation(location2)"
          >
            <div class="fa-solid fa-plus" />
            {{ location2 }}
          </div>
        </div>
        <div class="col s12 m4">
          <div
            v-for="location3 in options3"
            :key="location3"
            class="btn btn-blank btn-small btn-flat btn-add-location"
            @click="appendLocation(location3)"
          >
            <div class="fa-solid fa-plus" />
            {{ location3 }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s12 px-4">
        <v-text-field
          v-model="location"
          placeholder="Use the buttons above to build a location. Click this box to customize."
        />
      </div>
    </div>
    <div class="row">
      <div class="col s12 right-align">
        <v-btn color="primary" @click="submit"> Save Location </v-btn>
        <v-btn @click="$emit('close')"> Cancel </v-btn>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['options1', 'options2', 'options3', 'value'],
  data() {
    return {
      location: '',
    }
  },
  mounted() {
    this.location = this.value || ''
    this.$modal.show('location-builder-dialog')
  },
  beforeDestroy() {
    this.$modal.hide('location-builder-dialog')
  },
  methods: {
    appendLocation(newLocation) {
      if (newLocation === ',') {
        this.location += newLocation
      } else {
        this.location += ' ' + newLocation
      }
    },
    onClose(event) {
      this.$emit('close')
    },
    submit() {
      this.$emit('input', this.location)
      this.$emit('change')
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.location-builder.modal-content .location-options .btn {
  display: block;
  margin-bottom: 0.5rem;
}

.modal-content {
  padding: 24px;
}

.save-all-button {
  margin-top: 1rem;
  margin-bottom: 0;
}
</style>
