export default async ({ commit }, { report, reportView }) => {
  const attributify = (collection) =>
    collection.map((attributes) => ({ id: attributes.id, attributes }))
  const stats = report.stats

  // Data mapping
  report.sections.map((section) => {
    section.items.map((item) => {
      item.observations.map((observation) => {
        observation.photos.map((photo) => {
          if (photo.video_url) {
            photo.video = true
          }
          if (!photo.video_url) {
            photo.image = photo.edited_image_url || photo.original_image_url
          }
          photo.report_section_id = section.id
          photo.report_item_id = item.id
          photo.observation_id = observation.id
        })
        observation.report_section_id = section.id
        observation.report_item_id = item.id
      })
      item.report_section_id = section.id
    })
  })

  const sections = report.sections
  const items = sections.map((x) => x.items).flat()
  const observations = items
    .map((item) => {
      item.observations.map((observation) => {
        observation.observation_photos = attributify(observation.photos)
      })
      return item.observations
    })
    .flat()
  commit('setValue', {
    key: 'report',
    value: {
      ...report,
      firestore: true,
      attributes: {
        ...report,
        image_url: report.image,
        settings: report,
        stats,
      },
    },
  })

  const defects = attributify(
    observations.filter((observation) => observation.comment_type === 'defect')
  )
  const defectsBySection = defects.reduce((acc, curr) => {
    const section = sections.find(
      ({ id }) => id === curr.attributes.report_section_id
    )
    if (!acc[section.name]) {
      acc[section.name] = {
        defects: [],
      }
    }

    acc[section.name].defects.push(curr)
    return acc
  }, {})

  commit('setValue', { key: 'sections', value: attributify(report.sections) })
  commit('setValue', { key: 'items', value: attributify(items) })
  commit('setValue', { key: 'observations', value: attributify(observations) })
  commit('setValue', {
    key: 'defects',
    value: defects,
  })
  commit('setValue', {
    key: 'defectsBySection',
    value: defectsBySection,
  })
  commit('setValue', {
    key: 'inspection',
    value: {
      attributes: {
        property_address: report.inspection.address1,
        property_address_2: report.inspection.address2,
      },
    },
  })
  commit('setValue', {
    key: 'inspector',
    value: {
      attributes: {
        property_address: report.inspection.address1,
        property_address_2: report.inspection.address2,
      },
    },
  })
  if (reportView) {
    commit('setValue', { key: 'reportView', value: reportView })
  }
  if (report.inspectors && report.inspectors.length) {
    commit('setValue', { key: 'reportAssignments', value: report.inspectors })
  }
  return true
}
