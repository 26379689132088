<template>
  <div class="placeholder-editor">
    <div class="row">
      <label class="v-label small">{{ label }}</label>
    </div>
    <div class="row">
      <div class="col m9 s12">
        <div v-if="isHtml">
          <froala
            v-model="localValue"
            tag="textarea"
            :config="froalaConfig"
            @input="$emit('input', localValue)"
          />
        </div>
        <v-textarea
          v-if="!isHtml"
          id="editor-textarea"
          v-model="localValue"
          @input="$emit('input', localValue)"
          @click="trackPosition"
          @keyup="trackPosition"
        />
      </div>
      <div class="col m3 s12">
        <placeholder-picker
          :placeholders="placeholders"
          @pick="insertPlaceholder"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dig from '../../utils/Dig'
import PlaceholderPicker from '../PlaceholderPicker.vue'
import { froalaOptionsAdvanced as froalaConfig } from '../../utils/froala'

const basePlaceholders = [
  {
    name: 'SERVICES',
    description:
      "This inserts code that will be replaced by a comma-separated list of the services for this inspection. (i.e. 'Residential, Mold, Radon')",
  },
  {
    name: 'FEES',
    description:
      "This inserts code that will be replaced by a comma-separated list of the fees for this inspection. (i.e. 'Residential Inspection $300, Radon Inspection $100')",
  },
  {
    name: 'PRICE',
    description:
      'This inserts code that will be replaced by the total price of the inspection',
  },
  {
    name: 'ADDRESS',
    description:
      "This inserts code that will be replaced by the property address. (i.e. '1234 Main St., Denver CO 80210')",
  },
  {
    name: 'YEAR_BUILT',
    description:
      'This inserts code that will be replaced by the year the property was built',
  },
  {
    name: 'FOUNDATION',
    description:
      'This inserts code that will be replaced by the foundation of the property',
  },
  {
    name: 'SQUARE_FEET',
    description:
      "This inserts code that will be replaced by the property's square footage",
  },
  {
    name: 'INSPECTION_DATE',
    description:
      "This inserts code that will be replaced by the scheduled inspection date. (i.e. '2/15/2017')",
  },
  {
    name: 'INSPECTION_TIME',
    description:
      "This inserts code that will be replaced by the scheduled inspection time. (i.e. '9:00AM')",
  },
  {
    name: 'INSPECTION_END_TIME',
    description:
      "This inserts code that will be replaced by the inspection ending time. (i.e. '11:00AM')",
  },
  {
    name: 'CLIENT_NAME',
    description:
      "This inserts code that will be replaced by the client's name. (i.e. 'John Smith')",
  },
  {
    name: 'CLIENT_FIRST_NAME',
    description:
      "This inserts code that will be replaced by the client's first name. (i.e. 'John')",
  },
  {
    name: 'CLIENT_PHONE',
    description:
      "This inserts code that will be replaced by the client's phone",
  },
  {
    name: 'CLIENT_EMAIL',
    description:
      "This inserts code that will be replaced by the client's email address",
  },
  {
    name: 'CLIENT_ADDRESS',
    description:
      "This inserts code that will be replaced by the client's address",
  },
  {
    name: 'CLIENT_CONTACT_INFO',
    description:
      "This inserts code that will be replaced by the client's phone and email",
  },
  {
    name: 'AGENT_NAME',
    description:
      "This inserts code that will be replaced by the agent's name. (i.e. 'John Smith')",
  },
  {
    name: 'AGENT_FIRST_NAME',
    description:
      "This inserts code that will be replaced by the agent's first name. (i.e. 'John')",
  },
  {
    name: 'AGENT_CONTACT_INFO',
    description:
      "This inserts code that will be replaced by the agent's phone and email",
  },
  {
    name: 'SELLING_AGENT_NAME',
    description:
      "This inserts code that will be replaced by the selling agent's name. (i.e. 'John Smith')",
  },
  {
    name: 'SELLING_AGENT_FIRST_NAME',
    description:
      "This inserts code that will be replaced by the selling agent's first name. (i.e. 'John')",
  },
  {
    name: 'SELLING_AGENT_CONTACT_INFO',
    description:
      "This inserts code that will be replaced by the selling agent's phone and email",
  },
  {
    name: 'INSPECTOR_NAME',
    description:
      "This inserts code that will be replaced by the inspector's name. (i.e. 'John Smith')",
  },
  {
    name: 'INSPECTOR_PHONE',
    description:
      "This inserts code that will be replaced by the inspector's phone",
  },
  {
    name: 'INSPECTOR_EMAIL',
    description:
      "This inserts code that will be replaced by the inspector's email address",
  },
  {
    name: 'INSPECTION_LINK',
    description:
      'This will be replaced by a link to the inspection page, where clients can accept agreements, pay for inspections, and view reports',
  },
  {
    name: 'INSPECTION_TEXT_LINK',
    description:
      'This will be replaced by a link to the inspection page, where clients can accept agreements, pay for inspections, and view reports',
  },
  {
    name: 'REPORT_LINK',
    description: 'This will be replaced by a direct link to a report',
  },
  {
    name: 'REPORT_TEXT_LINK',
    description: 'This will be replaced by a direct link to a report',
  },
  {
    name: 'REPORT_PUBLISHED_LINK',
    description:
      'This will be replaced by a direct link to the report(s) you are publishing or have been published already',
  },
  {
    name: 'REPORT_PUBLISHED_TEXT_LINK',
    description:
      'This will be replaced by a direct link to the report(s) you are publishing or have been published already',
  },
  {
    name: 'INVOICE_LINK',
    description: 'This will be replaced by a direct link to the invoice',
  },
  {
    name: 'INVOICE_TEXT_LINK',
    description: 'This will be replaced by a direct link to the invoice',
  },
  {
    name: 'REPORT_PDF',
    description:
      'This will be replaced by a direct link to a published report PDF',
  },
  {
    name: 'SUMMARY_PDF',
    description:
      'This will be replaced by a direct link to a published report summary PDF',
  },
  {
    name: 'EVENTS',
    description:
      "This inserts code that will be replaced by the inspection's events information in a table layout (i.e. 1/1/2020 10:00 AM - 1/1/2020 1:00 PM: Event Name ('John Smith'))",
  },
  {
    name: 'EVENTS_TEXT',
    description:
      "This inserts code that will be replaced by the inspection's events information (i.e. 1/1/2020 10:00 AM - 1/1/2020 1:00 PM: Event Name ('John Smith'))",
  },
  {
    name: 'ORDER_ID',
    description:
      "This inserts code that will be replaced by the inspection's order id",
  },
]

const conditionalPlaceholders = {
  'settings.enable_inspection_reviews': [
    {
      name: 'REVIEW_STARS',
      description:
        "This will be replaced by a row of stars that you're client's and client's agents can use to quickly leave a review.",
    },
    {
      name: 'REVIEW_LINK',
      description:
        "This will be replaced by a direct link for client's and client's agents to leave a review.",
    },
    {
      name: 'REVIEW_TEXT_LINK',
      description:
        "This will be replaced by a direct link for client's and client's agents to leave a review.",
    },
  ],
}

export default {
  components: {
    PlaceholderPicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['isHtml', 'label', 'value'],
  data() {
    const vm = this
    return {
      cursorPosition: null,
      filterString: '',
      froala: undefined,
      froalaConfig: Object.assign(froalaConfig, {
        height: 317,
        events: {
          initialized: function () {
            vm.froala = this
          },
        },
      }),
      localValue: '',
    }
  },
  computed: {
    ...mapState('autotracksManager', ['company']),
    filteredPlaceholders() {
      if (this.filterString.length === 0) {
        return this.placeholders
      }
      return this.placeholders.filter((x) =>
        x.name.toLowerCase().includes(this.filterString)
      )
    },
    placeholders() {
      let list = basePlaceholders
      Object.keys(conditionalPlaceholders).forEach((k) => {
        if (this.company.id && dig(this.company.attributes, k, false)) {
          list = list.concat(conditionalPlaceholders[k])
        }
      })

      if (this.isHtml) {
        return list
      }

      return list.filter((x) => {
        if (!x.name.endsWith('_LINK')) {
          return true
        }
        if (x.name.endsWith('TEXT_LINK')) {
          return true
        }
        return false
      })
    },
  },
  mounted() {
    if (this.value !== undefined) {
      this.localValue = this.value
    }
  },
  methods: {
    insertPlaceholder(value) {
      const wrappedValue = `{{${value}}}`
      let isLocal = true

      if (this.isHtml) {
        isLocal = !['INPUT', 'DIV'].includes(
          this.froala.selection.get().focusNode.tagName
        )
        if (isLocal) this.froala.html.insert(wrappedValue, false)
      } else {
        if (this.cursorPosition) {
          const half1 = this.localValue.slice(0, this.cursorPosition)
          const half2 = this.localValue.slice(
            this.cursorPosition,
            this.localValue.length
          )
          this.localValue = [half1, wrappedValue, half2].join('')
        } else {
          this.localValue += wrappedValue
        }
      }

      if (isLocal) {
        this.$emit('input', this.localValue)
      } else {
        this.$emit('pick', wrappedValue)
      }
    },
    trackPosition() {
      this.cursorPosition =
        document.getElementById('editor-textarea').selectionEnd
    },
  },
}
</script>

<style lang="scss">
.placeholder-editor {
  .placeholder-buttons {
    .v-btn {
      .v-btn__content {
        justify-content: start;
      }
      background-color: #f9890e !important;
      color: white;
      margin: 3px 0;
      width: 100%;
    }
  }
  .placeholder-buttons {
    border: 1px solid #ddd;
    height: 290px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .v-textarea {
    margin-top: 0;
    textarea {
      border: 1px solid #ddd;
      margin-top: 0;
      padding: 5px;
      background-color: white;
      height: 335px;
    }
  }
}
</style>
