<template>
  <modal
    id="new-sample-report"
    name="new-sample-report"
    :scrollable="false"
    width="60%"
    height="auto"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>New Sample Report</h2>
      </div>
      <div class="modal-body">
        <v-layout wrap>
          <v-flex xs12>
            <p>
              Enter a public-facing name and description to add this report as a
              sample report.<br />
              This creates an anonymized version of it, which will be available
              from your
              <a :href="companyProfileUrl" target="_blank">company profile</a>.
              (Note that this can take a few minutes to show up.)
            </p>
            <p>
              If you wish to make any further changes to this report, visit your
              <a :href="sampleReportsUrl" target="_blank"
                >sample reports page</a
              >
              or go to Settings->Sample Reports.
            </p>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="name"
              label="Name"
              data-cy="new-sample-report-name"
            />
          </v-flex>
          <v-flex xs12>
            <v-textarea
              v-model="description"
              data-cy="new-sample-report-description"
              label="Description"
              placeholder="This description will be viewable to anyone looking at your Sample Reports on your Company Profile"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 class="center">
            <div class="btn unbutton btn-xlarge" @click="close">Cancel</div>
            <div
              class="save-btn btn btn-large"
              data-cy="create-sample-report-button"
              @click="save"
            >
              <i v-if="!saving" class="fa-solid fa-square-check" />
              <i v-else class="fa-solid fa-spinner fa-spin" />
              Create Sample Report
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/utils/config'

const RAILS_DOMAIN = config('RAILS_DOMAIN')

export default {
  data() {
    return {
      description: '',
      name: '',
      saving: false,
    }
  },
  computed: {
    ...mapState('reports', ['company', 'report']),
    companyProfileUrl() {
      return `https://${RAILS_DOMAIN}/home-inspectors/${this.company.attributes.slug}/sample_reports`
    },
    sampleReportsUrl() {
      return `https://${RAILS_DOMAIN}/v/sample_reports?company_id=${this.company.id}`
    },
  },
  beforeDestroy() {
    this.close()
  },
  mounted() {
    this.$modal.show('new-sample-report')
  },
  methods: {
    close() {
      this.$modal.hide('new-sample-report')
      this.$emit('close')
    },
    save() {
      if (!this.saving && this.valid()) {
        this.saving = true
        const params = {
          company_id: this.company.id,
          report_id: this.report.id,
          name: this.name,
          description: this.description,
        }

        this.$store
          .dispatch('reports/createSampleReport', { attributes: params })
          .then(() => {
            this.close()
          })
          .catch(() => {
            this.saving = false
          })
      }
    },
    valid() {
      if (this.name.length > 0) return true
      this.$store.dispatch('notifier/show', {
        type: 'Error',
        msg: 'You need to name your sample report!',
      })
      return false
    },
  },
}
</script>

<style scoped>
.save-btn .fa-solid .save-btn .fa-regular {
  vertical-align: middle;
}
</style>

<style>
#new-sample-report textarea {
  margin-top: 0.5rem;
  padding: 0.25em;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

#new-sample-report .v-textarea .v-input__slot:before,
#new-sample-report .v-textarea .v-input__slot:after {
  content: none;
}
</style>
