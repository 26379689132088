/* eslint-disable array-callback-return */
import stateKeys from './keys'

export const addRecords = (state, records) => {
  if (records.length < 1) {
    return
  }
  const recordType = records[0].type
  const key = stateKeys[recordType]
  state[key] = state[key].concat(records)
}

export const setActions = (state, actions) => {
  state.actions = actions
}

export const setActionsLoading = (state, value = true) => {
  state.actionsLoading = value
}

export const setSmsTemplatesLoading = (state, value = true) => {
  state.smsTemplatesLoading = value
}

export const setSmsTemplates = (state, smsTemplates) => {
  state.smsTemplates = smsTemplates
}

export const setCompany = (state, value) => {
  state.company = value
}

export const setCompanyId = (state, value = undefined) => {
  state.companyId = value
}

export const setConditions = (state, conditions) => {
  state.conditions = conditions
}

export const setConditionsLoading = (state, value = true) => {
  state.conditionsLoading = value
}

export const setEditTarget = (state, item = undefined) => {
  state.editTarget = item
}

export const setEvents = (state, events) => {
  state.events = events
}

export const setEventsLoading = (state, value = true) => {
  state.eventsLoading = value
}

export const setInspectionSlug = (state, value) => {
  state.inspectionSlug = value
}

export const setInspection = (state, item) => {
  state.inspection = item
}

export const setRecords = (state, records) => {
  if (records.length < 1) {
    return
  }
  const recordType = records[0].type
  const key = stateKeys[recordType]
  const deletedIds = state.deletedRecords
    .filter((r) => r.type === recordType)
    .map((r) => r.id)
  state[key] = records.filter((r) => !deletedIds.includes(r.id))
}

export const setShowError = (state, value = true) => {
  state.showError = value
  if (!value) {
    // Clear errors
    state.errors = []
  }
}

export const setShowSaved = (state, value = true) => {
  state.showSaved = value
}

export const showError = (state, error) => {
  state.errors = [error].concat(state.errors)
  state.showError = true
}

// Kick the first error off. showError to false if there are no more.
export const shiftError = (state) => {
  const errors = state.errors.slice()
  errors.shift()
  state.errors = errors
  if (errors.length < 1) {
    state.showError = false
  }
}

// Sort collection by priority
export const sortRecords = (state, { collection }) => {
  state[collection] = state[collection].sort(function (a, b) {
    if (a.attributes.priority < b.attributes.priority) {
      return -1
    }
    if (a.attributes.priority > b.attributes.priority) {
      return 1
    }
    return 0
  })
}

// Walk through a state collection and replace an existing record
// with the ones provided. Records are expected to have *id* properties.
export const updateRecords = (state, { key, records, deleteIt = false }) => {
  if (records.length === 0) {
    return
  }
  if (key === undefined) {
    key = stateKeys[records[0].type]
  }
  const updatedIds = records.map((x) => x.id)
  const newCollection = []
  // Update collections
  state[key].map(function (existingRecord) {
    if (updatedIds.includes(existingRecord.id)) {
      const record = records.find((x) => x.id === existingRecord.id)
      if (!deleteIt) {
        newCollection.push(record)
      }
    } else {
      newCollection.push(existingRecord)
    }
  })
  state[key] = newCollection
}

export const setFilterValue = (state, value) => {
  state.filter = value
  const filtered = JSON.parse(JSON.stringify(state.tracks))
  filtered.forEach((t) => {
    delete t.hidden
  })

  if (!state.trackMode) {
    if (value.meetsConditions) {
      filtered.forEach((t) => {
        if (!t.hidden) {
          t.hidden = !t.attributes.meets_conditions
          if (!t.hidden) {
            t.hidden = t.attributes.occurs_once && t.attributes.runtime
          }
        }
      })
    }
    if (value.isFulfilled) {
      filtered.forEach((t) => {
        if (!t.hidden) {
          const hasFulfilledSubrun = state.tracks.find((sr) => {
            return (
              t.attributes.id === sr.attributes.parent_run_id &&
              sr.attributes.is_fulfilled
            )
          })
          t.hidden = !hasFulfilledSubrun && !t.attributes.is_fulfilled
        }
      })
    }
    if (value.hasRuntime) {
      filtered.forEach((t) => {
        if (!t.hidden) {
          const hasSubrunRuntime = state.tracks.find((sr) => {
            return (
              t.attributes.id === sr.attributes.parent_run_id &&
              sr.attributes.runtime
            )
          })
          t.hidden = !hasSubrunRuntime && !t.attributes.runtime
        }
      })
    }

    if (value.isLocked !== null)
      filtered.forEach((t) => {
        if (!t.hidden) t.hidden = t.attributes.is_locked !== value.isLocked
      })
  }

  if ((value.search || '').length > 2) {
    value = value.search.toLowerCase().split(' ')

    filtered.forEach((t) => {
      if (!t.hidden) {
        const searchString =
          `${t.attributes.auto_action} ${t.attributes.auto_event}
          ${t.attributes.name} ${t.attributes.params.to} ${t.attributes.params.body}
          ${t.attributes.params.subject}
        `.toLowerCase()

        t.hidden = value.find((v) => {
          return !searchString.includes(v)
        })
      }
    })
  }

  state.tracks = filtered
}

export const setTracksLoading = (state, value = true) => {
  state.tracksLoading = value
}

export const setTrackGroupsLoading = (state, value = true) => {
  state.trackGroupsLoading = value
}

export const setUserProfile = (state, item) => {
  state.userProfile = item
}

export const setUserProfileLoading = (state, value = true) => {
  state.userProfileLoading = value
}

export const setWizardStep = (state, value = 1) => {
  state.wizardStep = value
}
