<template>
  <modal
    :name="modalId"
    width="40%"
    height="auto"
    scrollable
    @closed="handleClose"
  >
    <div class="learn-more-modal">
      <div class="learn-more-modal-header">
        <div class="learn-more-modal-header__close" @click="handleClose">
          <i class="fa fa-close"></i>
        </div>
      </div>
      <div class="learn-more-modal-body">
        <div class="learn-more-modal-body-logo-container">
          <img
            v-if="contractor.logo_url"
            :src="contractor.logo_thumb_url"
            class="learn-more-modal-body-logo-container__logo"
          />
          <div
            v-else
            class="learn-more-modal-body-logo-container__missing-logo"
          >
            <i
              class="fa fa-building"
              style="font-size: 2.5em; color: #666"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <div class="learn-more-modal-body-info">
          <h1 class="learn-more-modal-body-info__name">
            {{ name }}
          </h1>
          <h3 v-if="address" class="learn-more-modal-body-info__address">
            {{ address }}
          </h3>
          <h3
            v-if="contractor.first_name && contractor.company_name"
            class="learn-more-modal-body-info__company-name"
          >
            {{ contractor.company_name }}
          </h3>
          <h3 v-if="contractor.phone" class="learn-more-modal-body-info__phone">
            <a :href="`tel:${contractor.phone}`">
              <i class="fa fa-phone" />
              {{ phone }}
            </a>
          </h3>
          <h3 v-if="website" class="learn-more-modal-body-info__website">
            <a :href="website" target="_BLANK">
              <i class="fa fa-external-link" />
              {{ contractor.website }}
            </a>
          </h3>
          <p class="learn-more-modal-body-info__description">
            {{ contractor.description }}
          </p>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import formatPhone from '@/utils/formatPhone'
import formatAddress from '@/utils/formatAddress'
import formatUrl from '@/utils/formatUrl'

export default {
  props: {
    contractor: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    modalId: 'learn-more-modal',
  }),
  computed: {
    /* eslint-disable */
    name() {
      const { first_name, last_name, company_name } = this.contractor

      if (first_name && last_name) {
        return `${first_name} ${last_name}`
      }

      return company_name
    },
    /* eslint-enable */
    address() {
      return formatAddress(this.contractor)
    },
    phone() {
      const { phone, country } = this.contractor

      return formatPhone({
        value: phone,
        locale: country,
      })
    },
    website() {
      return formatUrl(this.contractor.website)
    },
  },
  mounted() {
    this.$modal.show(this.modalId)
  },
  methods: {
    handleClose() {
      this.$emit('closed')
      this.$modal.hide(this.modalId)
    },
  },
}
</script>

<style lang="scss">
.learn-more-modal {
  font-family: 'Inter', 'Open Sans', sans-serif;
  position: relative;
  overflow: auto;

  &-header {
    padding: 0.875em 2.5em;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__close {
      color: #144266;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.04);
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 100%;
      margin-left: auto;
    }
  }

  &-body {
    $root: &;
    overflow: auto;
    min-height: 35.375em;
    padding: 4em 6.5em;
    display: flex;
    gap: 2.5em;

    @media (max-width: 960px) {
      padding: 4em 1em;
      flex-flow: column;
      align-items: center;
      text-align: center;
    }

    &-logo-container {
      width: 144px;

      &__missing-logo {
        width: 100%;
        height: 144px;
        background-color: rgba(0, 0, 0, 0.04);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-info {
      min-width: 0;
      flex: 1;

      &__name {
        color: #374151;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 8px;
        text-transform: none;
      }

      &__address,
      &__phone,
      &__website,
      &__company-name {
        font-size: 16px;
        color: #4b5563;
        font-weight: 500;
        margin-bottom: 0;
        text-transform: none;
      }

      &__description {
        font-size: 18px;
        color: #374151;
        font-weight: 400;
        line-height: 1.5;
        overflow-wrap: break-word;
        white-space: pre-line;
        margin-top: 10px;
      }
    }
  }
}
</style>
