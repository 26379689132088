<template>
  <div>
    <v-text-field v-model="filterString" hide-details>
      <template #append>
        <i class="fa-solid fa-search" />
      </template>
    </v-text-field>
    <label>Insert Placeholders</label>
    <div class="placeholder-buttons px-1">
      <transition-group name="slide-down">
        <div
          v-for="placeholder in filteredPlaceholders"
          :key="placeholder.name"
        >
          <v-tooltip top>
            <v-btn
              slot="activator"
              small
              class="full"
              @click="$emit('pick', placeholder.name)"
            >
              <i class="fa-regular fa-plus" />
              &thinsp;
              {{ placeholder.name.replace(/_/g, ' ') }}
            </v-btn>
            {{ placeholder.description }}
          </v-tooltip>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['placeholders'],
  data() {
    return {
      filterString: '',
    }
  },
  computed: {
    filteredPlaceholders() {
      if (this.filterString.length === 0) {
        return this.placeholders
      }
      return this.placeholders.filter((x) =>
        x.name.toLowerCase().includes(this.filterString)
      )
    },
  },
}
</script>
