<template>
  <modal
    name="publish-isn"
    :scrollable="true"
    width="60%"
    height="auto"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>Publish to ISN</h2>
      </div>
      <div class="modal-body">
        <v-layout v-if="isFirestore">
          <v-flex xs12 class="strong">
            Before publishing from the web, click "Preview/Publish" in the
            Report Editor to save your changes (<a
              href="https://share.getcloudapp.com/4gulpdnz"
              target="_blank"
              >don't know where that is?</a
            >). If this is not clicked, your latest changes may not appear in
            the report.
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <p>
              This will submit the inspection URL to ISN.
              <br />
              <v-checkbox
                v-model="isnComplete"
                label="Complete inspection (triggers ISN post-processing)"
              />
            </p>
          </v-flex>
          <v-flex xs12 class="center">
            <div class="btn unbutton btn-xlarge" @click="close">Cancel</div>
            <div class="btn btn-xlarge" @click="publish">
              <i v-if="!saving" class="fa-regular fa-paper-plane" />
              <i v-else class="fa-spinner fa-spinner fa-spin" />
              Publish
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import railsService from '../../../utils/AxiosService'
import dig from '../../../utils/Dig'
import { captureException } from '@/utils'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['reportId'],
  data() {
    return {
      isnComplete: true,
      saving: false,
    }
  },
  computed: {
    ...mapState('reports', ['report']),
    isFirestore() {
      return dig(this.report, 'attributes.is_firestore')
    },
  },
  mounted() {
    this.$modal.show('publish-isn')
  },
  methods: {
    close() {
      this.$modal.hide('publish-isn')
      this.$emit('closed')
    },
    publish() {
      if (this.saving) return
      this.saving = true

      const params = {
        attributes: { id: this.reportId, isn_complete: this.isnComplete },
      }

      railsService
        .put(
          `/api/v1/reports/${this.reportId}/report_integrations/isn_publish`,
          params
        )
        .then((response) => {
          this.$emit('change', { published: true })
          this.$store.dispatch('notifier/show', {
            type: 'Saved',
            msg: 'Report Published To ISN!',
          })
          this.close()
        })
        .catch((error) => {
          captureException(error)
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: 'There was an error publishing this report.',
          })
        })
        .then(() => {
          this.saving = false
        })
    },
  },
}
</script>

<style scoped></style>
