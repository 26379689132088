export default (dateFormat) => {
  const format = dateFormat || ''

  if (dateFormat && dateFormat.search('%') === -1) {
    return dateFormat
  }

  return format
    .replace('%m', 'MM')
    .replace('%d', 'DD')
    .replace('%Y', 'YYYY')
    .replace('%H', 'H')
    .replace('%l', 'h')
    .replace('%M', 'mm')
    .replace('%S', 'ss')
    .replace('%P', 'a')
}
