<template>
  <modal
    id="publish-report"
    name="publish-report"
    :scrollable="true"
    width="60%"
    height="auto"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2 data-cy="publish-report-modal-title">Publish Report</h2>
      </div>
      <div class="modal-body">
        <v-layout v-if="isFirestore">
          <v-flex xs12 class="strong">
            Before publishing from the web, click "Preview/Publish" in the
            Report Editor to save your changes (<a
              href="https://share.getcloudapp.com/4gulpdnz"
              target="_blank"
              >don't know where that is?</a
            >). If this is not clicked, your latest changes may not appear in
            the report.
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex v-if="publishable" xs12>
            <v-expansion-panel class="sections">
              <v-expansion-panel-content
                v-for="section in sectionsMap"
                :key="section.key"
              >
                <template v-if="section.email">
                  <template slot="header">
                    <div class="section-label light">
                      <v-checkbox
                        v-model="section.sendEmail"
                        class="section-checkbox"
                        data-cy="send-report-by-email-checkbox"
                      />
                      <em class="fa-regular fa-envelope" />
                      &nbsp; Send {{ getLabel(section.key) }} Email
                    </div>
                  </template>

                  <v-layout wrap class="section-content">
                    <v-flex xs12 vpx-1>
                      <v-text-field
                        v-model="section.email.attributes.subject"
                        label="Subject"
                      />
                    </v-flex>
                    <v-flex xs12 vpx-1>
                      <froala
                        v-model="section.email.attributes.text"
                        tag="textarea"
                        :config="froalaConfig"
                        label="Email body (your header and footer will be around the text)"
                      />
                    </v-flex>
                  </v-layout>
                </template>
              </v-expansion-panel-content>
              <v-expansion-panel-content
                v-for="section in sectionsMap"
                :key="section.key + '-text'"
              >
                <template v-if="section.text">
                  <template slot="header">
                    <div class="section-label light">
                      <v-checkbox
                        v-model="section.sendText"
                        class="section-checkbox"
                      />
                      <em class="fa-regular fa-envelope" />
                      &nbsp; Send {{ getLabel(section.key) }} Text Message
                    </div>
                  </template>

                  <v-layout wrap class="section-content">
                    <v-flex xs12 vpx-1>
                      <v-textarea
                        v-model="section.text.attributes.text"
                        label="Text Message"
                      />
                    </v-flex>
                  </v-layout>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-flex>
          <v-flex v-else xs12>
            There isn't a client or agent to publish for!
          </v-flex>
          <v-flex xs12>
            <p class="small light align-right">
              You can edit email/text templates in
              <a href="/automation#report">Automation</a>
              .
            </p>
          </v-flex>
          <v-flex xs12 class="center">
            <div class="btn unbutton btn-xlarge" @click="close">Cancel</div>
            <div
              :disabled="!publishable"
              class="btn btn-xlarge"
              data-cy="send-all-button"
              @click="save"
            >
              <i v-if="!saving" class="fa-regular fa-paper-plane" />
              <i v-else class="fa-solid fa-spinner fa-spin" />
              Send All
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { froalaOptionsSimple as froalaConfig } from '@/utils/froala'
import axios from '@/utils/AxiosService'
import dig from '@/utils/Dig'
import { captureException } from '@/utils'

export default {
  data() {
    return {
      froalaConfig: Object.assign(froalaConfig, {
        height: 300,
      }),
      saving: false,
      sectionsMap: {
        report_ready: {
          key: 'client',
          email: null,
          sendEmail: null,
          text: null,
          sendText: null,
        },
        report_ready_agent: {
          key: 'agent',
          email: null,
          sendEmail: null,
          text: null,
          sendText: null,
        },
      },
    }
  },
  computed: {
    ...mapState('reports', ['company', 'inspection', 'report']),
    ...mapGetters('reports', ['clientLabelCapitalized']),
    buyer2() {
      return dig(this.inspection, 'attributes.buyer_2')
    },
    isFirestore() {
      return dig(this.report, 'attributes.is_firestore')
    },
    publishable() {
      return (
        this.inspection.attributes.buyer ||
        this.inspection.attributes.buying_agent
      )
    },
  },
  mounted() {
    this.fetchTemplates()

    this.sectionsMap.report_ready.sendEmail =
      this.company.attributes.settings.report_ready_email_client
    this.sectionsMap.report_ready.sendText =
      this.company.attributes.settings.report_ready_text_client
    this.sectionsMap.report_ready_agent.sendEmail =
      this.company.attributes.settings.report_ready_email_agent
    this.sectionsMap.report_ready_agent.sendText =
      this.company.attributes.settings.report_ready_text_agent

    this.$modal.show('publish-report')
  },
  methods: {
    close() {
      this.$modal.hide('publish-report')
      this.$emit('close')
    },
    deliveries() {
      return this.toSend().map((object) => {
        const label = object.type === 'email' ? 'emails' : 'text_messages'
        return axios.post(`/api/v1/${label}`, object, { dupe: true })
      })
    },
    deliverNotifications() {
      return Promise.all(this.deliveries())
        .then(() => {
          this.$emit('change', { published: true })
          this.$store.dispatch('notifier/show', {
            type: 'Saved',
            msg: 'Your Report has been published!',
          })
          this.close()
        })
        .catch((error) => {
          captureException(error)
          this.saving = false
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: 'There was an error publishing your report.',
          })
        })
    },
    fetchEmailTemplates() {
      axios
        .get(
          `/api/v1/inspections/${this.inspection.id}/inspection_email_templates?q=report_ready&company_id=${this.company.id}`
        )
        .then((response) => {
          const templates = response.data.data
          this.sectionsMap.report_ready.email = templates.find((t) => {
            return t.attributes.name === 'report_ready'
          })
          this.sectionsMap.report_ready_agent.email = templates.find((t) => {
            return t.attributes.name === 'report_ready_agent'
          })
        })
        .catch((error) => {
          captureException(error)
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: 'There was an error loading your email templates.',
          })
        })
    },
    fetchTemplates() {
      this.fetchEmailTemplates()
      this.fetchTextTemplates()
    },
    fetchTextTemplates() {
      axios
        .get(
          `/api/v1/inspections/${this.inspection.id}/inspection_text_templates?q=report_ready&company_id=${this.company.id}`
        )
        .then((response) => {
          const templates = response.data.data
          this.sectionsMap.report_ready.text = templates.find((t) => {
            return t.attributes.name === 'report_ready'
          })
          this.sectionsMap.report_ready_agent.text = templates.find((t) => {
            return t.attributes.name === 'report_ready_agent'
          })
        })
        .catch((error) => {
          captureException(error)
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: 'There was an error loading your text templates.',
          })
        })
    },
    getLabel(key) {
      if (key === 'client') {
        return this.clientLabelCapitalized
      }
      return 'Agent'
    },
    save() {
      if (!this.saving && this.publishable) {
        this.saving = true
        axios
          .put(`/api/v1/reports/${this.report.id}/publish`)
          .then((response) => {
            this.deliverNotifications()
          })
          .catch((error) => {
            captureException(error)
            this.$store.dispatch('notifier/show', {
              type: 'Error',
              msg: 'There was an error publishing this inspection.',
            })
          })
      }
    },
    toSend() {
      const objects = []
      if (
        this.sectionsMap.report_ready.sendEmail &&
        this.sectionsMap.report_ready.email
      ) {
        objects.push(this.sectionsMap.report_ready.email)
        if (
          this.buyer2 &&
          dig(this.buyer2, 'data.attributes.email', '').length > 0
        ) {
          const emailDupe = JSON.parse(
            JSON.stringify(this.sectionsMap.report_ready.email)
          )
          emailDupe.attributes.recipient = this.buyer2.data.attributes.email
          emailDupe.attributes.cc = this.buyer2.data.attributes.cc_email
          objects.push(emailDupe)
        }
      }
      if (
        this.sectionsMap.report_ready.sendText &&
        this.sectionsMap.report_ready.text
      ) {
        objects.push(this.sectionsMap.report_ready.text)
        if (
          this.buyer2 &&
          dig(this.buyer2, 'data.attributes.phone', '').length > 0
        ) {
          const txtDupe = JSON.parse(
            JSON.stringify(this.sectionsMap.report_ready.text)
          )
          txtDupe.attributes.recipient_phone = this.buyer2.data.attributes.phone
          objects.push(txtDupe)
        }
      }
      if (
        this.sectionsMap.report_ready_agent.sendEmail &&
        this.sectionsMap.report_ready_agent.email
      )
        objects.push(this.sectionsMap.report_ready_agent.email)
      if (
        this.sectionsMap.report_ready_agent.sendText &&
        this.sectionsMap.report_ready_agent.text
      )
        objects.push(this.sectionsMap.report_ready_agent.text)

      return objects
    },
  },
}
</script>

<style scoped>
.sections {
  margin: 2em 0;
}

.section-label {
  display: flex;
  align-items: center;
  font-size: 1.25em;
}

.section-checkbox {
  flex-grow: 0;
}

.section-content {
  padding: 1em;
  background-color: #fafafa;
}

.align-right {
  text-align: right;
}
</style>

<style>
#publish-report .v-expansion-panel__header {
  border-bottom: 1px solid #d9d9d9;
}
</style>
