<template>
  <modal
    id="copy-observation-text"
    name="copy-observation-text"
    :scrollable="false"
    width="40%"
    height="auto"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>Copy & Paste</h2>
      </div>
      <div class="modal-body">
        <v-layout wrap>
          <v-flex v-if="categories > 1" xs12>
            <v-select
              v-model="viewing"
              label="Viewing"
              :items="viewingOptions"
              :menu-props="{ maxHeight: '400' }"
              item-text="text"
              item-value="value"
              :attach="true"
            >
              <template slot="item" slot-scope="data">
                <v-flex md12 class="observation-selection">
                  <div class="left">
                    <div class="fa" :class="data.item.icon" />
                  </div>
                  <span>{{ data.item.text }}</span>
                </v-flex>
              </template>
            </v-select>
          </v-flex>
          <!-- eslint-disable vue/no-v-text-v-html-on-component -->
          <v-flex
            id="text-select-container"
            xs12
            class="text-container"
            v-html="observationText"
          />
          <!-- eslint-enable -->

          <v-flex xs12 class="small light center">
            NOTE: This text is not meant to be final - modify with your buyer
            and according to local laws and regulations.
          </v-flex>

          <v-flex xs12 class="button-row center">
            <div class="btn unbutton btn-xlarge" @click="close">close</div>
            <div class="btn btn-large" @click="selectText">Select All</div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      viewing: 'all',
    }
  },
  computed: {
    ...mapState('reports', [
      'items',
      'report',
      'sections',
      'observations',
      'recommendations',
    ]),
    categories() {
      return this.report.attributes.settings.categories
    },
    catLabels() {
      return {
        low: this.report.attributes.settings.category_low,
        med: this.report.attributes.settings.category_med,
        high: this.report.attributes.settings.category_high,
      }
    },
    displayRecommendations() {
      return this.report.attributes.settings.display_recommendations
    },
    observationText() {
      let txt = ''
      this.sections.forEach((s, sIdx) => {
        const sectionNo = sIdx + 1
        this.items
          .filter((i) => {
            return (
              !i.attributes.is_overview &&
              i.attributes.report_section_id === s.id
            )
          })
          .forEach((i, iIdx) => {
            const itemNo = iIdx + 1
            this.observations
              .filter((o) => {
                return (
                  o.attributes.report_item_id === i.id &&
                  o.attributes.comment_type === 'defect'
                )
              })
              .forEach((o, oIdx) => {
                const oNo = oIdx + 1
                if (
                  this.viewing === 'all' ||
                  (o.attributes.category !== null &&
                    o.attributes.category === this.viewing) ||
                  (this.viewing === 'rh' &&
                    o.attributes.category !== null &&
                    o.attributes.category > -1)
                ) {
                  txt += `
              <div class='text-row'>
                <div class='name'>
                  <span class='light'>${sectionNo}.${itemNo}.${oNo}</span>
                  <strong>${s.attributes.name}: ${i.attributes.name} - ${
                    o.attributes.name
                  }</strong>
                  ${
                    o.attributes.location
                      ? '<span class="light"> (' +
                        o.attributes.location +
                        ')</span>'
                      : ''
                  }
                </div>
                <div class='text'>${o.attributes.text || ''}</div>
                ${
                  this.displayRecommendations &&
                  this.recommendationMap[o.attributes.recommendation_id]
                    ? "<span class='light'>Recommendation: </span>" +
                      this.recommendationMap[o.attributes.recommendation_id]
                        .attributes.text
                    : ''
                }
              </div>
              <br/>
              `
                }
              })
          })
      })

      return txt
    },
    recommendationMap() {
      const rm = {}
      this.recommendations.forEach((r) => {
        rm[r.id] = r
      })
      return rm
    },
    viewingOptions() {
      const opts = [
        {
          value: 'all',
          text: 'All Observations',
          icon: 'fa-asterisk blue-circle',
        },
      ]
      if (this.categories === 3)
        opts.push({
          value: -1,
          text: this.catLabels.low,
          icon: 'fa-solid fa-wrench',
        })
      opts.push({
        value: 0,
        text: this.catLabels.med,
        icon: 'fa-solid fa-minus-circle',
      })
      if (this.categories > 2)
        opts.push({
          value: 1,
          text: this.catLabels.high,
          icon: 'fa-solid fa-warning',
        })
      opts.push({
        value: 'rh',
        text: `${this.catLabels.med} & ${this.catLabels.high}`,
        icon: 'fa-solid fa-asterisk red-circle',
      })

      return opts
    },
  },
  beforeDestroy() {
    this.close()
  },
  mounted() {
    this.$modal.show('copy-observation-text')
  },
  methods: {
    close() {
      this.$modal.hide('copy-observation-text')
      this.$emit('close')
    },
    selectText() {
      const el = document.getElementById('text-select-container')
      let range, selection

      if (document.body.createTextRange) {
        range = document.body.createTextRange()
        range.moveToElementText(el)
        range.select()
      } else if (window.getSelection) {
        selection = window.getSelection()
        range = document.createRange()
        range.selectNodeContents(el)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    },
  },
}
</script>

<style scoped>
.text-container {
  max-height: 15em;
  min-height: 15em;
  padding: 0.5em;
  margin: 0.5em 0;
  border: 1px solid #000;
  overflow-y: auto;
}

.button-row {
  margin-top: 2em;
}
</style>

<style lang="scss">
#copy-observation-text {
  .observation-selection {
    font-size: 1.25em;
    .left {
      margin-right: 1em;
    }

    .fa {
      &-solid,
      &-regular {
        padding: 0;
        font-size: 1.5em;

        &.fa-circle-minus {
          color: #f9890e;
        }

        &.fa-wrench {
          color: #45769c;
        }

        &.fa-warning {
          color: #d53636;
        }

        &.fa-asterisk {
          font-size: 0.75em;
          padding: 0.5em;
          color: #fff;
        }
      }
    }

    .blue-circle {
      border-radius: 50%;
      background-color: #5c9ccf;
    }
    .red-circle {
      border-radius: 50%;
      background-color: #d53636;
    }
    span {
      color: #5c9ccf;
    }
  }
}
</style>
