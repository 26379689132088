<template>
  <v-layout wrap class="section-summary">
    <v-flex xs12>
      <v-tabs v-model="activeTab" class="tab" grow slider-color="#5c9ccf">
        <v-tab v-for="tab in tabs" :key="tab">
          {{ tab }}
        </v-tab>
        <v-tab-item v-for="tab in tabs" :key="tab">
          <v-card flat class="tab-content">
            <template v-if="tab === 'Overview'">
              <section-table
                :section="section"
                :section-number="sectionNumber"
              />
            </template>
            <template v-else-if="tab === 'Information'">
              <v-layout v-if="hasInfo" wrap>
                <template v-if="smartLayout">
                  <tab-observation
                    v-for="info in smartLayoutShort"
                    :key="info.id"
                    :observation="info"
                  />
                  <tab-observation
                    v-for="info in smartLayoutShortPicture"
                    :key="info.id"
                    :observation="info"
                  />
                  <tab-observation
                    v-for="info in smartLayoutLong"
                    :key="info.id"
                    :observation="info"
                  />
                </template>
                <template v-else>
                  <tab-observation
                    v-for="info in infos"
                    :key="info.id"
                    :observation="info"
                  />
                </template>
              </v-layout>
            </template>
            <template v-else-if="tab === limitationsLabel">
              <v-layout v-if="hasLimitations" wrap>
                <tab-observation
                  v-for="limitation in limitations"
                  :key="limitation.id"
                  :observation="limitation"
                />
              </v-layout>
            </template>
            <template v-else-if="tab === 'Standards'">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="small" v-html="section.attributes.disclaimer_text" />
            </template>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import SectionTable from './SectionTable.vue'
import TabObservation from './TabObservation.vue'

export default {
  components: {
    SectionTable,
    TabObservation,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['observations', 'section', 'sectionNumber'],
  data() {
    return {
      activeTab: 'Information',
    }
  },
  computed: {
    ...mapState('reports', ['report']),
    hasInfo() {
      return this.infos.length > 0
    },
    hasLimitations() {
      return this.limitations.length > 0
    },
    hasOverview() {
      return this.tabs.includes('Overview')
    },
    hasSops() {
      return (
        this.report.attributes.settings.display_sops &&
        (this.section.attributes.disclaimer_text || '').length > 0 &&
        this.section.attributes.disclaimer_text !== '\n'
      )
    },
    infos() {
      return this.observations.filter((o) => {
        return o.attributes.comment_type === 'info'
      })
    },
    limitations() {
      return this.observations.filter((o) => {
        return o.attributes.comment_type === 'limit'
      })
    },
    limitationsLabel() {
      return this.report.attributes.settings.limitations_label
    },
    smartLayout() {
      return this.report.attributes.settings.smart_layout
    },
    smartLayoutShort() {
      if (!this.smartLayout) return []
      return this.infos.filter((i) => {
        return (
          i.attributes.observation_photos.length === 0 &&
          (i.attributes.text || '').replace(/(<([^>]+)>)/gi, '').length < 75
        )
      })
    },
    smartLayoutShortPicture() {
      if (!this.smartLayout) return []
      return this.infos.filter((i) => {
        return (
          i.attributes.observation_photos.length === 1 &&
          (i.attributes.text || '').replace(/(<([^>]+)>)/gi, '').length < 75
        )
      })
    },
    smartLayoutLong() {
      if (!this.smartLayout) return []
      return this.infos.filter((i) => {
        return (
          i.attributes.observation_photos.length > 1 ||
          (i.attributes.text || '').replace(/(<([^>]+)>)/gi, '').length >= 75
        )
      })
    },
    tabs() {
      const t = []
      if (
        this.report.attributes.settings.use_ratings &&
        this.report.attributes.settings.display_report_grids &&
        !this.report.attributes.settings.combine_grid_info &&
        !this.section.attributes.is_overview
      )
        t.push('Overview')
      t.push('Information')
      if (this.hasLimitations) t.push(this.limitationsLabel)
      if (this.hasSops) t.push('Standards')

      return t
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab
    },
  },
}
</script>

<style scoped>
.section-summary {
  margin-bottom: 0.5em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.tab-content {
  padding: 1em;
}
</style>

<style lang="scss">
.section-summary .v-tabs__item {
  font-weight: normal;
  font-size: 1.25em;
  border-right: 1px solid #888;
  transition: all 0.1s linear;
}

.section-summary .v-tabs__item.v-tabs__item--active {
  color: #5c9ccf;
  border-left: 1px solid #888;
  opacity: 1;
}

.section-summary .v-tabs__item:not(.v-tabs__item--active) {
  background-color: #f9f9f9;
  border-bottom: 1px solid #888;
  border-right-color: #ddd;
  opacity: 0.5;
}

.section-summary .v-tabs__container div:nth-of-type(2) {
  a {
    border-left: none;
  }
}

.section-summary .v-tabs__container div:last-of-type {
  a {
    border-right: none;
  }
}

.section-summary .v-tabs__slider-wrapper {
  top: 0;
}

@media (max-width: 960px) {
  .section-summary .v-tabs__item {
    font-size: 1em;
  }
}
</style>
