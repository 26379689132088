import config from '@/utils/config'

const PDF_DOMAIN = config('PDF_DOMAIN')
const VIEWER_DOMAIN = config('VIEWER_DOMAIN')

/**
 * @function
 * @param text {string}
 * @param sampleData {{ report_id: string, sample_url: string }}
 * @returns {string}
 */
export default (text, sampleData) => {
  const reportURL = `https://${PDF_DOMAIN}/${sampleData.report_id}`
  const reportViewerURL = `https://${VIEWER_DOMAIN}/v/reports/${sampleData.report_id}`

  const reReportURL = new RegExp(reportURL, 'g')
  const reReportViewerURL = new RegExp(reportViewerURL, 'g')

  if (text.includes(reportURL)) {
    text = text.replace(reReportURL, sampleData.sample_url)
  }
  if (text.includes(reportViewerURL)) {
    text = text.replace(reReportViewerURL, sampleData.sample_url)
  }

  return text
}
