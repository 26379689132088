import { productAnalyticsService } from '@/services'

const Analytics = {
  install(Vue, opts = {}) {
    Vue.prototype.$analytics = {
      track: (event, attributes = {}) => {
        if (opts.debug) {
          // eslint-disable-next-line no-console
          console.log('[ANALYTICS] New event logged:', event, attributes)
        }

        productAnalyticsService.track(event, attributes)
      },
    }
  },
}

export default Analytics
