<template>
  <div class="search-field-container">
    <v-text-field
      v-model="searchValue"
      class="search-field"
      :placeholder="searchPlaceholder"
      @keyup="debounceSearch"
    />
    <ul v-if="searchResults.length > 0" class="search-results">
      <slot name="prependResults" />
      <li
        v-for="result in searchResults"
        :key="result.id"
        @click="selectResult(result)"
      >
        <slot name="result" :result="result">
          {{ parseResult(result) }}
        </slot>
      </li>
      <slot name="appendResults" />
    </ul>
    <ul v-else-if="displayNoResults" class="search-results no-results">
      <li @click="$emit('click', null)">
        <slot name="noResults"> No Results Found </slot>
      </li>
    </ul>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import axios from '../../utils/AxiosService'
import dig from '../../utils/Dig'

export default {
  /* eslint-disable vue/require-prop-types */
  props: [
    'url',
    'queryParams',
    'placeholder',
    'resultText',
    'selectionText',
    'searchLimit',
  ],
  /* eslint-enable */
  data() {
    return {
      noResults: false,
      searchValue: '',
      searchResults: [],
    }
  },
  computed: {
    displayNoResults() {
      return this.searchResults.length === 0 && this.noResults
    },
    searchLimitValue() {
      return this.searchLimit ? this.searchLimit : 2
    },
    searchPlaceholder() {
      return this.placeholder || 'Search'
    },
    urlWithParams() {
      const qp = this.queryParams || {}
      qp.q = this.searchValue
      const paramPairs = Object.keys(qp)
        .map((k) => {
          return [k, qp[k]].join('=')
        })
        .join('&')

      return [this.url, paramPairs].join('?')
    },
  },
  watch: {
    searchValue(newV, oldV) {
      if (newV !== oldV) {
        if (newV.length < 3) {
          this.searchResults = []
          this.noResults = false
        }
      }
    },
  },
  created() {
    const vm = this
    this.debounceSearch = debounce((e) => {
      vm.search()
    }, 300)
  },
  methods: {
    parseResult(result) {
      if (this.resultText) {
        return dig(result, this.resultText)
      }
    },
    parseSelectedResult(result) {
      return dig(result, this.selectionText)
    },
    search() {
      if (this.searchValue.length > this.searchLimitValue) {
        axios.get(this.urlWithParams).then((response) => {
          if (
            dig(response, 'data', []).length > 0 ||
            dig(response, 'data.data', []).length > 0
          ) {
            this.noResults = false
            this.searchResults = dig(response, 'data.data') || response.data
          } else {
            this.noResults = true
            this.searchResults = []
          }
        })
      }
    },
    selectResult(result) {
      this.searchValue = this.parseSelectedResult(result)
      this.searchResults = []
      this.$emit('click', result)
    },
  },
}
</script>

<style scoped>
.search-field-container {
  position: relative;
}

.search-results {
  z-index: 1;
  position: absolute;
  width: 100%;
  margin-top: -1.25em;
  background-color: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.search-results li {
  height: 3.5em;
  cursor: pointer;
  padding: 0.5em;
  border-bottom: 1px solid #ddd;
  transition: background 0.1s linear;
  display: flex;
  align-items: center;
}

.search-results li:hover {
  background-color: #f9f9f9;
}
</style>
