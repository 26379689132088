<template>
  <div class="get-quotes-modal__body--contractors">
    <h1 class="get-quotes-modal__body__title">
      Which contractors would you like to contact you?
    </h1>
    <div class="get-quotes-modal__body__subtitle">
      We’ll send details from the inspection report directly to them to save you
      time.
    </div>
    <div class="get-quotes-modal__body__list">
      <div
        v-for="contractor in availableContractors"
        :key="contractor.id"
        class="
          get-quotes-modal__body__list__item
          get-quotes-modal__body__list__item--contractors
        "
      >
        <input
          type="checkbox"
          :checked="selectedContractors.includes(contractor.id)"
          :value="contractor.id"
          :true-value="contractor.id"
          @change="handleChange"
        />
        <img
          v-if="contractor.logo_url"
          :src="contractor.logo_thumb_url"
          class="get-quotes-modal__body__list__item__picture"
        />
        <div
          v-else
          class="
            get-quotes-modal__body__list__item__picture
            get-quotes-modal__body__list__item__picture--missing
          "
        >
          <i
            class="fa fa-building"
            style="font-size: 2.5em; color: #666"
            aria-hidden="true"
          ></i>
        </div>
        <div class="get-quotes-modal__body__list__item__about">
          <div
            class="
              get-quotes-modal__body__list__item__about__name
              get-quotes-modal__body__list__item__about__name--bold
            "
          >
            {{ formatContractorName(contractor) }}
          </div>
          <div class="get-quotes-modal__body__list__item__about__description">
            {{ formatAddress(contractor) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formatAddress from '@/utils/formatAddress'
import formatContractorName from '@/utils/formatContractorName'
export default {
  props: {
    availableContractors: {
      type: Array,
      default: () => [],
    },
    selectedContractors: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formatAddress,
    formatContractorName,
    handleChange(e) {
      this.$emit('selectContractors', e, 'Contractors')
    },
  },
}
</script>
