import axios from 'axios'
import { pick } from 'lodash'
import railsService from '../../../utils/AxiosService'
import errorReporter from '@spectora/frontend.services.vue-error-service'
import { getReportContractorRecommendations } from '@/api'
import config from '@/utils/config.js'
import formatAndStoreReport from '@/utils/formatAndStoreReport'
import serializedV10SampleReportAdapter from '@/utils/serializedV10SampleReportAdapter'
import { signInWithCustomToken } from '@/db'

const genericError = 'An error has occurred. Your changes likely did not save.'
const loadingError = "We're having trouble loading data. Please try again."

const firebaseProject = config('FIREBASE_PROJECT_ID') || 'spectora-x'
const firebaseFunctionRegion =
  config('FIREBASE_FUNCTION_REGION') || 'us-central1'
const cloudFunctionUrl = `https://${firebaseFunctionRegion}-${firebaseProject}.cloudfunctions.net`

const cloudFunction = (name, data) => {
  // eslint-disable-next-line no-console
  console.log(`calling ${name} with data: `, JSON.stringify(data, undefined, 2))
  return axios.post(`${cloudFunctionUrl}/${name}`, { data })
}

const FIRESTORE_PROCESSED_MEDIA = config('FIRESTORE_PROCESSED_MEDIA')
const RAILS_DOMAIN = config('RAILS_DOMAIN')

export const closeOverlay = ({ commit, dispatch, state }) => {
  commit('setValue', { key: 'overlayPhoto', value: null })
  commit('setValue', { key: 'currentPanoPhoto', value: null })
}

export const fetchAccount = ({ commit, dispatch, state }) => {
  return dispatch('fetchUserProfile').then((profile) => {
    if (
      profile.attributes &&
      profile.type !== 'admin' &&
      profile.attributes.company_id
    )
      return dispatch('fetchCompany', profile.attributes.company_id)
  })
}

export const createSampleReport = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    railsService
      .post('/api/v1/sample_reports', params)
      .then((response) => {
        dispatch(
          'notifier/show',
          { type: 'Saved', msg: 'Sample Report Created!' },
          { root: true }
        )
        resolve(response.data.data)
      })
      .catch((error) => {
        commit('notifier/showError', genericError, { root: true })
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const fetchCompany = ({ commit, dispatch, state }, companyId) => {
  return new Promise((resolve, reject) => {
    const prefix =
      state.loggedIn && state.userProfile.attributes.company_id ? '' : 'public/'
    railsService
      .get(`/api/v1/${prefix}companies/` + companyId)
      .then((response) => {
        commit('setValue', { key: 'company', value: response.data.data })
        resolve(response.data.data)
      })
      .catch((error) => {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const fetchRecommendedContractors = async (
  { commit, dispatch, state },
  reportId
) => {
  const resp = await getReportContractorRecommendations({
    report_id: reportId,
  })

  if (resp.data) {
    commit('setValue', { key: 'reportContractors', value: resp.data.data })
  }
}

export const fetchInspection = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    const url =
      state.loggedIn && !state.sampleReport
        ? `inspections/${state.report.attributes.inspection_id}?include=inspection_attachments,buyer,reports,assignments`
        : `public/reports/${state.report.id}/inspections/${state.report.attributes.inspection_id}?include=inspectors,inspection_attachments,reports,inspection_reviews,assignments`
    railsService
      .get(`/api/v1/${url}`)
      .then((response) => {
        const insp = response.data.data
        const incs = response.data.included || []

        incs.forEach((inc) => {
          const key = inc.type + 's'
          if (!insp[key]) insp[key] = []
          insp[key].push(inc)
        })

        commit('setValue', { key: 'inspection', value: insp })
        resolve(insp)
      })
      .catch((error) => {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const fetchPdfStatus = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    railsService
      .get(`/api/v1/public/reports/${state.report.id}`)
      .then((response) => {
        const report = response.data.data

        if (!report.attributes.pdf_started_at) {
          state.report.attributes.pdf_started_at = null
          state.report.attributes.pdf_url = report.attributes.pdf_url
          state.report.attributes.summary_url = report.attributes.summary_url
          dispatch(
            'notifier/show',
            { type: 'Saved', msg: 'Your PDFs have been refreshed!' },
            { root: true }
          )
        }
      })
  })
}

export const fetchRecommendations = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    const companyId = state.inspection.attributes.company_id
    railsService
      .get(
        `/api/v1/public/recommendations?company_id=${companyId}&reporting_format=true`
      )
      .then((response) => {
        commit('setValue', {
          key: 'recommendations',
          value: response.data.data,
        })
        resolve(response.data.data)
      })
      .catch((error) => {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const fetchToken = async ({ commit }) => {
  const res = await axios.get(
    `https://${RAILS_DOMAIN}/api/v1/users/additional_creds`,
    { withCredentials: true }
  )
  const token = res.data.firestore_token
  if (token) {
    signInWithCustomToken(token)
    commit('setIsFirestoreAuthenticated', true)
  } else {
    commit('setIsFirestoreAuthenticated', false)
  }
  commit('setCompanyId', res.data.company_id)
  commit('setIsAdmin', res.data.is_admin)
  commit('setUserEmail', res.data.user_email)
  commit('setUserId', res.data.user_id)
}

export const fetchReportForSample = async ({ commit }, sampleReportId) => {
  const response = await axios.get(`
    https://${RAILS_DOMAIN}/api/v2/sample_reports/${sampleReportId}/report
  `)

  const result = await formatAndStoreReport(
    { commit },
    {
      report: serializedV10SampleReportAdapter(response.data.report),
      reportView: response.data.report_view,
    }
  )
  return result
}

export const fetchReportFirestore = async ({ commit }, reportId) => {
  let response
  try {
    response = await axios.get(
      `${FIRESTORE_PROCESSED_MEDIA}/${reportId}.json?alt=media`
    )
  } catch (error) {
    return false
  }
  const result = await formatAndStoreReport(
    { commit },
    { report: response.data.report, reportView: response.data.report_view }
  )
  return result
}

export const checkForReportUpdates = async (
  { commit, dispatch, state },
  reportId
) => {
  return (await cloudFunction('checkForReportUpdates', { id: reportId })).data
    .result
}

export const updateReportPayload = (
  { commit, dispatch, state },
  { reportId, clean = false }
) => {
  return cloudFunction('compileReport', { id: reportId, clean })
}

export const updateTemplateComment = async (
  { commit, dispatch, state },
  comment
) => {
  return railsService.put(
    `/api/v1/item_attributes/${comment.template_comment_id}`,
    {
      id: comment.id,
      type: 'item_attribute',
      attributes: pick(comment, ['name', 'text']),
    }
  )
}

export const fetchReportRails = (
  { commit, dispatch, state },
  { reportId, idToken, readOnly = false }
) => {
  let params = `id_token=${idToken}&view=stats,not_show_recommended_contractors_on_reports`
  if (readOnly) params += `&read_only=true`

  return railsService
    .get(`/api/v1/public/reports/${reportId}?${params}`)
    .then((response) => {
      const report = response.data.data.attributes
      const stats = report.stats
      commit('setValue', {
        key: 'reportRails',
        value: {
          ...report,
          firestore: false,
          attributes: {
            ...report,
            image_url: report.image,
            settings: report,
            stats,
          },
        },
      })
      return response.data.data
    })
}

export const fetchReportView = ({ commit, dispatch, state }, reportViewId) => {
  const params = {}
  if (
    reportViewId === state.report.attributes.settings.report_view_id ||
    reportViewId === state.report_view_id
  ) {
    params.view = 'observations,observation_photos'
    params.include = 'report'
  }

  return new Promise((resolve, reject) => {
    railsService
      .get(
        `/api/v1/public/reports/${state.report.id}/report_views/${reportViewId}`,
        { params: params }
      )
      .then((response) => {
        commit('setValue', { key: 'reportView', value: response.data.data })
        if (response.data.included)
          commit('setValue', {
            key: 'originalReport',
            value: response.data.included[0],
          })
        resolve(response.data.data)
      })
      .catch((error) => {
        commit(
          'notifier/showError',
          "We're having trouble loading your Repair Request",
          { root: true }
        )
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const fetchSampleReport = (
  { commit, dispatch, state },
  sampleReportId
) => {
  return new Promise((resolve, reject) => {
    railsService
      .get(`/api/v1/sample_reports/${sampleReportId}`)
      .then((response) => {
        commit('setValue', { key: 'sampleReport', value: response.data.data })
        resolve(response.data.data)
      })
      .catch((error) => {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const fetchUserProfile = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    railsService
      .get('/api/v1/user_profile')
      .then((response) => {
        commit('setValue', { key: 'userProfile', value: response.data.data })
        commit('setValue', { key: 'loggedIn', value: true })
        resolve(response.data.data)
      })
      .catch((_) => {
        // Commented out because it's an expected failure
        // captureException(error)
        commit('setValue', { key: 'loggedIn', value: false })
        resolve(true)
      })
  })
}

export const fetchViewer = ({ commit, dispatch, state }, idToken) => {
  return railsService
    .get(`/api/v1/public/reports/${state.report.id}/profiles/${idToken}`)
    .then((response) => {
      commit('setValue', { key: 'viewer', value: response.data.data })
      return response
    })
}

export const reportViewUpdateSelection = (
  { commit, dispatch, state },
  observation
) => {
  let dupeSelections = JSON.parse(JSON.stringify(state.reportViewObservations))
  if (
    state.reportViewObservations.find((o) => {
      return o.observation_id === observation.observation_id
    })
  ) {
    dupeSelections = dupeSelections.map((o) => {
      return o.observation_id === observation.observation_id ? observation : o
    })
  } else {
    dupeSelections.push(observation)
  }

  commit('setValue', { key: 'reportViewObservations', value: dupeSelections })
}

export const submitReport = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    railsService
      .put(`/api/v1/reports/${state.report.id}`, {
        attributes: { id: state.report.id, submitted_on: new Date() },
      })
      .then((response) => {
        dispatch(
          'notifier/show',
          { type: 'Saved', msg: 'This report has been submitted!' },
          { root: true }
        )
        resolve(response.data.data)
      })
      .catch((error) => {
        commit(
          'notifier/show',
          { type: 'Error', msg: 'There was an error submitting this report.' },
          { root: true }
        )
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const updatePdfs = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    railsService
      .put(`/api/v1/reports/${state.report.id}/update_pdf`)
      .then((response) => {
        dispatch(
          'notifier/show',
          {
            type: 'Saved',
            msg: "Your PDFs are being refreshed! They'll be available again shortly.",
          },
          { root: true }
        )
        const updateReport = state.report
        updateReport.attributes.pdf_started_at =
          response.data.data.attributes.pdf_started_at
        updateReport.attributes.summary_pdf_started_at =
          response.data.data.attributes.summary_pdf_started_at

        commit('setValue', { key: 'report', value: updateReport })
      })
      .catch((error) => {
        commit('notifier/showError', 'There was an error refreshing your PDFs')
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const removeObservation = (
  { commit, dispatch, state },
  observationId
) => {
  let obs = state.observations
  obs = obs.filter((o) => o.id !== observationId)
  commit('setValue', { key: 'observations', value: obs })
  dispatch('setDefectCounts')
}

export const setReinspection = ({ commit, dispatch, state }, reinspection) => {
  commit('setValue', { key: 'reinspection', value: reinspection })
}

export const setReportViewID = ({ commit, dispatch, state }, reportViewId) => {
  commit('setValue', { key: 'report_view_id', value: reportViewId })
}

export const setDefectCounts = ({ commit, dispatch, state }) => {
  const dc = {
    full: {},
    summary: {},
    hazards: {},
    all: {},
    rh: {},
    resolved: [],
    notResolved: [],
    unsatisfactory: [],
  }
  state.sections.forEach((s) => {
    Object.keys(dc).forEach((k) => {
      dc[k][s.id] = []
    })
  })

  const summaryCategories = []
  if (state.report.attributes.settings.category_high_in_summary)
    summaryCategories.push(1)
  if (state.report.attributes.settings.category_low_in_summary)
    summaryCategories.push(-1)
  if (state.report.attributes.settings.category_med_in_summary)
    summaryCategories.push(0)

  state.observations.forEach((o) => {
    if (o.attributes.comment_type === 'defect') {
      const item = state.items.find((i) => {
        return i.id === o.attributes.report_item_id
      })
      if (item) {
        dc.full[item.attributes.report_section_id].push(o.id)
        dc.all[item.attributes.report_section_id].push(o.id)
        if (summaryCategories.includes(o.attributes.category))
          dc.summary[item.attributes.report_section_id].push(o.id)
        if (o.attributes.category === 1)
          dc.hazards[item.attributes.report_section_id].push(o.id)
        if ([1, 0].includes(o.attributes.category))
          dc.rh[item.attributes.report_section_id].push(o.id)
        if (o.attributes.category === 2)
          dc.resolved[item.attributes.report_section_id].push(o.id)
        if (o.attributes.category === 3)
          dc.unsatisfactory[item.attributes.report_section_id].push(o.id)
        if (o.attributes.category === 4)
          dc.notResolved[item.attributes.report_section_id].push(o.id)
      }
    }
  })

  commit('setValue', { key: 'defectCounts', value: dc })
}

export const validateAccessToken = async (
  { commit, dispatch, state },
  accessToken
) => {
  return new Promise((resolve, reject) => {
    railsService
      .post(`/api/v1/public/reports/${state.report.id}/validate_access_token`, {
        report_access_token: accessToken,
      })
      .then((response) => {
        commit('setAccessTokenValid', true)
        resolve(response.data.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
