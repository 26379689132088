<template>
  <v-layout
    wrap
    class="report-hero"
    :style="{ 'background-image': `url(${report.attributes.image_url})` }"
  >
    <v-menu
      v-if="!isViewingRestricted"
      v-model="showReportOptions"
      class="report-options"
      absolute
      offset-y
    >
      <div slot="activator" class="report-tools-btn" data-cy="report-tools-btn">
        Report Tools

        <div class="fa-solid fa-caret-down" />
      </div>

      <v-list>
        <v-list-tile
          v-if="!sampleReport && canEdit && profileEnabled"
          class="report-option"
          data-cy="new-sample-report"
          @click="$emit('show-sample-report')"
        >
          <v-tooltip bottom content-class="info-tooltip">
            <v-list-tile-title slot="activator">
              <div class="icon-container">
                <div class="fa-regular fa-file" />
              </div>
              <div class="combo">
                <div>Make A Sample Report</div>
                <div class="small light">(Only you can see this)</div>
              </div>
            </v-list-tile-title>
            Show off this report by making it your sample report! You'll get a
            link to an anonymized version where the address, client, and agent
            have been replaced with placeholders.
          </v-tooltip>
        </v-list-tile>
        <v-list-tile
          v-if="!reinspection"
          class="report-option"
          @click="$emit('copy-text')"
        >
          <v-tooltip bottom content-class="info-tooltip">
            <v-list-tile-title slot="activator">
              <div class="icon-container">
                <div class="fa-regular fa-file-lines" />
              </div>
              {{ company.attributes.settings.deficiencies_label }}
              Copy-and-Paste Text
            </v-list-tile-title>
            All of the {{ company.attributes.settings.deficiencies_label }} text
            in one place, ready to click-and-paste into other documents.
          </v-tooltip>
        </v-list-tile>
        <v-list-tile class="report-option" @click="enableRrb">
          <v-tooltip bottom content-class="info-tooltip">
            <v-list-tile-title slot="activator">
              <div class="icon-container">
                <div class="fa-solid fa-wrench" />
              </div>
              Repair Request Builder
            </v-list-tile-title>
            Create a repair request document to share.
          </v-tooltip>
        </v-list-tile>
        <v-list-tile v-if="canRib" class="report-option" @click="enableRib">
          <v-tooltip bottom content-class="info-tooltip">
            <v-list-tile-title slot="activator">
              <div class="icon-container">
                <div class="fa-solid fa-arrow-rotate-right" />
              </div>
              Schedule Reinspection
            </v-list-tile-title>
            Schedule a new inspection for this property and with a report with
            the defects you'll be reinspecting
          </v-tooltip>
        </v-list-tile>
        <v-list-tile
          v-if="!sampleReport && !reinspection && isAdmin"
          :href="'/reports/' + report.id + '?force=true'"
        >
          <v-list-tile-title class="report-option">
            <div class="fa-solid fa-file-lines" />
            Legacy Report
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>

    <v-flex v-if="sampleReport" xs12 class="inspection-info">
      1234 Main Street
      <br />
      {{ cityStateZip }}
      <div class="date">{{ oneDayAgo }} 9:00AM</div>
    </v-flex>
    <v-flex v-else xs12 class="inspection-info" data-cy="report-address">
      {{ inspection.attributes.property_address }}
      <br />
      {{ inspection.attributes.property_address_2 }}
      <div class="date">{{ inspection.attributes.datetime_formatted }}</div>
    </v-flex>

    <div class="inspector-sidebar" data-cy="inspector-name">
      <a
        v-for="inspector in inspectors"
        :key="inspector.id"
        class="person-chip inspector-chip"
        :href="inspectorUrl(inspector)"
      >
        <div
          v-if="
            (inspector.thumb && !inspector.thumb.includes('default-user')) ||
            (inspector.square_image &&
              !inspector.square_image.includes('default-user'))
          "
          class="chip-image"
        >
          <img :src="inspector.thumb || inspector.square_image" />
        </div>
        <div class="chip-info">
          <div class="chip-label">{{ staffName }}</div>
          <div class="chip-name">{{ inspector.name }}</div>
          <div
            v-if="
              report.attributes.settings.display_signatures &&
              (inspector.signature_url || inspector.signature)
            "
          >
            <img
              class="signature"
              :src="inspector.signature_url || inspector.signature"
            />
          </div>
          <v-tooltip
            v-if="inspector.credentials"
            tag="div"
            top
            content-class="info-tooltip"
          >
            <div slot="activator" class="chip-sublabel">
              {{ inspector.credentials }}
            </div>
            {{ inspector.credentials }}
          </v-tooltip>
        </div>
      </a>
    </div>

    <a
      v-if="displayBuyingAgent && hasBuyingAgent"
      class="person-chip agent-chip"
      :href="buyingAgentUrl"
    >
      <div
        v-if="buyingAgent.image && !buyingAgent.image.includes('default-user')"
        class="chip-image"
      >
        <img :src="buyingAgent.thumb" />
      </div>

      <div class="chip-info">
        <div class="chip-label">Agent</div>
        <div class="chip-name" data-cy="buying-agent-name">
          {{ buyingAgent.name }}
        </div>
        <div class="chip-sublabel">{{ buyingAgent.agency_name }}</div>
      </div>
    </a>
    <div
      v-else-if="!isViewingRestricted && displayBuyingAgent && loggedIn"
      class="agent-share-btn"
      data-cy="agent-share-btn"
    >
      <div class="btn btn-white" @click="$emit('adding-agent')">
        <div class="fa-solid fa-share" />
        Share with your agent
      </div>
    </div>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../utils/AxiosService'
import dig from '../../utils/Dig'
import config from '@/utils/config'

const RAILS_DOMAIN = config('RAILS_DOMAIN')

export default {
  props: {
    railsReport: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      addingAgent: false,
      addedAgent: null,
      inspectors: [],
      showReportOptions: false,
    }
  },
  computed: {
    ...mapState('reports', [
      'company',
      'inspection',
      'loggedIn',
      'report',
      'reportRails',
      'reportAssignments',
      'userProfile',
      'reinspection',
      'report_view_id',
    ]),
    buyingAgent() {
      if (this.sampleReport) {
        return {
          name: 'Agent Name',
          agency_name: 'Agency Name',
          image: 'https://di12hdd3bsox6.cloudfront.net/sample-agent.png',
          thumb: 'https://di12hdd3bsox6.cloudfront.net/sample-agent.png',
        }
      } else {
        return dig(
          this.inspection,
          'attributes.buying_agent.data.attributes',
          {}
        )
      }
    },
    buyingAgentUrl() {
      if (this.sampleReport) {
        return '#'
      }

      return `https://${RAILS_DOMAIN}/agents/${this.inspection.attributes.buying_agent_id}`
    },
    canEdit() {
      return (
        this.loggedIn &&
        !this.sampleReport &&
        ((['inspector', 'manager'].includes(this.userProfile.type) &&
          this.userProfile.attributes.company_id ===
            this.company.attributes.id) ||
          this.userProfile.type === 'admin')
      )
    },
    canRib() {
      return (
        this.canEdit &&
        !this.reinspection &&
        dig(this.company, 'attributes.settings.enable_reinspections', false)
      )
    },
    cityStateZip() {
      let s = ''

      if ((this.company.attributes.city || '').length > 0)
        s += this.company.attributes.city
      if ((this.company.attributes.state || '').length > 0)
        s += `, ${this.company.attributes.state}`
      if ((this.company.attributes.zip || '').length > 0)
        s += ` ${this.company.attributes.zip}`

      return s
    },
    displayBuyingAgent() {
      return (
        dig(this.company, 'attributes.company_type.can_use_agents', true) &&
        dig(this.company, 'attributes.settings.agent_display_buying', true)
      )
    },
    hasBuyingAgent() {
      return this.sampleReport || this.inspection.attributes.buying_agent_id
    },
    isAdmin() {
      return this.loggedIn && this.userProfile.type === 'admin'
    },
    isViewingRestricted() {
      return (
        this.report.attributes.viewing_restricted ||
        this.reportRails.settings.tools_restricted
      )
    },
    oneDayAgo() {
      const d = new Date()
      d.setDate(d.getDate() - 1)
      const month = d.getMonth() + 1
      const day = d.getDate()
      let first, second

      if (
        (this.company.attributes.settings.date_format || '%m/%d').includes(
          '%m/%d'
        )
      ) {
        first = month
        second = day
      } else {
        first = day
        second = month
      }

      return `${first}/${second}/${d.getFullYear()}`
    },
    profileEnabled() {
      return this.company.attributes.settings.enable_profile
    },
    sampleReport() {
      return (
        this.$route.name === 'sample-report' ||
        (this.$route.query.sample_id || '').length > 0
      )
    },
    staffName() {
      return this.company.attributes.staff_name || 'Inspector'
    },
  },
  mounted() {
    this.fetchAssignments()
  },
  methods: {
    enableRib() {
      this.$store.commit('reports/setValue', { key: 'rib', value: true })
      this.$store.commit('reports/setValue', {
        key: 'activeFilter',
        value: 'all',
      })
    },
    enableRrb() {
      this.$store.commit('reports/setValue', { key: 'rrb', value: true })
      this.$store.commit('reports/setValue', {
        key: 'activeFilter',
        value: 'all',
      })
    },
    fetchAssignments() {
      if (this.reportAssignments.length > 0) {
        this.inspectors = this.reportAssignments.map((ra) => {
          return ra.attributes || ra
        })
      } else if (this.inspection.inspectors) {
        this.inspectors = this.inspection.inspectors.map((inspector) => {
          return inspector.attributes || inspector
        })
      } else {
        axios
          .get(
            `/api/v1/assignments?inspection_id=${this.inspection.id}&include=inspector&hidden=false`
          )
          .then((response) => {
            this.inspectors = response.data.included.map(
              (inspector) => inspector.attributes || inspector
            )
          })
      }
    },
    inspectorUrl(inspector) {
      if (this.company.attributes.multi_inspector) {
        return `https://${RAILS_DOMAIN}/home-inspectors/${this.company.attributes.slug}/${inspector.slug}`
      } else {
        return `https://${RAILS_DOMAIN}/home-inspectors/${this.company.attributes.slug}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.report-hero {
  height: 30em;
  background-size: cover;
  background-position: center;
  position: sticky;
  align-content: center;
  align-items: center;
  background-color: #3b464f;
}

.report-options {
  position: absolute;
  top: 0;
  right: 2em;
}

.report-tools-btn {
  padding: 0.25em 0.75em 0.5em 0.75em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border: 1px solid #fff;
  border-top: none;
  transition: all 0.1s linear;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &:hover {
    background-color: #000;
  }
}

.report-option {
  color: #45769c;

  .combo {
    display: inline-block;
  }
  &:first-child .v-list__tile__title {
    height: auto;
  }
}

.icon-container {
  vertical-align: top;
  display: inline-block;
  margin-right: 0.25em;
}

.inspection-info {
  color: #fff;
  text-align: center;
  font-size: 2.25em;
  text-shadow: 2px 2px 4px black;

  .date {
    font-size: 0.75em;
  }
}

.inspector-sidebar {
  position: absolute;
  height: 100%;
  left: 2em;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1em;

  .person-chip {
    align-self: flex-start;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.agent-share-btn {
  .btn {
    font-size: 16px;
    padding: 0 2rem;
  }
  position: absolute;
  right: 2em;
  bottom: 0;
}

.person-chip {
  text-align: center;
  background-color: #f9f9f9;
  height: 5em;
  min-width: 17em;
  border-radius: 2.5em;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  transition: background 0.1s linear;

  .chip-info,
  .chip-image {
    display: inline-block;
  }

  .chip-info {
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    align-content: center;
    max-width: 12em;
    padding: 0 1em;
    div {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .chip-image,
  .chip-image img {
    width: 5em;
    height: 5em;
    border-radius: 50%;
  }

  .chip-label {
    text-transform: uppercase;
    font-size: 0.75em;
    line-height: 1em;
    color: #bbb;
  }

  .chip-name {
    color: #666;
    font-weight: bold;
  }

  .chip-sublabel {
    font-size: 0.75em;
    line-height: 1em;
    color: #888;
  }

  &:hover {
    background-color: #fff;
  }
}

.agent-chip {
  position: absolute;
  right: 2em;
  bottom: 1em;
}

.inspector-chip .signature {
  height: 1.5em;
}

@media (max-width: 960px) {
  .report-hero {
    font-size: 10px;
    align-content: flex-start;
  }

  .inspection-info {
    text-align: left;
    padding-top: 0.25em;
    padding-left: 1em;
    line-height: 1.25;
  }
}

@media (max-width: 600px) {
  .inspector-sidebar {
    margin-top: 1em;
    position: relative;
    justify-content: flex-start;
    height: auto;
  }

  .agent-share-btn {
    left: 2em;
    position: relative;
  }

  .agent-chip {
    position: relative;
    left: 2em;
    top: 0em;
  }
}
</style>
