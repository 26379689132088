<template>
  <flat-pickr
    ref="datePicker"
    class="form-control"
    :placeholder="placeholder"
    :value="value"
    :config="dateConfig"
    @on-close="validate"
    @input="handleInput"
    v-on="$listeners"
  />
</template>

<script>
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import dig from '../utils/Dig.js'
import { captureException } from '@/utils'

export default {
  components: {
    flatPickr,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Select a date...',
    },
    config: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: 'date',
      validator: (prop) => ['date', 'datetime'].includes(prop),
    },
    value: {
      type: [String, Date],
      default: null,
    },
  },
  computed: {
    dateConfig() {
      if (this.config && (this.config.dateFormat || this.config.altFormat)) {
        return this.config
      }

      if (this.mode === 'date') {
        return {
          enableTime: false,
          dateFormat: 'Y-m-d',
        }
      }

      return {
        enableTime: true,
        dateFormat: 'Y-m-d h:i K',
        altInput: true,
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeCalendar)
  },
  created() {
    document.addEventListener('click', this.closeCalendar)
  },
  methods: {
    // adds close on blur overlay functionality
    closeCalendar(e) {
      const grandParentClass = dig(
        e,
        'target.parentElement.parentElement.className',
        ''
      )
      const parentClass = e.target.parentElement.className
      const targetClass = e.target.className
      const classes = `${grandParentClass} ${parentClass} ${targetClass}`

      if (!classes.includes('calendar') && !classes.includes('flatpickr')) {
        try {
          const dateString = this.$refs.datePicker.fp.selectedDates[0]

          if (!dateString) {
            return
          }

          const date = this.$refs.datePicker.fp.formatDate(
            this.$refs.datePicker.fp.selectedDates[0],
            this.dateConfig.dateFormat
          )

          this.handleInput(date)
          this.$refs.datePicker.fp.close()
        } catch (err) {
          captureException(err)
        }
      }
    },
    handleInput(value) {
      this.$emit('input', value)
    },
    validate() {
      const date = this.$refs.datePicker.fp.selectedDates[0]
      let formatted = this.value

      if (date) {
        formatted = this.$refs.datePicker.fp.formatDate(
          date,
          this.dateConfig.dateFormat
        )
      }

      this.$emit('input', formatted)
    },
  },
}
</script>

<style></style>
