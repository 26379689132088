<template>
  <modal
    id="submit-report"
    name="submit-report"
    :scrollable="false"
    width="60%"
    height="auto"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>Submit Report</h2>
      </div>
      <div class="modal-body">
        <v-layout wrap>
          <v-flex xs12>
            <p>
              This report will be submitted for final review. Are you ready to
              submit it?
            </p>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 class="center">
            <div class="btn unbutton btn-xlarge" @click="close">Cancel</div>
            <div class="save-btn btn btn-large" @click="save">
              <i v-if="!saving" class="fa-regular fa-square-check" />
              <i v-else class="fa-solid fa-spinner fa-spin" />
              Submit Report
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    ...mapState('reports', ['report']),
  },
  beforeDestroy() {
    this.close()
  },
  mounted() {
    this.$modal.show('submit-report')
  },
  methods: {
    close() {
      this.saving = false
      this.$modal.hide('submit-report')
      this.$emit('close')
    },
    save() {
      if (this.saving) return
      this.saving = true

      this.$store
        .dispatch('reports/submitReport')
        .then((response) => {
          this.$emit('change', response.attributes.submitted_on_formatted)
          this.close()
        })
        .catch(() => {
          this.saving = false
        })
    },
  },
}
</script>
