<template>
  <div class="toast-container">
    <v-snackbar
      v-model="showSuccess"
      :timeout="2000"
      color="success"
      class="show-saved-snackbar"
      left
    >
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="successDefaultMessage" />
      <!-- eslint-enable -->
    </v-snackbar>
    <v-snackbar
      v-model="showSaved"
      :timeout="2000"
      color="success"
      class="show-saved-snackbar"
      left
    >
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="saveMessage" />
      <!-- eslint-enable -->
    </v-snackbar>
    <v-snackbar
      v-model="showWarning"
      :timeout="4000"
      color="orange"
      class="show-saved-snackbar"
      left
    >
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="warningMessage" />
      <!-- eslint-enable -->
    </v-snackbar>
    <v-snackbar
      v-for="(error, index) in toastErrors"
      :key="index"
      v-model="showError"
      :timeout="4000"
      color="error"
      class="show-error-snackbar"
      left
    >
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="error" />
      <!-- eslint-enable -->
      <v-btn flat class="pl-3 pr-0 mx-0" @click.native="shiftError">
        <div class="fa-regular fa-close" />
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('notifier', [
      'savedMessage',
      'successMessage',
      'warningMessage',
    ]),
    ...mapState('notifier', {
      toastErrors: 'errors', // errors is a vue component reserved word
    }),
    saveMessage() {
      return this.savedMessage || 'Saved!'
    },
    successDefaultMessage() {
      return this.successMessage || 'Success!'
    },
    showError: {
      get() {
        return this.$store.state.notifier.showError
      },
      set(newValue) {
        return this.$store.commit('notifier/setShowError', newValue)
      },
    },
    showSaved: {
      get() {
        return this.$store.state.notifier.showSaved
      },
      set(newValue) {
        return this.$store.commit('notifier/setShowSaved', newValue)
      },
    },
    showSuccess: {
      get() {
        return this.$store.state.notifier.showSuccess
      },
      set(newValue) {
        return this.$store.commit('notifier/setShowSuccess', newValue)
      },
    },
    showWarning: {
      get() {
        return this.$store.state.notifier.showWarning
      },
      set(newValue) {
        return this.$store.commit('notifier/setShowWarning', newValue)
      },
    },
  },
  methods: {
    ...mapMutations('notifier', ['shiftError']),
  },
}
</script>

<style lang="scss" scoped>
.toast-container {
  z-index: 999999;
}
</style>

<style lang="scss">
.toast-container .v-snack__wrapper.error .v-snack__content {
  color: #fff;
  height: auto;
  min-height: 48px;
}

.toast-container {
  .v-snack__wrapper.success {
    background-color: #65c635;
  }
  .v-snack__wrapper.orange {
    background-color: #f9890e;
  }
  .v-snack__wrapper.error {
    background-color: #d53636;
  }
}
</style>
