const masks = {
  US: '###-###-####',
  CA: '###-###-####',
}

const formatPhone = ({ value, locale }) => {
  const mask = masks[locale || 'US']

  if (!value) return ''
  if (!mask) return value

  const sanitizedValue = String(value).replace(/^\+1|\D+/g, '')

  let maskedValue = ''
  let valueIndex = 0

  for (let i = 0; i < mask.length; i++) {
    if (mask[i] === '#') {
      if (valueIndex < sanitizedValue.length) {
        maskedValue += sanitizedValue[valueIndex]
        valueIndex++
      }
    } else {
      maskedValue += mask[i]
    }
  }

  return maskedValue
}

export default formatPhone
