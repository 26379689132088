import Inflect from 'i'
import stateKeys from './keys'
import errorReporter from '@spectora/frontend.services.vue-error-service'
import railsService from '@/utils/AxiosService'
import { captureException } from '@/utils'

const fieldsToStrip = ['created_at', 'updated_at', 'meets_conditions']
const genericError = 'An error has occurred. Your changes likely did not save.'
const inflect = Inflect()

const dataToFormData = (data, fileFields) => {
  const formData = new FormData()
  const key = data.type
  for (const prop in data.attributes) {
    formData.append(`${key}[${prop}]`, data.attributes[prop])
  }
  return formData
}

// The unified API interaction action
//
// @action - controller action to target
//
// @data - payload to send with call.  This determines the API path through
// rails-like inflections.
//
// This is expected to be an object in the
// form of a typical rails JSON serialization.
//
//   {
//     id: undefined,
//     type: 'report_section',
//     attributes: {
//       name: 'New name for section'
//     }
//   }
//
// @method - HTTP method to use, defaults to POST
//
// @multipart - if true, payload will be transformed into a multipart form
//
// @onUploadProgress - supply a callback for upload updates.
//
// @getParams - include additional GET parameters by passing an object
//
// Example implementation:
//   let onUploadProgress = progressEvent => {
//     let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
//     commit('setCoverPhotoProgress', percentCompleted)
//   }
//
//
// Returns a promise upon response receipt
//
export const callApi = (
  { commit, dispatch, state },
  { action, data, method, multipart, onUploadProgress, getParams }
) => {
  // Determine api path
  let modelName
  const isCollection = Array.isArray(data)
  if (isCollection) {
    // Make sure you don't send a zero length payload to callApi
    modelName = inflect.pluralize(data[0].type)
  } else {
    modelName = inflect.pluralize(data.type)
  }

  // Base railsService options
  const request = {
    method: method || 'post',
    url: '/api/v1/' + modelName,
    data: data,
  }

  // Append id if this is a typical edit or destroy
  if (!isCollection && ['patch', 'put', 'delete'].includes(request.method)) {
    request.url += '/' + data.id
  }

  if (request.method === 'get') {
    request.url += '/' + data.id
    delete request.data
  }

  // Append action if available
  if (action) {
    request.url += '/' + action
  }

  if (getParams) {
    const queryString = Object.keys(getParams)
      .map((key) => key + '=' + getParams[key])
      .join('&')
    request.url += '?'
    request.url += queryString
  }

  // Strip fields that shouldn't be updated
  if (!isCollection && request.data && request.data.attributes) {
    fieldsToStrip.map(function (field) {
      delete request.data.attributes[field]
    })
  }
  if (isCollection) {
    request.data.map(function (data) {
      fieldsToStrip.map(function (field) {
        delete data.attributes[field]
      })
    })
  }

  // Transform data from JSON to FormData
  if (multipart) {
    request.data = dataToFormData(request.data, multipart)
    request.headers = {
      'content-type': 'multipart/form-data',
    }
    if (onUploadProgress) {
      request.onUploadProgress = onUploadProgress
    }
  }

  // Rig up railsService and forward the Promise
  return new Promise((resolve, reject) => {
    railsService(request)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const deleteRecord = ({ commit, dispatch, state }, payload) => {
  dispatch('callApi', {
    method: 'delete',
    data: payload,
  }).then((response) => {
    commit('updateRecords', { records: [payload], deleteIt: true })
    dispatch('notifier/show', { type: 'Saved', msg: 'Saved!' }, { root: true })
  })
}

export const fetchActions = ({ commit, dispatch, state }, params = {}) => {
  commit('setActionsLoading', true)
  let url = '/api/v1/auto_actions'
  if (state.companyId !== undefined)
    url = `${url}?company_id=${state.companyId}&`

  railsService
    .get(url)
    .then(function (response) {
      commit('setActions', response.data.data)
      commit('setActionsLoading', false)
    })
    .catch(function (error) {
      dispatch(
        'notifier/show',
        { type: 'Error', msg: genericError },
        { root: true }
      )
      errorReporter.error(error)
    })
}

export const fetchSmsTemplates = ({ commit, dispatch, state }, params = {}) => {
  commit('setSmsTemplatesLoading', true)
  let url = '/api/v1/auto_actions/sms_templates'
  if (state.companyId !== undefined)
    url = `${url}?company_id=${state.companyId}&`

  railsService
    .get(url)
    .then(function (response) {
      commit('setSmsTemplates', response.data.data)
      commit('setSmsTemplatesLoading', false)
    })
    .catch(function (error) {
      dispatch(
        'notifier/show',
        { type: 'Error', msg: 'An error has occurred loading SMS templates' },
        { root: true }
      )
      errorReporter.error(error)
    })
}

export const fetchCompany = ({ commit, dispatch, state }, id) => {
  railsService
    .get('/api/v1/companies/' + id)
    .then((response) => {
      commit('setCompany', response.data.data)
    })
    .catch((error) => {
      captureException(error)
    })
}

export const fetchConditions = ({ commit, dispatch, state }) => {
  commit('setConditionsLoading', true)
  let url = '/api/v1/auto_conditions'
  if (state.companyId !== undefined) {
    url = `${url}?company_id=${state.companyId}&`
  }
  railsService
    .get(url)
    .then(function (response) {
      commit('setConditions', response.data.data)
      commit('setConditionsLoading', false)
    })
    .catch(function (error) {
      dispatch(
        'notifier/show',
        { type: 'Error', msg: genericError },
        { root: true }
      )
      errorReporter.error(error)
    })
}

export const fetchEvents = ({ commit, dispatch, state }) => {
  commit('setEventsLoading', true)
  railsService
    .get('/api/v1/auto_events')
    .then(function (response) {
      commit('setEvents', response.data.data)
      commit('setEventsLoading', false)
    })
    .catch(function (error) {
      dispatch(
        'notifier/show',
        { type: 'Error', msg: genericError },
        { root: true }
      )
      errorReporter.error(error)
    })
}

export const fetchInspection = (
  { commit, dispatch, state },
  inspectionSlug
) => {
  return new Promise((resolve, reject) => {
    railsService
      .get('/api/v1/inspections/' + inspectionSlug)
      .then(function (response) {
        commit('setInspection', response.data.data)
        commit('setInspectionSlug', response.data.data.attributes.slug)
        resolve(response.data.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const fetchTracks = ({ commit, dispatch, state }, params = {}) => {
  commit('setTracksLoading', true)
  let url =
    state.inspectionSlug || state.autoTrackRunId
      ? '/api/v1/auto_track_runs?'
      : '/api/v1/auto_tracks?'
  Object.keys(params).forEach((k) => {
    url += `${k}=${params[k]}&`
  })
  if (state.inspectionSlug !== undefined)
    url += `inspection_id=${state.inspectionSlug}&`
  if (state.autoTrackRunId !== undefined)
    url += `auto_track_run_id=${state.autoTrackRunId}&`
  if (state.companyId !== undefined) url += `company_id=${state.companyId}`

  railsService
    .get(url)
    .then((response) => {
      commit('setRecords', response.data.data)
      commit('setTracksLoading', false)
      if (state.inspectionSlug) dispatch('updateFilter')
      if (state.autoTrackRunId !== undefined) {
        dispatch(
          'fetchInspection',
          response.data.data[0].attributes.inspection_id
        )
      }
    })
    .catch((error) => {
      dispatch(
        'notifier/show',
        { type: 'Error', msg: genericError },
        { root: true }
      )
      errorReporter.error(error)
    })
}

export const fetchTrackGroups = ({ commit, dispatch, state }) => {
  commit('setTrackGroupsLoading', true)
  if (state.autoTrackRunId !== undefined) {
    commit('setRecords', [
      {
        type: 'auto_track_group',
        attributes: {},
      },
    ])
    commit('setTrackGroupsLoading', false)
    return
  }
  let url = '/api/v1/auto_track_groups?'
  if (state.companyId !== undefined) {
    url = `${url}company_id=${state.companyId}&`
  }
  railsService
    .get(url)
    .then(function (response) {
      const groups = response.data.data
      if (state.inspectionSlug)
        groups.push({
          id: null,
          attributes: { id: null, name: 'Manual Inspection Actions' },
        })

      commit('setRecords', response.data.data)
      commit('setTrackGroupsLoading', false)
    })
    .catch(function (error) {
      dispatch(
        'notifier/show',
        { type: 'Error', msg: genericError },
        { root: true }
      )
      errorReporter.error(error)
    })
}

export const fetchUserProfile = ({ commit, dispatch, state }) => {
  commit('setUserProfileLoading', true)
  railsService
    .get('/api/v1/user_profile')
    .then(function (response) {
      dispatch(
        'fetchCompany',
        state.companyId || response.data.data.attributes.company_id
      )
      commit('setUserProfile', response.data.data)
      commit('setUserProfileLoading', false)
    })
    .catch(function (_) {
      dispatch(
        'notifier/show',
        { type: 'Error', msg: genericError },
        { root: true }
      )
      // Commented out because it's an expected failure
      // errorReporter.error(error)
    })
}

export const refreshRun = ({ commit, dispatch, state }, payload) => {
  dispatch('callApi', {
    data: payload,
    action: 'refresh',
    method: 'patch',
  })
    .then((response) => {
      dispatch('callApi', {
        method: 'get',
        data: payload,
      })
        .then((response) => {
          commit('updateRecords', { records: [response.data.data] })
          dispatch(
            'notifier/show',
            { type: 'Saved', msg: 'Saved!' },
            { root: true }
          )
        })
        .catch(function (error) {
          // 404 received, refresh deleted run
          captureException(error)
          commit('updateRecords', { records: [payload], deleteIt: true })
          dispatch(
            'notifier/show',
            { type: 'Saved', msg: 'Saved!' },
            { root: true }
          )
        })
    })
    .catch(function (error) {
      dispatch(
        'notifier/show',
        {
          type: 'Error',
          msg: 'Error occurred while refreshing.Try again later.',
        },
        { root: true }
      )
      errorReporter.error(`Error occurred refreshing run ${payload.id}`, error)
    })
}

export const refreshRuns = ({ commit, dispatch, state }) => {
  const payload = state.inspection
  return dispatch('callApi', {
    data: payload,
    action: 'manage_auto_track_runs',
    method: 'patch',
  })
}

export const updateRecord = ({ commit, dispatch, state }, payload) => {
  return new Promise((resolve, reject) => {
    const isNew = payload.id === undefined
    const method = isNew ? 'post' : 'patch'
    dispatch('callApi', {
      method: method,
      data: payload,
    })
      .then((response) => {
        if (isNew) {
          return response
        }
        return dispatch('callApi', {
          method: 'get',
          data: payload,
        })
      })
      .then((response) => {
        if (isNew) {
          commit('addRecords', [response.data.data])
        } else {
          commit('updateRecords', { records: [response.data.data] })
        }
        dispatch(
          'notifier/show',
          { type: 'Saved', msg: 'Saved!' },
          { root: true }
        )
        resolve(response)
      })
      .catch(function (error) {
        dispatch(
          'notifier/show',
          {
            type: 'Error',
            msg: `Error occurred updating ${payload.type}. Try again later.`,
          },
          { root: true }
        )
        errorReporter.error(`Error occurred updating ${payload.type}`, error)
      })
  })
}

export const updatePrioritiesRecords = (
  { commit, dispatch, state },
  collection
) => {
  if (collection.length < 1) {
    return
  }
  const key = stateKeys[collection[0].type]
  collection.map((x, index) => {
    x.attributes.priority = index
  })

  commit('updateRecords', { records: collection })
  commit('sortRecords', { collection: key })
  dispatch('updatePriorities', collection)
}

export const updatePriorities = ({ commit, dispatch, state }, collection) => {
  if (collection.length < 1) {
    return
  }
  const payload = collection.map((x) => {
    const attributes = {
      priority: x.attributes.priority,
    }
    if (x.type === 'auto_track') {
      attributes.auto_track_group_id = x.attributes.auto_track_group_id
    }
    return {
      id: x.id,
      type: x.type,
      attributes: attributes,
    }
  })
  dispatch('callApi', { data: payload, action: 'batch', method: 'patch' }).then(
    function (response) {
      dispatch(
        'notifier/show',
        { type: 'Saved', msg: 'Saved!' },
        { root: true }
      )
    }
  )
}

export const updateFilter = ({ commit, dispatch, state }, payload = {}) => {
  const filter = JSON.parse(JSON.stringify(state.filter))

  Object.keys(payload).forEach((k) => {
    filter[k] = payload[k]
  })

  commit('setFilterValue', filter)
}

export const validateRecord = ({ commit, dispatch, state }, payload) => {
  return dispatch('callApi', {
    action: 'validate',
    method: 'post',
    data: payload,
  })
}
