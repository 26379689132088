export const updateInspectionAttributes = (state, attributes) => {
  Object.keys(attributes).forEach((k) => {
    state.inspection.attributes[k] = attributes[k]
  })
}

export const setCompany = (state, value) => {
  state.company = value
}

export const setInspection = (state, value) => {
  state.inspection = value
}

// Avoid using this for new code. Tracking vuex changes is a PitA with this function
export const setValue = (state, { key, value }) => {
  state[key] = value
}

export const reload = (state, { key, value }) => {
  if (value === undefined) value = true
  const newReload = Object.assign({}, state.reload)
  newReload[key] = value
  state.reload = newReload
}
