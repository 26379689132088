<template>
  <v-layout class="overlay-photo" @click="close">
    <v-flex xs12 center>
      <v-flex v-if="isPano" xs12 center class="inner-container">
        <div v-if="imageData !== undefined" class="image-container">
          <v-pannellum :src="overlayPhoto.attributes.image" :hfov="120">
          </v-pannellum>
          <div
            class="fa-solid fa-xmark icon close-button"
            @click="close('closeButton')"
          />
        </div>
      </v-flex>
      <v-flex v-else xs12 center class="inner-container">
        <img
          class="image-container"
          style="object-fit: contain"
          :src="overlayPhoto.attributes.image"
        />
        <div v-if="hasCaption" class="caption-text">
          {{ overlayPhoto.attributes.caption }}
        </div>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import dig from '../../utils/Dig'

export default {
  data() {
    return {
      imageData: undefined,
    }
  },
  computed: {
    ...mapState('reports', ['overlayPhoto']),
    hasCaption() {
      return (this.overlayPhoto.attributes.caption || '').length > 0
    },
    isPano() {
      return dig(this, 'overlayPhoto.attributes.is_pano')
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.close)
  },
  mounted() {
    this.$store.commit('reports/setValue', {
      key: 'showPanoButton',
      value: false,
    })

    this.setScrollListener()
    if (this.isPano) {
      fetch(this.overlayPhoto.attributes.image, { cache: 'no-cache' })
        .then((response) => response.blob())
        .then((blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => {
              resolve(reader.result)
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
        })
        .then((data) => {
          this.imageData = data
        })
    }
  },
  methods: {
    close(source) {
      if (this.isPano && source !== 'closeButton') {
        return
      }
      this.$store.dispatch('reports/closeOverlay')
    },
    setScrollListener() {
      window.addEventListener('scroll', this.close)
    },
  },
}
</script>

<style lang="scss" scoped>
.overlay-photo {
  z-index: 3;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-out;
}

.inner-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2em;
}

.caption,
.image-container {
  width: 95%;
}

.image-container {
  height: 95%;
  max-height: 95%;
  background-size: contain;
}

.caption-text {
  width: 100%;
  color: #fff;
  font-size: 1em;
  margin-top: 1em;
}

.close-button {
  color: white;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  cursor: pointer;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  font-size: 2rem;
  z-index: 1;
}
</style>
