<template>
  <v-layout
    wrap
    class="section-table"
    :class="{ 'wide-ratings': alotOfRatings }"
  >
    <v-layout class="ratings-header">
      <div class="number">&nbsp;</div>
      <div class="item-name">&nbsp;</div>
      <v-layout class="ratings-headers">
        <div
          v-for="(abbr, idx) in report.attributes.ratings_abbreviations"
          :key="abbr"
          class="rating"
        >
          <v-tooltip top content-class="info-tooltip">
            <span slot="activator">{{ abbr }}</span>
            {{ report.attributes.ratings_names[idx] }}
          </v-tooltip>
        </div>
      </v-layout>
    </v-layout>
    <v-flex
      v-for="(item, idx) in sectionItems"
      :key="item.id"
      xs12
      class="item-row"
    >
      <v-layout>
        <div class="number">{{ sectionNumber }}.{{ idx + 1 }}</div>
        <div class="item-name">{{ item.attributes.name }}</div>
        <v-layout class="ratings">
          <template
            v-for="(abbr, index) in report.attributes.ratings_abbreviations"
          >
            <div
              :key="index"
              class="rating"
              :class="{ selected: item.attributes[`rating_${index}`] }"
            >
              X
            </div>
          </template>
        </v-layout>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['section', 'sectionNumber'],
  computed: {
    ...mapState('reports', ['report', 'items']),
    alotOfRatings() {
      return this.report.attributes.ratings_abbreviations.length > 4
    },
    sectionItems() {
      return this.items.filter((i) => {
        return (
          i.attributes.report_section_id === this.section.id &&
          !i.attributes.is_overview
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ratings-header {
  width: 100%;
  .ratings-headers {
    justify-content: flex-end;

    .rating {
      width: 3%;
      color: #45769c;
      text-align: center;
      overflow: hidden;
      flex: 1;
    }
  }
}

.item-row {
  border: 1px solid #45769c;
  border-bottom: none;

  &:nth-child(odd) {
    background-color: #eee;
  }
  &:last-child {
    border-bottom: 1px solid #45769c;
  }
}

.number {
  width: 5%;
  text-align: center;
  border-right: 1px solid #45769c;
}

.ratings-header .number {
  border: none;
}

.item-name {
  width: 75%;
  padding-left: 1em;
}

.ratings {
  border-left: 1px solid #45769c;
  display: flex;
  justify-content: space-evenly;

  .rating {
    width: 3%;
    color: transparent;
    text-align: center;
    border-right: 1px solid #45769c;
    flex: 1;
    &:last-child {
      border-right: none;
    }
    &.selected {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.wide-ratings {
  .item-name {
    width: 40%;
  }
}

@media (max-width: 960px) {
  .number {
    width: 10%;
  }
  .item-name {
    width: 60%;
  }

  .wide-ratings {
    .ratings-headers .rating {
      font-size: 0.75em;
    }
    .item-name {
      width: 30%;
    }
  }
}
</style>
