<template>
  <v-layout wrap>
    <v-flex v-if="company" xs12 class="sidebar-top">
      <a
        class="company-link"
        :href="companyUrl"
        data-cy="company-link"
        target="_blank"
      >
        <img class="company-logo" :src="company.attributes.image_url" />
      </a>

      <v-layout class="social-row">
        <a
          v-if="company.attributes.settings.social_link_facebook"
          :href="company.attributes.settings.social_link_facebook"
          target="_blank"
        >
          <em class="fa-brands fa-facebook-square" />
        </a>
        <a
          v-if="company.attributes.settings.social_link_twitter"
          :href="company.attributes.settings.social_link_twitter"
          target="_blank"
        >
          <em class="fa-brands fa-twitter-square" />
        </a>
        <a
          v-if="company.attributes.settings.social_link_youtube"
          :href="company.attributes.settings.social_link_youtube"
          target="_blank"
        >
          <em class="fa-brands fa-youtube-square" />
        </a>
        <a
          v-if="company.attributes.settings.social_link_google"
          :href="company.attributes.settings.social_link_google"
          target="_blank"
        >
          <em class="fa-brands fa-google-plus-square" />
        </a>
        <a
          v-if="company.attributes.settings.social_link_linkedin"
          :href="company.attributes.settings.social_link_linkedin"
          target="_blank"
        >
          <em class="fa-brands fa-linkedin-square" />
        </a>
        <a
          v-if="company.attributes.settings.social_link_instagram"
          :href="company.attributes.settings.social_link_instagram"
          target="_blank"
        >
          <em class="fa-brands fa-instagram" />
        </a>
        <a
          v-if="company.attributes.settings.social_link_yelp"
          :href="company.attributes.settings.social_link_yelp"
          target="_blank"
        >
          <em class="fa-brands fa-yelp" />
        </a>
      </v-layout>

      <template v-if="canEdit">
        <a :href="reportEditUrl" class="btn btn-primary btn-lrg">
          <i class="fa-solid fa-arrow-left"></i>
          Edit Report
        </a>

        <a
          v-if="inspection.id"
          :href="inspectionEditUrl"
          class="btn btn-primary btn-lrg"
        >
          <div class="fa-solid fa-search" />
          Inspection Details
        </a>
        <template v-if="requiresCompletion && !complete">
          <v-tooltip top content-class="info-tooltip">
            <div slot="activator" class="btn btn-action btn-lrg disabled">
              <div class="fa-regular fa-paper-plane" />
              {{ report.attributes.published ? 'Resend' : 'Publish'
              }}{{ isIsn ? ' to ISN' : '' }}
            </div>
            Report must be completed before publishing is allowed. You can
            change this in Settings->Report Tools.
          </v-tooltip>
        </template>
        <template v-else>
          <template
            v-if="
              canPublish &&
              ((requiresCompletion && complete) || !requiresCompletion)
            "
          >
            <div
              v-if="isIsn"
              class="btn btn-action btn-lrg"
              @click="emitPublish('isn')"
            >
              <div class="fa-regular fa-paper-plane" />
              Publish to ISN
            </div>
            <div
              v-if="!isIsn || isnWithSpectoraPublishing"
              class="btn btn-action btn-lrg"
              data-cy="publish-report-btn"
              @click="emitPublish"
            >
              <div class="fa-regular fa-paper-plane" />
              {{ report.attributes.published ? 'Resend' : 'Publish' }}
            </div>
          </template>
          <template v-else>
            <div class="btn btn-action btn-lrg" @click="emitAction('submit')">
              <div class="fa-regular fa-file-lines" />
              Submit Report
            </div>
            <div v-if="submissionDate" class="small center light">
              submitted on {{ submissionDate }}
            </div>
          </template>
        </template>
      </template>
    </v-flex>
    <v-flex xs12>
      <div
        v-if="displaySummary"
        class="section-row"
        :class="{ active: 'summary' === activeSectionId }"
        @click="setActiveSectionId('summary')"
      >
        <div class="section-name">
          <div class="fa-solid fa-file-text section-icon" />
          Summary
        </div>
      </div>

      <div
        v-for="section in sections"
        :key="section.id"
        class="section-row"
        :class="{
          active: section.id === activeSectionId,
          empty:
            activeFilter !== 'full' &&
            defectCounts[activeFilter][section.id].length === 0,
        }"
        @click="setActiveSectionId(section.id)"
      >
        <div class="section-name">
          <section-icon
            :icon="section.attributes.icon"
            :active="section.id === activeSectionId"
          />
          {{ section.attributes.name }}
        </div>
        <div
          v-if="
            defectCounts && defectCounts[activeFilter][section.id].length > 0
          "
          class="section-badge"
        >
          {{ defectCounts[activeFilter][section.id].length }}
        </div>
      </div>
      <div
        v-if="canRecommendContractors"
        class="section-row"
        :class="{ active: recommendedContractorsSelected }"
        @click="handleRecommendedContractorsClick"
      >
        <div class="section-name">
          <em
            class="fa-solid fa-wrench section-icon"
            style="font-size: 1.5em; margin-right: 5px"
            aria-hidden="true"
          />
          <recommended-contractors-title />
        </div>
        <div v-if="recommendedContractorsCount > 0" class="section-badge">
          {{ recommendedContractorsCount }}
        </div>
      </div>
    </v-flex>

    <v-flex v-if="activeFilter !== 'full'" xs12 class="section center">
      <p class="light center small">
        <em class="fa-solid fa-warning" />
        You're currently not viewing the full report.
      </p>
      <div
        class="btn-blank btn-flat btn-small"
        @click="setActiveFilter('full')"
      >
        <div class="fa-regular fa-file-lines" />
        view full report
      </div>
    </v-flex>

    <template
      v-if="
        !sampleReport && (reportAttachments.length > 0 || reports.length > 0)
      "
    >
      <hr />

      <h5 class="light">Other Reports</h5>

      <v-flex
        v-for="siblingReport in reports"
        :key="siblingReport.id"
        xs12
        class="hoverable-row"
      >
        <a :href="siblingReport.attributes.id" target="_blank">
          <div class="icon-container">
            <div class="fa-regular fa-file-lines fa-2x" />
          </div>
          <div>{{ siblingReport.attributes.name }}</div>
        </a>
      </v-flex>

      <v-flex
        v-for="attachment in reportAttachments"
        :key="attachment.id"
        xs12
        class="hoverable-row"
      >
        <a :href="attachment.attributes.file_url" target="_blank">
          <div class="icon-container">
            <div class="fa-2x" :class="attachment.attributes.icon" />
          </div>
          <div>
            <div class="small">{{ attachment.attributes.name }}</div>
            <div class="small light">
              {{ attachment.attributes.file_file_name }}
            </div>
          </div>
        </a>
      </v-flex>
    </template>

    <template v-if="!sampleReport && plainAttachments.length > 0">
      <hr />

      <h5 class="light">Attachments</h5>
      <v-flex
        v-for="attachment in plainAttachments"
        :key="attachment.id"
        xs12
        class="hoverable-row"
      >
        <a :href="attachment.attributes.file_url" target="_blank">
          <div class="icon-container">
            <div class="fa fa-2x" :class="attachment.attributes.icon" />
          </div>
          <div>
            <div class="small">{{ attachment.attributes.name }}</div>
            <div class="small light">
              {{ attachment.attributes.file_file_name }}
            </div>
          </div>
        </a>
      </v-flex>
    </template>

    <template v-if="reportViews.length > 0">
      <hr />

      <h5 class="light">Repair Requests</h5>
      <v-flex
        v-for="reportView in reportViews"
        :key="reportView.id"
        xs12
        class="hoverable-row"
      >
        <a :href="'/report_views/' + reportView.id" target="_blank">
          <div class="icon-container">
            <div class="fa-solid fa-wrench fa-2x" />
          </div>
          <div>
            <div class="small">{{ reportView.attributes.name }}</div>
            <div class="small light">
              {{ reportView.attributes.created_at_formatted }}
            </div>
          </div>
        </a>
      </v-flex>
    </template>

    <template v-if="!sampleReport && originalReport">
      <hr />

      <h5 class="light">Original Report</h5>
      <v-flex xs12 class="hoverable-row">
        <a :href="'/v/reports/' + originalReport.id" target="_blank">
          <div class="icon-container">
            <div class="fa-solid fa-file fa-2x" />
          </div>
          <div>
            <div class="small">{{ originalReport.attributes.name }}</div>
            <div class="small light">
              {{
                originalReport.attributes.published_on_formatted ||
                'Unpublished'
              }}
            </div>
          </div>
        </a>
      </v-flex>
    </template>

    <v-flex xs12>
      <v-layout center>
        <a
          class="spectora-link"
          :href="spectoraUrl"
          target="_blank"
          data-cy="powered-by-spectora"
        >
          <img
            src="https://di12hdd3bsox6.cloudfront.net/powered_by_spectora_2.png"
            alt="Powered by Spectora"
          />
        </a>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/utils/config'
import RecommendedContractorsTitle from './RecommendedContractorsTitle.vue'
import SectionIcon from './SectionIcon.vue'

const FIRESTORE_DOMAIN = config('FIRESTORE_DOMAIN')
const FIRESTORE_OPTIMIZED_DOMAIN = config('FIRESTORE_OPTIMIZED_DOMAIN')
const RAILS_DOMAIN = config('RAILS_DOMAIN')

export default {
  components: {
    RecommendedContractorsTitle,
    SectionIcon,
  },
  props: {
    firestore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recommendedContractorsSelected: false,
      attachments: [],
      reports: [],
    }
  },

  computed: {
    ...mapState('reports', [
      'activeFilter',
      'activeSectionId',
      'company',
      'defectCounts',
      'inspection',
      'loggedIn',
      'originalReport',
      'report',
      'reportRails',
      'reportContractors',
      'reportViews',
      'recommendedContractors',
      'sections',
      'userProfile',
    ]),

    canRecommendContractors() {
      return (
        this.reportContractors.length > 0 &&
        this.company.attributes.settings.enable_recommended_contractors &&
        this.report.settings.display_recommendations &&
        !this.reportRails.attributes.not_show_recommended_contractors_on_reports
      )
    },
    canEdit() {
      return (
        this.loggedIn &&
        !this.sampleReport &&
        ((['inspector', 'manager'].includes(this.userProfile.type) &&
          this.userProfile.attributes.company_id ===
            this.company.attributes.id) ||
          this.userProfile.type === 'admin')
      )
    },

    canPublish() {
      return (
        this.userProfile.attributes.settings.can_publish &&
        !this.company.attributes.settings.lockout
      )
    },

    companyUrl() {
      return `https://${RAILS_DOMAIN}/home-inspectors/${this.company.attributes.slug}`
    },

    inspectionEditUrl() {
      return `https://${RAILS_DOMAIN}/inspection/${this.inspection.attributes.slug}/edit`
    },

    spectoraUrl() {
      return `https://${RAILS_DOMAIN}/`
    },

    complete() {
      return (
        this.report.attributes.complete ||
        ((this.report.attributes.special_type || '').length > 0 &&
          this.report.attributes.special_type !== 'TREC')
      )
    },

    displaySummary() {
      return (
        this.report.attributes.settings.display_summary &&
        this.summaryCategories.length > 0
      )
    },

    isIsn() {
      return this.inspection.attributes.isn_id
    },

    isnWithSpectoraPublishing() {
      return (
        this.isIsn &&
        this.company.attributes.settings.isn_display_spectora_publishing
      )
    },

    plainAttachments() {
      return this.attachments.filter((a) => {
        return !a.attributes.report
      })
    },

    reportAttachments() {
      return this.attachments.filter((a) => {
        return a.attributes.report
      })
    },

    requiresCompletion() {
      return this.company.attributes.settings.require_completion_to_publish
    },
    reportEditUrl() {
      if (this.firestore) {
        if (this.company.attributes.optimized_web_editor) {
          return `https://${FIRESTORE_OPTIMIZED_DOMAIN}/#/${this.report.id}`
        }
        return `https://${FIRESTORE_DOMAIN}/#/${this.report.id}`
      }
      return `https://${RAILS_DOMAIN}/report/${this.report.id}/editor`
    },
    recommendedContractorsCount() {
      if (!this.recommendedContractors) {
        return 0
      }

      let count = 0
      this.recommendedContractors.forEach((result) => {
        count += (result.contractors || []).length
      })

      return count
    },
    sampleReport() {
      return this.$route.name === 'sample-report'
    },

    submissionDate() {
      return this.report.attributes.submitted_on_formatted
    },

    summaryCategories() {
      const cats = []
      if (
        this.report.attributes.settings.category_high_in_summary &&
        this.report.attributes.categories > 1
      )
        cats.push(1)
      if (this.report.attributes.settings.category_low_in_summary) cats.push(-1)
      if (this.report.attributes.settings.category_med_in_summary) cats.push(0)

      return cats
    },
  },

  mounted() {
    if (!this.sampleReport) {
      this.fetchAttachments()
      this.fetchReports()
    }
    const activeId = this.displaySummary ? 'summary' : this.sections[0].id
    this.$store.commit('reports/setValue', {
      key: 'activeSectionId',
      value: activeId,
    })
  },

  methods: {
    emitAction(action) {
      this.$emit(action)
    },
    emitPublish(publishType) {
      if (publishType === 'isn') {
        this.$emit('publish', publishType)
      } else {
        const type = this.inspection.attributes.settings.enable_auto_tracks
          ? 'actions'
          : 'automations'
        this.$emit('publish', type)
      }
    },
    fetchAttachments() {
      this.attachments = this.inspection.inspection_attachments || []
    },
    fetchReports() {
      if (this.report.firestore) {
        // TODO make this better
        this.reports = []
        return
      }
      this.reports = this.inspection.reports.filter((r) => {
        return r.id !== this.report.id && r.attributes.published
      })
    },
    handleRecommendedContractorsClick() {
      this.recommendedContractorsSelected = true
      this.$store.commit('reports/setValue', {
        key: 'activeSectionId',
        value: null,
      })

      const el = document.getElementById('recommended-contractor-section')

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    setActiveFilter(filter) {
      this.$store.commit('reports/setValue', {
        key: 'activeFilter',
        value: filter,
      })
    },
    setActiveSectionId(id) {
      this.recommendedContractorsSelected = false

      this.$store.commit('reports/setValue', {
        key: 'activeSectionId',
        value: id,
      })
      if (id !== 'summary') id = `section-${id}`
      this.$emit('change')

      document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar-top {
  padding-left: 2em;
  padding-right: 2em;
}

.company-link {
  display: block;
  text-align: center;
}

.company-logo {
  width: 12em;
}

.social-row {
  justify-content: space-evenly;
  font-size: 1.5em;
  margin: 0.5em 0;
}

.spectora-link {
  margin: 2em;
  text-align: center;
  filter: grayscale(100%);
  img {
    width: 75%;
  }
}

.btn {
  width: 100%;
  height: auto;
  font-size: 16px;
  text-align: center;
  padding: 0.25em 0;
}

.section-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em 1em;
  background-color: #fff;
  transition: all 0.1s linear;

  .section-name {
    display: flex;
    align-items: center;
  }

  .section-icon {
    height: 1.5em;
    width: 1.5em;
    min-height: 1.5em;
    min-width: 1.5em;
    opacity: 0.4;
    display: inline-block;
    margin-right: 1em;

    &.fa-file-text {
      font-size: 1.4em;
      margin-right: 0.25em;
      padding-left: 0.1em;
      display: inline;
      vertical-align: sub;
    }
  }

  .section-badge {
    background-color: #5c9ccf;
    color: #fff;
    border-radius: 50%;
    font-size: 0.75em;
    text-align: center;
    height: 2em;
    width: 2em;
    min-width: 2em;
    line-height: 2em;
  }

  &.active {
    color: #5c9ccf;
  }
  &.empty {
    opacity: 0.2;
  }

  &:hover {
    cursor: pointer;
    background-color: #fafafa;
  }

  &.active,
  &:hover {
    border-left: 3px solid #5c9ccf;
    .section-icon {
      opacity: 1;
    }
  }
}

hr {
  border-bottom: none;
  width: 90%;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
}
h5 {
  width: 100%;
  text-align: center;
}
a {
  width: inherit;
}

.hoverable-row {
  cursor: pointer;
  transition: all 0.1s linear;
  background-color: #fff;

  a {
    display: flex;
    padding: 0.5em 0;
    width: 90%;
    margin: 0 auto;
    .icon-container {
      margin-right: 1em;
    }
  }

  &:hover {
    background-color: #fafafa;
  }
}
</style>
