const stripHTML = (html, sliceAt = null) => {
  const sanitizer = document.createElement('div')
  sanitizer.innerHTML = html

  let strippedContent = sanitizer.textContent.split(' ')

  if (sliceAt) {
    strippedContent = strippedContent.slice(0, sliceAt)

    if (strippedContent.length === sliceAt) strippedContent.push('...')
  }

  return strippedContent.join(' ')
}

export default stripHTML
