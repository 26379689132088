<template>
  <v-layout class="overlay-photos">
    <v-flex xs12 center @click.self="close">
      <v-flex md8 xs10 offset-xs1 offset-md2 center class="inner-container">
        <v-layout wrap class="images-container">
          <template v-if="reinspectionWithPhotos">
            <v-flex
              v-for="(beforeAfter, idx) in photosMap.beforeAfter"
              :key="idx"
              xs12
              class="photo-row"
            >
              <v-layout wrap>
                <v-flex xs12 md4 class="image-container">
                  <div
                    v-if="!beforeAfter.before.attributes.video"
                    class="image grayscale"
                    :style="{
                      'background-image': `url(${beforeAfter.before.attributes.image})`,
                    }"
                    @click="setOverlayPhoto(beforeAfter.before)"
                  />
                  <video
                    v-else
                    preload="auto"
                    controls="true"
                    :poster="beforeAfter.before.attributes.video_thumb"
                    class="image"
                  >
                    <source
                      :src="beforeAfter.before.attributes.video_url"
                      type="video/mp4"
                    />
                  </video>
                  <div class="caption-text">Original Photo</div>
                </v-flex>
                <v-flex xs12 md8>
                  <v-layout wrap>
                    <v-flex
                      v-for="photo in beforeAfter.after"
                      :key="photo.id"
                      xs6
                      md4
                      class="image-container"
                    >
                      <div
                        v-if="!photo.attributes.video"
                        class="image"
                        :style="{
                          'background-image': `url(${photo.attributes.image})`,
                        }"
                        @click="setOverlayPhoto(photo)"
                      />
                      <video
                        v-else
                        preload="auto"
                        controls="true"
                        :poster="photo.attributes.video_thumb"
                        class="image"
                      >
                        <source
                          :src="photo.attributes.video_url"
                          type="video/mp4"
                        />
                      </video>
                      <div class="caption-text">
                        {{ photo.attributes.caption || '&nbsp;' }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </template>
          <v-flex
            v-for="photo in gridPhotos"
            :key="photo.id"
            xs6
            md4
            class="image-container"
          >
            <div
              v-if="!photo.attributes.video"
              class="image"
              :style="{ 'background-image': `url(${photo.attributes.image})` }"
              @click="setOverlayPhoto(photo)"
            />
            <video
              v-else
              preload="auto"
              controls="true"
              :poster="photo.attributes.video_thumb"
              class="image"
            >
              <source :src="photo.attributes.video_url" type="video/mp4" />
            </video>
            <div class="caption-text">
              {{ photo.attributes.caption || '&nbsp;' }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import dig from '../../utils/Dig'

export default {
  computed: {
    ...mapState('reports', ['overlayPhotos', 'reportView']),
    gridPhotos() {
      return this.reinspectionWithPhotos
        ? this.photosMap.newPhotos
        : this.overlayPhotos
    },
    originalPhotos() {
      const sourceIds = this.overlayPhotos.map((p) => p.attributes.source_id)
      return dig(this.reportView, 'attributes.observation_photos.data').filter(
        (p) => sourceIds.includes(p.id)
      )
    },
    photosMap() {
      const pm = { beforeAfter: [] }
      pm.newPhotos = this.overlayPhotos.filter((p) => !p.attributes.source_id)
      this.originalPhotos.forEach((op) => {
        pm.beforeAfter.push({
          before: op,
          after: this.overlayPhotos.filter(
            (p) => op.id === p.attributes.source_id
          ),
        })
      })

      return pm
    },
    reinspectionWithPhotos() {
      return (
        dig(this.reportView, 'attributes.type') === 'ReinspectionView' &&
        this.overlayPhotos.find((p) => p.attributes.source_id) &&
        this.originalPhotos.length > 0
      )
    },
  },
  methods: {
    close() {
      this.$store.commit('reports/setValue', {
        key: 'overlayPhotos',
        value: [],
      })
    },
    setOverlayPhoto(photo) {
      this.$store.commit('reports/setValue', {
        key: 'overlayPhoto',
        value: photo,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.overlay-photos {
  z-index: 2;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  > .flex {
    display: flex;
    align-items: center;
  }
}

.inner-container {
  cursor: default;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background-color: #555;
}

.images-container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  align-items: flex-start;
  align-content: flex-start;

  .image-container {
    width: 100%;
    height: auto;
    padding: 2em;
  }

  .image {
    height: 15em;
    width: 100%;
    background-size: contain;
    background-position: center;
    transition: opacity 0.1s linear;

    &:hover {
      cursor: zoom-in;
      opacity: 0.8;
    }
  }
}

.caption-text {
  color: #fff;
  padding: 0.5em 1em;
}

.grayscale {
  filter: grayscale(100);
}

.photo-row {
  border-bottom: 1px solid #bbb;
}

@media (max-width: 960px) {
  .images-container .image-container {
    padding: 0.25em;
  }
  .images-container .image {
    min-height: 10em;
  }
  .photo-row {
    margin-top: 1em;
  }
}
</style>
