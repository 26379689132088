<template>
  <div
    class="photo-container"
    :class="{ video: !isPhoto }"
    @click="openGallery"
  >
    <div class="photo-wrapper">
      <template v-if="isPhoto">
        <img :src="photo.attributes.image" />
      </template>
      <template v-else-if="photo.attributes.video_processing">
        <div class="processing">This video is currently processing</div>
      </template>
      <video v-else preload="auto" :poster="photo.attributes.video_thumb">
        <source :src="photo.attributes.video_url" type="video/mp4" />
      </video>

      <v-tooltip
        v-if="hasCaption"
        top
        content-class="info-tooltip"
        class="caption"
      >
        <div slot="activator" class="fa-solid fa-comment icon" />
        {{ photo.attributes.caption }}
      </v-tooltip>

      <v-tooltip v-if="isPano" top content-class="info-tooltip" class="pano">
        <div
          slot="activator"
          class="fa-solid fa-cube icon"
          style="cursor: pointer"
        />
        360 Photo
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['photo', 'photoIndex'],
  computed: {
    hasCaption() {
      return (this.photo.attributes.caption || '').length > 0
    },
    isPano() {
      return this.photo.attributes.is_pano
    },
    isPhoto() {
      return !this.photo.attributes.video
    },
  },
  methods: {
    openGallery() {
      this.$emit('openGallery', { index: this.photoIndex, photo: this.photo })
    },
  },
}
</script>

<style lang="scss" scoped>
.photo-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  background-color: #fff;

  &.video {
    transition: opacity 0.1s linear;
    cursor: pointer;
    &:after {
      font-family: 'Font Awesome 6 Free';
      content: '\f144';
      color: white;
      font-size: 4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      text-align: center;
      transform: translate(-50%, -50%);
      text-shadow: 1px 1px 3px #000;
    }
  }
  &.video:hover {
    opacity: 0.8;
  }

  .photo-wrapper {
    max-height: 100%;
    position: relative;
  }
}

.tooltip-container {
  position: relative;
}

.processing {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);
}

img {
  transition: opacity 0.1s linear;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

video {
  width: 100%;
  max-height: 11em;
}

.caption,
.pano {
  position: absolute;
  .icon {
    font-size: 1.25em;
    color: #fff;
    text-shadow: 1px 1px 1px #333333;
  }
}

.caption {
  position: absolute;
  left: 0.75em;
  bottom: 0.75em;
}

.pano {
  right: 0.75em;
  bottom: 0.75em;
}
</style>
