import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

import config from '@/utils/config.js'
import createChangeset from '@/utils/createChangeset'

const db = firebase
  .initializeApp({
    apiKey: config('FIREBASE_API_KEY'),
    projectId: config('FIREBASE_PROJECT_ID'),
  })
  .firestore()

export const serverTimestamp = () =>
  firebase.firestore.FieldValue.serverTimestamp()

export const signInWithCustomToken = (token) =>
  firebase.auth().signInWithCustomToken(token)

export const COMMENTS = 'report_comments'

export const commentsCollection = db.collection(COMMENTS)

// { collection = [], meta = {}, record: {}, values: {} }
export const updateRecord = (options = {}) => {
  // eslint-disable-next-line no-console
  console.log(options)
  return createChangeset(options)
}

export default db
