<template>
  <modal
    id="share-rrb"
    name="share-rrb"
    :scrollable="true"
    width="60%"
    height="auto"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>Repair Request</h2>
      </div>
      <div class="modal-body">
        <v-layout wrap>
          <v-flex xs12>
            <p>Your Repair Request has been created!</p>
            <p>
              <strong>You can view it or copy the link here:</strong>
            </p>
            <p>
              <a target="_blank" :href="reportView.attributes.url">{{
                reportView.attributes.url
              }}</a>
            </p>
            <p>
              <strong>Or send this link directly now:</strong>
            </p>
          </v-flex>
          <v-flex xs12>
            <v-expansion-panel class="sections">
              <v-expansion-panel-content>
                <template slot="header">
                  <div class="section-label light">
                    <em class="fa-regular fa-envelope" />
                    &nbsp; Email
                  </div>
                </template>

                <v-layout wrap class="section-content">
                  <v-flex xs12 md6 vpx-1>
                    <v-text-field
                      v-model="email.recipient"
                      label="Recipient"
                      persistent-hint
                      :hint="
                        sampleReport
                          ? ''
                          : 'Defaulting to Listing Agent\'s email'
                      "
                    />
                  </v-flex>
                  <v-flex xs12 md6 vpx-1>
                    <v-text-field
                      v-model="email.sender"
                      label="Sender (Your Email)"
                    />
                  </v-flex>
                  <v-flex xs12 vpx-1>
                    <v-text-field v-model="email.subject" label="Subject" />
                  </v-flex>

                  <v-flex xs12 vpx-1>
                    <froala
                      v-model="email.body"
                      tag="textarea"
                      :config="froalaConfig"
                    />
                  </v-flex>
                  <v-flex xs12>&nbsp;</v-flex>
                  <v-flex xs12 class="button-row">
                    <div class="save-btn btn btn-primary" @click="sendEmail">
                      <div
                        v-if="!savingEmail"
                        class="fa-regular fa-paper-plane"
                      />
                      <div v-else class="fa-solid fa-spinner fa-spin" />
                      Send
                    </div>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <template slot="header">
                  <div class="section-label light">
                    <em class="fa-regular comment-dots" />
                    &nbsp; Text Message
                  </div>
                </template>

                <v-layout wrap class="section-content">
                  <v-flex xs12 md6 vpx-1>
                    <v-text-field
                      v-model="text.recipient"
                      label="Recipient Phone Number"
                      persistent-hint
                      :hint="
                        sampleReport
                          ? ''
                          : 'Defaulting to Listing Agent\'s phone'
                      "
                    />
                  </v-flex>
                  <v-flex xs12 vpx-1>
                    <v-textarea
                      v-model="text.body"
                      hint="This message will originate from a system number that cannot be replied to"
                      persistent-hint
                    />
                  </v-flex>
                  <v-flex xs12 class="button-row">
                    <div class="save-btn btn btn-primary" @click="sendText">
                      <div
                        v-if="!savingText"
                        class="fa-regular fa-paper-plane"
                      />
                      <div v-else class="fa-solid fa-spinner fa-spin" />
                      Send
                    </div>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-flex>
          <v-flex xs12 class="center">
            <div class="btn btn-xlarge" @click="close">Done</div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import railsService from '../../../utils/AxiosService'
import { froalaOptionsSimple as froalaConfig } from '@/utils/froala'
import dig from '../../../utils/Dig'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import config from '@/utils/config.js'
import { captureException } from '@/utils'

const RECAPTCHA_SITE_KEY = config('RECAPTCHA_SITE_KEY')

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['reportView'],
  data() {
    return {
      email: {},
      froalaConfig: Object.assign(froalaConfig, {
        height: 150,
      }),
      savingEmail: false,
      savingText: false,
      text: {},
    }
  },
  computed: {
    ...mapState('reports', ['inspection', 'userProfile']),
    notificationParams() {
      return {
        user_id: this.userProfile.user_id,
        name: 'report_view_share',
        object_type: 'ReportView',
        object_id: this.reportView.id,
        send_on: new Date(),
        inspection_id: this.inspection.id,
      }
    },
    sampleReport() {
      return this.$route.name === 'sample-report'
    },
    sellingAgent() {
      return dig(
        this.inspection,
        'attributes.selling_agent.data.attributes',
        {}
      )
    },
  },
  mounted() {
    Vue.use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY })

    this.email.sender = JSON.parse(
      JSON.stringify(dig(this.userProfile, 'attributes', {}).email || '')
    )
    if (!this.sampleReport) this.email.recipient = this.sellingAgent.email
    this.email.body = `Here is the Repair Request List:<br/><a href='${this.reportView.attributes.url}'>${this.reportView.attributes.url}</a>`

    if (!this.sampleReport) this.text.recipient = this.sellingAgent.phone
    this.text.body = `Here is the Repair Request List:\n\n${this.reportView.attributes.url}`

    this.email = JSON.parse(JSON.stringify(this.email))
    this.text = JSON.parse(JSON.stringify(this.text))

    this.$modal.show('share-rrb')
  },
  methods: {
    close() {
      this.$modal.hide('share-rrb')
      this.$emit('closed')
    },
    recaptcha() {
      return this.$recaptchaLoaded().then(() => {
        return this.$recaptcha('sendMessage').then((response) => {
          return this.verifyTextOrEmail(response)
        })
      })
    },
    async sendEmail() {
      if (this.savingEmail) return
      if (!this.validNotification('email')) return
      this.savingEmail = true

      await this.recaptcha()
      const params = JSON.parse(JSON.stringify(this.notificationParams))
      params.subject = this.email.subject
      params.text = this.email.body
      params.recipient = this.email.recipient
      params.sender = this.email.sender

      railsService
        .post('/api/v1/public/emails', {
          attributes: params,
          'g-recaptcha-response-data': this.email.verified,
        })
        .then(() => {
          this.savingEmail = false
          this.$store.dispatch('notifier/show', {
            type: 'Saved',
            msg: 'Email Sent!',
          })
        })
        .catch((error) => {
          captureException(error)
          this.savingEmail = false
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: 'There was an error sending this email.',
          })
        })
    },
    sendText() {
      if (this.savingText) return
      if (!this.validNotification('text')) return
      this.savingText = true

      this.recaptcha().then(() => {
        const params = JSON.parse(JSON.stringify(this.notificationParams))
        params.recipient_phone = this.text.recipient
        params.text = this.text.body

        railsService
          .post('/api/v1/public/text_messages', {
            attributes: params,
            'g-recaptcha-response-data': this.text.verified,
          })
          .then(() => {
            this.savingText = false
            this.$store.dispatch('notifier/show', {
              type: 'Saved',
              msg: 'Text Message Sent!',
            })
          })
          .catch((error) => {
            captureException(error)
            this.savingText = false
            this.$store.dispatch('notifier/show', {
              type: 'Error',
              msg: 'There was an error sending this text message.',
            })
          })
      })
    },
    validEmail(email) {
      return email.match(/^[^@\s]+@[^@\s]+$/)
    },
    validNotification(type) {
      if (type === 'email') {
        let msg = []
        if (!this.email.recipient || this.email.recipient.length === 0)
          msg.push('You forgot to add a recipient for this email')
        if (
          this.email.recipient &&
          this.email.recipient.length > 0 &&
          !this.validEmail(this.email.recipient)
        )
          msg.push('The recipient email you provided is invalid!')
        if (!this.email.sender || this.email.sender.length === 0)
          msg.push('You forgot to add a sender for this email')
        if (
          this.email.sender &&
          this.email.sender.length > 0 &&
          !this.validEmail(this.email.sender)
        )
          msg.push('The sender email you provided is invalid!')
        if ((this.email.subject || '').length === 0)
          msg.push('Remember to add a subject for this email')

        if (msg.length > 0) {
          if (msg.length === 1) {
            msg = msg[0]
          } else {
            msg = msg.join('<br/>')
            msg = `We found some issues with your email:<br/>${msg}`
          }

          this.$store.dispatch('notifier/show', { type: 'Error', msg: msg })
          return false
        }
      } else {
        if (!this.text.recipient || this.text.recipient.length < 8) {
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: "You didn't add a phone number to send this message to.",
          })
          return false
        }
      }

      return true
    },
    verifyTextOrEmail(response) {
      if (this.email) {
        this.email.verified = response
        this.email = JSON.parse(JSON.stringify(this.email))
      }

      if (this.text) {
        this.text.verified = response
        this.text = JSON.parse(JSON.stringify(this.text))
      }

      return Promise.resolve()
    },
  },
}
</script>

<style scoped>
.sections {
  margin-bottom: 2em;
}

.section-label {
  display: flex;
  align-items: center;
  font-size: 1.25em;
}

.section-content {
  padding: 1em;
  background-color: #fafafa;
}

.button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<style>
#share-rrb .v-expansion-panel__header {
  border-bottom: 1px solid #d9d9d9;
}
</style>
