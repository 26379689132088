<template>
  <modal
    id="quick-edit-obs"
    name="quick-edit-obs"
    :scrollable="false"
    width="60%"
    height="auto"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>Edit Observation</h2>

        <div class="header-actions">
          <a :href="editUrl" class="btn btn-flat btn-blank btn-small">
            <div class="fa-solid fa-pencil" />
            Report Editor
          </a>
        </div>
      </div>
      <div class="modal-body">
        <v-layout wrap>
          <v-flex xs9 md6 px-2>
            <v-text-field
              v-model="localObservation.attributes.name"
              label="Name"
              data-cy="edit-observation-name"
            />
          </v-flex>
          <v-flex xs3 md6 class="solo-btn">
            <div
              class="btn btn-flat"
              :class="{ flagged: flagged, 'btn-blank': !flagged }"
              @click="flagged = !flagged"
            >
              <div class="fa-solid fa-flag" />
            </div>
            <div
              class="btn btn-flat"
              :class="{ 'flagged warning': remove, 'btn-blank': !remove }"
              @click="remove = !remove"
            >
              <div class="fa-solid fa-close" />
            </div>
          </v-flex>

          <v-flex xs12 px-2>
            <v-layout v-if="answerType === 'checkbox'" wrap class="choices">
              <v-flex v-for="(choice, index) in choices" :key="index" xs6 md4>
                <v-checkbox
                  v-model="choice.value"
                  hide-details
                  color="primary"
                  class="my-2"
                  :label="choice.name"
                />
              </v-flex>
              <template v-if="!localObservation.attributes.locked">
                <v-flex v-if="addOption" xs6 md4>
                  <v-text-field
                    v-model="newChoice"
                    hide-details
                    :append-icon="
                      newChoice.length > 0 ? 'fa-regular fa-floppy-disk' : ''
                    "
                    class="new-choice-input"
                    placeholder="+ add choice"
                    @keyup.enter="addChoice"
                  />
                </v-flex>
                <v-flex v-else xs6 md4>
                  <div class="add-option" @click="addOption = true">
                    <div class="fa-solid fa-plus" />
                    <div class="small">OTHER</div>
                  </div>
                </v-flex>
              </template>
            </v-layout>
            <v-layout v-else-if="answerType === 'range'">
              <v-flex xs6 md3 px-2>
                <v-text-field
                  v-model="localObservation.attributes.value"
                  hide-details
                  label="From"
                  class="my-2"
                />
              </v-flex>
              <v-flex xs6 md3 px-2>
                <v-text-field
                  v-model="localObservation.attributes.value2"
                  hide-details
                  label="To"
                  class="my-2"
                />
              </v-flex>
            </v-layout>
            <div v-else-if="answerType === 'text'">
              <v-flex xs12 md6>
                <v-text-field
                  v-model="localObservation.attributes.value"
                  hide-details
                  :label="localObservation.attributes.name"
                />
              </v-flex>
            </div>
            <div v-if="answerType === 'date'" class="date-picker-container">
              <v-flex xs12 md6>
                <v-text-field
                  v-model="localObservation.attributes.value"
                  hide-details
                  @focus="showDatePicker = true"
                />
                <v-date-picker
                  v-if="showDatePicker"
                  v-model="localObservation.attributes.value"
                  class="date-picker-popup"
                  @input="showDatePicker = false"
                >
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="showDatePicker = false"
                    >Cancel</v-btn
                  >
                </v-date-picker>
              </v-flex>
            </div>
            <v-layout v-if="answerType === 'number'" wrap>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="localObservation.attributes.value"
                  hide-details
                  placeholder="#"
                  class="my-2"
                  :label="localObservation.attributes.name"
                />
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex v-if="hasUnitTypes" xs12>
            <v-layout wrap>
              <template v-if="hasUnitTypeChoices">
                <v-flex
                  v-for="(choice, index) in unitTypeChoices"
                  :key="index"
                  xs6
                  md4
                >
                  <v-radio-group
                    v-model="localObservation.attributes.unit_type"
                    hide-details
                    class="pt-0"
                  >
                    <v-radio color="success" :label="choice" :value="choice" />
                  </v-radio-group>
                </v-flex>
              </template>
              <v-flex v-if="addOption" xs6 md4>
                <v-text-field
                  v-model="newUnitChoice"
                  hide-details
                  class="new-choice-input"
                  placeholder="+ add unit"
                  :append-icon="
                    newUnitChoice.length > 0 ? 'fa-regular fa-floppy-disk' : ''
                  "
                  @keyup.enter="addUnitChoice"
                />
              </v-flex>
              <v-flex v-else xs6 md4>
                <div class="add-option" @click="addOption = true">
                  <div class="fa-solid fa-plus" />
                  <div class="small">OTHER</div>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex v-if="displayCategorySelection" xs12 md6 px-2>
            <template v-if="reinspection">
              <template
                v-if="report.attributes.settings.reinspect_categories === 2"
              >
                <v-checkbox
                  v-model="localObservation.attributes.category"
                  hide-details
                  on-icon="fa-thumbs-o-up pa-1"
                  color="info darken-2"
                  class="mt-2"
                  :label="report.attributes.settings.reinspect_category_good"
                />
              </template>
              <template
                v-if="report.attributes.settings.reinspect_categories === 3"
              >
                <label>Category</label>
                <div class="category-selector">
                  <div
                    :class="`category ${catClass(2)}`"
                    @click="localObservation.attributes.category = 2"
                  >
                    <v-tooltip bottom>
                      <div slot="activator" class="tooltip-trigger" />
                      <span>{{
                        report.attributes.settings.reinspect_category_good
                      }}</span>
                    </v-tooltip>
                    <div class="fa-regular fa-thumbs-up" />
                  </div>
                  <div
                    :class="`category ${catClass(3)}`"
                    @click="localObservation.attributes.category = 3"
                  >
                    <v-tooltip bottom>
                      <div slot="activator" class="tooltip-trigger" />
                      <span>{{
                        report.attributes.settings.reinspect_category_partial
                      }}</span>
                    </v-tooltip>
                    <div class="fa-regular fa-hand" />
                  </div>
                  <div
                    :class="`category ${catClass(4)}`"
                    @click="localObservation.attributes.category = 4"
                  >
                    <v-tooltip bottom>
                      <div slot="activator" class="tooltip-trigger" />
                      <span>{{
                        report.attributes.settings.reinspect_category_bad
                      }}</span>
                    </v-tooltip>
                    <div class="fa-regular fa-thumbs-down" />
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <template v-if="report.attributes.settings.categories === 2">
                <v-checkbox
                  v-model="localObservation.attributes.category"
                  hide-details
                  data-cy="edit-observation-checkbox-category"
                  on-icon="fa-warning pa-1"
                  color="red darken-2"
                  class="mt-2"
                  :label="report.attributes.settings.category_high"
                />
              </template>
              <template v-if="report.attributes.settings.categories === 3">
                <label>Category</label>
                <div class="category-selector">
                  <div
                    :class="`category ${catClass(-1)}`"
                    @click="localObservation.attributes.category = -1"
                  >
                    <v-tooltip bottom>
                      <div slot="activator" class="tooltip-trigger" />
                      <span>{{ report.attributes.settings.category_low }}</span>
                    </v-tooltip>
                    <div class="fa-solid fa-wrench" />
                  </div>
                  <div
                    :class="`category ${catClass(0)}`"
                    @click="localObservation.attributes.category = 0"
                  >
                    <v-tooltip bottom>
                      <div slot="activator" class="tooltip-trigger" />
                      <span>{{ report.attributes.settings.category_med }}</span>
                    </v-tooltip>
                    <div class="fa-solid fa-circle-minus" />
                  </div>
                  <div
                    :class="`category ${catClass(1)}`"
                    @click="localObservation.attributes.category = 1"
                  >
                    <v-tooltip bottom>
                      <div slot="activator" class="tooltip-trigger" />
                      <span>{{
                        report.attributes.settings.category_high
                      }}</span>
                    </v-tooltip>
                    <div class="fa-solid fa-warning" />
                  </div>
                </div>
              </template>
            </template>
          </v-flex>
          <v-flex xs12 md6 px-2>
            <v-text-field
              v-model="localObservation.attributes.location"
              label="Location"
              append-icon="edit"
              data-cy="edit-observation-location"
              @click:append="() => openEditLocation()"
            />
          </v-flex>
          <v-flex xs12>
            <froala
              v-model="localObservation.attributes.text"
              tag="textarea"
              :config="froalaConfig"
            />
          </v-flex>
          <v-flex
            v-if="
              report.attributes.settings.display_recommendations &&
              localObservation.attributes.comment_type === 'defect'
            "
            xs12
          >
            <v-select
              v-model="localObservation.attributes.recommendation_id"
              label="Recommendation"
              :items="recommendationItems"
            />
          </v-flex>

          <v-flex
            v-if="
              company.attributes.settings.display_cost_estimates &&
              localObservation.attributes.comment_type === 'defect'
            "
            xs12
          >
            <v-layout>
              <v-flex xs4>
                <v-checkbox
                  v-model="localObservation.attributes.display_estimate"
                  hide-details
                  color="primary"
                  class="my-2 mb-2"
                  label="Provide Cost Estimate"
                />
              </v-flex>
              <template v-if="localObservation.attributes.display_estimate">
                <v-flex xs4 px-2>
                  <v-text-field
                    v-model="localObservation.attributes.estimate_min"
                    label="Min"
                    type="number"
                  />
                </v-flex>
                <v-flex xs4 px-2>
                  <v-text-field
                    v-model="localObservation.attributes.estimate_max"
                    label="Max"
                    type="number"
                  />
                </v-flex>
              </template>
            </v-layout>
          </v-flex>

          <v-flex
            v-if="
              canEditTemplate && localObservation.attributes.template_comment_id
            "
            xs12
            class="save-to-template-row"
          >
            <v-checkbox
              v-model="saveToTemplate"
              label="Save as default name/text for this comment"
            />
          </v-flex>
        </v-layout>
        <v-layout class="section">
          <v-flex xs12 class="center">
            <div class="btn unbutton btn-xlarge" @click="close">Cancel</div>
            <div
              class="btn btn-large"
              data-cy="edit-observation-save-changes"
              @click="save"
            >
              <i v-if="!saving" class="fa-regular fa-square-check" />
              <i v-else class="fa-solid fa-spinner fa-spin" />
              Save Changes
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { froalaOptionsSimple as froalaConfig } from '@/utils/froala'
import dig from '../../../utils/Dig'
import { omit, pick } from 'lodash'
import { commentsCollection as collection, updateRecord } from '@/db'
import config from '@/utils/config'

const FIRESTORE_DOMAIN = config('FIRESTORE_DOMAIN')
const FIRESTORE_OPTIMIZED_DOMAIN = config('FIRESTORE_OPTIMIZED_DOMAIN')

export default {
  data() {
    return {
      addOption: false,
      newChoice: '',
      newUnitChoice: '',
      localObservation: { attributes: {} },
      originalObservation: { attributes: {} },
      remove: false,
      saveToTemplate: false,
      saving: false,
      showDatePicker: false,
      froalaConfig: Object.assign(froalaConfig, { height: 317 }),
    }
  },
  computed: {
    ...mapState('reports', [
      'company',
      'editObservation',
      'recommendations',
      'report',
      'userProfile',
      'reinspection',
    ]),
    answerType() {
      return this.localObservation.attributes.answer_type
    },
    canEditTemplate() {
      return dig(
        this.userProfile,
        'attributes.settings.can_edit_template',
        false
      )
    },
    checkboxValue() {
      if (this.answerType !== 'checkbox') return null
      return this.localObservation.attributes.value
        .split(',')
        .map((c) => c.trim())
    },
    choices() {
      return (this.localObservation.attributes.choices || '')
        .split(',')
        .map((c) => c.trim())
        .map((c) => ({ name: c, value: this.checkboxValue.includes(c) }))
    },
    displayCategorySelection() {
      if (dig(this.editObservation, 'attributes.comment_type') !== 'defect')
        return
      const cats = this.reinspection
        ? this.report.attributes.settings.reinspect_categories
        : this.report.attributes.settings.categories
      return cats > 1
    },
    editUrl() {
      if (this.company.attributes.optimized_web_editor) {
        return `https://${FIRESTORE_OPTIMIZED_DOMAIN}/#/${this.reportId}/s/${this.sectionId}/i/${this.itemId}/${this.editObservation.attributes.comment_type}/${this.commentId}`
      }
      return `https://${FIRESTORE_DOMAIN}/#/${this.reportId}/s/${this.sectionId}/i/${this.itemId}/c/${this.commentId}`
    },
    flagged: {
      get() {
        return dig(this.localObservation, 'attributes.flagged')
      },
      set(value) {
        this.$set(this.localObservation.attributes, 'flagged', value)
      },
    },
    reportId() {
      return this.report.id
    },
    sectionId() {
      return dig(this.editObservation, 'attributes.report_section_id')
    },
    itemId() {
      return dig(this.editObservation, 'attributes.report_item_id')
    },
    commentId() {
      return dig(this.editObservation, 'attributes.id')
    },
    hasUnitTypeChoices() {
      return this.unitTypeChoices.length > 0
    },
    hasUnitTypes() {
      return ['number', 'range'].includes(this.answerType)
    },
    recommendationItems() {
      const noRec = [{ id: null, text: 'No Recommendation' }]

      return noRec.concat(
        this.recommendations.map((r) => {
          return {
            text: r.attributes.name,
            value: r.attributes.id,
          }
        })
      )
    },
    unitTypeChoices() {
      return (this.localObservation.attributes.unit_type_choices || '')
        .split(',')
        .map((c) => c.trim())
    },
  },
  watch: {
    editObservation: {
      handler: function (newV) {
        this.localObservation.attributes.location = newV.attributes.location
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.close()
  },
  mounted() {
    this.originalObservation = {
      ...omit(this.editObservation, ['attributes']),
      attributes: omit(this.editObservation.attributes, [
        'photos',
        'observation_photos',
        'report_item',
      ]),
    }
    this.localObservation = {
      ...omit(this.editObservation, ['attributes']),
      attributes: omit(this.editObservation.attributes, [
        'photos',
        'observation_photos',
        'report_item',
      ]),
    }
    if (this.reinspection) {
      if (
        this.report.attributes.settings.reinspect_categories === 2 &&
        this.localObservation.attributes.category === 4
      )
        this.localObservation.attributes.category = false
      if (
        this.report.attributes.settings.reinspect_categories === 2 &&
        this.localObservation.attributes.category === 2
      )
        this.localObservation.attributes.category = true
    }
    this.$modal.show('quick-edit-obs')
  },
  methods: {
    ...mapActions('reports', ['updateTemplateComment']),
    addChoice() {
      this.localObservation.attributes.choices += `,${this.newChoice}`
      this.addOption = false
      this.newChoice = ''
    },
    addUnitChoice() {
      this.localObservation.attributes.unit_type_choices += `,${this.newUnitChoice}`
      this.addOption = false
      this.newUnitChoice = ''
    },
    catClass(cat) {
      let shortName = 'low'
      if (cat === 0 || cat === 3) shortName = 'med'
      if (cat === 1 || cat === 4) shortName = 'high'
      if (cat === 2) shortName = 'good'

      const active =
        this.localObservation.attributes.category === cat ? 'active' : ''
      return `cat-${shortName} ${active}`
    },
    close() {
      this.$modal.hide('quick-edit-obs')
      this.$emit('close')
    },
    openEditLocation() {
      this.$store.commit('reports/setValue', {
        key: 'editLocation',
        value: true,
      })
    },
    async save() {
      if (!this.saving) {
        this.saving = true

        const fields = [
          'id',
          'company_id',
          'report_item_id',
          'report_section_id',
          'name',
          'category',
          'text',
          'flagged',
          'location',
          'recommendation_id',
          'display_estimate',
          'estimate_min',
          'estimate_max',
          'unit_type_choices',
          'unit_type',
        ]
        const intFields = ['company_id', 'estimate_min', 'estimate_max']

        const record = pick(this.originalObservation.attributes, fields)
        const values = pick(this.localObservation.attributes, fields)

        record.report_id = this.report.id
        record.company_id = this.company.id
        values.report_id = this.report.id
        values.company_id = this.company.id

        intFields.map((field) => {
          if (typeof record[field] === 'string') {
            record[field] = parseInt(record[field])
          }
          if (typeof values[field] === 'string') {
            values[field] = parseInt(values[field])
          }
        })

        if (this.reinspection) {
          if (this.report.attributes.settings.reinspect_categories === 3) {
            values.category = this.localObservation.attributes.category
          } else if (
            this.report.attributes.settings.reinspect_categories === 2
          ) {
            values.category = this.localObservation.attributes.category ? 2 : 4
          }
        } else {
          if (this.report.attributes.settings.categories === 3) {
            values.category = this.localObservation.attributes.category
          } else if (this.report.attributes.settings.categories === 2) {
            values.category = this.localObservation.attributes.category ? 1 : 0
          }
        }

        if (this.answerType === 'checkbox' && this.choices.length > 0) {
          this.localObservation.attributes.value = this.choices
            .filter((c) => c.value)
            .map((c) => c.name)
            .join(', ')
          values.value = this.localObservation.attributes.value
          values.choices = this.choices.map((c) => c.name).join(', ')
        } else {
          values.value = this.localObservation.attributes.value
        }

        if (this.remove) {
          values.value = null
          values.value2 = null
        }

        await updateRecord({ collection, record, values })

        if (this.saveToTemplate) {
          this.updateTemplateComment({
            ...values,
            template_comment_id:
              this.originalObservation.attributes.template_comment_id,
          })
        }

        const newObservation = {
          ...values,
          attributes: values,
        }
        this.$emit('change', newObservation)
        this.close()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-header {
  display: flex;
  justify-content: space-between;

  .header-actions {
    display: flex;
    align-items: center;
  }

  .btn {
    color: #fff;
    border-color: #fff;

    .fa {
      &-solid,
      &-regular {
        color: #fff;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.solo-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn {
    margin: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    &:first-child {
      margin-right: 0.5em;
    }
  }
}

.flagged {
  transition: background linear 0.1s;
  background-color: #f9890e;
}

.add-option {
  margin-top: 16px;
  color: #ccc;
  cursor: pointer;

  div {
    display: inline-block;
  }

  .fa {
    &-solid,
    &-regular {
      color: #fff;
      background-color: #ccc;
      border-radius: 50%;
      height: 1.25em;
      width: 1.25em;
      text-align: center;
      vertical-align: middle;
    }
  }

  &:hover {
    color: #45769c;

    .fa {
      &-solid,
      &-regular {
        color: #fff;
        background-color: #45769c;
      }
    }
  }
}

.date-picker-container {
  position: relative;
}

.date-picker-popup {
  position: absolute;
  z-index: 10;
}

.save-to-template-row {
  text-align: right;

  .v-input {
    display: inline-block;
  }
}
</style>
