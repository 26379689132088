<template>
  <modal
    :name="modalId"
    width="70%"
    height="auto"
    scrollable
    @closed="handleClose"
  >
    <div class="get-quotes-modal">
      <div class="get-quotes-modal__header" :class="headerClasses">
        <h1 v-if="currentStep <= 2" class="get-quotes-modal__header__title">
          Get Quotes {{ angiRecommendation ? 'from Angi' : '' }}
        </h1>
        <div class="get-quotes-modal__header__close" @click="handleClose">
          <i class="fa fa-close" />
        </div>
      </div>
      <div class="modal-body get-quotes-modal__body">
        <defects-step
          v-if="currentStep === 0"
          :available-defects="normalizeCollection(availableDefects)"
          :selected-defects="selectedDefects"
          @selectDefect="handleSelect"
        />
        <contractors-step
          v-if="currentStep === 1"
          :available-contractors="normalizeCollection(availableContractors)"
          :selected-contractors="selectedContractors"
          @selectContractors="handleSelect"
        />
        <contact-step
          v-if="currentStep === 2"
          :form="form"
          @submit="handleSubmit"
          @changeForm="handleChangeForm"
        />
        <thanks-step v-show="currentStep === 3" @close="handleClose" />
      </div>
      <div v-if="currentStep <= 2" class="get-quotes-modal__footer">
        <div
          class="
            spectora-ds__button spectora-ds__button--text
            get-quotes-modal__footer__button--text
          "
          @click="handleClear(currentStep)"
        >
          Clear all
        </div>
        <div
          v-if="currentStep < 2"
          id="get-quotes-modal-next-btn"
          class="
            spectora-ds__button spectora-ds__button--default
            get-quotes-modal__footer__button
          "
          :class="nextButtonClasses"
          @click="handleNextStep"
        >
          {{ angiRecommendation ? 'Go to Angi' : 'Next' }}
        </div>
        <input
          v-if="currentStep === 2"
          id="get-quotes-modal-submit-btn"
          class="
            spectora-ds__button spectora-ds__button--default
            get-quotes-modal__footer__button
          "
          value="Get Quote"
          type="submit"
          form="get-quote-form"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
import { postContractorLead } from '@/api'
import normalizeCollection from '@/utils/normalizeCollection'
import angiURLMapper from '@/utils/angiURLMapper'

import { DefectsStep, ContractorsStep, ContactStep, ThanksStep } from './steps'

export default {
  components: {
    DefectsStep,
    ContractorsStep,
    ContactStep,
    ThanksStep,
  },
  props: {
    buyer: {
      type: Object,
      default: () => ({}),
    },
    sectionDefects: {
      type: Array,
      default: () => [],
    },
    currentDefect: {
      type: Object,
      default: () => ({}),
    },
    contractorTypes: {
      type: Array,
      default: () => [],
    },
    reportId: {
      type: String,
      default: null,
    },
    inspectionId: {
      type: Number,
      default: null,
    },
    inspectorId: {
      type: [Number, String],
      default: null,
    },
    angiRecommendation: {
      type: Boolean,
      default: () => false,
    },
    recommendations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const selectedDefects = []
    const availableDefects = []
    const selectedContractors = []
    const availableContractors = []
    const steps = ['Defects', 'Contractors', 'Contact', 'Thanks']
    const form = {}

    return {
      modalId: 'get-quotes-modal',
      selectedDefects,
      availableDefects,
      selectedContractors,
      availableContractors,
      steps,
      form,
      currentStep: 0,
    }
  },

  computed: {
    nextButtonClasses() {
      return {
        'get-quotes-modal__footer__button--disabled': this.disableNext,
      }
    },
    disableNext() {
      if (this.currentStep >= 2) return false
      const currentStepResource = this.steps[this.currentStep]
      return this[`selected${currentStepResource}`].length === 0
    },
    headerClasses() {
      return {
        'get-quotes-modal__header--angi': this.angiRecommendation,
        'get-quotes-modal__header--thanks': this.currentStep > 2,
      }
    },
  },

  mounted() {
    const recommendedContractorAvailableTypes = this.contractorTypes
      .filter((contractorType) =>
        this.angiRecommendation
          ? contractorType.contractors.length === 0
          : contractorType.contractors.length > 0
      )
      .map((contractorType) => contractorType.attributes.id)

    this.availableDefects = this.sectionDefects.filter((defect) =>
      recommendedContractorAvailableTypes.includes(
        (defect.attributes || defect).recommendation_id
      )
    )
    const preselectedDefects = this.currentDefect.id
      ? [this.currentDefect.id]
      : this.availableDefects.map((defect) => defect.id)
    this.selectedDefects.push(...preselectedDefects)
    this.form = {
      first_name: this.buyer?.attributes?.first_name || '',
      last_name: this.buyer?.attributes?.last_name || '',
      email: this.buyer?.attributes?.email || '',
      phone: this.buyer?.attributes?.phone || '',
      contact_method: '',
    }
    this.$modal.show(this.modalId)
  },

  methods: {
    ...mapActions('reports', ['fetchRecommendedContractors']),
    normalizeCollection,
    async handleSubmit() {
      const leads = []
      const selectedContractorsSet = new Set(this.selectedContractors)

      for (const selectedContractorId of selectedContractorsSet) {
        const contractor = this.availableContractors.find(
          (contractor) => contractor.id === selectedContractorId
        )

        const whitelist = this.selectedDefects.map((selectedDefectId) => {
          const defect = this.sectionDefects.find(
            (defect) => defect.id === selectedDefectId
          )
          return {
            observation_id: defect.id,
            number: defect.number,
          }
        })

        // eslint-disable-next-line camelcase
        const { contact_method, ...formData } = this.form
        const params = {
          report_view: {
            report_id: this.reportId,
            whitelist,
          },
          upsell_lead: {
            ...formData,
            company_contractor_id: contractor.id,
            contractor_type: contractor.contractorType,
            report_id: this.reportId,
            settings: {
              // eslint-disable-next-line camelcase
              contact_method,
            },
          },
        }
        leads.push(await postContractorLead(params))
      }
      await Promise.all(leads)
      await this.fetchRecommendedContractors(this.reportId)
      this.$root.$emit('submitted')
      this.handleNextStep()
    },
    handleClose() {
      this.$emit('closed')
      this.$modal.hide(this.modalId)
    },
    handleSelect(e, resource) {
      e.preventDefault()
      const resourceId = e.target.value
      const resourceSet = new Set(this[`selected${resource}`])
      if (!resourceSet.delete(resourceId)) {
        resourceSet.add(resourceId)
      }
      this[`selected${resource}`] = Array.from(resourceSet)
    },
    handleChangeForm(field, value) {
      this.form[field] = value
    },
    handleClear(step) {
      const currentResource = this.steps[step]
      if (Array.isArray(this[`selected${currentResource}`])) {
        this[`selected${currentResource}`] = []
      } else {
        this.form = {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          contact_method: '',
        }
      }
    },
    handleNextStep() {
      if (this.disableNext) return
      if (this.angiRecommendation) {
        const recommendationId = this.sectionDefects.find(
          (defect) => defect.id === this.selectedDefects[0]
        ).recommendation_id
        // Necessarry for custom recommendation types
        const baseRecommendationId = this.recommendations.find(
          (recommendation) => recommendation.attributes.id === recommendationId
        ).attributes.base_id

        const angiURL = angiURLMapper({
          recommendationId: baseRecommendationId || recommendationId,
          inspectorId: this.inspectorId,
        })
        if (angiURL) window.open(angiURL, '_blank')
        return
      }

      switch (this.currentStep) {
        case 0: {
          const recommendationId = this.sectionDefects
            .filter((defect) => this.selectedDefects.includes(defect.id))
            .map((defect) => defect.recommendation_id)[0]

          this.availableContractors = this.contractorTypes
            .filter(
              (contractorType) =>
                recommendationId === contractorType.attributes.id
            )
            .map((contractorType) =>
              contractorType.contractors
                .filter(
                  (contractor) =>
                    (contractor.lead_types_submitted || []).length === 0 ||
                    !contractor.lead_types_submitted.includes(recommendationId)
                )
                .map((contractor) => ({
                  ...contractor,
                  contractorType: contractorType.attributes.id,
                }))
            )
            .flat()
            .map((contractor) => {
              const associatedDefect = this.sectionDefects.find(
                (defect) =>
                  defect.recommendation_id === contractor.contractorType
              )
              return {
                ...contractor,
                associatedDefect: associatedDefect,
              }
            })

          this.selectedContractors = this.availableContractors.map(
            (contractor) => contractor.id
          )
          break
        }
        default: {
          break
        }
      }
      if (this.currentStep <= 2) {
        this.currentStep += 1
      }
    },
    defectDescription(defectText) {
      const sanitizer = document.createElement('div')
      sanitizer.innerHTML = defectText
      const preview = sanitizer.textContent.split(' ').slice(0, 20).join(' ')
      if (sanitizer.textContent.split(' ').length > 20) preview.concat('...')
      return preview
    },
  },
}
</script>

<style lang="scss">
.get-quotes-modal {
  font-family: 'Inter', 'Open Sans', sans-serif;
  position: relative;

  &__header {
    padding: 0.875em 2.5em;
    background-color: #3074aa;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--angi {
      background-color: #e24d40;
    }

    &--thanks {
      position: absolute;
      right: 0;
      border: 0;
      background: transparent;

      i {
        color: #144266;
      }
    }

    &__close {
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.04);
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 100%;
    }

    &__title {
      font-size: 16px;
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      margin-bottom: 0;
      letter-spacing: 0.8px;
    }
  }

  &__body {
    $root: &;
    overflow-y: scroll;
    min-height: 35.375em;

    // Necessary to address iOs chrome nav bar
    @media (max-width: 960px) {
      max-height: 80svh;
    }

    &--defects,
    &--contractors,
    &--contact {
      padding: 4em 6.5em;

      @media (max-width: 960px) {
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
    }

    &--thanks {
      display: flex;
      flex-flow: row;
    }

    &__half {
      width: 50%;

      &--image {
        object-fit: cover;
        height: 44.25em;
      }

      &--content {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 4em;

        #{$root}__title {
          margin-bottom: 0.25em;
        }
      }
      @media (max-width: 1025px) {
        &--content {
          padding: 8em 4em;
          align-items: center;
          text-align: center;
          width: 100%;
        }

        &--image {
          display: none;
        }
      }
    }

    &__title {
      color: #374151;
      font-size: 1.875em;
      font-weight: 700;
      margin-bottom: 1.6em;
      text-transform: none;
      letter-spacing: normal;

      &--type {
        color: #438fcc;
        text-transform: lowercase;
      }
    }

    &__subtitle {
      color: #4b5563;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 3em;
    }

    &__list {
      &__item {
        display: flex;
        flex-flow: row;
        align-items: flex-start;

        &--contractors {
          &:not(:last-child) {
            border-bottom: 1px solid #e5e7eb;
            padding-bottom: 1.5em;
          }
        }

        &:not(:last-child) {
          margin-bottom: 1.5em;
        }

        > input[type='checkbox'] {
          opacity: 1;
          position: relative;
          display: block;
          pointer-events: auto;
          left: 0;
        }

        &__picture {
          margin-left: 1.5em;
          margin-right: 0.5em;
          width: 64px;
          height: 64px;
          border-radius: 100%;

          &--missing {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &__about {
          margin-left: 0.5em;
          min-width: 0;
          line-height: 18px;

          &__name {
            font-size: 14px;
            font-weight: 500;
            color: #1f2937;
            margin-bottom: 4px;
            line-height: 1;

            &--bold {
              font-weight: 700;
            }
          }

          &__description {
            white-space: normal;
            font-size: 14px;
            font-weight: 400;
            color: #4b5563;
            line-break: anywhere;

            &__observation {
              font-style: italic;
            }
          }
        }
      }
    }

    &__form {
      display: flex;
      flex-flow: column;
      gap: 2em;

      &__group {
        display: flex;
        flex-flow: row;
        width: 100%;
        gap: 2.5em;
      }

      &__field {
        display: flex;
        flex-flow: column;
        min-width: auto;

        @media (min-width: 961px) {
          flex-basis: calc(50% - 1.25em); // Actual target size - half the gap

          &:last-child {
            &:first-child {
              margin-left: auto;
            }
          }
        }

        &__label {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 700;
          margin-bottom: 0.65em;
          color: #1f2937;
        }

        &__input {
          border: 1px solid #c0c6ce !important;
          border-radius: 6px !important;
          background-color: white !important;
          padding: 0.5em 0.75em !important;
          height: auto !important;
          width: auto !important;

          &--select {
            + i.fa {
              margin-top: -30px;
              margin-right: 5px;
              pointer-events: none;
              background-color: #fff;
              padding-right: 5px;
              margin-left: auto;
            }
          }
        }
      }

      @media (max-width: 1025px) {
        &__group {
          flex-flow: column;
        }

        &__field {
          width: 100%;
        }
      }
    }

    &--contractors {
      #{$root}__list {
        &__item {
          align-items: center;
        }
      }
    }

    &--contact {
      #{$root}__title {
        margin-bottom: 0.25em;
      }
    }
  }

  &__footer {
    border-top: 1px solid #e5e7eb;
    display: flex;
    padding: 1em 2.5em;

    &__button {
      width: 110px;
      cursor: pointer;

      &:last-child {
        margin-left: auto;
      }

      &--disabled {
        opacity: 0.75;
        cursor: not-allowed;
      }

      &--text {
        padding-left: 0;
      }
    }
  }
}
</style>
