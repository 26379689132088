<template>
  <div class="row">
    <div class="col s12">
      <!-- eslint-disable vue/no-mutating-props -->
      <v-select
        v-model="condition.subfield"
        :items="subfieldOptions"
        hide-details
        @input="handleSubfieldChange"
      />
    </div>
    <div class="col s12">
      <v-select
        v-model="condition.operator"
        :items="operatorOptions"
        hide-details
        @input="handleChange"
      />
    </div>
    <div class="col s12">
      <v-select
        v-if="isCheckbox"
        v-model="condition.value"
        :items="checkboxOptions"
        hide-details
        @input="handleChange"
      />
      <div v-if="isDate" class="calendar-overlay calendar-overlay-input">
        <date-picker
          v-model="condition.value"
          placeholder="Select a date..."
          :config="{
            enableTime: false,
            dateFormat: 'Y-m-d',
            altInput: true,
            altFormat: datePickerFormat,
          }"
        />
      </div>
      <div v-if="isDatetime" class="calendar-overlay calendar-overlay-input">
        <date-picker
          v-model="condition.value"
          placeholder="Select a date..."
          mode="datetime"
          :config="{
            enableTime: true,
            dateFormat: 'Y-m-d h:i K',
            altInput: true,
            altFormat: dateTimePickerFormat,
          }"
        />
      </div>
      <v-select
        v-if="isDropdown"
        v-model="condition.value"
        :items="choices"
        hide-details
        @input="handleChange"
      />
      <v-select
        v-if="isIndicator"
        v-model="condition.value"
        :items="indicatorOptions"
        hide-details
        @input="handleChange"
      />
      <v-text-field
        v-if="isNumber"
        v-model="condition.value"
        hide-details
        type="number"
        @input="handleChange"
      />
      <v-text-field
        v-if="isText"
        v-model="condition.value"
        hide-details
        @input="handleChange"
      />
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
import DatePicker from '../../components/DatePicker.vue'
import railsToFlatpickrFormat from '../../utils/railsToFlatpickrFormat'

export default {
  components: {
    DatePicker,
  },
  props: {
    condition: {
      type: Object,
      default: () => {},
    },
    dateFormat: {
      type: String,
      default: '',
    },
    template: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    choice() {
      const { subfield } = this.condition
      if (!subfield || subfield.length === 0) {
        return {}
      }

      return this.template.choices.find((choice) => choice.id === subfield)
    },
    choices() {
      if (this.choice && this.choice.choices.length) {
        return this.choice.choices
          .split(',')
          .map((choice) => ({ text: choice, value: choice }))
      }

      return []
    },
    checkboxOptions() {
      return [
        { text: 'Checked', value: true },
        { text: 'Not Checked', value: false },
      ]
    },
    dateTimePickerFormat() {
      return railsToFlatpickrFormat(this.dateFormat) + ' h:i K'
    },
    isCheckbox() {
      return this.choice.response_format === 'checkbox'
    },
    isDate() {
      return this.choice.response_format === 'date'
    },
    isDatetime() {
      return this.choice.response_format === 'datetime'
    },
    isDropdown() {
      return this.choice.response_format === 'dropdown'
    },
    isIndicator() {
      return this.choice.response_format === 'indicator'
    },
    isNumber() {
      return this.choice.response_format === 'number'
    },
    isText() {
      return (
        this.choice.response_format === 'paragraph' ||
        this.choice.response_format === 'text'
      )
    },
    indicatorOptions() {
      return [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
        { text: 'N/A', value: null },
      ]
    },
    operatorOptions() {
      if (this.isCheckbox) {
        return [
          { text: 'Equals', value: 'equal_to' },
          // { text: 'Does not equal', value: 'not_equal_to' },
        ]
      }

      if (this.isDate || this.isDatetime) {
        return [
          {
            text: 'Greater than or equal to',
            value: 'greater_than_or_equal_to',
          },
          { text: 'Less than', value: 'less_than' },
        ]
      }

      if (this.isDropdown) {
        return [
          { text: 'Equals', value: 'equal_to' },
          { text: 'Does not equal', value: 'not_equal_to' },
        ]
      }

      if (this.isNumber) {
        return [
          {
            text: 'Greater than or equal to',
            value: 'greater_than_or_equal_to',
          },
          { text: 'Less than', value: 'less_than' },
          { text: 'Equals', value: 'equal_to' },
          { text: 'Does not equal', value: 'not_equal_to' },
        ]
      }

      if (this.isIndicator) {
        return [
          { text: 'Equals', value: 'equal_to' },
          { text: 'Does not equal', value: 'not_equal_to' },
        ]
      }

      if (this.isText) {
        return [
          // { text: 'Equals', value: 'equal_to' },
          // { text: 'Does not equal', value: 'not_equal_to' },
          { text: 'Contains', value: 'contains' },
          { text: 'Does not contain', value: 'not_contains' },
        ]
      }

      return []
    },
    subfieldOptions() {
      return this.template.choices.map((choice) => ({
        text: choice.name,
        value: choice.id,
      }))
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', { condition: this.condition })
    },
    handleSubfieldChange() {
      this.condition.operator = (this.operatorOptions[0] || {}).value // eslint-disable-line vue/no-mutating-props
      this.condition.value = null // eslint-disable-line vue/no-mutating-props
      this.$emit('change', { condition: this.condition })
    },
  },
}
</script>

<style></style>
