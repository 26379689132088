<template>
  <modal
    name="ineligible-contractors-modal"
    width="70%"
    height="auto"
    scrollable
    @closed="handleClose"
  >
    <div class="ineligible-contractors-modal">
      <div class="ineligible-contractors-modal__header">
        <div class="ineligible-contractors-modal__header-title">
          Active contractors not displayed on the report
        </div>
        <div
          class="ineligible-contractors-modal__header-close"
          @click="handleClose"
        >
          <i class="fa fa-close" />
        </div>
      </div>
      <div class="modal-body ineligible-contractors-modal__body">
        <span class="ineligible-contractors-modal__body-title">
          {{ recommendation.attributes.name }}
        </span>
        <div class="ineligible-contractors-modal__body-subtitle">
          These contractors will not display on the client's report.
        </div>
        <div class="ineligible-contractors-modal__body-list">
          <div
            v-for="contractor in ineligibleContractors"
            :key="contractor.id"
            class="ineligible-contractors-modal__body-list-item"
          >
            <img
              v-if="contractor.logo_url"
              :src="contractor.logo_thumb_url"
              class="ineligible-contractors-modal__body-list-item-picture"
            />
            <div
              v-else
              class="
                ineligible-contractors-modal__body-list-item-picture
                ineligible-contractors-modal__body-list-item-picture--missing
              "
            >
              <i class="fa fa-building" />
            </div>
            <div class="ineligible-contractors-modal__body-list-item-about">
              <div
                class="ineligible-contractors-modal__body-list-item-about-name"
              >
                {{ formatContractorName(contractor) }}
              </div>
              <div
                class="
                  ineligible-contractors-modal__body-list-item-about-description
                "
              >
                <div>
                  {{ contractor.description }}
                </div>
                <div>
                  {{ formatAddress(contractor) }}
                </div>
              </div>
              <div
                class="
                  ineligible-contractors-modal__body-list-item-ineligible-reasons
                "
              >
                <div
                  class="
                    ineligible-contractors-modal__body-list-item-ineligible-reasons-label
                  "
                >
                  Reason
                </div>

                <div
                  class="
                    ineligible-contractors-modal__body-list-item-ineligible-reasons-value
                  "
                >
                  <ul
                    v-for="(reason, index) in getIneligibleReasons(contractor)"
                    :key="index"
                  >
                    <li>
                      {{ reason }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ineligible-contractors-modal__footer">
        <div
          class="spectora-ds__button spectora-ds__button--default"
          @click="handleClose"
        >
          Close
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import normalizeCollection from '@/utils/normalizeCollection'
import formatAddress from '@/utils/formatAddress'
import formatContractorName from '@/utils/formatContractorName'

export default {
  props: {
    recommendation: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ineligibleContractors() {
      return (this.recommendation.contractors || []).filter(
        (contractor) => !contractor.showInReport
      )
    },
  },
  mounted() {
    this.$modal.show('ineligible-contractors-modal')
  },
  methods: {
    normalizeCollection,
    formatAddress,
    formatContractorName,
    getIneligibleReasons(contractor) {
      const reasons = []
      const contractorEligibility = contractor?.eligibility || {}

      const {
        service_boundaries: serviceBoundaries,
        quote_requests: quoteRequests,
      } = contractorEligibility

      if (serviceBoundaries && !serviceBoundaries.eligible) {
        reasons.push(
          "The property is not within the contractor's service areas"
        )
      }

      if (quoteRequests && !quoteRequests.eligible) {
        reasons.push(quoteRequests.reason)
      }

      return reasons
    },
    handleClose() {
      this.$emit('closed')
      this.$modal.hide('ineligible-contractors-modal')
    },
  },
}
</script>

<style lang="scss">
.ineligible-contractors-modal {
  &__header {
    padding: 0.875em 2.5em;
    background-color: #3074aa;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-close {
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.04);
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 100%;
    }

    &-title {
      font-size: 16px;
      font-weight: 700;
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.8px;
    }
  }

  &__body {
    overflow-y: auto;
    min-height: 35.375em;
    max-height: 80svh;
    padding: 4em 6.5em;

    // Necessary to address iOs chrome nav bar
    @media (max-width: 960px) {
      max-height: 80svh;
      padding: 2em;
    }

    &-title {
      text-transform: uppercase;
      font-weight: 700;
    }

    &-subtitle {
      font-size: 16px;
      font-weight: 400;
      padding-top: 0.5em;
      margin-bottom: 3em;
    }

    &-list {
      &-item {
        display: flex;
        align-items: center;
        gap: 1em;

        &:not(:last-child) {
          border-bottom: 1px solid #e5e7eb;
          padding-bottom: 1.5em;
          margin-bottom: 1.5em;
        }

        &-picture {
          width: 64px;
          height: 64px;
          border-radius: 100%;

          &--missing {
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              font-size: 2.5em;
              color: #666;
            }
          }
        }

        &-about {
          font-size: 14px;

          &-name {
            font-weight: 700;
          }

          &-description {
            white-space: normal;
            line-break: anywhere;
          }
        }

        &-ineligible-reasons {
          &-label {
            font-weight: 500;
          }

          &-value {
            ul {
              padding-left: 1.3em;

              li {
                list-style-type: disc;
              }
            }
          }
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: end;
    padding: 1em 2.5em;
  }
}
</style>
