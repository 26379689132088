import axios from '../../../utils/AxiosService'
import { captureException } from '@/utils'
import errorReporter from '@spectora/frontend.services.vue-error-service'

const genericError = 'An error has occurred. Your changes likely did not save.'
const loadingError = "We're having trouble loading data. Please try again."

export const fetchInspection = (
  { commit, dispatch, state },
  inspectionSlug,
  assign = true
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/api/v1/inspections/${inspectionSlug}?include=custom_fields,charges,report_assignments,property,buyer,buyer_2,selling_agent,buying_agent,scheduled_by_user&view=image`
      )
      .then((response) => {
        const inspection = response.data.data
        const inclusions = response.data.included

        inspection.attributes.buyer = inclusions.find(
          (i) =>
            i.type === 'buyer' &&
            i.attributes.id === inspection.attributes.buyer_id
        )
        inspection.attributes.buyer_2 = inclusions.find(
          (i) =>
            i.type === 'buyer' &&
            i.attributes.id === inspection.attributes.buyer_2_id
        )
        inspection.attributes.buying_agent = inclusions.find(
          (i) =>
            i.type === 'agent' &&
            i.attributes.id === inspection.attributes.buying_agent_id
        )
        inspection.attributes.charges = inclusions.filter(
          (i) => i.type === 'charges'
        )
        inspection.attributes.custom_fields = inclusions.filter(
          (i) => i.type === 'custom_field'
        )
        inspection.attributes.property = inclusions.find(
          (i) => i.type === 'property'
        )
        inspection.attributes.report_assignments = inclusions.filter(
          (i) => i.type === 'report_assignment'
        )
        inspection.attributes.selling_agent = inclusions.find(
          (i) =>
            i.type === 'agent' &&
            i.attributes.id === inspection.attributes.selling_agent_id
        )
        inspection.attributes.scheduler = inclusions.find(
          (i) =>
            i.attributes.user_id === inspection.attributes.scheduled_by_user_id
        )

        if (assign) {
          commit('setValue', { key: 'inspection', value: inspection })
        }
        resolve(inspection)
      })
      .catch((error) => {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const setValue = ({ commit }, { key, value }) => {
  commit('setValue', { key, value })
}

export const fetchAssignments = ({ commit, dispatch, state }, inspectionId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/api/v1/assignments?inspection_id=${inspectionId}&include=inspector`
      )
      .then((response) => {
        const inspectors = response.data.included
        const assignments = response.data.data
        const assignedInspectors = inspectors.map((i) => {
          i.attributes.assignment = assignments.find(
            (a) => i.attributes.id === a.attributes.inspector_id
          )
          return i
        })
        commit('setValue', {
          key: 'assignedInspectors',
          value: assignedInspectors,
        })
        resolve(assignedInspectors)
      })
      .catch((error) => {
        captureException(error)
        commit('notifier/showError', loadingError, { root: true })
      })
  })
}

export const fetchCompany = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/v1/companies/${params.id}?view=${params.view || ''}`)
      .then(function (response) {
        commit('setValue', { key: 'company', value: response.data.data })
        resolve(response.data.data)
      })
      .catch(function (error) {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const fetchUserProfile = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/v1/user_profile')
      .then(function (response) {
        commit('setValue', { key: 'userProfile', value: response.data.data })
        resolve(response)
      })
      .catch(function (error) {
        commit('notifier/showError', genericError, { root: true })
        reject(error)
        // Commented out because it's an expected failure
        // errorReporter.error(error)
      })
  })
}

export const updateInspection = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/v1/inspections/${params.attributes.id}`, params)
      .then((response) => {
        commit('notifier/setShowMessage', 'Inspection Updated!', { root: true })
        commit('updateInspectionAttributes', response.data.data.attributes)
        resolve(response.data.data)
      })
      .catch((error) => {
        commit('notifier/showError', loadingError, { root: true })
        errorReporter.error(error)
        reject(error)
      })
  })
}

export const reload = ({ commit, dispatch, state }, { key, value }) => {
  if (value === undefined) value = true
  commit('reload', { key: key, value: value })
}
