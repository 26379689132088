<template>
  <div class="file-picker">
    <slot name="picker" :pick-function="pickFile">
      <v-text-field
        ref="fileTextField"
        v-model="fileName"
        :label="label"
        prepend-icon="fa-paperclip"
        @click="pickFile"
        @input="pickFile"
      />
    </slot>
    <input
      ref="filepicker"
      class="file-input"
      :accept="fileAccept"
      type="file"
      @change="filePicked"
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['accept', 'placeholder'],
  data() {
    return {
      file: null,
      fileData: null,
      fileName: null,
    }
  },
  computed: {
    fileAccept() {
      return this.accept || '*'
    },
    label() {
      return this.placeholder || 'Select a file'
    },
  },
  mounted() {
    const el = this.$refs.fileTextField
    if (el) {
      const label = el.$el.getElementsByTagName('label')[0]
      label.onclick = this.pickFile
    }
  },
  methods: {
    emitFile() {
      this.$emit('change', {
        name: this.fileName,
        data: this.fileData,
        file: this.file,
      })
    },
    filePicked(e) {
      const files = e.target.files
      if (files[0]) {
        this.fileName = files[0].name

        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.fileData = fr.result
          this.file = files[0]
          this.emitFile()
        })
      } else {
        this.resetData()
      }
    },
    pickFile() {
      this.$refs.filepicker.click()
    },
    resetData() {
      this.file = null
      this.fileData = null
      this.fileName = null
    },
  },
}
</script>

<style scoped>
.file-input {
  display: none;
}
</style>

<style>
.file-picker .v-input__prepend-outer {
  position: absolute;
}
.file-picker .v-text-field__slot,
.file-picker .v-text-field__slot input {
  text-indent: 1.5em;
}
</style>
