<template>
  <modal
    name="autotrack-wizard-dialog"
    class="autotrack-wizard-dialog"
    height="auto"
    :scrollable="true"
    :click-to-close="false"
    width="80%"
    @closed="onClose"
  >
    <div class="modal-content center-align">
      <div class="wrapper">
        <v-stepper v-model="step">
          <v-stepper-header class="grey lighten-3">
            <v-stepper-step
              :editable="isEditable"
              :complete="step > 1"
              :complete-icon="completeIcon"
              :rules="[() => validEvent]"
              :step="1"
            >
              Choose an Event
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              class="white-text"
              :editable="lastStep >= 2"
              :complete="step > 2"
              :rules="[() => validConditions]"
              :step="2"
            >
              Add Rules
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :editable="isEditable && lastStep >= 3"
              :complete="step > 3"
              :complete-icon="completeIcon"
              :rules="[() => validAction]"
              :step="3"
            >
              Choose an Action
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :editable="lastStep >= 4"
              :rules="[() => validParams]"
              :step="4"
            >
              Add Details
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="bordered-row">
                <div class="row event">
                  <transition name="slide-right">
                    <div class="col m2 s6">
                      <v-text-field
                        v-model="selectedEventOffset"
                        :error-messages="selectedEventOffsetErrors"
                        @input="emitChanges"
                      />
                    </div>
                  </transition>
                  <transition name="slide-right">
                    <div class="col m2 s6">
                      <v-select
                        v-model="selectedEventOffsetMultiplier"
                        :items="unitOptions"
                        @input="emitChanges"
                      />
                    </div>
                  </transition>
                  <div class="col m4 s6">
                    <v-select
                      v-model="selectedEventOffsetModifier"
                      :items="eventOffsetOptions"
                      @input="emitChanges"
                    />
                  </div>
                  <div class="col m4 s6">
                    <v-select
                      v-model="selectedEvent"
                      :items="eventOptions"
                      :item-disabled="eventDisabled"
                      :hint="hint"
                      @input="onEventChange()"
                    />
                  </div>
                </div>
                <div class="row event mt-2">
                  <div class="col s12">
                    <v-checkbox
                      v-model="occursOnce"
                      hide-details
                      :disabled="!trackMode"
                      :label="`Only trigger once${
                        trackMode
                          ? ''
                          : ' (Cannot be changed after Action has applied to Inspection)'
                      }`"
                    />
                  </div>
                  <div class="col s12">
                    <v-checkbox
                      v-model="alwaysSend"
                      hide-details
                      label="Send even when notifications disabled"
                    />
                  </div>
                  <div class="col m4 s12">
                    <v-checkbox
                      v-model="useWindow"
                      hide-details
                      label="Send during certain hours only"
                    />
                  </div>
                  <div class="col m8 s12">
                    <template v-if="useWindow">
                      <div class="small mb-1 mt-3">
                        <span class="medium-gray">From</span>
                        {{ eventTimeWindowStart }}
                        <span class="medium-gray">To</span>
                        {{ eventTimeWindowEnd }}
                      </div>
                      <range-slider
                        v-model="eventTimeWindow"
                        :range="{ min: 0, max: windowTimes.length - 1 }"
                      />
                    </template>
                  </div>
                  <div class="col s12">
                    <v-checkbox
                      v-model="avoidWeekend"
                      label="Do not send on weekends"
                    />
                  </div>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-layout
                v-if="selectedConditions && selectedConditions.length > 1"
                class="strong red-text text-center text-darken-2"
                style="margin-bottom: 25px; font-size: 1.1em"
              >
                <span
                  style="
                    text-align: right;
                    padding-top: 2px;
                    padding-right: 5px;
                  "
                  >To trigger this {{ autotrackName }}</span
                >
                <span>
                  <v-select
                    v-model="conditionsOperator"
                    :items="conditionsOperatorOptions"
                    class="conditions-operator-select"
                  />
                </span>
                <span
                  style="text-align: left; padding-top: 2px; padding-left: 5px"
                  >of the following must apply:</span
                >
              </v-layout>

              <div class="mb-2 condition-list">
                <div
                  v-for="condition in selectedConditions"
                  :key="condition.id"
                >
                  <!-- <pre>{{condition}}</pre> -->
                  <div class="bordered-row">
                    <div class="row condition">
                      <div class="col s3">
                        <!-- Has/Does not Have !-->
                        <v-select
                          v-model="condition.inclusive"
                          :items="conditionInclusiveOptions"
                          @input="emitChanges"
                        />
                      </div>
                      <div class="col s3">
                        <!-- Condition Options (Service, CustomField) !-->
                        <v-select
                          v-model="condition.field"
                          :items="conditionFieldOptions"
                          @input="handleConditionFieldChange(condition)"
                        />
                      </div>
                      <div class="col s5">
                        <!-- Condition Choices (tag names etc. defined in auto_condition/klass) !-->
                        <condition-property-field
                          v-if="conditionFieldProperty(condition)"
                          :condition="condition"
                          :template="conditionTemplate(condition)"
                          @change="emitChanges"
                        />
                        <condition-custom-field
                          v-if="conditionFieldCustomField(condition)"
                          :date-format="dateFormat"
                          :condition="condition"
                          :template="conditionTemplate(condition)"
                          @change="emitChanges"
                        />
                        <template
                          v-if="
                            conditionFieldStandard(condition) &&
                            !conditionIsBoolean(condition)
                          "
                        >
                          <v-text-field
                            v-if="conditionIsManual(condition)"
                            v-model="condition.value"
                          />
                          <v-select
                            v-if="!conditionIsManual(condition)"
                            v-model="condition.value"
                            :items="conditionValueOptions(condition)"
                            @input="emitChanges"
                          />
                        </template>
                      </div>
                      <div class="col s1 pt-4">
                        <i
                          class="fa-solid fa-trash"
                          @click="deleteCondition(condition.id)"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="and-separator my-3">
                    {{ conditionsOperator }}
                  </div>
                </div>
              </div>

              <div
                v-if="!selectedConditions || selectedConditions.length === 0"
                class="mb-2"
              >
                <div
                  class="row message"
                  style="
                    background: #fffff0;
                    color: #b7791f;
                    border: 1px solid #faf089;
                    padding: 10px;
                  "
                >
                  <div class="col s12">
                    Without rules, this {{ autotrackName }} will happen for
                    <strong>EVERY</strong> inspection!
                  </div>
                </div>
              </div>

              <div
                v-if="selectedConditions && selectedConditions.length > 0"
                class="mb-2"
              >
                <div class="row message">
                  <div class="col s12 py-2">
                    Adding rules won't stop other Actions from happening so make
                    sure to review all your actions to stop duplicates!
                  </div>
                </div>
              </div>

              <div class="row">
                <v-btn large @click="addCondition"> + Add Rule </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <div class="bordered-row">
                <div class="row action mb-2">
                  <div class="col s12">
                    <v-text-field
                      v-model="trackName"
                      label="Action Name (Optional)"
                    />
                  </div>
                </div>
                <div class="row action">
                  <div class="col s12">
                    <v-select
                      v-model="selectedAction"
                      :items="actionOptions"
                      @input="clearParams"
                    />
                  </div>
                </div>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <div>
                Provide details necessary to
                <strong>{{ humanize(selectedAction) }}</strong>
              </div>
              <div class="row mt-0">
                <div
                  v-for="param in selectedActionParams"
                  :key="param.name"
                  :class="paramColumnClass(param)"
                >
                  <div class="param">
                    <v-combobox
                      v-if="param.control === 'combobox'"
                      v-model="selectedParams[param.name]"
                      :items="optionizeParam(param)"
                      :label="`${humanize(
                        param.name
                      )} (type a value or select one from the list)`"
                      multiple
                      chips
                      :error-messages="paramErrors(param.name)"
                      @input="emitChanges"
                    />
                    <v-select
                      v-if="param.control === 'select'"
                      v-model="selectedParams[param.name]"
                      :items="optionizeParam(param)"
                      :label="humanize(param.name)"
                      :error-messages="paramErrors(param.name)"
                      :multiple="param.multiple"
                      chips
                      @input="emitChanges"
                    />
                    <v-text-field
                      v-if="param.control === 'text-field'"
                      v-model="selectedParams[param.name]"
                      :label="humanize(param.name)"
                      :error-messages="paramErrors(param.name)"
                    />
                    <v-textarea
                      v-if="param.control === 'textarea'"
                      v-model="selectedParams[param.name]"
                      :label="humanize(param.name)"
                      :error-messages="paramErrors(param.name)"
                    />
                    <div
                      v-if="param.control === 'htmleditor-with-placeholders'"
                      class="d-block left-align pb-3"
                    >
                      <placeholder-editor
                        v-model="selectedParams[param.name]"
                        :label="humanize(param.name)"
                        :is-html="true"
                        @pick="placeholderPicked"
                      />
                    </div>
                    <template
                      v-if="param.control === 'textarea-with-placeholders'"
                    >
                      <div
                        v-if="canCustomizeSms"
                        class="d-block left-align pb-3"
                      >
                        <placeholder-editor
                          v-model="selectedParams[param.name]"
                          :label="humanize(param.name)"
                          :is-html="false"
                        />
                      </div>
                      <div v-else class="d-block left-align pb-3">
                        <sms-template-selector
                          v-model="selectedParams[param.name]"
                          :label="humanize(param.name)"
                          :error-messages="paramErrors(param.name)"
                        />
                      </div>
                    </template>
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                      v-if="param.helper_html"
                      class="small light pb-3"
                      v-html="param.helper_html"
                    ></div>
                    <!-- eslint-enable -->
                  </div>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
          <div class="mb-2">
            <v-btn flat @click="onClose"> Cancel </v-btn>

            <v-btn v-if="lastStep === 4" color="primary" @click="save">
              Save
            </v-btn>

            <v-btn v-if="step !== 4" color="primary" @click="goToNext">
              Next &nbsp;
              <div class="fa-solid fa-arrow-right" />
            </v-btn>
          </div>
          <div v-if="!validWizard" class="orange-text small pb-4">
            Errors were detected and nothing was saved. Please correct these
            errors and try again.
          </div>
        </v-stepper>
      </div>
    </div>
  </modal>
</template>

<script>
// logic
import Inflect from 'i'
import uuid from 'uuid4'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
// components
import ConditionCustomField from './ConditionCustomField.vue'
import ConditionPropertyField from './ConditionPropertyField.vue'
import PlaceholderEditor from './PlaceholderEditor.vue'
import SmsTemplateSelector from './SmsTemplateSelector.vue'
import RangeSlider from '../RangeSlider.vue'
// utils
import { captureException } from '@/utils'

const windowTimes = [
  '12:30 am',
  '1:00 am',
  '1:30 am',
  '2:00 am',
  '2:30 am',
  '3:00 am',
  '3:30 am',
  '4:00 am',
  '4:30 am',
  '5:00 am',
  '5:30 am',
  '6:00 am',
  '6:30 am',
  '7:00 am',
  '7:30 am',
  '8:00 am',
  '8:30 am',
  '9:00 am',
  '9:30 am',
  '10:00 am',
  '10:30 am',
  '11:00 am',
  '11:30 am',
  '12:00 pm',
  '12:30 pm',
  '1:00 pm',
  '1:30 pm',
  '2:00 pm',
  '2:30 pm',
  '3:00 pm',
  '3:30 pm',
  '4:00 pm',
  '4:30 pm',
  '5:00 pm',
  '5:30 pm',
  '6:00 pm',
  '6:30 pm',
  '7:00 pm',
  '7:30 pm',
  '8:00 pm',
  '8:30 pm',
  '9:00 pm',
  '9:30 pm',
  '10:00 pm',
  '10:30 pm',
  '11:00 pm',
  '11:30 pm',
]

const defaultWindowStart = '7:00 am'
const defaultWindowEnd = '5:00 pm'
const defaultWindowStartIndex = windowTimes.findIndex(
  (x) => x === defaultWindowStart
)
const defaultWindowEndIndex = windowTimes.findIndex(
  (x) => x === defaultWindowEnd
)

const inflect = Inflect()

export default {
  components: {
    ConditionCustomField,
    ConditionPropertyField,
    PlaceholderEditor,
    SmsTemplateSelector,
    RangeSlider,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['embedded', 'track'],
  data() {
    return {
      alwaysSend: false,
      autoTrackGroupId: undefined,
      avoidWeekend: false,
      eventTimeWindow: [defaultWindowStartIndex, defaultWindowEndIndex],
      httpMethods: ['get', 'post', 'patch', 'put', 'delete'],
      lastStep: 1,
      occursOnce: false,
      priority: null,
      conditionsOperator: 'AND',
      selectedAction: '',
      selectedConditions: [],
      selectedEvent: 'inspection_scheduled',
      selectedEventOffset: 0,
      selectedEventOffsetMultiplier: 3600,
      selectedEventOffsetModifier: 'after',
      selectedParams: {},
      valid: false,
      validErrors: [],
      trackId: undefined,
      trackName: '',
      useWindow: false,
      windowTimes,
    }
  },
  computed: {
    ...mapGetters('autotracksManager', ['dateFormat']),
    ...mapState('autotracksManager', [
      'actions',
      'autotrackName',
      'company',
      'conditions',
      'events',
      'trackMode',
      'wizardStep',
    ]),
    actionOptions() {
      return this.actions.map((a) => {
        return {
          text: inflect.humanize(a.name),
          value: a.name,
        }
      })
    },
    canCustomizeSms() {
      return this.company.attributes.can_customize_sms
    },
    canPretriggerEvent() {
      const event = this.events.find((x) => x.name === this.selectedEvent)
      if (!event) {
        return false
      }
      return event.can_pretrigger
    },
    completeIcon() {
      return this.isEditable ? 'check' : 'lock'
    },
    conditionFieldOptions() {
      const runtimeConditions = this.conditions
        .filter(
          (x) =>
            x.limited_applicability &&
            x.applicable_events.includes(this.eventSpec.name)
        )
        .map((c) => {
          return {
            text: inflect.humanize(c.label),
            value: c.name,
          }
        })
      const normalConditions = this.conditions
        .filter((x) => !x.limited_applicability)
        .map((c) => {
          return {
            text: inflect.humanize(c.label),
            value: c.name,
          }
        })
      if (runtimeConditions.length === 0) {
        return normalConditions
      }
      return [
        {
          text: `- ${this.eventSpec.runtime_conditions_label} - `,
          disabled: true,
        },
      ]
        .concat(runtimeConditions)
        .concat([{ text: '- Inspection -', disabled: true }])
        .concat(normalConditions)
    },
    conditionInclusiveOptions() {
      return [
        {
          text: 'Has',
          value: true,
        },
        {
          text: 'Does not have',
          value: false,
        },
      ]
    },
    // this applies for the entire auto_track_run
    conditionsOperatorOptions() {
      return [
        {
          text: 'ALL',
          value: 'AND',
        },
        {
          text: 'ANY',
          value: 'OR',
        },
      ]
    },
    // this applies to each individual action
    conditionOperatorOptions() {
      return [
        {
          text: 'Equal to',
          value: 'equal_to',
        },
        {
          text: 'Not equal to',
          value: 'not_equal_to',
        },
      ]
    },
    eventOptions() {
      const options = []

      Object.keys(this.eventSections).forEach((k) => {
        options.push({ text: k, disabled: true })

        this.eventSections[k].forEach((name) => {
          const ev = this.events.find((e) => e.name === name)
          options.push({
            text: ev.label,
            value: ev.name,
            disabled: ev.disabled,
          })
        })
      })

      return options
    },
    eventOffsetOptions() {
      if (this.selectedEvent === undefined) {
        return []
      }
      const beforeOption = {
        text: 'Before',
        value: 'before',
      }
      if (!this.canPretriggerEvent) {
        beforeOption.disabled = true
      }
      const options = [
        beforeOption,
        {
          text: 'After',
          value: 'after',
        },
      ]
      return options
    },
    eventSections() {
      return {
        '— Pre-Inspection —': [
          'inspection_requested',
          'inspection_scheduled',
          'service_or_addon_added',
          'service_or_addon_removed',
          'agreement_added',
          'agreement_removed',
          'attachment_added',
          'attachment_removed',
          'fee_added',
          'fee_removed',
          'inspector_assigned',
          'inspector_unassigned',
          'inspection_rescheduled',
          'inspection_canceled',
        ],
        '— Client Actions —': [
          'agreements_signed',
          'payment_completed',
          'client_completed',
        ],
        '— Day of Inspection —': [
          'inspection_started',
          'inspection_ended',
          'reports_published',
        ],
        '— Inspection Events —': [
          'inspection_event_created',
          'inspection_event_updated',
          'inspection_event_deleted',
        ],
        '— Post-Inspection —': [
          'report_viewed_by_agent',
          'report_viewed_by_client',
        ],
      }
    },
    eventSpec() {
      return this.events.find((e) => e.name === this.selectedEvent)
    },
    hint() {
      if (this.eventSpec) {
        return this.eventSpec.hint
      }
      return ''
    },
    isEditable() {
      return this.trackMode || (!this.trackMode && !this.track.id)
    },
    selectedActionParams() {
      const action = this.actions.find((a) => a.name === this.selectedAction)
      if (action === undefined) {
        return []
      }
      return action.params
    },
    selectedEventOffsetErrors() {
      const errors = []
      if (
        this.selectedEventOffset &&
        !Number.isInteger(Number(this.selectedEventOffset))
      ) {
        errors.push('Time offset must be a whole number')
      }
      return errors
    },
    services() {
      return [
        {
          text: 'Residential Inspection',
          value: 1,
        },
        {
          text: 'Radon Testing',
          value: 2,
        },
      ]
    },
    step: {
      get() {
        return this.wizardStep
      },
      set(newValue) {
        if (!this.isEditable && ![2, 4].includes(newValue))
          newValue = this.lastStep
        this.setWizardStep(newValue)
      },
    },
    textTemplates() {
      return [
        {
          text: 'Template for you',
          value: 1,
        },
        {
          text: 'Template for me',
          value: 2,
        },
        {
          text: 'Template for everybody',
          value: 3,
        },
      ]
    },
    eventTimeWindowStart() {
      return windowTimes[this.eventTimeWindow[0]]
    },
    eventTimeWindowEnd() {
      return windowTimes[this.eventTimeWindow[1]]
    },
    unitOptions() {
      return [
        {
          text: 'minutes',
          value: 60,
        },
        {
          text: 'hours',
          value: 3600,
        },
        {
          text: 'days',
          value: 3600 * 24,
        },
        {
          text: 'weeks',
          value: 3600 * 24 * 7,
        },
        {
          text: 'months',
          value: 3600 * 24 * 30,
        },
      ]
    },
    validAction() {
      return this.validErrors.filter((x) => x.portion === 'action').length === 0
    },
    validConditions() {
      return (
        this.validErrors.filter((x) => x.portion === 'conditions').length === 0
      )
    },
    validEvent() {
      return (
        this.selectedEventOffsetErrors.length === 0 &&
        this.validErrors.filter((x) => x.portion === 'event').length === 0
      )
    },
    validParams() {
      return this.validErrors.filter((x) => x.portion === 'params').length === 0
    },
    validWizard() {
      return this.validErrors.length === 0
    },
  },
  mounted() {
    if (this.track) {
      this.loadTrack()
    }
    this.$modal.show('autotrack-wizard-dialog')
  },
  beforeDestroy() {
    this.$modal.hide('autotrack-wizard-dialog')
  },
  methods: {
    ...mapActions('autotracksManager', [
      'deleteRecord',
      'updateRecord',
      'validateRecord',
    ]),
    ...mapMutations('autotracksManager', ['setEditTarget', 'setWizardStep']),
    addCondition() {
      this.selectedConditions.push({
        id: uuid(),
        inclusive: true,
        operator: 'equal_to',
      })
    },
    clearConditionValue(condition) {
      delete condition.value
    },
    clearParams() {
      this.selectedParams = {}
    },
    conditionTemplate(condition) {
      return this.conditions.find((x) => x.name === condition.field)
    },
    conditionIsBoolean(condition) {
      if (!condition.field) {
        return false
      }

      const conditionDef = this.conditions.find(
        (x) => x.name === condition.field
      )

      if (conditionDef.manual_input) {
        return false
      }

      if (
        conditionDef.choices.length === 2 &&
        conditionDef.choices[0][1] === true &&
        conditionDef.choices[1][1] === false
      ) {
        return true
      }

      return false
    },
    conditionIsManual(condition) {
      if (!condition.field) {
        return false
      }
      const conditionDef = this.conditions.find(
        (x) => x.name === condition.field
      )
      return conditionDef.manual_input
    },
    conditionFieldCustomField(condition) {
      return condition.field === 'custom_field'
    },
    conditionFieldProperty(condition) {
      return condition.field === 'property_detail'
    },
    conditionFieldStandard(condition) {
      return (
        !this.conditionFieldProperty(condition) &&
        !this.conditionFieldCustomField(condition)
      )
    },
    conditionValueOptions(condition) {
      if (!condition.field) {
        return []
      }
      const conditionDef = this.conditions.find(
        (x) => x.name === condition.field
      )
      return conditionDef.choices.map(function (pair) {
        return {
          text: pair[0],
          value: pair[1],
        }
      })
    },
    deleteCondition(id) {
      this.selectedConditions = this.selectedConditions.filter(
        (x) => x.id !== id
      )
      this.emitChanges()
    },
    emitChanges() {
      this.$emit('autotrack_changed', this.rollup())
    },
    eventDisabled(e) {
      return e.disabled
    },
    flat(arr, d = 1) {
      // Because Edge doesn't do flat
      return d > 0
        ? arr.reduce(
            (acc, val) =>
              acc.concat(Array.isArray(val) ? this.flat(val, d - 1) : val),
            []
          )
        : arr.slice()
    },
    goToNext() {
      // Run validations
      this.step = this.trackMode ? this.step + 1 : this.lastStep
      if (this.step > this.lastStep) {
        this.lastStep = this.step + 0
      }
      if (this.lastStep === 3 && this.selectedAction === '') {
        this.selectedAction = 'send_email'
      }
      if (this.step === 4) {
        this.initializeParams()
      }
      this.emitChanges()
    },
    handleConditionFieldChange(condition) {
      this.clearConditionValue(condition)
      this.initConditionValue(condition)
      this.emitChanges()
    },
    humanize(s) {
      return inflect.humanize(s)
    },
    initConditionValue(condition) {
      if (this.conditionIsBoolean(condition)) {
        condition.value = true
      }
    },
    initializeParams() {
      if (Object.keys(this.selectedParams).length > 0) {
        return
      }
      this.selectedActionParams.map((param) => {
        this.selectedParams[param.name] = param.default_value
      })
    },
    loadTrack() {
      const track = JSON.parse(JSON.stringify(this.track))
      let foundWindow = false
      this.lastStep = this.wizardStep
      this.autoTrackGroupId = track.attributes.auto_track_group_id
      if (!track.id && !track.duplicate) {
        this.priority = track.attributes.priority
        return
      }

      this.trackId = track.id
      this.conditionsOperator = track.attributes.conditions_operator || 'AND'
      this.trackName = track.attributes.name || ''
      this.selectedAction = track.attributes.auto_action
      this.selectedEvent = track.attributes.auto_event
      this.selectedConditions = track.attributes.conditions
      this.selectedEventOffsetMultiplier =
        this.track.attributes.offset_multiplier || 3600
      this.selectedEventOffset =
        Math.abs(this.track.attributes.offset) /
        this.selectedEventOffsetMultiplier
      this.selectedParams = track.attributes.params
      this.priority = track.attributes.priority
      if ([true, false].includes(track.attributes.occurs_once)) {
        this.occursOnce = track.attributes.occurs_once
      } else {
        this.occursOnce = this.eventSpec.occurs_once
      }
      this.alwaysSend = track.attributes.always_send
      if (track.attributes.offset >= 0) {
        this.selectedEventOffsetModifier = 'after'
      }
      if (track.attributes.offset < 0) {
        this.selectedEventOffsetModifier = 'before'
      }
      if (this.selectedParams) {
        Object.keys(this.selectedParams).map((paramName) => {
          this.updateSelectedParamBody(paramName)
          this.updateSelectedParamSubject(paramName)
        })
      }
      this.useWindow = track.attributes.scheduling_options.use_window
      if (
        track.attributes.scheduling_options.window_start &&
        track.attributes.scheduling_options.window_end
      ) {
        const windowStart = track.attributes.scheduling_options.window_start
        const windowEnd = track.attributes.scheduling_options.window_end
        const windowStartIndex = windowTimes.findIndex((x) => x === windowStart)
        const windowEndIndex = windowTimes.findIndex((x) => x === windowEnd)
        if (
          windowStartIndex > -1 &&
          windowEndIndex > -1 &&
          windowStartIndex <= windowEndIndex
        ) {
          this.eventTimeWindow = [windowStartIndex, windowEndIndex]
          foundWindow = true
        }
      }
      if (!foundWindow) {
        this.eventTimeWindow = [defaultWindowStartIndex, defaultWindowEndIndex]
      }
      this.avoidWeekend = track.attributes.scheduling_options.avoid_weekend
      this.lastStep = 4
    },
    onClose(event) {
      this.setEditTarget(undefined)
      this.$emit('close')
    },
    onEventChange() {
      this.occursOnce = this.eventSpec.occurs_once
      this.selectedEventOffsetModifier = 'after'
      this.selectedConditions = this.selectedConditions.filter((condition) => {
        const conditionDef = this.conditions.find(
          (x) => x.name === condition.field
        )
        return (
          !conditionDef.limited_applicability ||
          conditionDef.applicable_events.includes(this.eventSpec.name)
        )
      })
      this.emitChanges()
    },
    optionizeParam(param) {
      return param.special_values
        .map((value) => {
          let text = value
          if (param.special_value_labels && param.special_value_labels[value]) {
            text = param.special_value_labels[value]
          }
          return { text, value }
        })
        .concat(param.choices || [])
    },
    paramColumnClass(param) {
      const classParts = ['bordered-row', 'col', 'param']
      if (param.column_class) {
        classParts.push(param.column_class)
      } else {
        classParts.push('s12')
      }
      return classParts.join(' ')
    },
    paramErrors(paramName) {
      return this.flat(
        this.validErrors
          .filter((x) => x.portion === 'params' && x.error === Object(x.error))
          .map((paramErrors) =>
            paramErrors.error
              .filter((x) => x.name === paramName)
              .map((x) => x.error)
          ),
        3
      )
    },
    placeholderPicked(value) {
      this.selectedParams.subject += value
    },
    rollup() {
      let type = 'auto_track'
      let offsetMod = 1
      if (this.track) {
        type = this.track.type
      }
      if (this.selectedEventOffsetModifier === '') {
        offsetMod = 0
      }
      if (this.selectedEventOffsetModifier === 'before') {
        offsetMod = -1
      }
      const selectedConditions = this.selectedConditions.filter((x) => {
        return x.field !== undefined && x.value !== undefined
      })

      const params = JSON.parse(JSON.stringify(this.selectedParams))
      Object.keys(params).forEach((k) => {
        if (typeof params[k] === 'object') {
          params[k] = params[k].map((p) => {
            if (typeof p === 'object') {
              return p.value
            } else {
              return p
            }
          })
        }
      })

      const payload = {
        type: type,
        attributes: {
          name: this.trackName,
          auto_action: this.selectedAction,
          auto_event: this.selectedEvent,
          company_id: this.companyId,
          conditions: JSON.parse(JSON.stringify(selectedConditions)),
          conditions_operator: this.conditionsOperator,
          auto_track_group_id: this.autoTrackGroupId,
          offset:
            this.selectedEventOffset *
            this.selectedEventOffsetMultiplier *
            offsetMod,
          offset_multiplier: this.selectedEventOffsetMultiplier,
          params: params,
          priority: this.priority,
          occurs_once: this.occursOnce,
          always_send: this.alwaysSend,
          scheduling_options: {
            avoid_weekend: this.avoidWeekend,
          },
        },
      }
      if (this.trackId) {
        payload.id = `${this.trackId}`
        payload.attributes.id = this.trackId
      } else {
        payload.attributes.active = true
      }
      if (type === 'auto_track_run') {
        payload.attributes.inspection_id = this.track.attributes.inspection_id
        delete payload.attributes.active
        delete payload.attributes.auto_track_group_id
        delete payload.attributes.occurs_once
      }
      if (
        this.useWindow &&
        this.eventTimeWindow[0] <= this.eventTimeWindow[1]
      ) {
        payload.attributes.scheduling_options.use_window = true
        payload.attributes.scheduling_options.window_start =
          windowTimes[this.eventTimeWindow[0]]
        payload.attributes.scheduling_options.window_end =
          windowTimes[this.eventTimeWindow[1]]
      }

      return payload
    },
    save(payload) {
      const vm = this
      this.validate()
        .then(() => {
          const rollup = this.rollup()
          if (vm.embedded) {
            vm.$emit('updatedRecord', rollup)
            vm.onClose()
          } else {
            vm.updateRecord(rollup).then(function (data) {
              vm.onClose()
            })
          }
        })
        .catch((errors) => {
          captureException(errors)
        })
    },
    updateSelectedParamBody(paramName) {
      let body
      this.selectedActionParams.map((sap) => {
        if (sap.name === paramName && sap.has_body) {
          sap.choices.map((choice) => {
            if (choice.value === this.selectedParams[paramName]) {
              body = `${choice.body}`
            }
          })
        }
      })
      if (body !== undefined) {
        this.selectedParamBody[paramName] = body
      }
    },
    updateSelectedParamSubject(paramName) {
      let subject
      this.selectedActionParams.map((sap) => {
        if (sap.name === paramName && sap.has_subject) {
          sap.choices.map((choice) => {
            if (choice.value === this.selectedParams[paramName]) {
              subject = `${choice.subject}`
            }
          })
        }
      })
      if (subject !== undefined) {
        this.selectedParamSubject[paramName] = subject
      }
    },
    validate() {
      const vm = this
      return new Promise((resolve, reject) => {
        this.validateRecord(this.rollup()).then((response) => {
          vm.validErrors = response.data
          if (
            vm.validErrors.length < 1 &&
            vm.selectedEventOffsetErrors.length < 1
          ) {
            vm.valid = true
            resolve()
          } else {
            reject(vm.validErrors)
          }
        })
      })
    },
  },
}
</script>
<style scoped lang="scss">
.bordered-row {
  > div {
    margin: 0px;
    background-color: #fbfbfb;
    border-radius: 3px;
    border: 1px solid #bbb;
    &.event {
      border-left: 4px solid #5c9ccf;
    }
    &.condition {
      border-left: 4px solid #45769c;
      &.message {
        font-size: 1.25em;
      }
    }
    &.action {
      border-left: 4px solid #f57c00;
    }
    &.param {
      border-left: 4px solid #388e3c;
      margin-bottom: 8px;
      padding: 0 8px;
    }
  }
  &.param {
    padding-left: 0;
    padding-right: 0;
  }
}

.condition-list .and-separator {
  font-weight: bold;
  font-size: 1.2em;
}

.condition-list > div:last-child .and-separator {
  display: none;
}
</style>

<style lang="scss">
.autotrack-wizard-dialog {
  .fr-box {
    background-color: white;
  }
  .v-messages__message {
    padding-bottom: 5px;
  }
  .conditions-operator-select {
    margin-top: 0px;
    padding-top: 0px;
    width: 100px;
    display: inline-block;

    .v-input__slot {
      margin-bottom: 0px;
    }

    .v-text-field__details {
      display: none;
    }
  }
}
</style>
