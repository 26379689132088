<template>
  <v-layout wrap center class="triggers">
    <v-flex hidden-sm-and-down md1 pa-1 />
    <v-flex xs12 sm11 md10 class="detail-container">
      <v-layout justify-center wrap>
        <v-flex xs12 md8 lg6 class="label-counter">
          <div class="label-columns">
            <div v-if="hasDeliverables" class="label-column">
              <div class="number fg-success">{{ deliverables.length }}</div>
              <div class="number-description light">
                {{ deliverableName
                }}{{ deliverables.length === 1 ? '' : 's' }} Sent
              </div>
            </div>
            <div class="label-column">
              <div class="number light">
                <div v-if="hasScheduled" class="fa-solid fa-check fg-success" />
                <div v-else class="fa-solid fa-circle-minus" />
              </div>
              <div class="number-description light">
                {{ hasScheduled ? 'Is Scheduled' : 'Not Scheduled' }}
              </div>
            </div>
            <div class="label-column">
              <div class="number light">{{ triggers.length }}</div>
              <div class="number-description light">Times Triggered</div>
            </div>
          </div>
        </v-flex>

        <v-flex xs12>
          <v-layout
            v-for="(trigger, idx) in triggers"
            :key="idx"
            :data-id="'auto-track-' + trigger.attributes.id"
            wrap
            div
            class="trigger-details"
          >
            <v-flex xs12>
              <div
                class="trigger-header light"
                :class="{ 'fg-success': trigger.attributes.is_fulfilled }"
              >
                Trigger {{ idx + 1 }}
              </div>
            </v-flex>
            <v-flex xs12 md6 class="trigger-messages">
              <div
                v-for="(detail, detailIdx) in dig(
                  trigger,
                  'attributes.details',
                  []
                )"
                :key="detailIdx"
                class="row light"
              >
                <div class="col s4 l3 left-align" :title="detail.time">
                  {{ detail.time | moment('from') }}
                </div>
                <div class="col s8 l9 left-align">
                  {{ detail.message }}
                </div>
              </div>
            </v-flex>
            <v-flex xs12 md6>
              <v-layout wrap>
                <template v-for="deliverable in deliverables">
                  <v-flex
                    v-if="
                      deliverable.attributes.object_id === trigger.attributes.id
                    "
                    :key="deliverable.id"
                    xs12
                    class="deliverable"
                  >
                    <v-layout align-center>
                      <v-flex xs4 md3 class="deliverable-status">
                        <div class="small">
                          <div :class="lastEventStatus(deliverable)">
                            <em
                              :class="
                                lastEventClass(
                                  deliverable.attributes.last_event
                                )
                              "
                            />
                            <span class="last-event">{{
                              deliverable.attributes.last_event || 'Sent At'
                            }}</span>
                          </div>
                          <div class="fg-blue">
                            {{ deliverable.attributes.sent_at_formatted }}
                          </div>
                          <div class="light">
                            {{ deliverable.attributes.sent_at_time_formatted }}
                          </div>
                        </div>
                      </v-flex>
                      <v-flex xs6 md7 class="deliverable-info fg-blue">
                        <div
                          v-if="deliverable.attributes.receiver"
                          class="left"
                        >
                          <img
                            class="thumb circle"
                            :src="deliverable.attributes.receiver.image_url"
                          />
                        </div>
                        <div class="small light capitalize">{{ runName }}</div>
                        <div class="small">
                          to:
                          {{
                            deliverable.attributes.recipient ||
                            deliverable.attributes.recipient_phone
                          }}
                        </div>
                        <div v-if="deliverable.attributes.cc" class="small">
                          cc: {{ deliverable.attributes.cc }}
                        </div>
                        <div
                          v-if="deliverable.attributes.subject"
                          class="small light"
                        >
                          {{ deliverable.attributes.subject }}
                        </div>
                      </v-flex>
                      <v-flex xs2 md2 class="deliverable-actions">
                        <a
                          :href="deliverableUrl(deliverable.id)"
                          target="_blank"
                          class="btn btn-blank btn-flat btn-small"
                        >
                          View
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </template>
                <!-- eslint-disable vue/no-template-shadow -->
                <v-flex
                  v-for="(trigger, idx) in scheduledForTrigger(trigger)"
                  :key="idx"
                  xs12
                  class="scheduled-deliverable deliverable"
                >
                  <!-- eslint-enable -->
                  <v-layout align-center>
                    <v-flex xs4 md3 class="deliverable-status">
                      <div class="small">
                        <div class="light">
                          <template v-if="trigger.attributes.is_locked">
                            <em class="fa-solid fa-lock" />
                            <span class="last-event">Disabled</span>
                          </template>
                          <template v-else>
                            <em class="fa-regular fa-clock" />
                            <span class="last-event">Scheduled</span>
                          </template>
                        </div>
                        <div class="fg-blue">
                          {{ trigger.attributes.runtime_date_formatted }}
                        </div>
                        <div class="light">
                          {{ trigger.attributes.runtime_time_formatted }}
                        </div>
                      </div>
                    </v-flex>
                    <v-flex xs6 md6 class="deliverable-info fg-blue">
                      <div class="small light capitalize">{{ runName }}</div>
                      <div v-if="hasDeliverables" class="small">
                        to:
                        {{
                          dig(trigger, 'attributes.params.to', []).join(', ')
                        }}
                      </div>
                      <div v-else class="small">
                        {{ dig(trigger, 'attributes.params.url', '') }}
                      </div>
                      <div
                        v-if="
                          trigger.attributes.cc &&
                          trigger.attributes.cc.length > 0
                        "
                        class="small"
                      >
                        cc:
                        {{
                          dig(trigger, 'attributes.params.cc', []).join(', ')
                        }}
                      </div>
                      <div
                        v-if="
                          trigger.attributes.bcc &&
                          trigger.attributes.bcc.length > 0
                        "
                        class="small"
                      >
                        bcc:
                        {{
                          dig(trigger, 'attributes.params.bcc', []).join(', ')
                        }}
                      </div>
                      <div
                        v-if="trigger.attributes.subject"
                        class="small light"
                      >
                        {{ trigger.attributes.subject }}
                      </div>
                    </v-flex>
                    <v-flex xs2 md3 class="deliverable-actions">
                      <div
                        class="btn btn-blank btn-flat btn-small"
                        @click="editTrack(trigger)"
                      >
                        edit
                      </div>
                      <v-tooltip top>
                        <template v-if="trigger.attributes.is_locked">
                          <div
                            slot="activator"
                            class="btn btn-blank btn-flat btn-small"
                            @click="toggleLock(trigger)"
                          >
                            enable
                          </div>
                          Let this trigger happen again. If this action only
                          happens once it will enable the action for this
                          inspection.
                        </template>
                        <template v-else>
                          <div
                            slot="activator"
                            class="btn btn-blank btn-flat danger btn-small"
                            @click="toggleLock(trigger)"
                          >
                            disable
                          </div>
                          Stop this trigger from happening. If this action only
                          happens once it will disable the action for this
                          inspection.
                        </template>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex hidden-sm-and-down md1 pa-1 />
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../../utils/AxiosService'
import dig from '../../utils/Dig'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['run', 'subruns'],
  data() {
    return {
      deliverables: [],
    }
  },
  computed: {
    ...mapState('autotracksManager', ['inspection']),
    activeScheduledTriggers() {
      return this.scheduledTriggers.filter((st) => !st.attributes.is_locked)
    },
    deliverableEndpoint() {
      return this.run.attributes.auto_action === 'send_email'
        ? 'emails'
        : 'text_messages'
    },
    deliverableName() {
      let label = ''

      switch (this.run.attributes.auto_action) {
        case 'send_email':
          label = 'Email'
          break
        case 'send_text':
          label = 'Text'
          break
        case 'call_webhook':
          label = 'Webhook'
          break
      }

      return label
    },
    badEvents() {
      return ['dropped', 'bounce', 'spamreport']
    },
    goodEvents() {
      return ['delivered', 'open', 'click']
    },
    hasDeliverables() {
      return ['send_email', 'send_text'].includes(
        this.run.attributes.auto_action
      )
    },
    hasScheduled() {
      return this.activeScheduledTriggers.length > 0
    },
    isFulfilled() {
      return (
        this.run.attributes.is_fulfilled ||
        this.subruns.find((sr) => sr.attributes.is_fulfilled)
      )
    },
    runName() {
      if (this.run.attributes.name) {
        return this.run.attributes.name
      } else {
        return this.run.attributes.auto_event.split('_').join(' ')
      }
    },
    reversedSubruns() {
      const arr = []
      for (let idx = this.subruns.length - 1; idx >= 0; idx--) {
        arr.push(this.subruns[idx])
      }
      return arr
    },
    scheduledTriggers() {
      if (this.subruns.length === 0) {
        if (
          this.run.attributes.runtime &&
          !this.run.attributes.is_fulfilled &&
          !this.run.attributes.is_failed
        ) {
          return [this.run]
        } else {
          return []
        }
      } else {
        return this.subruns.filter((s) => {
          return (
            s.attributes.runtime &&
            !s.attributes.is_fulfilled &&
            !s.attributes.is_failed
          )
        })
      }
    },
    triggers() {
      return this.reversedSubruns
        .concat([this.run])
        .filter((r) => dig(r, 'attributes.details', []).length > 0)
    },
  },
  mounted() {
    this.fetchDeliverables()
  },
  methods: {
    dig,
    editTrack(track) {
      this.$store.commit('autotracksManager/setWizardStep', 4)
      this.$store.commit('autotracksManager/setEditTarget', track)
    },
    emailBcc(email) {
      return dig(email, 'attributes.params.bcc', []).join(', ')
    },
    emailTimeFormatted(email) {
      if (email.email) {
        return email.email.attributes.sent_at_time_formatted
      } else {
        return email.attributes.runtime_time_formatted
      }
    },
    deliverableUrl(id) {
      if (this.run.attributes.auto_action === 'send_email') {
        return `/emails/${id}`
      } else {
        return `/text_messages/${id}`
      }
    },
    lastEventClass(lastEvent) {
      let klass

      switch (lastEvent) {
        case 'delivered':
          klass = 'fa-solid fa-envelope'
          break
        case 'open':
          klass = 'fa-solid fa-envelope-open'
          break
        case 'click':
          klass = 'fa-solid fa-mouse-pointer'
          break
        case 'dropped':
          klass = 'fa-solid fa-ban'
          break
        case 'bounce':
          klass = 'fa-solid fa-minus-circle'
          break
        case 'spamreport':
          klass = 'fa-solid fa-trash'
          break
        case 'scheduled':
          klass = 'fa-regular fa-clock'
          break
        case 'will send':
          klass = 'fa-regular fa-hand-point-right'
          break
        default:
          klass = 'fa-solid fa-check'
      }

      return klass
    },
    lastEventStatus(deliverable) {
      let klass = 'light'
      if (
        deliverable.attributes.is_failed ||
        this.badEvents.includes(deliverable.attributes.last_event)
      )
        klass = 'fg-warning'
      if (
        deliverable.attributes.is_success ||
        this.goodEvents.includes(deliverable.attributes.last_event)
      )
        klass = 'fg-success'
      if (deliverable.type === 'text_message') klass = 'fg-success'
      return klass
    },
    fetchDeliverables() {
      if (this.isFulfilled && this.hasDeliverables) {
        const params = axios.objectToQuery({
          view: 'receiver',
          inspection_id: this.inspection.id,
          object_type: 'AutoTrackRun',
          object_id: [this.run.id].concat(this.subruns.map((s) => s.id)),
        })

        axios
          .get(`api/v1/${this.deliverableEndpoint}?${params}`)
          .then((response) => {
            this.deliverables = response.data.data
          })
      }
    },
    scheduledForTrigger(trigger) {
      return this.scheduledTriggers.filter(
        (st) => st.attributes.id === trigger.attributes.id
      )
    },
    toggleLock(track) {
      const newTrack = JSON.parse(JSON.stringify(track))
      newTrack.attributes = { is_locked: !newTrack.attributes.is_locked }
      this.$store.dispatch('autotracksManager/updateRecord', newTrack)
    },
  },
}
</script>

<style lang="scss" scoped>
.triggers {
  justify-content: center;
}

.detail-container {
  background-color: #fff;
  border: 1px solid #ddd;
}
.detail-row {
  border-bottom: 1px solid #ddd;
}

.deliverables-container {
  display: flex;
  align-items: center;
}
.deliverable {
  padding: 0.5em;
}

.deliverable-status {
  text-align: left;
  .last-event {
    text-transform: capitalize;
  }
}

.deliverable-info {
  text-align: left;
  .thumb {
    height: 3em;
    width: 3em;
    vertical-align: middle;
  }
}

.deliverable-actions {
  text-align: right;
  margin-bottom: 0;
}

.trigger-header {
  font-size: 1.25em;
  text-align: left;
  text-decoration: underline;
  font-weight: normal;
}
.trigger .row {
  margin-bottom: 0;
}
.trigger-details {
  padding-bottom: 0.25em;
  margin-bottom: 0.25em;
  border-bottom: 1px solid rgba(221, 221, 221, 0.5);
}

.label-counter {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0.5em;

  .label-columns {
    border: 2px solid #ddd;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .label-column {
    display: inline-block;
    text-align: center;
    padding: 0.5em;
    border-right: 2px solid #ddd;
    width: 10em;

    .number {
      padding: 0;
      line-height: 1;
      font-size: 4em;
      font-weight: bold;
    }
    .number-description {
      font-size: 1em;
      text-align: center;
    }
  }

  .label-column:nth-child(3) {
    border-right: none;
  }
}

.trigger-details {
  padding: 1em;
  .row {
    margin-bottom: 0em;
  }
}

.trigger-messages {
  padding: 0.5em;
  border-right: 1px solid rgba(221, 221, 221, 0.5);
}

@media (max-width: 960px) {
  .triggers {
    font-size: 0.75em;
  }
  .trigger-messages {
    font-size: 1em;
    border-right: none;
  }
}
</style>
