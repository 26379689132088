import * as actions from './actions'
import * as mutations from './mutations'
import dig from '../../../utils/Dig'
import railsToMomentFormat from '../../../utils/railsToMomentFormat'

const getters = {
  dateFormat: (state) => (state) => {
    const format = dig(
      state,
      'company.attributes.settings.date_format',
      'MM/DD/YYYY'
    )
    return railsToMomentFormat(format)
  },
}

export default {
  namespaced: true,
  state: {
    autotrackName: 'Action',
    actions: [],
    actionsLoading: true,
    smsTemplates: [],
    smsTemplatesLoading: true,
    company: {},
    companyId: undefined,
    conditions: [],
    conditionsLoading: true,
    editTarget: undefined,
    events: [],
    eventsLoading: true,
    deletedRecords: [],
    errors: [],
    filter: { meetsConditions: true, isLocked: false },
    inspection: {
      type: 'inspection',
      attributes: {},
    },
    inspectionSlug: undefined,
    autoTrackRunId: undefined,
    showError: false,
    showSaved: false,
    trackMode: true,
    tracks: [],
    tracksLoading: true,
    trackGroups: [],
    trackGroupsLoading: true,
    userProfile: {
      attributes: {},
    },
    userProfileLoading: true,
    wizardStep: 1,
  },
  actions,
  getters,
  mutations,
}
