/* eslint-disable camelcase */

import railsService from '@/utils/AxiosService'

const getApi = (options = {}) => {
  const api = railsService.create({})
  api.defaults.timeout = 10000

  return api
}

// gets all the contractors (along with the quotes that might have already been submitted)
export const getReportContractorRecommendations = ({ report_id }) => {
  return getApi().get('/api/v1/public/report_contractors', {
    params: { report_id },
  })
}

// --------------------------------------------------
// --------------------------------------------------
// POST
// --------------------------------------------------
// --------------------------------------------------

export const postContractorLead = (params) => {
  return railsService.post('/api/v1/public/contractor_leads', {
    report_view: params.report_view,
    upsell_lead: params.upsell_lead,
  })
}
