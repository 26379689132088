import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function (date, format = 'MM/DD/YYYY') {
  return moment(date).format(format)
})

Vue.filter('formatDatetime', function (date, format = 'MM/DD/YYYY h:mm a') {
  return moment(date).format(format)
})

Vue.filter('truncate', function (value, size) {
  if (!value) return ''
  value = value.toString()

  if (value.length <= size) {
    return value
  }

  return value.substr(0, size) + '...'
})

Vue.filter('lowercase', function (value) {
  if (value === null) {
    return ''
  }

  return (value || '').toLowerCase()
})
