import config from '@/utils/config'

const RAILS_DOMAIN = config('RAILS_DOMAIN')

export const froalaOptions = {
  key: 'hWA2C-7I2C4C1B4E4D1A2wd1DBKSPF1WKTUCQOa1OURPJ1KDe2F-11D2C2D2E2C3B3D2D6D1F1==',
  attribution: false,
  pastePlain: true,
  useClasses: false,
  placeholderText: 'Enter text here',
  charCounterCount: false,
  videoResponsive: true,
  toolbarButtons: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    '|',
    'formatOL',
    'formatUL',
    '|',
    'align',
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
  toolbarButtonsMD: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    '|',
    'align',
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
  toolbarButtonsSM: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    '|',
    'align',
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],
  toolbarButtonsXS: [
    'inlineStyle',
    'bold',
    'italic',
    'underline',
    'textColor',
    'backgroundColor',
    '|',
    'align',
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    '|',
    'html',
    'clearFormatting',
  ],

  colorsText: [
    '#000000',
    '#222222',
    '#555555',
    '#888888',
    '#aaaaaa',
    '#dddddd',
    '#ffffff',
    '#d53636',
    '#f9890e',
    '#e5e318',
    '#65c635',
    '#5c9ccf',
    '#731eb5',
    'REMOVE',
  ],
  colorsBackground: [
    '#000000',
    '#222222',
    '#555555',
    '#888888',
    '#aaaaaa',
    '#dddddd',
    '#ffffff',
    '#d53636',
    '#f9890e',
    '#e5e318',
    '#65c635',
    '#5c9ccf',
    '#731eb5',
    'REMOVE',
  ],
  inlineStyles: {
    XLarge: 'font-size:2rem; color: inherit; line-height:2.25rem;',
    Large: 'font-size:1.5rem; color:inherit; line-height:1.7rem;',
    Normal: 'font-size:1rem; color: inherit; line-height: 1.2rem',
    Small: 'font-size: .8rem; line-height:1rem;',
    Light: 'color: #aaaaaa;',
    'Small/Light': 'font-size: .8rem; line-height:1rem; color: #aaaaaa;',
  },
  quickInsertButtons: ['ul', 'ol', 'hr'],
  pluginsEnabled: [
    'draggable',
    'align',
    'lineBreaker',
    'lists',
    'colors',
    'link',
    'image',
    'video',
    'table',
    'inlineStyle',
    'paragraphFormat',
    'paragraphStyle',
    'codeMirror',
    'codeView',
  ],
  height: 200,
  imageUploadURL: `https://${RAILS_DOMAIN}/editor_assets`,
  linkInsertButtons: ['linkBack'],
  videoInsertButtons: ['videoByURL', 'videoEmbed'],
  imageDefaultAlign: 'left',
  imageDefaultDisplay: 'inline',
  imageDefaultWidth: 200,
  imageEditButtons: [
    'imageAlign',
    'imageDisplay',
    'imageStyle',
    '-',
    'imageCaption',
    'imageAlt',
    'imageRemove',
    '-',
    'imageLink',
    'linkOpen',
    'linkEdit',
    'linkRemove',
  ],
  imageStyles: {
    circle: 'Circle',
    bordered: 'Border',
    shadowed: 'Shadow',
    margin: 'Margin',
    'padding-left': 'Padding Left',
    'padding-right': 'Padding Right',
  },
}

export const froalaOptionsSimple = Object.assign(
  JSON.parse(JSON.stringify(froalaOptions)),
  {
    toolbarButtons: [
      'inlineStyle',
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      '|',
      'formatOL',
      'formatUL',
      '|',
      'insertLink',
      'insertImage',
      'insertVideo',
      'insertTable',
      '|',
      'html',
      'clearFormatting',
    ],
    toolbarButtonsMD: [
      'inlineStyle',
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      '|',
      'formatOL',
      'formatUL',
      '|',
      'insertLink',
      'insertImage',
      'insertVideo',
      'insertTable',
      '|',
      'html',
      'clearFormatting',
    ],
    toolbarButtonsSM: [
      'inlineStyle',
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      '|',
      'insertLink',
      'insertVideo',
      '|',
      'html',
      'clearFormatting',
    ],
    toolbarButtonsXS: [
      'inlineStyle',
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      '|',
      'insertLink',
      'insertVideo',
      '|',
      'html',
      'clearFormatting',
    ],
  }
)

export const froalaOptionsAdvanced = Object.assign(
  JSON.parse(JSON.stringify(froalaOptions)),
  {
    imageEditButtons: [
      'imageReplace',
      'imageAlign',
      'imageRemove',
      '|',
      'imageLink',
      'linkOpen',
      'linkEdit',
      'linkRemove',
      '-',
      'imageDisplay',
      'imageStyle',
      'imageAlt',
      'imageSize',
    ],
    toolbarButtons: [
      'paragraphFormat',
      'paragraphStyle',
      'inlineStyle',
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      '|',
      'formatOL',
      'formatUL',
      '|',
      'align',
      'insertLink',
      'insertImage',
      'insertVideo',
      'insertTable',
      '|',
      'html',
      'clearFormatting',
    ],
    toolbarButtonsMD: [
      'paragraphFormat',
      'paragraphStyle',
      'inlineStyle',
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      '|',
      'align',
      'insertLink',
      'insertImage',
      'insertVideo',
      'insertTable',
      '|',
      'html',
      'clearFormatting',
    ],
    toolbarButtonsSM: [
      'paragraphFormat',
      'paragraphStyle',
      'inlineStyle',
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      '|',
      'align',
      'insertLink',
      'insertImage',
      'insertVideo',
      'insertTable',
      '|',
      'html',
      'clearFormatting',
    ],
    toolbarButtonsXS: [
      'paragraphFormat',
      'paragraphStyle',
      'inlineStyle',
      'bold',
      'italic',
      'underline',
      'textColor',
      'backgroundColor',
      '|',
      'align',
      'insertLink',
      'insertImage',
      'insertVideo',
      'insertTable',
      '|',
      'html',
      'clearFormatting',
    ],
  }
)

const bare = JSON.parse(JSON.stringify(froalaOptionsSimple))
const bareOptions = [
  'toolbarButtons',
  'toolbarButtonsMD',
  'toolbarButtonsSM',
  'toolbarButtonsXS',
  'quickInsertButtons',
  'pluginsEnabled',
  'linkInsertButtons',
  'videoInsertButtons',
  'imageEditButtons',
]
for (let i = 0; i < bareOptions.length; i++) {
  bare[bareOptions[i]] = []
}

export const froalaOptionsBare = bare
