import * as actions from './actions'
import * as mutations from './mutations'

export default {
  namespaced: true,
  state: {
    errors: [],
    notifications: [],
    savedMessage: null,
    successMessage: null,
    showError: false,
    showSaved: false,
    showSuccess: false,
    showWarning: false,
    warningMessage: null,
  },
  actions,
  mutations,
}
