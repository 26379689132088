import dig from '@/utils/Dig.js'

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const clientLabel = (state) =>
  dig(state, 'company.attributes.settings.client_label') || 'client'

export const clientLabelCapitalized = (state) => capitalize(clientLabel(state))

export const personTitles = (state) => {
  const client = clientLabelCapitalized(state)
  return {
    buyer: client,
    buyer_2: `${client} #2`,
    buying_agent: `${client}'s Agent`,
    selling_agent: 'Listing Agent',
  }
}
