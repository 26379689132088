import moment from 'moment'
import railsToMomentFormat from './railsToMomentFormat'

export default ({ value, format = 'MM/DD/YYYY' }) => {
  const momentFormat = railsToMomentFormat(format)

  if (!value) {
    return null
  }

  return moment(value).format(momentFormat)
}
