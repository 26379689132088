const meetsServiceBoundariesRequirements = (companyContractor) => {
  const contractorEligibility = companyContractor?.eligibility || {}

  if (!contractorEligibility.service_boundaries) {
    return true
  }

  const serviceBoundaries = contractorEligibility.service_boundaries

  if (!serviceBoundaries?.required) {
    return true
  }

  return serviceBoundaries?.eligible
}

const meetsQuoteRequestsRequirements = (companyContractor) => {
  const contractorEligibility = companyContractor?.eligibility || {}

  if (!contractorEligibility.quote_requests) {
    return true
  }

  const { eligible } = contractorEligibility.quote_requests

  return eligible
}

export default (companyContractor) => {
  const criteria = []

  criteria.push(meetsServiceBoundariesRequirements(companyContractor))
  criteria.push(meetsQuoteRequestsRequirements(companyContractor))

  return criteria.every((eligible) => eligible === true)
}
