<template>
  <v-layout :id="id" class="card-photos">
    <template v-if="reinspectionWithPhotos">
      <v-flex xs12 class="reinspection-photos">
        <v-layout wrap>
          <v-flex xs6 half>
            <photo
              :photo="originalPhotos[0]"
              :photo-index="0"
              class="original"
              @openGallery="openGallery"
            />
          </v-flex>
          <v-flex xs6 half>
            <photo
              :photo="
                photos.find(
                  (p) => p.attributes.source_id === originalPhotos[0].id
                )
              "
              :photo-index="1"
              @openGallery="openGallery"
            />
          </v-flex>
          <v-flex
            v-if="photoCount > 1"
            xs12
            class="more additional-photo"
            @click="openGallery({ index: 1, photo: null })"
          >
            + View {{ photoCount - 1 }} more photos
          </v-flex>
        </v-layout>
      </v-flex>
    </template>
    <template v-else>
      <v-flex
        :class="{
          xs12: photoCount === 1,
          'xs6 half': photoCount === 2,
          xs8: photoCount > 2,
        }"
      >
        <photo :photo="photos[0]" :photo-index="0" @openGallery="openGallery" />
      </v-flex>
      <v-flex
        v-if="photoCount > 1"
        :class="{
          'xs6 half': photoCount === 2,
          'xs4 additional-photos': photoCount > 2,
        }"
      >
        <photo
          v-if="photoCount === 2"
          :photo="photos[1]"
          :photo-index="1"
          @openGallery="openGallery"
        />
        <template v-else>
          <v-flex xs12 class="additional-photo">
            <photo
              :photo="photos[1]"
              :photo-index="1"
              @openGallery="openGallery"
            />
          </v-flex>
          <v-flex v-if="photoCount > 2" xs12 class="additional-photo">
            <photo
              :photo="photos[2]"
              :photo-index="2"
              @openGallery="openGallery"
            />
          </v-flex>
          <v-flex
            v-if="photoCount > 3"
            xs12
            class="more additional-photo"
            @click="openGallery({ index: 3, photo: null })"
          >
            + {{ photoCount - 3 }} more
          </v-flex>
        </template>
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import dig from '../../utils/Dig'
import Photo from './Photo.vue'
import { buildGalleryItem } from '@/utils'
import EventBus from '@/services/EventBus'
import config from '@/utils/config'

const LIGHT_GALLERY_KEY = config('LIGHT_GALLERY_KEY')

export default {
  components: {
    Photo,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['photos', 'id'],
  computed: {
    ...mapState('reports', ['reportView']),
    photoCount() {
      return this.photos.length
    },
    originalPhotos() {
      const sourceIds = this.photos.map((p) => p.attributes.source_id)
      return dig(this.reportView, 'attributes.observation_photos.data').filter(
        (p) => sourceIds.includes(p.id)
      )
    },
    reinspectionWithPhotos() {
      return (
        dig(this.reportView, 'attributes.type') === 'ReinspectionView' &&
        this.photos.find((p) => p.attributes.source_id) &&
        this.originalPhotos.length > 0
      )
    },
  },
  mounted() {
    EventBus.$on('closeGallery', this.closeGallery)

    this.$galleryId = document.getElementById(this.id)
    if (!this.$galleryId) return

    this.$galleryId.addEventListener('lgAfterClose', this.hidePanoButton)
    this.$galleryId.addEventListener('lgAfterSlide', (event) => {
      const { index } = event.detail
      const record = this.photos[index]

      if (record.attributes.is_pano) {
        this.$store.commit('reports/setValue', {
          key: 'currentPanoPhoto',
          value: record,
        })
        this.$store.commit('reports/setValue', {
          key: 'showPanoButton',
          value: true,
        })
        return
      }

      this.$store.commit('reports/setValue', {
        key: 'currentPanoPhoto',
        value: null,
      })
      this.$store.commit('reports/setValue', {
        key: 'showPanoButton',
        value: false,
      })
    })

    this.$gallery = window.lightGallery(this.$galleryId, {
      dynamic: true,
      zoom: true,
      licenseKey: LIGHT_GALLERY_KEY,
      numberOfSlideItemsInDom: 3,
      plugins: [window.lgZoom, window.lgVideo, window.lgThumbnail],
      dynamicEl: this.buildDynamicGallery(),
    })
  },
  methods: {
    hidePanoButton() {
      this.$store.commit('reports/setValue', {
        key: 'showPanoButton',
        value: false,
      })
    },
    closeGallery() {
      if (!this.$gallery) return

      this.$gallery.closeGallery(true)
    },
    openGallery({ index, photo }) {
      if (!this.$gallery) return

      if (!photo) {
        return this.$gallery.openGallery(index || 0)
      }

      if (photo.attributes.is_pano) {
        return this.$store.commit('reports/setValue', {
          key: 'overlayPhoto',
          value: photo,
        })
      }

      return this.$gallery.openGallery(index || 0)
    },
    photosWithOriginals() {
      const photos = []
      this.photos.forEach((photo) => {
        const original = this.originalPhotos.find(
          (p) => p.attributes.id === photo.attributes.source_id
        )
        if (original) {
          photos.push(original)
        }
        photos.push(photo)
      })
      return photos
    },
    buildDynamicGallery() {
      let photos = this.photos
      if (this.reinspectionWithPhotos) {
        photos = this.photosWithOriginals()
      }
      return photos.map((record) => {
        return buildGalleryItem(record)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-photos {
  height: 11em;
  max-height: 11em;
  min-height: 11em;
  border: 1px solid #fff;
}

.flex {
  overflow: hidden;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.half {
  &:first-child {
    border-right: 1px solid #fff;
  }
  &:last-child {
    border-left: 1px solid #fff;
  }
}

.additional-photos {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 2px solid #fff;

  .additional-photo {
    &:nth-child(1) {
      border-bottom: 1px solid #fff;
    }
    &:nth-child(2) {
      border-top: 1px solid #fff;
    }
    &:nth-child(3) {
      border-top: 2px solid #fff;
    }
  }
}

.more {
  width: 100%;
  color: #fff;
  background-color: #555;
  transition: opacity 0.1s linear;
  max-height: 2em;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.reinspection-photos {
  height: inherit;
  position: relative;

  > .layout {
    height: inherit;
  }

  .additional-photo {
    position: absolute;
    bottom: 0;
    height: 2em;
  }
}
</style>

<style lang="scss">
.card-photos {
  img:not(.materialboxed) {
    max-width: 250%;
    max-height: 250%;
  }

  .original img {
    filter: grayscale(100);
  }

  .additional-photo {
    width: 100%;
    height: 100%;
  }

  .photo-wrapper {
    height: inherit;
    width: inherit;
    position: relative;
    display: flex;
    justify-content: center;

    img {
      position: absolute;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }
  }
}
</style>
