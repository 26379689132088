<template>
  <div class="get-quotes-modal__body--defects">
    <h1 class="get-quotes-modal__body__title">
      What defects do you want estimated?
    </h1>
    <div class="get-quotes-modal__body__list">
      <div
        v-for="defect in availableDefects"
        :key="defect.id"
        class="get-quotes-modal__body__list__item"
      >
        <input
          :id="`${defect.id}--checkbox`"
          type="checkbox"
          :checked="selectedDefects.includes(defect.id)"
          :value="defect.id"
          :true-value="defect.id"
          @change="handleChange"
        />
        <div class="get-quotes-modal__body__list__item__about">
          <div class="get-quotes-modal__body__list__item__about__name">
            {{ defect.name }}
          </div>
          <div class="get-quotes-modal__body__list__item__about__description">
            {{ stripHTML(defect.text, 20) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stripHTML from '@/utils/stripHTML'

export default {
  props: {
    availableDefects: {
      type: Array,
      default: () => [],
    },
    selectedDefects: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    stripHTML,
    handleChange(e) {
      this.$emit('selectDefect', e, 'Defects')
    },
  },
}
</script>
