<template>
  <modal
    name="confirmation-dialog"
    height="auto"
    :width="width"
    :scrollable="true"
    @closed="onClose"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ _confirmHeader }}</h2>
      </div>
      <div class="modal-body">
        <slot name="confirmationBody">
          <p class="center-align">{{ queryText }}</p>
        </slot>
        <div class="center-align">
          <v-btn :color="_confirmColor" @click="onConfirm">{{
            _confirmText
          }}</v-btn>
          <v-btn @click="onClose">Cancel</v-btn>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['confirmColor', 'confirmText', 'headerText', 'queryText', 'width'],
  computed: {
    _confirmColor() {
      return this.confirmColor ? this.confirmColor : 'warning'
    },
    _confirmHeader() {
      return this.headerText ? this.headerText : 'Confirm'
    },
    _confirmText() {
      return this.confirmText ? this.confirmText : 'Ok'
    },
  },
  mounted() {
    this.$modal.show('confirmation-dialog')
  },
  beforeDestroy() {
    this.$modal.hide('confirmation-dialog')
  },
  methods: {
    onConfirm() {
      this.$emit('confirm')
    },
    onClose(event) {
      this.$emit('cancel')
    },
  },
}
</script>
