import Vue from 'vue'
import Vuex from 'vuex'

import autotracksManager from '../autotracksManager/store'
import inspection from '../inspection/store'
import notifier from '../notifier/store'
import reports from './store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    autotracksManager,
    inspection,
    notifier,
    reports,
  },
  state: {},
})
