<template>
  <div class="feedback-container">
    <img
      :src="spectoraLogo"
      class="spectora-logo"
      alt="Shows the Spectora logo"
    />
    <div v-if="pendingUserActions">
      <h1 class="feedback-title">How to access your report</h1>
      <h2 class="feedback-subtitle">
        To access your report, please ensure you have completed the following:
      </h2>
      <ul class="feedback-ul-list">
        <li>✔️ View and accept your Inspection Agreement</li>
        <li>✔️ Pay for your Inspection</li>
        <li>✔️ Your inspector has published it</li>
      </ul>
      <a :href="redirectURL" target="_blank" class="feedback-a-link">
        REVIEW WHAT'S PENDING
      </a>
    </div>
    <div v-if="reportNotPublished">
      <h1 class="feedback-title">Report in progress</h1>
      <h2 class="feedback-subtitle">
        Your report is still in progress and will be available once it is
        published 🚧
      </h2>
    </div>
  </div>
</template>

<script>
import config from '@/utils/config'
import spectoraLogo from '@/assets/spectora_logo.png'

const RAILS_DOMAIN = config('RAILS_DOMAIN')

export default {
  data() {
    return {
      spectoraLogo: spectoraLogo,
    }
  },
  computed: {
    redirectURL() {
      return `https://${RAILS_DOMAIN}/inspection/${this.$route.params.id}`
    },
    pendingUserActions() {
      return this.$route.name === 'report-feedback'
    },
    reportNotPublished() {
      return this.$route.name === 'report-not-published'
    },
  },
}
</script>

<style lang="scss" scoped>
.feedback-container {
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  text-align: center;
}
.spectora-logo {
  width: 64px;
  margin: 32px;
}
.feedback-title {
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}
.feedback-subtitle {
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
}
.feedback-ul-list {
  width: 668px;
  margin: 0 auto;
  text-align: left;
  padding: 0 32px;
}
.feedback-a-link {
  border-radius: 2px;
  margin: 32px;
  padding: 16px;
  background-color: #65c635;
  display: inline-block;
  color: white;
  cursor: pointer;
}
.feedback-a-link:hover {
  opacity: 0.9;
}
</style>
