<template>
  <modal
    id="publish-report-actions"
    name="publish-report-actions"
    :scrollable="true"
    width="80%"
    height="auto"
    @closed="close"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h2>Publish Report</h2>
      </div>
      <div class="modal-body">
        <v-layout v-if="isFirestore">
          <v-flex xs12 pb-2 class="strong">
            Before publishing from the web, click "Preview/Publish" in the
            Report Editor to save your changes (<a
              href="https://share.getcloudapp.com/4gulpdnz"
              target="_blank"
              >don't know where that is?</a
            >). If this is not clicked, your latest changes may not appear in
            the report.
          </v-flex>
        </v-layout>
        <div v-if="!inspectionRunsLoaded" class="loading-wrapper">
          <div class="loading">
            <div class="fa-solid fa-spinner fa-spin" />
            Loading Actions
          </div>
        </div>

        <v-layout v-else wrap>
          <v-flex xs12>
            <p class="explainer-text">
              Select the actions you want to trigger when publishing your
              Report.
              <br />
              If you don't want one of your actions to trigger make sure to turn
              it off!
            </p>
          </v-flex>

          <v-flex v-if="inspectionRuns.length > 0" xs12>
            <div v-if="todaysRuns.length > 0" class="expandable-section">
              <div
                class="expandable-header"
                :class="{ open: showToday }"
                @click="showToday = !showToday"
              >
                <div class="publish-category-header">
                  <i
                    class="fa-solid fa-chevron-right"
                    :class="{ rotate90: showToday }"
                  />
                  Actions going out in next 24hrs
                </div>

                <div class="track-count">
                  <template v-if="todaysRuns.length === 1"> 1 Action </template>
                  <template v-else-if="todaysRuns.length > 1">
                    {{ todaysRuns.length }} Actions
                  </template>
                </div>
              </div>
              <div v-if="showToday" class="run-container">
                <div v-for="run in todaysRuns" :key="run.id">
                  <auto-track
                    :track-group-id="1"
                    :track="run"
                    :company-id="company.id"
                    :embedded="true"
                    @updatedRecord="updatedRecord"
                  />
                </div>
              </div>
            </div>

            <div v-if="laterRuns.length > 0" class="expandable-section">
              <div
                class="expandable-header"
                :class="{ open: showLater }"
                @click="showLater = !showLater"
              >
                <div class="publish-category-header">
                  <i
                    class="fa-solid fa-chevron-right"
                    :class="{ rotate90: showLater }"
                  />
                  Actions going out in the future
                </div>
                <div class="track-count">
                  <template v-if="laterRuns.length === 1"> 1 Action </template>
                  <template v-else-if="laterRuns.length > 1">
                    {{ laterRuns.length }} Actions
                  </template>
                </div>
              </div>
              <div v-if="showLater" class="run-container">
                <div v-for="run in laterRuns" :key="run.id">
                  <auto-track
                    :track-group-id="1"
                    :track="run"
                    :company-id="company.id"
                    :embedded="true"
                    @updatedRecord="updatedRecord"
                  />
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="inspectionRunsLoaded && inspectionRuns.length === 0"
          justify-center
        >
          <p class="explainer-text">
            You have no Actions setup to trigger when Reports are published.
          </p>
        </v-layout>
        <v-layout v-if="inspectionRunsLoaded">
          <v-flex xs12 class="section center">
            <div class="btn unbutton btn-xlarge" @click="close">Cancel</div>
            <div class="btn btn-xlarge" @click="save">
              <div v-if="!saving" class="fa-regular fa-paper-plane" />
              <div v-else class="fa-solid fa-spinner fa-spin" />
              Save & Publish
            </div>
          </v-flex>
        </v-layout>
      </div>
      <wizard
        v-if="editTarget"
        :track="editTarget"
        :embedded="true"
        @close="closeWizard"
        @updatedRecord="updatedWizardRecord"
      />
    </div>
  </modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { captureException } from '@/utils'
import axios from '../../../utils/AxiosService'
import dig from '../../../utils/Dig'
import AutoTrack from '../../autotracks_manager/Track.vue'
import Wizard from '../../autotracks_manager/Wizard.vue'

export default {
  components: {
    AutoTrack,
    Wizard,
  },
  data() {
    return {
      filtered: false,
      inspectionRuns: [],
      inspectionRunsLoaded: false,
      saving: false,
      showLater: false,
      showToday: true,
    }
  },
  computed: {
    ...mapState('reports', ['company', 'inspection', 'report', 'userProfile']),
    ...mapState('autotracksManager', ['editTarget']),
    isFirestore() {
      return dig(this.report, 'attributes.is_firestore')
    },
    laterRuns() {
      return this.inspectionRuns.filter((ir) => {
        const o = ir.attributes.offset

        return o > 0 && ((o > 3600 && o !== 86400) || o > 3600 * 24)
      })
    },
    todaysRuns() {
      const laterRunIds = this.laterRuns.map((r) => {
        return r.attributes.id
      })

      return this.inspectionRuns.filter((ir) => {
        return !laterRunIds.includes(ir.attributes.id)
      })
    },
  },
  mounted() {
    this.$store.commit('autotracksManager/setUserProfile', this.userProfile)
    this.$store.commit('autotracksManager/setCompany', this.company)
    this.$modal.show('publish-report-actions')
  },
  created() {
    this.fetchActions()
    this.fetchSmsTemplates()
    this.fetchConditions()
    this.fetchEvents()
    this.fetchInspectionRuns()
  },
  methods: {
    ...mapActions('autotracksManager', [
      'fetchActions',
      'fetchSmsTemplates',
      'fetchConditions',
      'fetchEvents',
    ]),
    closeWizard() {
      this.$store.commit('autotracksManager/setEditTarget', null)
    },
    close() {
      this.filtered = false
      this.$modal.hide('publish-report-actions')
      this.$emit('close')
    },
    updateRuns() {
      return this.inspectionRuns.map((at) => {
        if (at.attributes.grantedId) {
          return axios.post('/api/v1/auto_track_runs', this.runParams(at))
        } else if (at.attributes.updated) {
          return axios.put(
            `/api/v1/auto_track_runs/${at.id}`,
            this.runParams(at)
          )
        }
      })
    },
    fetchInspectionRuns() {
      return axios
        .get(
          `/api/v1/auto_track_runs?inspection_id=${this.inspection.id}&auto_event=reports_published&meets_conditions=true`
        )
        .then((response) => {
          const runs = []

          response.data.data.forEach((ar) => {
            if (
              ar.attributes.auto_action !== 'disabled' &&
              !ar.attributes.parent_run_id
            ) {
              ar.attributes.active = !ar.attributes.is_locked
              ar.type = 'auto_track'
              runs.push(ar)
            }
          })

          this.inspectionRuns = runs
          this.inspectionRunsLoaded = true
        })
        .catch((error) => {
          captureException(error)
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: 'There was an error loading your actions.',
          })
        })
    },
    publishReport() {
      axios
        .put(`/api/v1/reports/${this.report.id}/publish`)
        .then((response) => {
          const updated = response.data.data.attributes
          const attributes = {
            published: updated.published,
            published_at: updated.published_at,
            published_at_formatted: updated.published_at_formatted,
          }

          this.$emit('change', attributes)
          this.$store.dispatch('notifier/show', {
            type: 'Saved',
            msg: 'Report Published!',
          })
          this.close()
        })
        .catch((error) => {
          captureException(error)
          this.$store.dispatch('notifier/show', {
            type: 'Error',
            msg: 'There was an error publishing this inspection.',
          })
        })
        .then(() => {
          this.saving = false
        })
    },
    save() {
      if (!this.saving) {
        this.saving = true
        return Promise.all(this.updateRuns())
          .then((response) => {
            this.publishReport()
          })
          .catch((error) => {
            captureException(error)
            this.saving = false
            this.$store.dispatch('notifier/show', {
              type: 'Error',
              msg: 'There was an error publishing this report.',
            })
          })
      }
    },
    runParams(autoTrack) {
      const run = {}

      run.inspection_id = this.inspection.id
      run.auto_track_id = autoTrack.attributes.id
      run.auto_event = autoTrack.attributes.auto_event
      run.conditions = autoTrack.attributes.conditions
      run.auto_action = autoTrack.attributes.auto_action
      run.is_locked = !autoTrack.attributes.active
      run.params = autoTrack.attributes.params
      run.offset = autoTrack.attributes.offset
      run.offset_multiplier = autoTrack.attributes.offset_multiplier
      run.scheduling_options = autoTrack.attributes.scheduling_options
      run.is_custom = true

      if (autoTrack.attributes.is_failed || autoTrack.attributes.is_fullfilled)
        run.runtime = new Date()

      return { attributes: run }
    },
    tempRecordId(record) {
      return [
        record.attributes.auto_event,
        record.attributes.auto_action,
        record.attributes.auto_track_group_id,
        record.attributes.params.to[0],
      ].join('-')
    },
    updatedRecord(record) {
      if (!record.id) {
        record.id = this.tempRecordId(record)
        record.attributes.grantedId = true
      } else {
        record.attributes.updated = true
        this.inspectionRuns.forEach((r) => {
          if (r.id === record.id) {
            r.attributes = record.attributes
          }
        })
      }
    },
    updatedWizardRecord(record) {
      record.attributes.active = true
      this.updatedRecord(record)

      if (this.inspectionRuns.find((at) => at.id === record.id)) {
        record.attributes.updated = true
        this.inspectionRuns.forEach((r) => {
          if (r.id === record.id) r.attributes = record.attributes
        })
      } else {
        this.inspectionRuns = this.inspectionRuns.concat([record])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.explainer-text {
  font-size: 1.25em;
}

.expandable-header {
  color: #888;
  padding: 0.5em;
  margin-top: 1em;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fa {
    &-solid,
    &-regular {
      font-size: 0.75em;
      transition: 0.1s;
    }
  }

  span {
    margin-left: 0.5em;
  }

  &.open {
    border-bottom: 1px solid #ddd;
  }
}

.track-count {
  height: 32px;
  border-radius: 28px;
  cursor: default;
  padding: 0 12px;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #888;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
}

.publish-category-header {
  cursor: pointer;
  font-size: 1.25em;
  font-weight: 300;
  display: inline-block;
}

.expandable-section {
  background-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.run-container {
  background-color: #fff;
  padding: 0.5em;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15em;

  .loading {
    font-size: 2em;

    .fa {
      &-solid,
      &-regular {
        margin-right: 0.25em;
      }
    }
  }
}
</style>
