const buildCaption = (record) => {
  return `
    <div class="gallery-caption-container">
      <h3 class="gallery-caption-text">
        ${record.attributes.caption || ''}
      </h3>
    </div>
  `
}

export const buildGalleryItem = (record) => {
  if (record.attributes.video) {
    return {
      video: {
        source: [{ src: record.attributes.video_url, type: 'video/mp4' }],
        attributes: { preload: false, controls: true },
        subHtml: buildCaption(record),
      },
      thumb: record.attributes.video_thumb,
    }
  }
  return {
    src: record.attributes.image,
    thumb: record.attributes.small_image_url,
    subHtml: buildCaption(record),
  }
}
