import normalizeCollection from './normalizeCollection'
import validateContractorEligibility from './validateContractorEligibility'

export default (
  numberedSections,
  reportContractors,
  recommendations,
  useAngi,
  eligibleContractorsOnly = true
) => {
  // Get all defects and contractors present for the report
  const reportDefects = normalizeCollection(
    numberedSections
      .map((section) => section.observations)
      .flat()
      .filter(
        (observation) =>
          !['limit', 'info'].includes(
            (observation.attributes || observation).comment_type
          )
      )
  )

  // Add additional properties to the contractors
  let contractors = normalizeCollection(reportContractors).map((contractor) => {
    return {
      ...contractor,
      showInReport: validateContractorEligibility(contractor),
    }
  })

  if (eligibleContractorsOnly) {
    contractors = contractors.filter((contractor) => contractor.showInReport)
  }

  // Get UNIQUE types of contractors we're looking for
  const reportContractorTypes = Array.from(
    new Set(
      reportDefects
        .filter(
          (defect) =>
            defect.recommendation_id !== null &&
            defect.recommendation_id !== undefined
        )
        .map((defect) => defect.recommendation_id)
    )
  )

  // Group defects by recommendation type
  const defectsByRecommendationType = reportDefects.reduce(
    (defectsMap, defect) => {
      const currentDefects = defectsMap.get(defect.recommendation_id) || []
      currentDefects.push(defect)
      defectsMap.set(defect.recommendation_id, currentDefects)
      return defectsMap
    },
    new Map()
  )

  // Build list of contractors by recommendation type
  const contractorsByRecommendationType = reportContractorTypes.reduce(
    (contractorsMap, contractorType) => {
      const availableContractors =
        contractors.filter((contractor) =>
          (contractor.contractor_types || []).includes(contractorType)
        ) || []
      const currentContractors = contractorsMap.get(contractorType) || []
      contractorsMap.set(contractorType, [
        ...availableContractors,
        ...currentContractors,
      ])
      return contractorsMap
    },
    new Map()
  )

  // Build the final megazord
  const normalizedRecommendations = reportContractorTypes
    .map((contractorType) => {
      const recommendation = recommendations.find(
        (recommendation) => recommendation.attributes.id === contractorType
      )
      const contractors = contractorsByRecommendationType.get(contractorType)
      const defects = defectsByRecommendationType.get(contractorType)

      if ((!useAngi && contractors.length === 0) || !recommendation) return null

      const displayableContractors = contractors.filter(
        (contractor) => contractor.showInReport
      )

      return {
        ...recommendation,
        contractors,
        defects,
        useAngi: useAngi && displayableContractors.length === 0,
      }
    })
    .filter((item) => item !== null)

  return normalizedRecommendations.filter((item) => item)
}
