import formatMomentDate from './formatDate'

const formatDate = (value, format) => {
  return formatMomentDate({ format, value })
}

const formatDatetime = (value, format) => {
  const datetimeFormat = `${format || 'MM/DD/YYYY'} hh:mm a`
  return formatMomentDate({ format: datetimeFormat, value })
}

const formatIndicator = (value) => {
  if (value === null) {
    return 'N/A'
  }

  return value ? 'Yes' : 'No'
}

export default ({ field, value, options = {} }) => {
  const responseFormat = field.response_format
  const dateFormat = (options || {}).dateFormat

  if (responseFormat === 'date') {
    return formatDate(value, dateFormat)
  }

  if (responseFormat === 'datetime') {
    return formatDatetime(value, dateFormat)
  }

  if (responseFormat === 'indicator') {
    return formatIndicator(value)
  }

  return value
}
