<template>
  <div class="defect-options">
    <div
      class="select"
      :class="{ selected: selected }"
      @click="toggleValue('selected')"
    >
      <div class="fa-solid fa-check" />
    </div>
    <div v-if="rrb && selected" class="repairs">
      <div class="repair-row">
        <div
          class="repair-option"
          :class="{ selected: addCredit }"
          @click="toggleValue('addCredit')"
        >
          <div class="fa-solid fa-dollar-sign" />
          Credit
        </div>
        <div
          class="repair-option"
          :class="{ selected: addComment }"
          @click="toggleValue('addComment')"
        >
          <div class="fa-regular fa-comment" />
          Comment
        </div>
      </div>
      <div v-show="addCredit || addComment" class="repair-form">
        <v-layout v-show="addCredit">
          <v-flex xs1 vpx-2>
            <div class="fa-solid fa-dollar-sign light" />
          </v-flex>
          <v-flex xs11 vpx-2>
            <v-text-field
              :id="`${observationId}-addCredit`"
              ref="addCredit"
              v-model="credit"
              type="number"
              placeholder="Enter amount requested"
              @keyup="update"
            />
          </v-flex>
        </v-layout>
        <v-layout v-show="addComment">
          <v-flex xs1 vpx-2>
            <div class="fa-regular fa-comment light" />
          </v-flex>
          <v-flex xs8 sm9 vpx-2>
            <froala
              :id="`${observationId}-addComment`"
              ref="addComment"
              v-model="comment"
              tag="textarea"
              :config="froalaConfig"
              @input="update"
            />
          </v-flex>
          <v-flex xs3 sm2 center>
            <v-tooltip top content-class="info-tooltip">
              <div
                slot="activator"
                class="btn btn-blank btn-flat"
                @click="comment += 'Repair Requested.'"
              >
                <div class="fa-solid fa-wrench" />
              </div>
              Add 'Repair Requested.'
            </v-tooltip>
            <br />
            <v-tooltip top content-class="info-tooltip">
              <div
                slot="activator"
                class="btn btn-blank btn-flat"
                @click="comment += 'Replacement Requested.'"
              >
                <div class="fa-solid fa-refresh" />
              </div>
              Add 'Replacement Requested.'
            </v-tooltip>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { froalaOptionsBare as froalaConfig } from '@/utils/froala'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['label', 'observationId'],
  data() {
    return {
      addComment: false,
      addCredit: false,
      comment: '',
      credit: null,
      froalaConfig: Object.assign(froalaConfig, {
        height: 75,
        placeholderText:
          'Enter comment text or use quick text buttons to the right',
      }),
      selected: false,
    }
  },
  computed: {
    ...mapState('reports', ['rrb', 'reportViewObservations']),
    observation() {
      return {
        observation_id: this.observationId,
        number: this.label,
        credit: this.credit,
        displayCredit: this.addCredit,
        request_text: this.comment,
        displayText: this.addComment,
        selected: this.selected,
      }
    },
  },
  watch: {
    reportViewObservations: {
      handler: function (newV, oldV) {
        this.updateReportViewObservationState({ newV, oldV })
      },
      deep: true,
    },
  },
  mounted() {
    this.updateReportViewObservationState({
      newV: this.reportViewObservations,
      oldV: [],
    })
  },
  methods: {
    toggleValue(key) {
      this[key] = !this[key]
      if (this[key] && this.$refs[key]) {
        setTimeout(() => {
          if (key === 'addCredit') {
            document.getElementById(`${this.observationId}-${key}`).focus()
          } else if (key === 'addComment') {
            this.$refs[key]._$editor.focus()
          }
        }, 250)
      }
      this.update()
    },
    updateReportViewObservationState({ newV, oldV }) {
      const obs = newV.find((o) => o.observation_id === this.observationId)
      const oldObs = oldV.find((o) => o.observation_id === this.observationId)

      if (obs && obs.selected) {
        this.selected = true
        if (!oldObs) {
          this.credit = obs.credit || ''
          this.comment = obs.request_text || ''
          this.addCredit = (obs.credit || '').length > 0
          this.addComment = (obs.request_text || '').length > 0
        }
      } else {
        this.selected = false
      }
    },
    update() {
      this.$store.dispatch(
        'reports/reportViewUpdateSelection',
        this.observation
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.defect-options {
  position: absolute;
  width: 100%;
  z-index: 3;
  padding: inherit;
  left: 0;
  top: 0;
}

.select {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  position: absolute;
  top: -1em;
  left: -1em;
  box-shadow: 1px 1px 5px #aaa;
  color: #ddd;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s linear;
  z-index: 0;

  .fa {
    &-solid,
    &-regular {
      font-size: 2em;
    }
  }

  &.selected {
    color: #fff;
    background-color: #65c635;
  }
}

.repairs {
  box-shadow: 0px 5px 7px #aaa;
}

.repair-row {
  margin-top: 2.75em;
  display: flex;
}

.repair-option {
  display: inline-block;
  width: 50%;
  color: #5c9ccf;
  border: 1px solid #5c9ccf;
  border-top: none;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  padding: 0.5em;

  &.selected {
    color: #fff;
    background-color: #65c635;
    border-color: #65c635;
  }
}

.repair-form {
  padding: 1em 0;
  background-color: #fff;

  .layout {
    align-items: center;

    .fa {
      &-solid,
      &-regular {
        &.light {
          font-size: 1.5em;
        }
      }
    }

    .btn-blank {
      height: 2em;
      padding: 0.5em;

      .fa {
        &-solid,
        &-regular {
          vertical-align: top;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .repair-row {
    margin-top: 4em;
  }
}
</style>

<style>
.defect-options .fr-element.fr-view {
  padding: 0 0.5em;
}
</style>
