<template>
  <div id="recommended-contractor-section" class="recommended-contractors">
    <div class="recommended-contractors-header">
      <i class="fa fa-hammer" />
      <h3 class="recommended-contractors-header__title">
        <recommended-contractors-title />
      </h3>
      <i
        v-if="isAdmin"
        class="fa fa-eye"
        title="RC Expert Mode"
        style="color: #e1c16e; cursor: grab"
        @click="toggleExpertMode"
      />
    </div>
    <div v-if="expertMode">
      <pre>
        {{ expertModeData }}
      </pre>
    </div>
    <div v-if="!emptyContractorsList" class="recommended-contractors-statement">
      <div v-if="customStatement">
        {{ company.attributes.settings.recommended_contractor_statement }}
      </div>
      <p v-else>
        For issues found during the inspection, you can get a repair estimate
        for any of these contractors.
      </p>
    </div>
    <div v-if="emptyContractorsList" class="recommended-contractors-empty">
      There aren't any contractors for this report.
    </div>
    <div class="recommended-contractors-container">
      <div
        v-for="recommendation in recommendationsList"
        :key="`recommended-contractors-for-${recommendation.id}`"
        class="recommended-contractors-recommendations"
      >
        <div class="recommended-contractors-recommendations-header">
          <div class="recommended-contractors-recommendations-header-info">
            <h3
              class="recommended-contractors-recommendations-header-info__title"
            >
              {{
                recommendation.attributes ? recommendation.attributes.name : ''
              }}
            </h3>
            <span
              class="
                italic
                recommended-contractors-recommendations-header-info__subtitle
              "
            >
              {{
                `${recommendation.defects.length} ${pluralizeWithCount(
                  recommendation.defects.length,
                  'associated defects'
                )}`
              }}
            </span>
          </div>
          <div class="recommended-contractors-recommendations-header-actions">
            <img
              v-if="
                useAngi(
                  recommendation.contractors,
                  recommendation.attributes.id
                )
              "
              src="@/assets/powered_by_angi.png"
              class="
                recommended-contractors-recommendations-header-actions__angi-logo
              "
            />
            <div
              v-if="
                useAngi(
                  recommendation.contractors,
                  recommendation.attributes.id
                ) ||
                canGetQuotes(
                  recommendation.contractors,
                  recommendation.attributes.id
                )
              "
              class="spectora-ds__button"
              :class="getQuotesButtonClasses(recommendation.contractors)"
              :data-recommendation-id="
                recommendation?.attributes?.id || 'no-recommendation'
              "
              @click="
                handleGetQuotesOpen(
                  recommendation.defects,
                  useAngi(recommendation.contractors)
                )
              "
            >
              Get quotes
            </div>
            <div
              v-if="
                !useAngi(
                  recommendation.contractors,
                  recommendation.attributes.id
                ) &&
                getEligibleContractors(recommendation.contractors).length > 0 &&
                !canGetQuotes(
                  recommendation.contractors,
                  recommendation.attributes.id
                )
              "
              class="
                spectora-ds__button
                spectora-ds__button--default
                spectora-ds__button--disabled
              "
            >
              Quotes Submitted
            </div>
          </div>
        </div>
        <div
          v-if="showIneligibleContractors(recommendation.contractors)"
          class="recommended-contractors-ineligible-contractors-warning"
        >
          <div
            class="
              recommended-contractors-ineligible-contractors-warning-message
            "
          >
            <i class="fa fa-info-circle" />
            <div>
              {{
                getIneligibleContractorsWarningMessage(
                  recommendation.contractors
                )
              }}
            </div>
          </div>
          <div
            class="
              spectora-ds__button
              spectora-ds__button--light
              spectora-ds__button--small
            "
            @click="handleIneligibleContractorsOpen(recommendation)"
          >
            View
          </div>
        </div>
        <recommended-contractor-card
          v-for="contractor in getEligibleContractors(
            recommendation.contractors
          )"
          :key="`recommended-contractor-${recommendation.id}-${contractor.id}`"
          :contractor="contractor"
          :recommendation-types="recommendationTypes"
          @learnMoreClicked="handleLearnMoreOpen"
        />
      </div>
      <get-quotes-modal
        v-if="defectsForQuotes.length > 0"
        :buyer="buyer"
        :section-defects="defectsForQuotes"
        :contractor-types="getQuotesModalContractors"
        :inspection-id="report.inspection_id"
        :inspector-id="primaryInspectorId"
        :report-id="report.id"
        :angi-recommendation="isAngiModal"
        :recommendations="recommendations"
        @closed="handleGetQuotesClosed"
        @submitted="formatRecommendedContractors"
      />
      <ineligible-contractors-modal
        v-if="isIneligibleContractorsModalOpen"
        :recommendation="ineligibleContractorsRecommendation"
        @closed="handleIneligibleContractorsClosed"
      />
      <learn-more-modal
        v-if="currentContractor"
        :contractor="currentContractor"
        @closed="handleLearnMoreClosed"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import pluralizeWithCount from '@/utils/pluralizeWithCount'
import formatAddress from '@/utils/formatAddress'
import formatPhone from '@/utils/formatPhone'
import formatContractorName from '@/utils/formatContractorName'
import buildContractorTypes from '@/utils/buildContractorTypes'

import GetQuotesModal from '@/components/recommended_contractors/modal/GetQuotesModal.vue'
import LearnMoreModal from '@/components/recommended_contractors/modal/LearnMoreModal.vue'
import RecommendedContractorsTitle from '../reports/RecommendedContractorsTitle.vue'

import RecommendedContractorCard from './RecommendedContractorCard.vue'
import IneligibleContractorsModal from './modal/IneligibleContractorsModal.vue'

export default {
  components: {
    GetQuotesModal,
    LearnMoreModal,
    RecommendedContractorCard,
    RecommendedContractorsTitle,
    IneligibleContractorsModal,
  },
  props: {
    numberedSections: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    currentContractor: null,
    recommendationsList: [],
    defectsForQuotes: [],
    isAngiModal: false,
    expertMode: false,
    expertModeData: undefined,
    ineligibleContractorsRecommendation: null,
    isIneligibleContractorsModalOpen: false,
  }),
  computed: {
    ...mapState('reports', [
      'company',
      'inspection',
      'observations',
      'report',
      'recommendations',
      'reportContractors',
      'recommendedContractors',
      'userProfile',
    ]),
    isAdmin() {
      return this.userProfile.type === 'admin'
    },
    isAuthenticatedUser() {
      return !!this.userProfile.type
    },
    buyer() {
      if (!this.inspection) {
        return {}
      }
      return this.inspection.attributes.buyer
        ? this.inspection.attributes.buyer.data
        : (this.inspection.buyers || [])[0]
    },
    customStatement() {
      return (
        this.company &&
        this.company.attributes.settings.recommended_contractor_statement
      )
    },
    hasContractorServiceAreasEnabled() {
      return this.$features.enabledFor(
        'recommended_contractors_service_boundaries',
        this.company
      )
    },
    recommendationTypes() {
      const recommendationTypes = this.recommendations.reduce(
        (recommendationTypesMap, type) => {
          recommendationTypesMap.set(type.attributes.id, type)
          return recommendationTypesMap
        },
        new Map()
      )
      return recommendationTypes
    },
    emptyContractorsList() {
      const companySettings = this.company.attributes.settings
      return (
        !companySettings.use_angi_network_contractors &&
        this.recommendationsList.filter(
          (recommendation) =>
            (
              recommendation.contractors ||
              recommendation.attributes.contractors ||
              []
            ).length > 0
        ).length === 0
      )
    },
    primaryInspectorId() {
      return this.inspection?.assignments?.find(
        ({ attributes }) => attributes?.primary
      )?.attributes?.inspector_id
    },
    getQuotesModalContractors() {
      return this.isAuthenticatedUser
        ? this.groupContractorsByRecommendation(true)
        : this.recommendationsList
    },
  },
  mounted() {
    this.formatRecommendedContractors()
    this.$root.$on('submitted', this.formatRecommendedContractors)
  },
  methods: {
    ...mapActions('reports', ['fetchRecommendedContractors']),
    pluralizeWithCount,
    formatAddress,
    formatPhone,
    formatContractorName,
    groupContractorsByRecommendation(eligibleContractorsOnly = true) {
      const companySettings = this.company.attributes.settings

      return buildContractorTypes(
        this.numberedSections,
        this.reportContractors,
        this.recommendations,
        companySettings.eligible_to_use_angi_network &&
          companySettings.use_angi_network_contractors,
        eligibleContractorsOnly
      )
    },
    formatRecommendedContractors() {
      // Clients will only see eligible contractors
      const eligibleContractorsOnly = !this.isAuthenticatedUser

      this.recommendationsList = this.groupContractorsByRecommendation(
        eligibleContractorsOnly
      )
    },
    handleGetQuotesOpen(defectsForQuotes, isAngiModal) {
      this.isAngiModal = isAngiModal
      this.defectsForQuotes = defectsForQuotes
    },
    handleGetQuotesClosed() {
      this.defectsForQuotes = []
    },
    async handleSubmitted(resp) {
      await this.fetchRecommendedContractors(this.report.id)
      this.formatRecommendedContractors()

      this.currentContractor = null
    },
    displayableContractorTypes(types) {
      const names = types
        .map((type) => {
          const availableType = this.recommendationTypes.get(type)
          if (availableType) {
            return availableType.attributes.name
          }
        })
        .filter((name) => name !== undefined && name !== null)
      return names.join(', ')
    },
    getQuotesButtonClasses(contractors) {
      return {
        'spectora-ds__button--default': !this.useAngi(contractors),
        'spectora-ds__button--angi': this.useAngi(contractors),
      }
    },
    useAngi(contractors, recommendationType = null) {
      if (!this.company.attributes.settings.use_angi_network_contractors) {
        return false
      }

      const UNSUPPORTED_CATS_BY_ANGI = [
        'diy',
        'pro',
        'monitor',
        'hoa',
        'utility',
        'seller',
      ]

      if (UNSUPPORTED_CATS_BY_ANGI.includes(recommendationType)) {
        return false
      }

      return this.getEligibleContractors(contractors).length === 0
    },
    canGetQuotes(contractors, contractorType) {
      const eligibleContractors = this.getEligibleContractors(contractors)

      const leadTypesSubmitted = eligibleContractors
        .map((contractor) => contractor.lead_types_submitted || [])
        .flat()
        .filter((leadType) => leadType === contractorType)

      return leadTypesSubmitted.length < eligibleContractors.length
    },
    handleLearnMoreOpen(contractor) {
      this.currentContractor = contractor
    },
    handleLearnMoreClosed() {
      this.currentContractor = null
    },
    toggleExpertMode() {
      if (this.expertMode) {
        this.expertMode = false
        this.expertModeData = undefined
      } else {
        // eslint-disable-next-line no-console
        console.log(this.groupContractorsByRecommendation(false))
        // Printing to console as the JS object cannot be stringified
        // due to circular references
        this.expertMode = true
        this.expertModeData = 'See browser console'
      }
    },
    getEligibleContractors(contractors) {
      // Only filter out contractors that actually have the property present
      // If missing or true, show them
      return (contractors || []).filter(
        (contractor) => contractor.showInReport !== false
      )
    },
    showIneligibleContractors(contractors) {
      return this.getIneligibleContractors(contractors).length > 0
    },
    getIneligibleContractors(contractors) {
      return (contractors || []).filter(
        (contractor) => !contractor.showInReport
      )
    },
    getIneligibleContractorsWarningMessage(contractors) {
      const ineligibleContractorsLength =
        this.getIneligibleContractors(contractors).length

      let label = "contractors aren't"

      if (ineligibleContractorsLength === 1) {
        label = "contractor isn't"
      }

      return `${ineligibleContractorsLength} ${label} displayed below or on your client's report.`
    },
    handleIneligibleContractorsOpen(recommendation) {
      this.ineligibleContractorsRecommendation = recommendation
      this.isIneligibleContractorsModalOpen = true
    },
    handleIneligibleContractorsClosed() {
      this.isIneligibleContractorsModalOpen = false
      this.ineligibleContractorsRecommendation = null
    },
  },
}
</script>

<style lang="scss">
.recommended-contractors {
  font-family: 'Inter', 'Open Sans', sans-serif;
  padding-top: 4.25em;

  &-statement,
  &-empty {
    color: #4b5563;
    padding: 1.5em 0 1.5em 3.25em;
  }

  &-header {
    margin-bottom: 1em;
    display: flex;
    align-items: center;

    > i {
      color: #9ca3af;
      font-size: 24px;
      padding: 0.25em;
      width: 1.5em;
      height: 1.5em;
    }

    &__title {
      margin-left: 0.625em;
      margin-bottom: 0;
      color: #1f2937;
      font-size: 22px;
      font-weight: 700;
      text-transform: none;
      letter-spacing: 0;
    }
  }

  &-container {
    background-color: white;
    border-top: 3px solid #438fcc;
    padding: 1.5em 3.625em;

    @media (max-width: 960px) {
      padding: 0;
    }
  }

  &-recommendations {
    padding: 1.5em 0;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e7eb;
    }

    &-header {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      padding: 1.25em 0 1em;
      gap: 1.5em;

      &-actions {
        position: relative;

        &__angi-logo {
          width: 120px;
          height: 20px;
          position: absolute;
          top: -28px;
          left: -60px;
          margin-left: 50%;
        }
      }

      &-info {
        flex: 1;

        &__title {
          color: #1f2937;
          font-weight: 600;
          font-size: 22px;
          margin-bottom: 0;
          text-transform: none;
          letter-spacing: 0;
        }

        &__subtitle {
          color: #374151;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    @media (max-width: 961px) {
      $root: &;

      &:last-child {
        #{$root}-item:last-child {
          margin-bottom: 3em;
        }
      }
    }
  }

  &-ineligible-contractors {
    &-warning {
      padding: 15px;
      margin: 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      background-color: #fef6ef;
      color: #144266;

      &-message {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }

    &-link {
      padding: 0 10px;
    }
  }
}
</style>
